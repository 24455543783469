export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Майбутні',
  finished: 'Готово',
  prizePool: 'Призовий фонд',
  joinNow: 'Приєднуйся зараз',
  joined: 'Приєднався',
  startsIn: 'Починається в',
  endsIn: 'Закінчується в',
  ended: 'Турнір завершено',
  started: 'Турнір розпочато',
  seeMore: 'Побачити більше',
  winPrizes: 'Вигравайте захоплюючі призи',
  leaderBoard: 'Таблиця лідерів',
  prize: 'Приз',
  aboutTournament: 'Про Турнір',
  congratulationsMessage: 'браво ',
  payments: 'Оплата',
  tournaments: 'Турніри',
  shop: 'Магазин',
  blog: 'Блог',
  referral: 'Направлення',
  contacts: 'Контакти',
  retry: 'Повторіть спробу',
  incorrectCredsMessage: "Ваше ім'я користувача або пароль неправильні. ",
  bonuses: 'Бонус',
  depositBonus: 'Бонус на депозит',
  voucher: 'Ваучер',
  gamlings: 'Азартні ігри',
  transactionHistory: 'Історія транзакцій',
  offers: 'Пропозиції',
  editProfile: 'Редагувати профіль',
  continue: 'Продовжити',
  apply: 'Застосувати',
  male: 'Чоловік',
  female: 'Жінка',
  register: 'зареєструватися',
  login: 'Логін',
  grand: 'Гранд',
  major: 'Майор',
  minor: 'незначний',
  mini: 'Міні',
  crashGameTitle: 'Збій',
  betAmount: 'Сума ставки',
  profit: 'Прибуток',
  payout: 'Цільова виплата',
  chance: 'Виграти шанс',
  betBtn: 'Ставка',
  autoCashout: 'Автоматичне виведення грошей',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Макс',
  wagered: 'Ставка',
  nothingFound: 'Нічого не знайдено',
  lost: 'Загублений',
  won: 'виграв',
  streak: 'Смуга',
  highestWin: 'Найвищий виграш',
  promotions: 'Акції',
  latestPromotions: 'Останні акції',
  sponsorships: 'Спонсорська допомога',
  highestLost: 'Найвищий втрачений',
  highestBet: 'Найвища ставка',
  maxStreak: 'Макс Стрік',
  maxProfit: 'Максимальний прибуток: ',
  bal: 'Бал: ',
  messagesNoBetHistory: 'Ваша історія ставок порожня',
  messagesCopyNotSupported: 'Ваш браузер не підтримує автоматичне копіювання в буфер обміну. ​​Будь ласка, спробуйте скопіювати код вручну.',
  messagesCopied: 'Адресу скопійовано.',
  deposit: 'депозит',
  statReset: 'Скинути статистику',
  rollOver: 'Перекинутися',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Назад',
  inputFieldBtnMax: 'макс',
  buttonTextPlaceBet: 'Зробити ставку',
  buttonTextCancelBet: 'Скасувати ставку',
  buttonTextEscapeBet: 'Обналичить',
  buttonTextStartAutoBet: 'Запустіть Autobet',
  buttonTextCancelAutoBet: 'Скасувати автоматичну ставку',
  buttonTextLoading: 'Завантаження',
  tabsMyBets: 'Мої ставки',
  tabsDeposit: 'депозит',
  tabsCashout: 'Виведення коштів',
  tabsAllbets: 'Всі ставки',
  tabsTopbets: 'Кращі ставки',
  tabsBonus: 'Бонуси',
  tabsjackpot: 'Джекпот',
  tabsBetHistory: 'Історія ставок',
  timerTextCurrentPayout: 'Поточна виплата',
  timerTextStarting: 'Починаючи з',
  timerTextSec: 'с',
  timerTextFlewAway: 'Розбився',
  timerTextWait: 'Будь ласка, зачекайте..',
  timerTextPreparingRound: 'Підготовка раунду',
  timerTextLoadingRound: 'Завантажувальний раунд',
  comingSoon: 'Незабаром',
  labelsProfitOnWin: 'Прибуток від виграшу',
  inputsNoOfBets: 'Кількість ставок',
  inputsAuto: 'Авто',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Почніть',
  inputsCancel: 'Скасувати',
  buttonsMin: 'Хв',
  buttonsMax: 'Макс',
  prediction: 'Прогнозування',
  luckyNumber: 'Щасливий номер',
  multiplier: 'Множник',
  chanceToWin: 'Шанс виграти',
  bets: 'Ставки',
  depositBtn: 'депозит',
  rollUnder: 'Roll Under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'історія',
  navBarMyBets: 'Мої ставки',
  navBarAllBets: 'Всі ставки',
  navBarHighRollers: 'Хайроллери',
  navBarRoundHistory: 'Кругла історія',
  navBarTotal: 'Загальні ставки',
  topHeaderBetId: 'ID ставки',
  topHeaderUser: 'Користувач',
  topHeaderPayout: 'Виплата',
  topHeaderAmount: 'Сума',
  topHeaderTime: 'час',
  topHeaderProfit: 'Прибуток',
  topHeaderRoundCrashed: 'Розбився',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Хеш',
  topHeaderRoundBet: 'Ставка',
  topHeaderRoundProfit: 'Прибуток',
  topHeaderRoundSignature: 'Підпис',
  roundHistoryTitle: 'ІНФОРМАЦІЯ ПРО ГРУ',
  roundHistoryDescGame: 'Гра',
  roundHistoryDescCrashAt: 'Збій о:',
  roundHistoryDescDate: 'Дата :',
  roundHistoryDescBetHeader: 'Ставка',
  roundHistoryTableTitlePlayer: 'ГРАВЕЦЬ',
  roundHistoryTableTitleBet: 'СТАВКА',
  roundHistoryTableTitleCashedOut: 'ГОТІВКА',
  roundHistoryTableTitleProfit: 'ПРИБУТОК',
  roundHistoryTableTitleBetId: 'ID СТАВКИ',
  roundHistoryMessagesNoBetHistory: 'Ваша історія ставок порожня',
  placedBetsTabPlaced: 'Зроблена ставка',
  placedBetsTabPrev: 'Попередня ставка',
  placedBetsTitleUser: 'Користувач',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Ставка',
  placedBetsTitleProfit: 'Прибуток',
  placedBetsTitleCashout: 'Обналичить',
  placedBetsNoPlacedBet: 'Жодних ставок.',
  provablyFairContentCheckBtn: 'Перевірте доказову справедливість',
  provablyFairContentTitle: 'Доведено справедливо',
  provablyFairContentDesc: 'Чотиристоронній результат може бути доведений як справедливий. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Частота збоїв',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Частота збоїв без x',
  provablyFairContentInputFieldsRoundHashTitle: 'Круглий хеш',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'напр. ',
  provablyFairContentInputFieldsSubmitBtn: 'Створити підпис',
  provablyFairContentSignature: 'Круглий підпис',
  provablyFairContentErrorsCrashRate: 'введіть число в поле частоти збоїв',
  resetPasswordTitle: 'Скинути пароль',
  resetPasswordNewPasswordTitle: 'Новий пароль',
  resetPasswordNewPasswordErrorsRequired: '*потрібен пароль.',
  resetPasswordNewPasswordErrorsMinLength: 'Допускається мінімум 8 символів.',
  resetPasswordNewPasswordErrorsMaxLength: 'Максимально дозволено 16 символів.',
  resetPasswordNewPasswordErrorsPattern: '*Пароль має містити від 8 до 16 буквено-цифрових і спеціальних символів. ',
  resetPasswordConfirmPasswordTitle: 'Підтвердьте пароль',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Паролі не збігаються.',
  resetPasswordResetBtn: 'Скинути',
  passwordResetSuccess: 'Пароль змінено успішно, увійдіть, щоб насолоджуватися грою!',
  resetPasswordWentWrong: 'Щось пішло не так!',
  levelGrand: 'ГРАНД',
  levelMajor: 'ОСНОВНИЙ',
  levelMinor: 'МІНОР',
  levelMini: 'МІНІ',
  bannerContentWinDaily: 'Вигравайте щодня $1000 ',
  bannerContentContest: 'Конкурси зараз ',
  bannerContentjoin: 'Приєднуйтесь до нашого щоденного конкурсу ставок і станьте Героєм!  ',
  bannerContentPlayNow: 'Грати зараз',
  bannerContentTitle: 'Виграти джекпот',
  bannerContentContent: 'Грайте більше',
  bannerContentGameName: 'КРАШ-ГРА',
  bannerContentGameDesc: 'Грайте більше',
  partners: 'Партнери',
  gamesGoldenDragon: 'Золотий Дракон',
  gamesCrashGame: 'Гра Crash',
  gamesDice: 'кубики',
  gamesHilo: 'Хіло',
  allBetsTitle: 'Всі ставки',
  allBetsBetId: 'ID ставки',
  allBetsUser: 'Користувач',
  allBetsPayout: 'Виплата',
  allBetsAmount: 'Сума',
  allBetsTime: 'час',
  allBetsProfit: 'Прибуток',
  LoginwithOtp: 'Увійдіть за допомогою Otp',
  EnterLoginOtp: 'Введіть Логін Otp',
  submit: 'Надіслати',
  footerAboutUs: 'Про нас',
  footerFaq: 'поширені запитання',
  footerSitePolicy: 'Політика сайту',
  footerResponsibleGambling: 'Відповідальна гра в азартні ігри',
  footerTerms: 'Правила та умови',
  signinTitle: 'Логін',
  signinUsername: "Ім'я користувача/Електронна пошта",
  signinPassword: 'Пароль',
  signinLoginBtn: 'Логін',
  signinSignup: 'Зареєструватися!',
  signinForgetPassword: 'Забули свій пароль?',
  forgotPasswordMsg: 'Введіть адресу електронної пошти, пов’язану з вашим обліковим записом, і ми надішлемо вам посилання для зміни пароля.',
  emailLinkMsg: 'Посилання для скидання пароля надіслано на вашу електронну адресу для реєстрації, будь ласка, натисніть посилання електронної пошти та встановіть новий пароль.',
  resetPasswardConfirm: 'Пароль успішно скинуто',
  signinDontHaveAccount: 'Немає облікового запису? ',
  signinCreateAccount: 'Створити акаунт',
  signinErrorsUserNameRequired: "* Будь ласка, введіть ім'я користувача/електронну адресу.",
  signinErrorsUserNameMinLength: 'Допускається мінімум 4 символи.',
  signinErrorsUserNameMaxLength: 'Максимально дозволено 20 символів.',
  signinErrorsUserNamePattern: "Введіть дійсне ім'я користувача, дозволені лише літери та цифри.",
  signinErrorsPasswordRequired: '*Потрібен пароль.',
  signinErrorsPasswordMinLength: 'Допускається мінімум 8 символів.',
  signinErrorsPasswordMaxLength: 'Максимально дозволено 16 символів.',
  signinErrorsPasswordPattern: '*Пароль має містити від 8 до 16 буквено-цифрових і спеціальних символів. ',
  signinSuccessfullyLoggedIn: 'Успішно ввійшли.',
  signinResetMailSent: 'Електронний лист для скидання пароля надіслано.',
  signinTryAgain: 'Щось пішло не так! Повторіть спробу.',
  signinResetPasswordUsernameErr: "Будь ласка, введіть ім'я користувача/електронну пошту, щоб скинути пароль.",
  signinResetMailSentSuccessContent: 'Ми надіслали вам посилання для зміни пароля на вашу електронну адресу. ',
  signupTitle: 'зареєструватися',
  signupUsername: "Ім'я користувача",
  signupEmail: 'Електронна пошта',
  signupTermsConditions: 'Умови',
  signupWalletConnect: 'Увійдіть за допомогою Wallet ',
  signupPassword: 'Пароль',
  signupEighteenPlus: 'Мені вже виповнилося 18 років, і я погоджуюся з Умовами використання',
  signupRegisterBtn: 'Реєстрація',
  signupCreateNewAccount: 'Створити новий обліковий запис',
  signupFullName: "Повне ім'я",
  signupReferralCode: 'Промо-Код',
  signupConfirmPassword: 'Підтвердьте пароль',
  signupUseSocialAccount: 'Вхід через соціальні мережі',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Є обліковий запис? ',
  signupLoginBtn: 'Логін',
  signupErrorsUserNameRequired: "* Будь ласка, введіть ім'я користувача.",
  signupErrorsUserNameMinLength: 'Допускається мінімум 4 символи.',
  signupErrorsUserNameMaxLength: 'Максимально дозволено 20 символів.',
  signupErrorsUserNamePattern: 'Введіть дійсне ім’я користувача, дозволені лише літери та цифри.',
  signupErrorsFullNameRequired: "* Будь ласка, введіть повне ім'я.",
  signupErrorsFullNamePattern: 'Введіть дійсне повне ім’я, дозволені лише літери та цифри.',
  signupErrorsEmailRequired: '* Будь ласка, введіть адресу електронної пошти.',
  signupErrorsEmailMinLength: 'Допускається мінімум 4 символи.',
  signupErrorsEmailMaxLength: 'Максимально дозволено 20 символів.',
  signupErrorsEmailPattern: 'Введіть дійсну адресу електронної пошти.',
  signupErrorsPasswordRequired: '*Потрібен пароль.',
  signupErrorsPasswordMinLength: 'Допускається мінімум 8 символів.',
  signupErrorsPasswordMaxLength: 'Максимальний депозит: 16 символів.',
  signupErrorsPasswordPattern: '*Пароль має містити від 8 до 16 букв і цифр (верхнього та нижнього регістру) та спеціальних символів. ',
  signupErrorsConfirmPasswordMatch: 'Пароль повинен збігатися з.',
  signupPlaceholderUserName: 'Хв. ',
  signupPlaceholderEmail: 'напр. ',
  signupPlaceholderPassword: 'Хв. ',
  signupSomethingWentWrong: 'Щось пішло не так!',
  signupSignupGoogle: 'Успішна реєстрація в Google.',
  signupRegistered: 'Успішно зареєстровано.',
  signupConfirmAge: 'Підтвердьте, що вам виповнилося 18 років.',
  signupSignupFacebook: 'Успішно зареєструйтеся на Facebook.',
  signupCookiesConsent: 'Будь ласка, дозвольте сторонні файли cookie з налаштувань вашого браузера, щоб використовувати вхід із соціальних мереж.',
  signupRegisterEmailPopupContent: 'Ми надіслали вам електронний лист для підтвердження. ',
  accountMenuDeposit: 'депозит',
  accountMenuCashout: 'Обналичить',
  accountMenujackpotWinners: 'Переможці джекпоту',
  accountMenuTransactions: 'транзакції',
  accountMenuPromotions: 'Акції',
  accountMenuProfile: 'Профіль',
  accountMenuLogout: 'Вийти',
  accountMenuSound: 'Звук',
  accountMenuMusic: 'музика',
  accountMenuWithdraw: 'Вилучити',
  pageNotFound: 'Ми не можемо знайти сторінку, яку ви шукаєте!',
  error: 'Помилка',
  somethingWentWrong: 'Щось пішло не так!',
  verifyEmailThankYou: 'Дякую тобі!',
  verifyEmailEmailIsVerified: 'Ваша електронна пошта підтверджена. ',
  verifyEmailGoToLogin: 'Перейдіть на сторінку входу',
  verifyEmailWentWrong: 'Щось пішло не так!',
  verifyEmailTokenExpired: 'Електронна адреса вже підтверджена або термін дії посилання закінчився, будь ласка, надішліть посилання для підтвердження електронної пошти ще раз, указавши свою зареєстровану електронну адресу нижче. ',
  verifyEmailGoToHome: 'Перейти на домашню сторінку',
  verifyEmailSuccessMsg: 'Електронну пошту успішно перевірено.',
  bonusSectionSpanText: 'дарує найщедріші та найрізноманітніші',
  bonusSectionBonuses: 'Бонуси',
  bonusSectionSubtitleText: 'Грайте із задоволенням і отримуйте додаткові винагороди під час гри. ',
  JoiningBonusTitle: 'Бонус за приєднання',
  JoiningBonusButton: 'Вимагайте зараз',
  JoiningBonusDesc: 'Такі бонуси заохочують гравців онлайн-казино грати в азартні ігри, використовуючи біткойн або інші криптовалюти. ',
  depositBonusTitle: 'Бонус на депозит',
  depositBonusButton: 'депозит',
  depositBonusDesc: 'Такі бонуси заохочують гравців онлайн-казино грати в азартні ігри, використовуючи біткойн або інші криптовалюти. ',
  refferBonusTitle: 'Реферальний бонус',
  refferBonusButton: 'депозит',
  refferBonusDesc: 'Такі бонуси заохочують гравців онлайн-казино грати в азартні ігри, використовуючи біткойн або інші криптовалюти. ',
  aboutSectionSpanText2: 'Онлайн-казино дозволяє грати в улюблені ігри безкоштовно або за криптовалюту. ',
  aboutSectionSpanText3: ' У цьому віртуальному світі азартних ігор Bitcoin ви можете насолоджуватися багатьма можливостями — грати в улюблені ігри казино, отримувати бонуси, брати участь у конкурсах і акціях.',
  verifiedSectionTitle: 'Перевірене та ліцензоване програмне забезпечення',
  verifiedSectionSpanText1: 'Ми не копіюємо та не приймаємо програмне забезпечення з інших веб-сайтів. ',
  faqSectionTitle: 'FAQ від гравців',
  faqSectionForgetPasswordTitle: 'Я забув свій пароль.',
  faqSectionForgetPasswordDesc: 'Ви не вказали адресу електронної пошти для відновлення свого облікового запису. ',
  faqSectionMyDepositCreditedTitle: 'Мій депозит не зараховано.',
  faqSectionMyDepositCreditedDesc: ' Контент-заповнювач для цього акордеона, призначений для демонстрації класу. ',
  SupportedCurrencyTitle: 'Підтримувана валюта',
  SupportedCurrencySpanText1: 'Ми не копіюємо та не приймаємо програмне забезпечення з інших веб-сайтів. ',
  CarouselSectionTitle: 'HODL CRASH',
  CarouselSectionSubTitle: ' Грайте більше ігор і отримайте шанс виграти. ',
  WalletSettingTitle: 'Налаштування гаманця',
  WalletSettingHideZero: 'Приховати нульовий баланс',
  WalletSettingHideZeroInfo: 'Ваш нульовий баланс не відображатиметься у вашому гаманці',
  WalletSettingDisplayFiat: 'Відображати криптовалюту у фіатній валюті',
  WalletSettingDisplayFiatInfo: 'Всі ставки',
  WalletSettingNoteForApprox: 'Зверніть увагу, що це приблизні значення валюти.',
  WalletSettingSave: 'зберегти',
  WalletSettingNoWalletFound: 'Гаманця не знайдено',
  jackpotWinnersNoWinnerAvailable: 'Немає переможців',
  jackpotWinnersNew: 'новий',
  jackpotWinnersHeader: 'Переможець джекпоту',
  jackpotDetailsHeader: 'Деталь джекпоту',
  jackpotDetailsCampaignPeriod: 'Період кампанії',
  jackpotDetailsFrom: 'Від',
  jackpotDetailsTo: 'до',
  jackpotDetailsBettingRule: 'Правило ставок',
  jackpotDetailsMinBetAmount: 'Мінімальна сума ставки',
  jackpotDetailsMaxBetAmount: 'Максимальна сума ставки',
  jackpotDetailsBetPercentage: 'Відсоток ставок',
  jackpotDetailsWinningRuleHeader: 'Правило перемоги',
  jackpotDetailsWinningRule: 'Ставки з більшою сумою підвищать шанси на виграш.',
  jackpotDetailsProfitRule: 'Якщо сума ставки становить від minBetAmount до maxBetAmount, тоді betPercentage% від суми ставки буде додано до суми джекпоту.',
  profileTitle: 'Особиста інформація',
  profileFName: "Ім'я",
  profileLname: 'Прізвище',
  profileUserName: "Ім'я користувача",
  profileDob: 'Дата народження',
  profileEmail: 'Адреса електронної пошти',
  profilePhone: 'Номер телефону',
  profileChange: 'Зміна',
  profileSave: 'Зберегти зміни',
  profilePassword: 'Пароль',
  profileSubmit: 'Надіслати',
  profileUploadProfile: 'Завантажити профіль',
  profileCurrentPassword: 'Поточний пароль',
  profileNewPassword: 'Новий пароль',
  profileConfirmPassword: 'Підтвердьте пароль',
  profileScanQRCode: 'Відскануйте QR-код',
  profileEnterSecretCode: 'Введіть секретний код',
  profileErrorsFirstNameRequired: "Ім'я обов'язкове.",
  profileErrorsFirstNamePattern: "Введіть дійсне ім'я.",
  profileErrorsFirstNameMinLength: 'Допускається мінімум 3 символи.',
  profileErrorsFirstNameMaxLength: 'Максимально дозволено 20 символів.',
  profileErrorsLastNameRequired: "Прізвище обов'язкове.",
  profileErrorsLastNamePattern: "Введіть дійсне ім'я.",
  profileErrorsLastNameMinLength: 'Допускається мінімум 3 символи.',
  profileErrorsLastNameMaxLength: 'Максимально дозволено 20 символів.',
  profileErrorsChangePasswordOldRequired: 'Потрібен старий пароль.',
  profileErrorsChangePasswordOldPattern: 'Введіть дійсний пароль.',
  profileErrorsChangePasswordOldMinLength: 'Допускається мінімум 8 символів.',
  profileErrorsChangePasswordOldMaxLength: 'Максимально дозволено 16 символів.',
  profileErrorsChangePasswordNewRequired: 'Потрібен новий пароль.',
  profileErrorsChangePasswordNewPattern: 'Введіть дійсний пароль.',
  profileErrorsChangePasswordNewMinLength: 'Допускається мінімум 8 символів.',
  profileErrorsChangePasswordNewMaxLength: 'Максимально дозволено 16 символів.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Паролі не збігаються.',
  profileErrorsChangePasswordNewPasswordMatched: 'Новий пароль не може збігатися з поточним.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Потрібно підтвердити пароль.',
  profileErrorsUserNameRequired: 'Потрібно вказати ім’я користувача.',
  profileErrorsUserNamePattern: "Введіть дійсне ім'я.",
  profileErrorsUserNameMinLength: 'Допускається мінімум 3 символи.',
  profileErrorsUserNameMaxLength: 'Максимально дозволено 20 символів.',
  profileErrorsDobRequired: 'Необхідно вказати дату народження.',
  profileErrorsEmailRequired: 'Необхідно вказати адресу електронної пошти.',
  profileErrorsPostcodeRequired: 'Необхідно вказати поштовий індекс.',
  profileErrorsCityRequired: 'Необхідно вказати місто.',
  profileErrorsCurrencyRequired: 'Потрібна валюта.',
  profileErrorsAddressRequired: 'Необхідно вказати адресу.',
  profileErrorsCountryRequired: 'Необхідно вказати країну.',
  profileErrorsEmailPattern: 'Введіть дійсну адресу електронної пошти.',
  profileErrorsEmailMinLength: 'Допускається мінімум 3 символи.',
  profileErrorsEmailMaxLength: 'Максимально дозволено 20 символів.',
  profileMessagesProfileUpdate: 'Профіль успішно оновлено.',
  profileMessagesChangePassword: 'Пароль успішно оновлено.',
  profileProfilePicProfilePicUpload: 'Завантажте зображення профілю',
  profileNoNewChanges: 'Не вдалося оновити профіль! ',
  profileLoggedInWithSocial: 'Ви увійшли за допомогою соціального облікового запису.',
  profileSideNavigatorSound: 'Звук',
  profileSideNavigatorMusic: 'музика',
  profileSideNavigatorProfile: 'Мій профіль',
  profileSideNavigatorBetHistory: 'Історія ставок',
  profileSideNavigatorTransactions: 'транзакції',
  profileSideNavigatorDeposit: 'депозит',
  profileSideNavigatorWithdraw: 'Вилучити',
  profileSideNavigatorGameLimits: 'Ігрові обмеження',
  profileSideNavigatorReferral: 'Направлення',
  profileSideNavigatorChangePassword: 'Змінити пароль',
  profileSideNavigatorTermsConditions: 'Терміни',
  profileSideNavigatorLogOut: 'Вийти',
  profileSideNavigatorProvablyFair: 'Доведено справедливо',
  WalletInfoSectionNotAvailable: 'Недоступний',
  WalletInfoSectionRealBalance: 'Справжній баланс',
  GameLimitsMinimumBet: 'Мінімальна ставка',
  GameLimitsMaximumBet: 'Максимальна ставка',
  GameLimitsMaxWinFor1Bet: 'Максимальний виграш з однієї ставки',
  ProvablyFairSubTitle: 'Ця гра використовує технологію Provably Fair для визначення результату гри. ',
  ProvablyFairMaximumBet: 'Максимальна ставка:',
  ProvablyFairMaxWinFor1Bet: 'Максимальний виграш з однієї ставки',
  promotionsTitle: 'Акції',
  promotionsNoLosingData: 'На жаль, доступні безпрограшні бонусні акції для ',
  promotionsNoDepositData: 'На жаль, бездепозитні бонусні акції доступні для ',
  promotionsCurrency: ' валюта.',
  promotionsViewBtn: 'Переглянути',
  promotionsClaimSuccess: 'Ви успішно подали заявку',
  promotionsAvailability: 'Наявність: ',
  promotionsAvailabilityTabLosing: 'Програшний бонус',
  promotionsAvailabilityTabDeposit: 'Бонус на депозит',
  promotionsDepositBonusTableCode: 'Код',
  promotionsDepositBonusTableMinDeposit: 'Хв. ',
  promotionsDepositBonusTablePercentage: 'Відсоток',
  promotionsDepositBonusTableMaxBonus: 'Максимальний бонус',
  promotionsDepositBonusTableRolloverMultipler: 'Множник перекидання',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Максимальний ролловер на ставку',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Час для досягнення мети перекидання',
  promotionsBonusCode: 'БОНУС КОД: ',
  promotionsLossesClaim: 'Збитки можуть бути заявлені (протягом періоду кампанії): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Відсоток',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Хв. ',
  promotionsBonusStatus: 'Статус бонусу: ',
  promotionsBonusAmount: 'Сума бонусу: ',
  promotionsRolloverTarget: 'Ціль перекидання: ',
  promotionsRolloverAchieved: 'Досягнуто перекидання: ',
  promotionsActivatebtn: 'АКТИВУВАТИ',
  promotionsClaimBtn: 'ПРЕТЕНЗІЯ',
  promotionsCancelBtn: 'СКАСУВАТИ',
  promotionsBackBtn: 'НАЗАД',
  promotionsBonus: 'Бонус',
  promotionsTermAndConditions: ' Терміни',
  promotionsClaimDepositBonusTitle: 'Кроки для отримання бонусу на депозит',
  promotionsClaimDepositBonusFirst: 'Активуйте бонусний код',
  promotionsClaimDepositBonusSecond: 'Сума депозиту на гаманець',
  promotionsClaimDepositBonusThird: 'Досягніть цілі перекидання для отримання бонусу',
  promotionsClaimLosingBonusTitle: 'Кроки для отримання програшного бонусу',
  promotionsClaimLosingBonusFirst: 'Активуйте бонусний код',
  promotionsClaimLosingBonusSecond: 'Грайте в улюблені ігри',
  promotionsClaimLosingBonusThird: 'Натисніть «Заявити про збитки», щоб вимагати збитки протягом періоду кампанії',
  promotionsWentWrong: 'Щось пішло не так!',
  transactionTitle: 'транзакції',
  transactionBetId: 'ID ставки',
  transactionUser: 'Користувач',
  transactionPayout: 'Виплата',
  transactionAmount: 'Сума',
  transactionProfit: 'Прибуток',
  transactionCashout: 'Обналичить',
  transactionBet: 'Ставка',
  transactionMultix: 'Multi.x',
  transactionWin: 'Виграти',
  transactionFairness: 'Справедливість',
  transactionReferFriend: 'Запропонуйте другу',
  transactionTotal: 'Загальні ставки',
  transactionWins: 'Перемоги',
  transactionRefresh: 'Оновити',
  transactionFilterTopMultipliers: 'Кращі множники',
  transactionFilterHugeWins: 'Величезні перемоги',
  transactionFilterBiggestWins: 'Найбільші перемоги',
  transactionFilterMultipliers: 'Множники',
  transactionRealBalance: 'Справжній баланс',
  depositWithdrawTXDate: 'Дата',
  depositWithdrawTXAmount: 'Сума',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Тип транзакції',
  depositWithdrawTXStatus: 'Статус',
  depositWithdrawTXNoData: 'Немає даних.',
  depositTitle: 'депозит',
  depositHistory: 'історія',
  depositSubTitle: 'Користувач не може вносити або знімати фіатну валюту. ',
  depositCopyNotSupported: 'Ваш браузер не підтримує автоматичне копіювання в буфер обміну. ​​Будь ласка, спробуйте скопіювати код вручну.',
  depositCopied: 'Адресу скопійовано.',
  depositAddressCreated: 'Адресу успішно створено.',
  depositGenerateAddress: 'Згенерувати адресу',
  depositWarningText: 'Будь ласка, НЕ вносьте депозит за допомогою міжланцюжкового переказу',
  withdrawTitle: 'Вилучити',
  withdrawSubTitle: 'Користувач не може вносити або знімати фіатну валюту. ',
  withdrawNoteDesc: 'Переконайтеся, що ви НЕ вводите адреси BEP2, BEP20 (BSC) як гаманці для виведення адрес LTC',
  withdrawNoteSublist: ' Будь ласка, НЕ знімайте через міжланцюговий переказ',
  withdrawHistory: 'історія',
  withdrawWithdrawBtn: 'Відкликати запит',
  withdrawFees: 'Комісія за зняття {{fees}} {{token}}',
  withdrawWithdrawPending: 'Ваш запит на відкликання очікує на розгляд. ',
  withdrawEnterFields: 'Введіть суму виведення та адресу свого гаманця.',
  withdrawSelectWallet: 'Виберіть гаманець для виведення.',
  withdrawAmountFieldTitle: 'Сума (мін. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'ваш {{wallet.currency.code}} адресу',
  withdrawAddressFieldPlaceholder: 'Введіть свою адресу',
  withdrawNoBalance: 'Не вистачає балансу.',
  withdrawMinAmount: 'Будь ласка, введіть суму, більшу або рівну {{min}} .',
  fiatCurrencyInfo: 'Користувач не може вносити або знімати фіатну валюту. ',
  paginationNextBtn: 'Далі',
  paginationPrevBtn: 'Попередній',
  wentWrong: 'Щось пішло не так.',
  referralTitle: 'Направлення',
  referralSubTitle: 'Запропонуйте своїм друзям',
  referralEarn: ' Отримайте по 150 рупій кожен',
  referralReferAndEarn: 'Зверніться та отримуйте бонус за кожного реферала',
  referralReferPara: ' Якщо ви посилаєтеся на цей код будь-якому користувачеві',
  referralReferLink: 'Зверніться за посиланням',
  referralCopyReferralCode: 'Скопіюйте реферальний код',
  referralTotalRewards: 'Загальна винагорода',
  TwoWayAuthentication: 'Двостороння автентифікація',
  LoginRequired: 'Необхідно ввійти!',
  BetPlacedSuccessfully: 'Ставку зроблено успішно',
  BetAddedInQueueSuccessfully: 'Ставка успішно додана в чергу',
  BetRemovedFromQueueSuccessfully: 'Ставку видалено з черги',
  BetCancelledSuccessfully: 'Ставку успішно скасовано',
  PlayerEscapedSuccessfully: 'Гравець успішно втік',
  CopiedSuccessfull: 'Скопійовано успішно',
  NotEnoughBalance: 'Не вистачає балансу',
  AutoBetStarted: 'Автоматична ставка почалася ',
  AutoBetFinished: 'Автоматична ставка завершена ',
  IdCopied: 'ID скопійовано',
  usersConsent: 'Переходячи на цей сайт, я підтверджую, що мені виповнилося 18 років, я ознайомився, зрозумів і погодився з',
  termsOfService: 'Умови використання',
  email: 'Електронна пошта',
  playNow: 'Грати зараз',
  orContinueWith: 'Або продовжити',
  metamaskExtensionNotAvailable: 'Установіть розширення metaMask і оновіть сторінку.',
  cashier: 'КАСИР',
  account: 'ОБЛІКОВИЙ ЗАПИС',
  hello: 'Привіт',
  balance: 'Баланс',
  referrals: 'Реферали',
  settings: 'Налаштування',
  withdrawals: 'Зняття коштів',
  demoWallet: 'Демо гаманець',
  myAccount: 'Мій рахунок',
  wallet: 'Гаманець',
  user: 'Користувач',
  USD: 'доларів США',
  amountIsRequired: 'Необхідна сума',
  minDepositAmount: 'Сума депозиту має бути більшою ніж {{min}}.',
  maxDepositAmount: 'Сума депозиту має бути менше ніж {{max}}.',
  minWithdrawAmount: 'Сума зняття має бути більшою ніж {{min}}',
  maxWithdrawAmount: 'Сума вилучення повинна бути меншою, ніж {{max}}',
  withdrawMustLessThanWallet: 'Сума виведення має бути меншою за суму вашого гаманця',
  accountTypeIsRequired: 'Потрібно вказати тип облікового запису.',
  mobileNumberLength: 'Номер мобільного телефону має бути від {{length}} цифри',
  phoneIsRequired: "Телефон обов'язковий",
  cpfError: 'CPF має бути з {{length}} персонажів',
  cpfRequiredError: 'CPF обов’язковий',
  cnpjError: 'CNPJ має бути від {{length}} персонажів',
  mainMenu: 'ГОЛОВНЕ МЕНЮ',
  casino: 'Казино',
  sports: 'спорт',
  crypto: 'Крипто',
  NFT: 'NFT',
  poker: 'Покер',
  earnRewards: 'ЗАРОБЛЯЙТЕ НАГОРОДИ',
  rankUp: 'Ранг вгору',
  inviteFriends: 'Запросити друзів',
  partnerProgram: 'Партнерська програма',
  home: 'додому',
  sportsbook: 'Спортивна книга',
  menu: 'Меню',
  liveBets: 'LIVE СТАВКИ',
  allBets: 'Всі ставки',
  highRollers: 'Хайроллери',
  luckyBets: 'Щасливі ставки',
  trade: 'Торгівля',
  game: 'ГРА',
  player: 'ГРАВЕЦЬ',
  time: 'ЧАС',
  wager: 'ПАРІ',
  multiplierBan: 'МНОЖНИК',
  payoutText: 'ВИПЛАТА',
  bannerHeadOne: 'Відчуйте справжні інновації з найвищим',
  bannerHeadTwo: 'програма винагород у галузі.',
  registerNow: 'Зареєструватися зараз',
  liveWins: 'ЖИВІ ПЕРЕМОГИ',
  monthly: 'Щомісяця',
  weekly: 'Щотижня',
  daily: 'Щоденно',
  winner1: 'Переможець 1',
  winner2: 'Переможець 2',
  winner3: 'Переможець 3',
  spin: 'Обертання',
  spinsWon: 'Виграні обертання',
  joinedOn: 'Приєднався',
  wageringAmount: 'Сума ставок',
  name: "Ім'я",
  allGames: 'Всі ігри',
  allSports: 'Всі види спорту',
  cryptoFutures: "крипто ф'ючерси",
  tradeNow: 'Торгуйте зараз',
  allTables: 'Всі таблиці',
  nft: 'NFT',
  viewAllGames: 'Переглянути всі ігри',
  viewAll: 'Бачити все',
  rewards: 'НАГОРОДИ',
  profile: 'Профіль',
  totalBets: 'Загальні ставки',
  totalWagered: 'Загальна ставка',
  joinDate: 'Дата приєднання',
  displaySocialLinks: 'Показати пов’язані облікові записи соціальних мереж',
  privateProfile: 'Приватний профіль',
  rank: 'ранг',
  winner: 'Переможці',
  nextRank: 'Наступний ранг',
  changeUsername: "Змінити ім'я користувача",
  newPassword: 'Новий пароль',
  changeName: 'Змінити назву',
  linkSocialAccount: 'Пов’язати соціальну сторінку',
  privateProfileTooltip: 'Після того, як ваш профіль стане приватним, інші користувачі не бачитимуть ваше ім’я користувача разом із ставками, гонками тощо.',
  socialLinksTooltip: 'Вміст недоступний',
  rankTooltip: 'Вміст недоступний',
  balances: 'залишки',
  createReferralBalance: 'Створіть реферальний баланс',
  total: 'Всього',
  switchBalance: 'Перемикач балансу',
  mainAccount: 'Основний рахунок',
  tips: 'Поради',
  receivedAmount: 'Отримана сума',
  dateOrTime: 'Дата, час',
  view: 'Переглянути',
  allChains: 'Всі ланцюги',
  creditAmount: 'Сума кредиту',
  setCustomReferralCode: 'Встановіть спеціальний код рефералів',
  changeEmail: 'Змінити електронну адресу',
  newEmail: 'Нова електронна пошта',
  createPassword: 'Створити пароль',
  newUsername: "Нове ім'я користувача",
  username: "Ім'я користувача",
  changePassword: 'Змінити пароль',
  updateProfileMsg: 'Профіль успішно оновлено',
  twoFactorAuthentication: 'двофакторна аутентифікація',
  twoFactorAuthenticationTitle: 'Настійно рекомендується використовувати двофакторну автентифікацію, оскільки вона захищає ваш обліковий запис паролем і телефоном.',
  twoFactorAuthenticationSubTitle: 'Для активації 2FA необхідно встановити електронну адресу та пароль. ',
  enable2fa: 'УВІМКНУТИ 2FA',
  verifyIdentityByKyc: 'Підтвердьте свою особу (kyc)',
  oldPassword: 'Старий пароль',
  live: 'Жити',
  soccer: 'футбол',
  tennis: 'теніс',
  fifa: 'ФІФА',
  basketball: 'Баскетбол',
  iceHockey: 'Хокей на льоду',
  volleyball: 'Волейбол',
  tableTennis: 'Настільний теніс',
  baseball: 'Бейсбол',
  rubgy: 'Регбі',
  golf: 'Гольф',
  boxing: 'Бокс',
  myBets: 'Мої ставки',
  favourites: 'Вибране',
  americanFootball: 'американський футбол',
  buyCrypto: 'Купуйте Crypto',
  oldEmail: 'Стара електронна пошта',
  loggedInSuccess: 'Успішно ввійшли',
  loggedOutSuccess: 'Успішно вийшов',
  wagerLimit: 'Ліміт ставок встановлено успішно',
  lossLimit: 'Ліміт втрат встановлено успішно',
  accountDisable: 'Обліковий запис успішно вимкнено',
  sessionTimeout: 'Час очікування сеансу встановлено успішно',
  signup: 'Успішна реєстрація',
  depositLimit: 'Ліміт депозиту встановлено успішно',
  tokenVerify: 'Токен надіслано на вашу електронну пошту, підтвердьте',
  emailVerify: 'Електронну адресу було перевірено',
  emailChangedSuccess: 'Електронну адресу успішно змінено',
  profileUpdatedSuccess: 'Профіль оновлено',
  otpVerified: 'OTP перевірено',
  twoFADeactivated: 'Автентифікацію 2FA вимкнено',
  internalServerError: 'Внутрішня помилка сервера',
  unAuthorized: 'Несанкціонований',
  dataNotFound: 'Дані не знайдені',
  setReferralCode: 'Установити реферальний код...',
  ultimate: 'ULTIMATE',
  notFound: 'Не знайдено',
  casinoTransactions: 'Транзакції казино',
  sportsbookTransactions: 'Транзакції спортивних ставок',
  status: 'Статус',
  success: 'Успіх',
  failed: 'Не вдалося',
  pending: 'В очікуванні',
  date: 'Дата',
  to: 'до',
  gameName: 'Назва гри',
  transactionId: 'ID транзакції',
  roundId: 'Круглий ID',
  bet: 'Ставка',
  win: 'перемога',
  transactionType: 'Тип транзакції',
  at: 'в',
  downloadAll: 'Завантажити всі',
  gameText: 'Гра',
  betId: 'ID ставки',
  gameId: 'ID гри',
  actionType: 'Тип дії',
  searchGames: 'Пошукові ігри',
  provider: 'Провайдери',
  search: 'Пошук',
  sortBy: 'Сортувати за',
  popular: 'Популярний',
  challengePool: 'Пул викликів',
  loadMore: 'Завантажити ще',
  bitcoin: 'Bitcoin',
  chat: 'Чат',
  trades: 'Торги',
  battles: 'Битви',
  loginToChat: 'Увійти в чат',
  rules: 'правила',
  chatRule1: 'Не переслідуйте та не ображайте інших користувачів',
  chatRule2: 'Не благайте, не просіть позики, дрібниці чи підказки',
  chatRule5: 'Не рекламуйте будь-яку форму торгівлі, купівлі чи продажу послуг',
  chatRule6: 'Не повідомляйте та не рекламуйте свій реферальний код',
  chatRule7: 'Не просіть стати співробітником',
  chatRule8: 'тільки англійська',
  chatRule9: 'Поважайте модів, адмінів та інших користувачів',
  send: 'Надіслати',
  expand: 'Розгорнути',
  signIn: 'Увійти',
  cross: 'Хрестик',
  collapse: 'Згорнути',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'Видалити з вибраного',
  addToFavourite: 'Додати в обране',
  footerAboutSite: 'Давайте трохи поговоримо про науку? Веб-сайт казино Cash Machine є частиною вас, оскільки ми всі складаємося з одних і тих же атомів. Ваше взаємодія з ним підтверджує це зв\'язок.',
  footerRightsReserved: 'Усі права захищені Сash Machine Casino.',
  signupFirstName: "Ім'я",
  signupAddress: 'Адреса',
  signupCity: 'Місто',
  signupPostcode: 'Поштовий індекс ',
  signupCounty: 'Країна',
  signupCurrency: 'Валюта',
  signupGender: 'Стать',
  signupMan: 'людина',
  signupWomen: 'жінки',
  signupOther: 'Інший',
  signupLoginDetails: 'Увійти в Деталі',
  signupPersonalDetails: 'Особиста інформація',
  signupConfirm: 'Підтвердити',
  signupPrivacyPolicy: 'Я прочитав і приймаю Політику конфіденційності',
  signupTermAndConditions: 'Я згоден на обробку моїх персональних даних. ',
  signupNewsLetter: 'Я хотів би отримувати бонуси, акції, новини чи іншу актуальну інформацію періодично через News Letter.',
  signupSms: 'Я хочу отримувати бонуси, акції, новини або іншу актуальну інформацію періодично в SMS.',
  initialHeaderContent: 'Отримайте дивовижні 100%',
  secoundaryHeaderContent: 'Вітальний бонус до $100',
  playNowButton: 'Грати зараз',
  registerButtonContent: 'зареєструватися',
  results: 'Результати',
  refresh: 'Оновити',
  cancel: 'Скасувати',
  select: 'Виберіть',
  current: 'поточний',
  set: 'встановити',
  remove: 'видалити',
  save: 'зберегти',
  not: 'ні',
  before: 'Раніше',
  after: 'Після',
  action: 'Дія',
  withdrawal: 'Вилучення',
  requested: 'Просив',
  withdraw: 'Вилучити',
  areYouSure: 'Ти впевнений',
  yes: 'Так',
  no: 'Немає',
  cancelled: 'Скасовано',
  approved: 'Затверджено',
  refunded: 'Повернено',
  chargeback: 'Повернення платежу',
  processedOn: 'Оброблено',
  transaction: 'Транзакція',
  dateTime: 'Дата, час',
  more: 'більше',
  fromDate: 'З дати',
  toDate: 'На сьогоднішній день',
  percentage: 'Відсоток',
  info: 'Інформація',
  min: 'Хв',
  max: 'Макс',
  change: 'Зміна',
  continueToSite: 'Продовжити на сайт',
  choose: 'Виберіть',
  enterWithdrawalAmount: 'Виберіть або введіть суму вилучення',
  capDeposit: 'ДЕПОЗИТ',
  capWithdrawal: 'ЗНЯТТЯ',
  capAmount: 'СУМА',
  minimum: 'мінімум',
  required: 'вимагається',
  of: 'з',
  isRequired: 'необхідно',
  isAllowed: 'isAllowed',
  method: 'метод',
  capWithdraw: 'ВИЙТИ',
  showLess: 'Показати менше',
  clearFilter: 'Очистити фільтр',
  quantity: 'Кількість',
  reset: 'Скинути',
  incorrect: 'Невірно',
  load: 'навантаження',
  applied: 'Прикладний',
  ok: 'в порядку',
  list: 'Список',
  all: 'все',
  favorite: 'улюблений',
  endPointNotFound: 'Щось не так з підключенням до мережі',
  category: 'Категорія',
  emailUserNameRequired: 'Потрібна електронна адреса/ім’я користувача',
  emailUserNamePlaceHolder: "Введіть адресу електронної пошти або ім'я користувача",
  passwordRequired: 'Потрібен пароль',
  accountFrozen: 'Ваш обліковий запис заблоковано до',
  resendEmail: 'Повторно надіслати електронний лист',
  resendLink: 'ПОВТОРНО НАДІСЛАТИ ПОСИЛАННЯ',
  userLoggedOut: 'Успішний вихід',
  emailAlreadyRegistered: 'Цей імейл вже зареєстрований',
  userNameAlreadyTaken: "Це ім'я користувача вже зайняте",
  fillAllFields: 'Заповніть усі поля перед переходом до наступного кроку',
  pleaseAccept: 'Будь ласка, прийміть',
  acceptPrivacyPolicy: 'Політика конфіденційності',
  acceptTerms: 'Терміни і умови',
  modeOfComm: ' і спосіб спілкування',
  beforeNextStep: 'перед переходом до наступного кроку',
  userNamePlaceholder: "Введіть ім'я користувача",
  min8Characters: 'Мінімум 8 символів',
  min1Number: 'Мін. 1 число',
  min1LowerCase: 'Мінімум 1 символ нижнього регістру',
  min1UpperCase: 'Мінімум 1 символ верхнього регістру',
  min1SpecialChar: 'Мінімум 1 спеціальний символ',
  passwordTip: 'Пароль має містити принаймні вісім символів, принаймні одну велику літеру, одну маленьку літеру, одну цифру та один спеціальний символ',
  passwordPlaceholder: 'Введіть пароль',
  confirmPasswordPlaceholder: 'Введіть пароль підтвердження',
  acceptAll: 'Прийняти всі',
  firstNamePlaceholder: "Введіть ім'я",
  lastNamePlaceholder: 'Введіть прізвище',
  addressPlaceholder: 'Введіть адресу',
  cityPlaceholder: 'Введіть місто',
  postCodePlaceholder: 'Введіть поштовий індекс',
  phonePlaceholder: 'Введіть номер телефону',
  invalidEmail: 'InvalidEmail',
  emailRequired: 'Потрібна електронна пошта',
  confirmPasswordRequired: 'Потрібно підтвердити пароль',
  firstNameRequired: "Ім'я обов'язкове",
  lastNameRequired: "Прізвище обов'язкове",
  phoneRequired: 'Потрібен телефон',
  dateOfBirthRequired: 'Обов’язкова дата народження',
  genderRequired: 'Необхідно вказати стать',
  userNameRequired: 'Обов’язкове ім’я користувача',
  addressRequired: 'Потрібна адреса',
  cityRequired: 'Обов’язково місто',
  postCodeRequired: "Поштовий індекс обов'язковий",
  currencyCodeRequired: 'Необхідний код валюти',
  countryRequired: "Країна обов'язкова",
  matchPassword: 'Пароль повинен збігатися з',
  fName3Chars: "Ім'я має містити принаймні 3 символи",
  onlyAlphabet: 'Дозволені лише алфавіти',
  lName3Chars: 'Прізвище повинно містити не менше 3 символів',
  mustBe18: 'Вам має бути 18 років, щоб зареєструватися',
  mustBeUnder200: 'Щоб зареєструватися, вам має бути менше 200 років',
  validDOB: 'Введіть дійсну дату народження',
  max20Characters: 'Дозволено максимум 20 символів',
  min3Characters: 'Потрібно мінімум 3 символи',
  max100Characters: 'Дозволено максимум 100 символів',
  max50Characters: 'Дозволено максимум 50 символів',
  invalidNumber: 'Недійсний номер',
  preferredLanguageRequired: 'Обов’язкова мова',
  invalidPass: 'Невірний пароль',
  prevBtn: 'Попередній',
  dayRequired: 'Потрібно вказати день',
  monthRequired: 'Необхідно вказати місяць',
  yearRequired: 'Необхідно вказати рік',
  uploadImage: 'Завантажити зображення',
  removeImage: 'Видалити зображення',
  preferredLanguage: 'Бажана мова',
  wantToChangePass: 'Ви хочете змінити пароль?',
  selectCountryCode: 'Виберіть код країни',
  selectPreferredLanguage: 'Виберіть бажану мову',
  fName50Chars: "Ім'я має містити щонайменше 50 символів",
  lName50Chars: 'Прізвище має містити не більше 50 символів',
  invalidDate: 'Недійсна дата',
  DOBEarlyThanToday: 'Дата народження має бути раніше, ніж сьогодні',
  enterValidPhone: 'Введіть дійсний номер телефону',
  accountStatus: 'Статус рахунку',
  kycVerified: 'Ваш KYC успішно підтверджено.',
  denied: 'Відмовлено',
  chooseFile: 'Виберіть файл',
  upload: 'Завантажити',
  countryCantChange: 'Ви збираєтеся розпочати перевірку KYC. ',
  proceed: 'Продовжуйте',
  update: ' ',
  history: 'історія',
  type: 'Тип',
  amount: 'Сума',
  forfeited: 'Конфіскований',
  expired: 'Термін дії минув',
  completed: 'Виконано',
  zeroedOut: 'Обнулено',
  active: 'Активний',
  lapsed: 'Закінчився',
  yourActiveBonus: 'Ваші активні бонуси',
  currentActiveBonus: 'Поточні активні бонуси',
  bonusStatus: 'Статус',
  bonusWager: 'ставка',
  bonusActivate: 'активувати',
  bonusForfeit: 'Бонусне погашення',
  notUsableBonus: 'Якщо так, то ви більше не зможете використати цей бонус.',
  bonusLapsed: 'У вас недостатньо втрат, щоб отримати кешбек. ',
  inProcess: 'В процесі',
  claiming: 'Вимагаючи',
  loyaltyPopover: 'Ці бали лояльності можна використати для отримання кешбеку. ',
  loyaltyPage: 'Сторінка лояльності',
  loyaltyPerCurrency: 'Лояльність до валюти',
  maxLevelReached: 'Досягнуто максимального рівня',
  pointsToReach: 'очок для досягнення ',
  loyaltyBannerHeading: 'Ви заслуговуєте на нашу нову лояльність',
  loyaltyBannerDesc: '100% вітальний бонус до $200',
  loyaltyHeadingOne: 'Ви завжди отримуєте винагороду в CashMachine',
  loyaltyHeadingOneDesc: 'У CashMachine ми виводимо лояльність на абсолютно новий рівень захоплення, обсипаючи вас винагородами за кожен поворот! ',
  loyaltySubHeadOne: 'Нарахування балів лояльності',
  loyaltySubHeadOneDesc: 'За кожні 10 євро готівкових ставок, зроблених у нашому казино, ви отримаєте 1 бал лояльності. ',
  loyaltySubHeadTwo: 'Сходження по сходах вірності',
  loyaltySubHeadTwoDesc: 'Чим більше очок ви накопичите, тим вище ви підійметеся через рівні лояльності, відкриваючи ще більше фантастичних нагород на цьому шляху. ',
  loyaltySubHeadThree: 'Підвищуйте рівень, отримуйте винагороду ',
  loyaltySubHeadThreeDesc: 'Зробіть свій ігровий досвід кращим, переходячи на наші привабливі рівні винагороди, де кожен новий рівень відкриває захоплюючий набір безкоштовних обертань, щоб максимізувати ваші шанси на перемогу. ',
  loyaltyTestimonialHeadOne: 'У КАЗИНО CashMachine МИ ПЕРЕКОНАЄМО ЦЕ',
  loyaltyTestimonialHeadTwo: 'Кожен оберт має значення',
  loyaltyTestimonialDesc: 'Кожне обертання, яке ви робите в слотах, не тільки для розваги, але й наближає вас до справді приємних нагород! ',
  loyaltyPoints: 'Очки',
  headerLevel: 'рівень',
  loyaltyCashback: 'Кешбек',
  loyaltyBannerBtn: 'Перевірити прогрес',
  loyaltyHeadingTwo: 'Розблокуйте неперевершений ігровий досвід: VIP-програма CashMachine',
  loyaltyHeadingTwoDesc: 'Досягнувши рівня 6 у нашій програмі лояльності, ви відкриєте світ ексклюзивних привілеїв як учасник престижної VIP-програми CashMachine. ',
  loyaltySubHeadFour: 'Вищі ліміти депозитів і зняття',
  loyaltySubHeadFourDesc: 'Насолоджуйтесь гнучкістю збільшених лімітів на депозити та зняття як VIP-учасник.',
  loyaltySubHeadFive: 'Ексклюзивні бонуси та акції',
  loyaltySubHeadFiveDesc: 'Отримайте доступ до спеціальних бонусів і акцій, створених спеціально для наших VIP-гравців',
  loyaltySubHeadSix: 'Менеджер особистого кабінету',
  loyaltySubHeadSixDesc: 'Отримайте спеціальну підтримку та персональну допомогу від менеджера свого облікового запису.',
  loyaltySubHeadSeven: 'Швидший час виведення',
  loyaltySubHeadSevenDesc: 'Як VIP-особа, ви зможете насолоджуватися пришвидшеним часом виведення коштів, що гарантує вам ще швидший доступ до своїх виграшів.',
  loyaltySubHeadEight: 'Щомісячні грошові винагороди',
  loyaltySubHeadEightDesc: 'Збільште свій банкрол щедрими щомісячними кешбек винагородами виключно для наших VIP-членів.',
  loyaltySubHeadNine: 'Запрошення на ексклюзивні події та турніри',
  loyaltySubHeadNineDesc: 'Отримайте бажані запрошення на ексклюзивні події та турніри, де ви можете поспілкуватися з іншими VIP-персонами та змагатися за неймовірні призи.',
  loyaltySubHeadTen: 'Бонуси до дня народження',
  loyaltySubHeadTenDesc: 'Святкуйте свій особливий день стильно з чудовим бонусом на день народження спеціально для вас!',
  loyaltySubHeadEleven: 'Розкішні подарунки',
  loyaltySubHeadElevenDesc: 'Насолоджуйтесь вишуканим вибором розкішних подарунків, підібраних для винагороди наших найцінніших гравців.',
  loyaltyTableHeading: 'Огляд рівнів і нагород',
  loyaltyTableHeaderOne: 'Рівень лояльності',
  loyaltyTableHeaderTwo: 'Бали лояльності',
  loyaltyTableHeaderThree: 'Щоденний кешбек',
  promReadMore: 'Детальніше',
  currentPortalBlock: 'якщо ви хочете заблокувати свій обліковий запис лише для цього порталу.',
  allPortalBlock: 'щоб заблокувати ваш обліковий запис на всіх порталах.',
  limit24Reset: 'Коли ви встановите ліміти ставок, програшів, депозитів, їх можна буде редагувати та видалити через 24 години, але ліміти можна зменшити негайно.',
  enterAmount: 'Введіть суму',
  limit: 'Ліміт',
  loss: 'Втрата',
  takeABreak: 'Відпочинь',
  session: 'Сесія',
  selfExclusion: 'Самовиключення',
  used: 'б/в',
  edit: 'Редагувати',
  updatedAt: 'Оновлено At',
  cannotBeRemoved: 'Не можна видалити раніше',
  timePeriod: 'Період часу',
  custom: 'Custom',
  hours: 'години',
  days: 'днів',
  timePeriodPlaceholder: 'Кількість днів',
  months: 'Місяці',
  permanent: 'Постійний',
  onlyNumbers: 'Дозволені лише числа',
  notNumbers: 'Числа заборонені',
  userNameAllowed: 'Треба починати з алфавіту. ',
  timePeriodRequired: 'Необхідний період часу',
  cannotBeIncreased: 'Не можна збільшувати раніше',
  amountGTZero: 'Сума має бути більше 0',
  amountEqualOrLess: 'Сума має бути рівною або меншою ніж тижнева та місячна',
  amountInBetween: 'Сума має бути між щоденною та місячною (включно)',
  amountEqualOIrGreater: 'Сума повинна бути рівною або більшою ніж щоденна та місячна',
  limitRemoved: 'Ваш наступний ліміт буде видалено.',
  limitSet24Hrs: 'Ви збираєтеся встановити наступний ліміт для свого облікового запису. ',
  takeABreakInfo: 'Ви збираєтеся заблокувати доступ до свого облікового запису на заданий період часу. ',
  sessionLimitInfo: 'Ви збираєтеся встановити наступний ліміт для свого облікового запису. ',
  limitCantSetBefore: 'Ваш ліміт сеансу не можна встановити раніше',
  selfExclusionInfo: 'Ви збираєтеся заблокувати доступ до свого облікового запису на заданий період часу. ',
  quickSearch: 'Швидкий пошук',
  cash: 'Готівка',
  nonCash: 'Безготівковий',
  userDetail: 'Деталі користувача',
  gameIdentifier: 'Ідентифікатор гри',
  rollback: 'Відкат',
  rollbackBeforeBetWin: 'Відкат до виграшу ставки',
  freeSpins: 'Безкоштовні обертання',
  betInternal: 'Внутрішня ставка',
  winInternal: 'Win Internal',
  addMoney: 'Додати гроші',
  removeMoney: 'Видалити гроші',
  addMoneyInternal: 'Додати гроші внутрішньо',
  removeMoneyInternal: 'Видалити Money Internal',
  depositInternal: 'Внутрішній депозит',
  withdrawInternal: 'Вилучити внутрішні',
  promotionTitle: 'Назва акції',
  cancelWithdrawRequest: 'Ви хочете скасувати цей запит',
  rowsPerPage: 'Рядків на сторінці',
  availedBonus: 'У вас вже є активний бонус. ',
  capForfeit: 'КОНСУЛЬТАЦІЯ',
  itFirst: 'це перше.',
  selectYourBonus: 'Виберіть свій бонус',
  skip: 'ПРОПУСТИТИ',
  with: 'з',
  without: 'без',
  selectPaymentMethod: 'Виберіть спосіб оплати',
  volatility: 'Волатильність',
  paylines: 'Лінії виплат',
  theme: 'Тема',
  tryForFree: 'Спробуйте безкоштовно',
  resultsFound: 'Результати знайдено',
  games: 'Ігри',
  in: 'в',
  low: 'Низький',
  medium: 'Середній',
  high: 'Високий',
  'medium-high': 'Середній Високий',
  'very-high': 'Дуже високо',
  'low-medium': 'Низький Середній',
  fantasy: 'Фантазія',
  ancient_civilizations: 'Стародавні цивілізації',
  fruits: 'Фрукти',
  africa: 'Африка',
  military: 'військовий',
  joker: 'Джокер',
  asia: 'Азії',
  luxurylife: 'Розкішне життя',
  underwater_world: 'Підводний світ',
  book_off: 'Bookoff',
  western: 'західний',
  retro: 'Ретро',
  egypt: 'Єгипет',
  party: 'Вечірка',
  st_patrick_day: 'День Святого Патрика',
  space: 'космос',
  easter: 'Великдень',
  girls: 'дівчата',
  branded: 'Фірмова',
  x_mas_and_new_year: 'Різдво і Новий рік',
  horrors: 'Жахи',
  other: 'Інший',
  loginFirst: 'Будь ласка, спочатку увійдіть, щоб додати гру у вибране',
  loginFirstPlay: 'Будь ласка, спочатку увійдіть, щоб зіграти в цю гру',
  sport: 'спорт',
  pirates: 'Пірати',
  sweets: 'солодощі',
  luxury_life: 'Розкішне життя',
  st_valentines_day: 'День Святого Валентина',
  halloween: 'Хеллоуїн',
  food: 'харчування',
  lobby: 'лобі',
  favourite: 'улюблений',
  playNGO: 'Грати в N Go',
  loyaltySystem: 'Система лояльності',
  progressionSystem: 'Система прогресування',
  startPoint: 'Початкова точка',
  endPoint: 'Кінцева точка',
  loyaltyBonus: 'Бонус лояльності',
  pageRemoved: 'Ця сторінка не існує або була видалена',
  suggestToBackHome: 'Ми пропонуємо вам повернутися додому',
  backToHome: 'Назад додому',
  goHome: 'Іди додому',
  oopps: 'Ой!',
  somethingNotWorking: 'Вибачте, тут щось не працює!',
  tournament: 'Турнір',
  none: 'Жодного',
  validOnDays: 'Дійсний у дні',
  validTill: 'Дійсний до',
  yourRequest: 'Ваш запит',
  withdrawalRequestPending: 'У вас є один запит на зняття в стані очікування. ',
  realBalance: 'Справжній баланс',
  bonusInCasino: 'Бонус в казино',
  bonusSelected: 'Вибраний бонус',
  payWith: 'Платити за допомогою',
  reached: 'Досягнуто',
  receiptOfYour: 'Отримання вашого',
  capEmail: 'ЕЛЕКТРОННА ПОШТА',
  capCreditCard: 'НОМЕР КРЕДИТНОЇ КАРТИ',
  capExpiry: 'ТЕРМІН ДІЇ',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY не має правильного формату',
  min13Numbers: 'Необхідно мінімум 13 чисел',
  max19Numbers: 'Дозволено максимум 19 чисел',
  min3Numbers: 'Необхідно мінімум 3 номери',
  max4Numbers: 'Дозволено максимум 4 числа',
  Other: 'Інший',
  forfeit: 'неустойка',
  withdrawRequestCreated: 'Запит на відкликання створено успішно',
  noBetHistory: 'Історії ставок не знайдено',
  noPromotions: 'Рекламних акцій не знайдено',
  noBonuses: 'Бонуси не знайдено',
  noHistory: 'Історія не знайдена',
  noWithdrawalHistory: 'Історію зняття не знайдено',
  noCMS: 'CMS не знайдено',
  noProvidersFound: 'Постачальники не знайдено',
  noDataFound: 'Даних не знайдено',
  countryBlocked: 'КРАЇНА ЗАБЛОКУВАНА',
  forbidden: 'ЗАБОРОНЕНО',
  notAcceptingVisitors: 'Нам дуже шкода, але shinywilds.com недоступний у вашій країні.',
  contactUsAt: 'Якщо ви вважаєте, що це було показано вам помилково, зв’яжіться з нашою службою підтримки електронною поштою на',
  siteUnderMaintenance: 'Сайт на технічному обслуговуванні',
  weWillBeRightBack: 'Ми зараз повернемося!',
  serviceUnavailable: 'Наш сервіс тимчасово недоступний. ',
  tryAgainLater: 'Будь-ласка спробуйте пізніше.',
  checkYourEmail: 'Перевірити свою електронну пошту',
  sentAVerification: 'Ми надіслали посилання для підтвердження',
  toYourEmail: 'на вашу електронну пошту.',
  backToLogin: 'Назад до входу',
  goToLogin: 'Перейти до входу',
  accountVerified: 'Обліковий запис підтверджено',
  yourAccountVerified: 'Ваш обліковий запис підтверджено',
  successfully: 'Успішно',
  verificationFailed: 'Не вдалося перевірити',
  emailFailedContact: 'Не вдалося перевірити. Якщо це відбувається постійно, зв’яжіться з нами ',
  setNewPass: 'Встановіть новий пароль',
  passResetLink: 'Ми надіслали посилання для зміни пароля',
  toYourAccount: 'На Ваш рахунок',
  sendPasswordResetLink: 'Запит на скидання пароля',
  gamePlayWagerComplete: 'Вітаємо, ви успішно зробили ставку, і виграні бонусні гроші перевищують максимальну допустиму суму виграшу. ',
  real: 'Справжня',
  demo: 'Демо',
  money: 'гроші',
  formatRquired: 'Потрібен формат .png , .jpg, .jpeg',
  chooseImage: 'Будь ласка, виберіть зображення',
  imageSize3MB: 'Введіть зображення розміром менше 3 Мб',
  noProfileImage: 'Немає зображення профілю, яке можна видалити',
  limitSetSuccess: 'обмеження встановлено успішно',
  accountDisabled: 'Обліковий запис успішно вимкнено',
  loginSuccess: 'Успішний вхід',
  signupSuccess: 'Успішна реєстрація',
  linkExpired: 'Термін дії посилання закінчився',
  linkSent: 'Посилання надіслано',
  accAlreadyVerified: 'Обліковий запис уже підтверджено. ',
  profileImage: 'Зображення профілю',
  profileImageRemoved: 'Зображення профілю успішно видалено',
  docs: 'Документи',
  updatedSuccess: 'Оновлено успішно',
  MATCH: 'МАТЧ',
  BALANCE: 'БАЛАНС',
  FREESPINS: 'ФРИСПІНИ',
  MATCH_1: 'МАТЧ',
  DEPOSIT: 'ДЕПОЗИТ',
  WAGERING: 'СТАРИ',
  PROMOTION: 'АКЦІЯ',
  CREDITCARD: 'КРЕДИТНА КАРТА',
  WALLET: 'ГАМАНЕЦЬ',
  CRYPTO: 'КРИПТО',
  OTHER: 'ІНШИЙ',
  INSTANTBANKING: 'МИТТЄВИЙ БАНК',
  cookieTitle: 'печиво!',
  declineButton: 'відхилити',
  acceptButton: 'прийняти',
  cookieDeclinedMessage: 'Щоб продовжити використання цього веб-сайту, необхідно прийняти файли cookie для найкращого досвіду. ',
  cookieDescription: 'Ласкаво просимо на Shinywilds.com! ',
  forfeitMessageFirst: 'Зараз у вас є наступний активний бонус:',
  forfeitMessageSecond: 'Ви повинні втратити цей бонус, перш ніж отримати новий.',
  forfeitButton: 'неустойка',
  pleaseVerifyFirstText: 'Щоб подати запит на зняття коштів, нам спочатку потрібно підтвердити вашу електронну адресу. ',
  clickHere: 'Натисніть тут',
  pleaseVerifyLastText: 'щоб ми могли повторно надіслати вам електронний лист для підтвердження та продовжити.',
  pleseWait: 'Будь ласка, зачекайте',
  toResend: 'щоб повторно надіслати посилання',
  notVerified: 'Ви повинні підтвердити свою електронну адресу, щоб зняти гроші',
  verification: 'Перевірка',
  UPLOAD_IMG_MSG: 'Перетягніть файли сюди або натисніть, щоб вибрати файли',
  affiliate: 'Філіал',
  affiliateTitle: 'Партнерська Програма',
  affiliateDescription: ' Зверніться до нашого бренду та заробляйте гроші, беручи участь у партнерській програмі Cash Machine! ',
  refferalTitle: 'Реферальна програма',
  refferalDescription: 'Ви можете поділитися цим реферальним посиланням з іншими та отримати бонус, якщо користувач зареєструється за вашим посиланням',
  affiliateButtonText: 'Стати партнером',
  affiliateLink: 'Партнерське посилання',
  affiliateStatus: 'Ваш запит на партнерство успішно надіслано',
  currentStatus: 'і поточний статус партнера {{status}}',
  referralLink: 'Реферальне посилання',
  copyTitleMessage: 'Будь ласка, скопіюйте це посилання',
  copiedMessage: 'Посилання скопійовано',
  searchedGame: 'Пошукова гра',
  favoriteGame: 'Улюблені ігри',
  randomGames: 'Випадкові ігри',
  poolPrize: 'Призовий фонд',
  chooseYourWelcomeBonus: 'Виберіть вітальний бонус',
  useNow: 'Використовуйте зараз',
  readTheTerms: 'Прочитайте умови',
  proceedWithoutWelcomeBonus: 'Продовжуйте без вітального бонусу',
  doYouAlreadyHaveAccount: 'Ви вже маєте акаунт? ',
  registerViaSocialNetworks: 'Зареєструватися через соціальні мережі',
  iconfirmIm18YearsOlder: 'Я підтверджую, що мені 18 років або більше',
  iAgreeWithTermsAndConditions: 'Я згоден з Правилами та Умовами',
  thankYouSuccessfullyRegisteringCashMachine: 'Дякуємо за успішну реєстрацію на Cash Machine.',
  verifyEmailMessage: 'Ми просимо вас підтвердити свою електронну пошту, натиснувши посилання для підтвердження, надіслане на вашу електронну адресу, для успішного входу на сайт.',
  gotIt: 'Зрозумів',
  phonecodeRequired: 'Потрібно ввести код телефону',
  cpNotAllow: 'копіювання/вставлення заборонено',
  playFun: 'Грати Fun',
  playReal: 'Грати в Real',
  yourRealBalance: 'Ваш реальний баланс:',
  chooseYourPaymentMethod: 'Виберіть спосіб оплати',
  balanceHistory: 'Історія балансу',
  liveChat: 'Живий чат',
  mail: 'Пошта',
  chooseEnterYourDepositAmounts: 'Виберіть або введіть суму депозиту',
  noBonusDataAvailable: 'Немає даних про бонуси',
  nowTryPlayForRealBets: 'Тепер спробуйте грати на реальні ставки',
  registerAndPlayForRealBets: 'Реєструйтеся та грайте на реальні ставки',
  tournamentDataNotAvailable: 'Дані про турнір недоступні',
  startDate: 'Дата початку',
  endDate: 'Дата закінчення',
  merchant: 'Торговець',
  seachHere: 'Шукайте тут',
  show: 'Показати',
  betResults: 'Результати ставок:',
  noTransactionsFoundForselectedDate: 'Немає трансакцій для вибраної дати',
  loginError: 'Помилка входу',
  errorMessageIncorrectlyAuthorization: 'Електронна пошта / логін або пароль введені неправильно Помилка авторизації.',
  profileSaved: 'Профіль збережено',
  addInfo: 'Додати інформацію',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Я прочитав і погоджуюся з обмеженнями платіжних систем*',
  paymentOptions: 'Варіанти оплати',
  language: 'Мова',
  betTime: 'Час ставок',
  noActiveBonusFound: 'Активного бонусу не знайдено',
  bonusType: 'Тип бонусу',
  requiredWageringAmount: 'Необхідна сума ставок',
  currentWageringAmount: 'Поточна сума ставок',
  validUpto: 'Дійсний до',
  bonusCode: 'Бонус-код',
  wageringProgressBar: 'Індикатор прогресу ставок',
  noGamesFound: 'Ігор не знайдено',
  noteYouMayOptfreeSpin: 'Примітка. Ви можете вибрати FREESPIN у будь-якій із цих ігор',
  viewOfferDetails: 'Переглянути деталі пропозиції',
  viewFreespinGames: 'Перегляньте ігри Freespin',
  depositBonusPercentage: 'Відсоток бонусу на депозит: ',
  validity: 'Термін дії',
  activated: 'активовано',
  bonusName: 'Назва бонусу',
  freeRounds: 'Безкоштовні раунди',
  wagering: 'Ставки',
  ended2: 'Завершено',
  searchBonusTitle: 'Пошук за назвою бонусу',
  claimed: 'Заявлено',
  noBonusHistoryFoundselectedDates: 'Історії бонусів для вибраних дат не знайдено',
  noBonusDetailsFound: 'Деталі бонусу не знайдено',
  enterCode: 'Введіть код',
  transactionTime: 'Час транзакції',
  credit: 'Кредит',
  debit: 'Дебетова',
  bonusInfoOfHeading: 'Бонуси - це додаткові виплати, які винагороджують вашу ефективність',
  onlineCasino: 'онлайн казино',
  isTheTop: ' є вершиною ',
  bonusInfoPara0: 'Шукаєте найкращі онлайн-ігри казино? ',
  tournamentsHistory: 'Історія турнірів',

  loginWelcomeBonusInfo: `Cash Machine вітає нових гравців вітальним бонусом - 100%
  депозит + 100 фріспінів в грі «Pat’s Heroes» (Platipus).
  Мінімальна сума депозиту становить 20 євро / 900 архангельських песо / 30 австралійських доларів / 500 чеських крон
  / 150 DKK / 200 NOK / 30 NZD / 80 PLN / 20 USD / 300 ZAR / 70 GEL
  / 9 000 KZT / 200 TJS / 200 000 UZS / 130 TRY,`,
  loginDepositBonusInfo: `Максимальна сума бонусу: 100% від суми депозиту, але не більше
  понад 100 євро / 4000 архангельських песо / 150 австралійських доларів / 2500 чеських крон / 750 датських крон / 1000 норвезьких крон
  / 150 новозеландських доларів / 400 злотих / 100 доларів США / 1500 ZAR / 350 ларі / 45 000 тенге /
  1000 TJS / 1 000 000 UZS / 600 TRY,`,
  loginFreespinBonusInfo: `Безкоштовні обертання видаються протягом 5 днів з моменту активації бонусу,
  20 безкоштовних обертань щодня. Усі кошти, виграні на безкоштовних обертаннях, зараховуються
  бонусний рахунок і доступні для гри/ставок
  бонус. Виведення доступне після ставки X40. Бонусний час відіграшу
  становить 7 днів.`,
  loginMaximumBetLimitInfo: `Ліміт максимальної ставки під час ставок: 1 євро / 45 ARS / 1,5 австралійських доларів / CZK
  25 / 7 датських крон / 10 норвезьких крон / 1,5 новозеландського долара / 5 злотих / 1 долар США / 15 ZAR / 3,5 ларі /
  450 KZT / 10 TJS / 10 000 UZS / TRY 6`,

  bonusInfoPara1: ' , з великим вибором фантастичних ігор і чудових спеціальних пропозицій. Наші слот-ігри представлені в різноманітних темах, ігри з прогресивним джекпотом пропонують гравцям вигідний шанс отримати великий виграш. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як ваші карти перемішуються∂ і роздаються в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж вирішите грати по-справжньому.',
  bonusInfoPara2: ' Наші слот-ігри мають різноманітні теми, ігри з прогресивним джекпотом пропонують прибутковий шанс отримати великий виграш. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як перемішуються та роздаються ваші карти в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж грати по-справжньому. Наші слот-ігри представлені в широкому спектрі тем, з прогресивними слот-іграми з джекпотом, які пропонують гравцям вигідний шанс забити велика перемога. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як перемішуються та роздаються ваші карти в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж вирішите грати по-справжньому.',
  bonusInfoPara3: ' Наші слот-ігри представлені в різноманітних темах, ігри з прогресивним джекпотом пропонують гравцям вигідний шанс отримати великий виграш. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як перемішуються та роздаються ваші карти в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж вирішите грати по-справжньому.',
  bonusInfoPara4: ' Наші слот-ігри представлені в різноманітних темах, ігри з прогресивним джекпотом пропонують гравцям вигідний шанс отримати великий виграш. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як перемішуються та роздаються ваші карти в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж грати по-справжньому. Наші слот-ігри представлені в широкому спектрі тем, з прогресивними слот-іграми з джекпотом, які пропонують гравцям вигідний шанс забити велика перемога. Ми також пропонуємо захоплюючі ігри з дилерами в реальному часі, де ви можете бачити, як перемішуються та роздаються ваші карти в реальному часі! Не впевнені, яка гра підходить саме вам? Не хвилюйтеся – ви можете спочатку потренуватися в демо-режимі, щоб відчути кожну гру, перш ніж вирішите грати по-справжньому.',
  cashMachineFooterPara1: 'Шукаєте найкращі ігри в казино онлайн? Тоді ви потрапили за адресою.',
  cashMachineFooterPara2: 'з великим вибором фантастичних ігор та дивовижних спеціальних пропозицій. Наші ігрові автомати представлені в широкому спектрі тематик, з прогресивними джекпотами, що пропонують гравцям вигідну можливість виграти великий приз. Ми також пропонуємо захоплюючі ігри з живими дилерами, де ви можете бачити, як ваші карти перемішуються і роздаються в реальному часі! Не впевнені, яка гра підходить вам? Не хвилюйтеся - спочатку ви можете попрактикуватися в демо-режимі, щоб зрозуміти кожну гру, перш ніж вирішити грати на справжні гроші.',
  tournamentDescTitle: 'Живе казино, Слоти та Турніри казино Затримки та перемоги',
  tournamentPara1: 'Шукаєте найкращі ігри в казино онлайн? Тоді ви потрапили за адресою.',
  tournamentPara2: 'з великим вибором фантастичних ігор та дивовижних спеціальних пропозицій. Наші ігрові автомати представлені в широкому спектрі тематик, з прогресивними джекпотами, що пропонують гравцям вигідну можливість виграти великий приз. Ми також пропонуємо захоплюючі ігри з живими дилерами, де ви можете бачити, як ваші карти перемішуються і роздаються в реальному часі! Не впевнені, яка гра підходить вам? Не хвилюйтеся - спочатку ви можете попрактикуватися в демо-режимі, щоб зрозуміти кожну гру, перш ніж вирішити грати на справжні гроші.',
  tournamentPara3: 'Наші ігрові автомати представлені в широкому спектрі тематик, з прогресивними джекпотами, що пропонують гравцям вигідну можливість виграти великий приз. Ми також пропонуємо захоплюючі ігри з живими дилерами, де ви можете бачити, як ваші карти перемішуються і роздаються в реальному часі! Не впевнені, яка гра підходить вам? Не хвилюйтеся - спочатку ви можете попрактикуватися в демо-режимі, щоб зрозуміти кожну гру, перш ніж вирішити грати на справжні гроші. Наші ігрові автомати представлені в широкому спектрі тематик, з прогресивними джекпотами, що пропонують гравцям вигідну можливість виграти великий приз. Ми також пропонуємо захоплюючі ігри з живими дилерами, де ви можете бачити, як ваші карти перемішуються і роздаються в реальному часі! Не впевнені, яка гра підходить вам? Не хвилюйтеся - спочатку ви можете попрактикуватися в демо-режимі, щоб зрозуміти кожну гру, перш ніж вирішити грати на справжні гроші.',
  'New Games': 'Нові Ігри',
  'Popular Games': 'Популярні Ігри',
  'Games Of The Month': 'Ігри Місяця',
  Exclusive: 'Ексклюзив',
  'Bonus Buy': 'Бонусна покупка',
  'Jackpot Games': 'Ігри з джекпотом',
  lightMode: 'Світловий режим',
  darkMode: 'Темний режим',
  searchProvider: 'Постачальник пошуку',
  tournamentHistoryDataNotAvailable: 'Дані про історію турніру недоступні',
  viewMore: 'Дивитись більше',
  description: 'опис',
  codeCopied: 'Код скопійовано',
  tournamentGames: 'Турнірні ігри',
  freeSpinGames: 'Безкоштовні ігри обертання',
  promoCode: 'Промокод',
  paymentNote: 'Примітка: Будь ласка, не закривайте або оновлюйте вікно під час обробки транзакції',
  liveGames: 'Live Ігри',
  play: 'ГРАТИ',
  demo1: 'ДЕМО',
  registration: 'РЕЄСТРАЦІЯ',
  confirmation: 'Підтвердження',
  forfeitBonusMessage: 'Якщо ви активуєте цей бонус, будь-які старі активні бонуси будуть анульовані.',
  bonusConfirmationMessage: 'Ви впевнені, що хочете активувати цей бонус?',

  forfeitRewardMessage: 'Якщо ви активуєте цю нагороду, усі старі активні бонуси буде втрачено.',
  rewardConfirmationMessage: 'Ви впевнені, що бажаєте активувати цю нагороду?',
  reward: 'Нагорода',
  purchasedReward: 'Придбана винагорода',
  noActiveRewardFound: 'Активної нагороди не знайдено',
  noRewardDetailsFound: 'Нагорода не знайдена',
  betDate: 'Дата ставки'
}
