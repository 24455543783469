import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import LobbyBanner from "./components/LobbyBanner/index";
import { HomeWrapper } from "./style";
import Slider3D from "./components/Slider3D/index";
// import GamesSlider from './components/GamesSlider/index'
import GameGrid from "pages/GameGrid/index";
// import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import {
  favoriteGameHandlerGenric,
  getCasinoGameByTag,
  getFavoriteGameStart,
  getRandomGameData,
  playGame,
} from "redux-thunk/thunk/game.thunk";
import { verifyEmail } from "redux-thunk/thunk/auth.thunk";
import { openErrorToaster } from "helpers/toaster.helpers";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import GamesSlider from "./components/GamesSlider/index";
// import { jackpotGames } from 'utils/constant'
import { GameTagTitle } from "config/game.config";
import {
  setShowRandomGames,
  setShowTagGames,
} from "redux-thunk/redux/slices/gameSetting.slice";

import { defaultCurrency } from "utils/constant";
import { setAuthModal } from "redux-thunk/redux/slices/auth.slice";
import { clearRandomGames } from "redux-thunk/redux/slices/games.slice";
import { getDeviceType } from "utils/getDeviceType";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    categoryWiseGamesList,
    filteredGames,
    count,
    searchedGames,
    subCategoryId,
    search,
    favoriteGames,
    randomGames,
    casinoTagsGameList,
  } = useSelector((state) => state.games);
  const {
    showFavoriteGames,
    showRandomGames,
    showProviderGames,
    selectedProvierData,
    isAuthenticated,
    showTagsGames,
  } = useSelector((state) => state.gameSetting);
  const { selectedLanguage } = useSelector((state) => state.language);
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailToken, setEmailToken] = useState("");
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user?.user);

  const languageCode = selectedLanguage?.code.toUpperCase() || "EN";

  const { REACT_APP_S3_URL } = process.env;

  const handleFilteredFavouriteGame = (game) => {
    if (!isAuthenticated) {
      return openErrorToaster({ message: t("loginFirst") });
    } else {
      dispatch(favoriteGameHandlerGenric({ gameData: game }));
    }
  };

  const handleCustomCategoryFavouriteGame = (game) => {
    if (!isAuthenticated) {
      return openErrorToaster({ message: t("loginFirst") });
    } else {
      dispatch(
        favoriteGameHandlerGenric({
          gameData: game,
          isCustomGame: true,
          showTagsGames,
        })
      );
    }
  };

  const handleCategoryFavouriteGame = (game) => {
    if (!isAuthenticated) {
      return openErrorToaster({ message: t("loginFirst") });
    } else {
      dispatch(favoriteGameHandlerGenric({ gameData: game, isAllGame: true }));
    }
  };

  useEffect(() => {
    setEmailToken(searchParams.get("emailToken"));
    dispatch(getCasinoGameByTag());
    dispatch(clearRandomGames());
    dispatch(setShowRandomGames(false));
  }, []);

  useEffect(() => {
    if (isAuthenticated && showFavoriteGames) {
      dispatch(getFavoriteGameStart({ limit: 200, pageNo: 1 }));
    }
  }, [isAuthenticated, showFavoriteGames]);

  const openRandomGame = (game) => {
    const openGame = (gameCode, url) => {
      navigate(`/casino/play-game/${gameCode}`, { state: { gameURL: url } });
    };
    if (isAuthenticated) {
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: game?.casinoGameId,
        funMode: false,
        device: getDeviceType(),
      };
      dispatch(playGame({ data, openGame, fromRandomGames: true }));
    } else if (!isAuthenticated) {
      dispatch(setAuthModal("login"));
    }
  };

  useEffect(() => {
    if (showRandomGames) {
      const data = {
        isRandom: true,
        pageNo: 1,
        limit: 10,
      };
      dispatch(getRandomGameData({ data, openRandomGame }));
      if (isAuthenticated) navigate("/casino/play-game/random-game");
    }
  }, [showRandomGames]);

  useEffect(() => {
    const param = {
      emailToken: emailToken,
    };

    const handleVerificationSuccess = () => {
      setSearchParams("");
      navigate("/");
    };
    if (emailToken) dispatch(verifyEmail({ param, handleVerificationSuccess }));
  }, [emailToken]);

  const handleSeeAllForTagsGames = (data) => {
    dispatch(setShowTagGames(data));
  };

  // const jackpotSection = (
  //   <GamesSlider
  //     gameSliderArr={jackpotGames}
  //     headingNameNeon='Jackpot'
  //     showSlider={false}
  //   />
  // )
  // const handleScrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   })
  // }
  return (
    <HomeWrapper theme={theme} className="HomeWrapper">
      <LobbyBanner />

      {showFavoriteGames ||
      search ||
      subCategoryId ||
      showRandomGames ||
      showProviderGames ||
      showTagsGames ? (
        <GameGrid
          gameArr={
            search
              ? searchedGames
              : showFavoriteGames
              ? favoriteGames?.rows
              : showRandomGames
              ? randomGames
              : showTagsGames
              ? casinoTagsGameList[showTagsGames?.tag]
              : showProviderGames
              ? filteredGames
              : filteredGames
          }
          favoriteGameHandler={
            showTagsGames
              ? handleCustomCategoryFavouriteGame
              : handleFilteredFavouriteGame
          }
          headingImg="/assets/images/stock-images/game-icon-3.png"
          headingName={
            search
              ? t("searchedGame")
              : showFavoriteGames
              ? t("favoriteGame")
              : showRandomGames
              ? t("randomGames")
              : showTagsGames
              ? t(GameTagTitle[showTagsGames?.tag])
              : showProviderGames
              ? selectedProvierData?.providerName || t("all")
              : filteredGames[0]?.CasinoCategory?.name[languageCode] ||
                filteredGames[0]?.CasinoCategory?.name?.EN ||
                ""
          }
          isFiltered={
            !!subCategoryId ||
            !!search ||
            showFavoriteGames ||
            !!showRandomGames ||
            !!showProviderGames ||
            !!showTagsGames
          }
          gamesCount={
            showFavoriteGames
              ? favoriteGames?.count
              : showTagsGames?.tag
              ? casinoTagsGameList[showTagsGames?.tag].length
              : count
          }
          isSearched={!!search}
          handleSeeAllForTagsGames={handleSeeAllForTagsGames}
        />
      ) : (
        <>
          {categoryWiseGamesList?.map(
            ({ games, name, id, thumbnailUrl, metaData }) => {
              if (metaData?.gameCardSlider === "animated") {
                return (
                  <Slider3D
                    gameSliderArr={games}
                    headingName={name?.[languageCode] || name?.EN || ""}
                    metaData={metaData}
                    showJackpot={metaData?.prizePool}
                    headingImg={
                      thumbnailUrl
                        ? `${REACT_APP_S3_URL}/${thumbnailUrl}`
                        : "/assets/images/stock-images/game-icon-3.png"
                    }
                  />
                );
              } else if (metaData?.gameCardSlider === "standard") {
                return (
                  <GamesSlider
                    gameSliderArr={games}
                    headingName={name?.[languageCode] || name?.EN || ""}
                    metaData={metaData}
                    showJackpot={metaData?.prizePool}
                    headingImg={
                      thumbnailUrl
                        ? `${REACT_APP_S3_URL}/${thumbnailUrl}`
                        : "/assets/images/stock-images/game-icon-3.png"
                    }
                    showSlider
                    t={t}
                  />
                );
              } else {
                return (
                  <GameGrid
                    // gameArr={games?.length >= 12 ? games.slice(0, 12) : games?.length >= 6 ? games.slice(0, 6) : games.slice(0, 3)}
                    gameArr={games.slice(0, metaData?.gameCardCount)}
                    headingImg={
                      thumbnailUrl
                        ? `${REACT_APP_S3_URL}/${thumbnailUrl}`
                        : "/assets/images/stock-images/game-icon-3.png"
                    }
                    headingName={name?.[languageCode] || name?.EN || ""}
                    key={id}
                    categoryId={id}
                    isFiltered={
                      !!subCategoryId || !!search || showFavoriteGames
                    }
                    favoriteGameHandler={(gameData) =>
                      handleCategoryFavouriteGame({
                        ...gameData,
                        gameSubCategoryId: id,
                      })
                    }
                    metaData={metaData}
                    showJackpot={metaData?.prizePool}
                  />
                );
              }
            }
          )}
        </>
      )}

      <Box className="footer-text-wrap">
        <Box className="footer-text-box" />
        <img
          src="/assets/images/logo/brand-logo2.png"
          className="logo-img"
          alt="Img"
          loading="lazy"
        />
        <img
          src="/assets/images/stock-images/footer-text-bg-img.png"
          className="cash-machine-img"
          alt="Img"
          loading="lazy"
        />
        <Box className="text-box">
          <Typography variant="body1">
            {t("cashMachineFooterPara1")}{" "}
            <Typography variant="caption">{t("cashMachineTitle1")} </Typography>
            ,{t("cashMachineFooterPara2")}{" "}
            <Typography variant="caption">{t("cashMachineTitle2")} </Typography>
            , {t("cashMachineFooterPara3")}
            <Typography variant="caption">{t("cashMachineTitle3")} </Typography>
          </Typography>
        </Box>
      </Box>
    </HomeWrapper>
  );
};

export default Home;
