import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRewardService, getRewardDetailService, getUserActiveReward, purchaseRewardService } from 'network/services/shop.service'

export const getAllRewards = createAsyncThunk(
  'fetch/all-rewards',
  async (data, thunkApi) => {
    try {
      const res = await getAllRewardService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getRewardDetail = createAsyncThunk(
  'fetch/detail-rewards',
  async (data, thunkApi) => {
    try {
      const res = await getRewardDetailService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const purchaseReward = createAsyncThunk(
  'fetch/purchaseReward',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await purchaseRewardService(data)
      // setLoading(false)
      // setClaimed(true)
      if (onSuccess) onSuccess()
      return res
    } catch (error) {
      // setLoading(false)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getActiveReward = createAsyncThunk(
  'fetch/active-user-reward',
  async (data, thunkApi) => {
    try {
      const res = await getUserActiveReward(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
