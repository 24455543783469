import React, { useState } from 'react'
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material'
import { BonusCardWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { activateBonus, getActiveBonuses, getUserBonus } from 'redux-thunk/thunk/bonus.thunk'
import { InfoCircleIcon, SpinnerSVG } from 'assets/icons/index'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { BonusDialog } from './BonusDialog'
import { useTranslation } from 'react-i18next'
import { setAuthModal } from 'redux-thunk/redux/slices/auth.slice'
import ForfeitConfirmationDialog from './ForfeitConfirmationDialog'

const BonusCard = ({
  data,
  from,
  bonusImg,
  bonusId,
  bonusTitle,
  bonusCode,
  description,
  depositBonusPercent,
  requiredWager,
  currentWagered,
  userBonusId,
  validFrom,
  validTo,
  isClaimed,
  gamesArr,
  bonusType,
  freeSpinsQty,
  termCondition,
  freeSpinCount
}) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const langCode = useSelector((state) =>
    state.language.selectedLanguage?.code?.toUpperCase()
  )
  const user = useSelector(state => state.user?.user)
  const dispatch = useDispatch()
  const [claimed, setClaimed] = useState(isClaimed)
  const [copySuccess, setCopySuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const [forfeitConfirmationPopup, setForfeitConfirmationPopup] = useState(false)

  const { t } = useTranslation()

  // const bonusGamesData = {
  //   headingName: `${t('freeSpins')} ${t('games')}`,
  //   headingImg: '/assets/images/stock-images/game-icon-4.png'
  // }

  const onActivateBonusSuccess = () => {
    setForfeitConfirmationPopup(false)
    setViewMore(true)
    if (from === 'active') dispatch(getActiveBonuses())
    else dispatch(getUserBonus())
  }

  const handleActivateBonus = () => {
    setLoading(true)
    let payload = {
      bonusId,
      id: user?.userId || null,
      user: user || null
    }
    if (data?.status === 'readyToClaim')
      {
        payload = {
          bonusId,
          userBonusId,
          id: user?.userId || null,
          user: user || null
        }
      }
    dispatch(activateBonus({ data: payload, setClaimed, setLoading, onSuccess: onActivateBonusSuccess }))
  }

  const handleDefaultBonus = () => {
    dispatch(setAuthModal('signup'))
  }

  const copyCode = () => {
    navigator.clipboard.writeText(bonusCode)
    setCopySuccess(t('codeCopied'))
    setTimeout(() => {
      setCopySuccess('')
    }, [1000])
  }

  const activeBonusElement = (
    data?.status === 'readyToClaim' && data?.bonusSlug === 'referral' ? (
      <>
       <Typography variant='subtitle2' className='text-2'>
          {t('bonusCode')}: <strong>{' ' + bonusCode}</strong>
          <ContentCopyIcon onClick={copyCode} />
          <span className='text-3'>{' ' + copySuccess}</span>
        </Typography>
        <CustomMainButton
        onClick={handleActivateBonus}
        >
        {t('claimNow')}
        </CustomMainButton>
      </>
    ) : <>
    <Typography variant='subtitle2' className='text-2'>
          {t('bonusCode')}: <strong>{' ' + bonusCode}</strong>
          <ContentCopyIcon onClick={copyCode} />
          <span className='text-3'>{' ' + copySuccess}</span>
        </Typography>
        <Typography variant='subtitle2' className='text-2'>
          {t('status')}: <strong>{data?.status}</strong>
        </Typography>
      </>
  );
  const activeBonuses = (
    <Box className='content-wrap'>
      <Typography variant='subtitle1' className='text-1'>{bonusTitle?.[langCode] || bonusTitle?.EN}</Typography>
      <Typography variant='subtitle2' className='text-2'>{depositBonusPercent > 0 && `${t('depositBonus')} -${depositBonusPercent}%`} {depositBonusPercent > 0 && freeSpinCount > 0 && '+'} {freeSpinCount > 0 && `${t('freeSpins')} -${freeSpinCount}FS`}</Typography>

      {activeBonusElement}
      <IconButton className='info-icon' onClick={() => setViewMore(true)}><InfoCircleIcon /></IconButton>

      {/* {viewGames
        ? (
            gamesArr?.length
              ? (
                <GameGrid
                  gameArr={gamesArr}
                  headingImg={bonusGamesData.headingImg}
                  headingName={bonusGamesData.headingName}
                  hideViewBtn
                />
                )
              : (
                <Typography variant='h3' className='text-4'>
                  {t('noGamesFound')}
                </Typography>
                )
          )
        : (
            activeBonusElement
          )}
      {viewGames && gamesArr?.length && (
        <Typography variant='body1' className='text-4'>
          {t('noteYouMayOptfreeSpin')}{' '}
        </Typography>
      )}
      {gamesArr?.length && (
        <CustomMainButton
          onClick={() => setViewGames(!viewGames)}
          className='view-btn'
        >
          {viewGames ? t('viewOfferDetails') : t('viewFreespinGames')}
        </CustomMainButton>
      )} */}
    </Box>
  )

  const offerBonuses = (
    <Box className='content-wrap'>
      <Typography variant='subtitle1' className='text-1'>{bonusTitle?.[langCode] || bonusTitle?.EN}</Typography>
      {/* <Typography variant='subtitle2' className='text-2'>{depositBonusPercent > 0 && `${t('depositBonus')} -${depositBonusPercent}%`} {depositBonusPercent > 0 && freeSpinCount > 0 && '+'} {freeSpinCount > 0 && `${t('freeSpins')} -${freeSpinCount}FS`}</Typography> */}
      <Typography variant='subtitle2' className='text-2'>{bonusType === 'joining' || bonusType === 'vip' ? `${t('freeSpins')} - ${freeSpinsQty} FS` : depositBonusPercent > 0 && `${t('depositBonus')} - ${depositBonusPercent}%`}</Typography>

      <CustomMainButton
        onClick={() => setForfeitConfirmationPopup(true)}
        disabled={claimed || isClaimed}
      >
        {loading
          ? (
            <SpinnerSVG style={{ fill: 'white' }} />
            )
          : claimed || isClaimed
            ? data?.userBonus?.status || t('activated')
            : (
                t('useNow')
              )}
      </CustomMainButton>
      <IconButton className='info-icon' onClick={() => setViewMore(true)}><InfoCircleIcon /></IconButton>
    </Box>
  )

  const defaultBonuses = (
    <Box className='content-wrap'>
      <Typography variant='subtitle1' className='text-1'>{bonusTitle?.[langCode] || bonusTitle?.EN}</Typography>
      <Typography variant='subtitle2' className='text-2'>{depositBonusPercent > 0 && `${t('depositBonus')} -${depositBonusPercent}%`} {depositBonusPercent > 0 && freeSpinCount > 0 && '+'} {freeSpinCount > 0 && `${t('freeSpins')} -${freeSpinCount}FS`}</Typography>
      <Typography variant='subtitle2' className='text-2'>{bonusType === 'joining' || bonusType === 'vip' ? `${t('freeSpins')} - ${freeSpinsQty} FS` : depositBonusPercent > 0 && `${t('depositBonus')} - ${depositBonusPercent}%`}</Typography>

      <CustomMainButton
        onClick={handleDefaultBonus}
      >
        {t('useNow')}
        {/* {data?.bonusSlug === 'joining'
          ? t('useNow')
          : t('registration')} */}
      </CustomMainButton>
      <IconButton className='info-icon' onClick={() => setViewMore(true)}><InfoCircleIcon /></IconButton>
    </Box>
  )

  return (
    <BonusCardWrapper>
      <Box className='bonus-card-wrap'>
        <Box className='bonus-img-box'>
          <Box className='img-box'>
            <img src={bonusImg} alt='Bonus' loading='lazy' />
          </Box>
          <Box className='text-box'>
            <Typography variant='body1'>{bonusTitle?.[langCode] || bonusTitle?.EN}</Typography>
            <Typography variant='body2'>{depositBonusPercent > 0 && `${depositBonusPercent}%`} <span>{depositBonusPercent > 0 && freeSpinCount > 0 && '+'}{freeSpinCount > 0 && `${freeSpinCount}FS`}</span></Typography>
          </Box>
        </Box>

        <Box className='bonus-text-box'>
          {isDesktop
            ? (
              <img
                src='/assets/images/stock-images/bonus-img-border.png'
                className='bonus-img-border'
                alt='Border'
                loading='lazy'
              />)
            : (
              <img
                src='/assets/images/stock-images/bonus-img-border-mobile.png'
                className='bonus-img-border'
                alt='Border'
                loading='lazy'
              />)}
          {from === 'offer'
            ? offerBonuses
            : from === 'active'
              ? activeBonuses
              : defaultBonuses}
        </Box>
      </Box>
      {viewMore && (
        <BonusDialog
          data={data}
          from={from}
          bonusTitle={bonusTitle}
          bonusCode={bonusCode}
          description={description}
          depositBonusPercent={depositBonusPercent}
          requiredWager={requiredWager}
          currentWagered={currentWagered}
          validFrom={validFrom}
          validTo={validTo}
          isClaimed={isClaimed}
          gamesArr={gamesArr}
          bonusType={bonusType}
          freeSpinsQty={freeSpinsQty}
          viewMore={viewMore}
          handleViewMore={setViewMore}
          termCondition={termCondition}
        />
      )}
      {forfeitConfirmationPopup && (
        <ForfeitConfirmationDialog
          forfeitConfirmationPopup={forfeitConfirmationPopup}
          setForfeitConfirmationPopup={setForfeitConfirmationPopup}
          handleActivateBonus={handleActivateBonus}
          claimed={claimed}
          isClaimed={isClaimed}
          loading={loading}
        />
      )}
    </BonusCardWrapper>
  )
}

export default BonusCard
