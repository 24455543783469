import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { ActiveBonusesWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveBonuses } from 'redux-thunk/thunk/bonus.thunk'
import { useTranslation } from 'react-i18next'
import { SpinnerSVG } from 'assets/icons/index'
import BonusCard from 'pages/Bonus/BonusCard'

const ActiveBonuses = ({ isVip }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const activeBonus = useSelector(state => state?.bonus?.activeBonus?.userBonus)
  const { bonusLoading } = useSelector(state => state?.bonus)

  useEffect(() => {
    dispatch(getActiveBonuses())
  }, [dispatch])

  const filteredBonuses = activeBonus?.rows?.filter(bonus =>
    isVip ? bonus?.bonus?.isVisibleInPromotions : !bonus?.bonus?.isVisibleInPromotions
  )

  const activeBonusElement = (
    <ActiveBonusesWrapper theme={theme} className='content-wrapper'>
      <Box className='offer-wrap'>
        {filteredBonuses?.length
          ? (
              filteredBonuses.map((data, i) => {
              const termCondition = typeof data?.termCondition === 'string' ? JSON.parse(data?.termCondition) : data?.termCondition;
              const description = typeof data?.bonus?.description === 'string' ? JSON.parse(data?.bonus?.description) : data?.bonus?.description;

                return (
                  <BonusCard
                    key={i}
                    data={data}
                    userBonusId = {data?.userBonusId}
                    bonusId={data?.bonusId}
                    bonusTitle={data?.bonus?.bonusTitle}
                    bonusCode={data?.bonus?.bonusCode}
                    description={description}
                    bonusType={data?.bonusSlug}
                    freeSpinsQty={data?.freeSpinsQty}
                    requiredWager={data?.requiredWagerMultiplier}
                    currentWagered={data?.currentWageredAmount}
                    validFrom={data?.updatedAt}
                    validTo={data?.expireAt}
                    termCondition={termCondition}
                    bonusImg={data?.bonusImg || '/assets/images/stock-images/offers/active-thumbnail.png'}
                    from='active'
                    gamesArr={data?.games}
                    className='active-bonus'
                  />
                )
              })
            )
          : (
            <Typography variant='h3' className='form-title'>
              {t(isVip ? 'noActiveVIPBonusFound' : 'noActiveBonusFound')}
            </Typography>
            )}
      </Box>
    </ActiveBonusesWrapper>
  )

  return (
    <>
      {bonusLoading
        ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SpinnerSVG style={{ fill: 'white' }} />
          </div>
          )
        : (
            activeBonusElement
          )}
    </>
  )
}

export default React.memo(ActiveBonuses)
