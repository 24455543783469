import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { SpinnerSVG } from 'assets/icons/index'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { OffersBonusesWrapper } from './style'
import { SpinnerSVG } from 'assets/icons/index'
import { useTranslation } from 'react-i18next'
import BonusCard from '../BonusCard'
import { getAllRewards } from 'redux-thunk/thunk/shop.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { defaultCurrency } from 'utils/constant'
const { REACT_APP_S3_URL } = process.env

const OffersBonuses = ({ from }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { [LOADER_HANDLER_TYPES.content]: contentLoading, [LOADER_HANDLER_TYPES.submit]: submitLoading } = useSelector(state => state.loader)
  const { t } = useTranslation()
  const userCurrency = useSelector((state) => state.user?.user)
  const { isAuthenticated } = useSelector((state) => state.gameSetting)

  const { allRewards } = useSelector(state => state?.shop)

  useEffect(() => {
    dispatch(getAllRewards())
  }, [])

  const OffersBonusElement = (
    <OffersBonusesWrapper theme={theme} className='content-wrapper'>
      <Box className='offer-wrap'>
        {allRewards?.rewards?.rows?.map((reward) => {
          return (
            <BonusCard
              rewardData={reward}
              key={reward?.id}
              userCurrency={userCurrency?.currencyCode || defaultCurrency}
              rewardId={reward?.id}
              rewardImage={reward?.imageUrl ? `${REACT_APP_S3_URL}/${reward?.imageUrl}` : '/assets/images/stock-images/offers/offer-thumbnail.png'}
              submitLoading={submitLoading}
              isAuthenticated={isAuthenticated}
            />
          )
        }
        )}
      </Box>
    </OffersBonusesWrapper>
  )
  return (
    <>
      {contentLoading
        ? <div style={{ display: 'flex', justifyContent: 'center', height: '400px' }}><SpinnerSVG style={{ fill: 'white' }} /></div>
        : allRewards?.rewards?.count ? OffersBonusElement : <Typography sx={{ height: '250px' }} variant='h3' className='form-title'>{t('noRewardDetailsFound')}</Typography>}
    </>
  )
}

export default React.memo(OffersBonuses)
