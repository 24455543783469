import React, { useEffect, useState, useCallback } from "react";
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { KycWrapper } from "./style";
import { Form, Formik } from "formik";
import ErrorMessage from "components/layout/ErrorMessage";
import { SpinnerSVG } from "assets/icons";
import {
  getDocumentsInfoRequest,
  getDocumentsLabelRequest,
  updateDocVerificationRequest,
} from "redux-thunk/thunk/user.thunk";
import FilePreview from "./ImagePreview";
import {
  CustomFileInput,
  CustomFormLabel,
} from "components/common-ui/CustomFormInputs/CustomFormInputs";
import { CustomPrimaryButton } from "components/common-ui/CustomButton/CustomButton";
import { LOADER_HANDLER_TYPES } from "constants/index";
const { REACT_APP_S3_URL } = process.env;

const Kyc = () => {
  const [files, setFiles] = useState({});
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    verificationLabels,
    verificationUserDocument,
    isKycLabelLoading,
    isKycDocumentLoading,
  } = useSelector((state) => state.user);
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  );

  useEffect(() => {
    dispatch(getDocumentsLabelRequest());
    dispatch(getDocumentsInfoRequest());
  }, [dispatch]);

  const onUpdateForm = () => {
    dispatch(getDocumentsInfoRequest());
  };

  const handleSave = useCallback(
    (formValues, documentLabelId) => {
      const formData = new FormData();
      formData.append("document", formValues[`file_${documentLabelId}`]);
      formData.append("documentLabelId", documentLabelId);
      dispatch(
        updateDocVerificationRequest({
          data: formData,
          onSuccess: onUpdateForm,
        })
      );
      setFiles({});
    },
    [dispatch]
  );

  const isDocumentSubmitted = useCallback(
    (documentLabelId) => {
      if (!verificationUserDocument?.userDocument?.rows?.length) {
        return false;
      }

      const document = verificationUserDocument?.userDocument?.rows.find(
        (row) => row.documentLabelId === documentLabelId
      );
      return document?.status !== 4 && document !== undefined;
    },
    [verificationUserDocument]
  );

  const getDocumentUrl = useCallback(
    (documentLabelId) => {
      if (!verificationUserDocument?.userDocument?.rows?.length) {
        return null;
      }

      const document = verificationUserDocument?.userDocument?.rows.find(
        (row) => row.documentLabelId === documentLabelId
      );
      return document ? `${REACT_APP_S3_URL}/${document?.documentUrl}` : null;
    },
    [verificationUserDocument]
  );

  const getReason = useCallback(
    (documentLabelId) => {
      if (!verificationUserDocument?.userDocument?.rows?.length) {
        return null;
      }

      const document = verificationUserDocument?.userDocument?.rows.find(
        (row) => row.documentLabelId === documentLabelId
      );
      return document?.reason || null;
    },
    [verificationUserDocument]
  );

  const handleFileChange = useCallback((event, labelId) => {
    const selectedFile = event.target.files[0];
    setFiles((prevFiles) => ({
      ...prevFiles,
      [labelId]: selectedFile,
    }));
  }, []);

  const renderSubmitButton = useCallback(
    (isSubmitted, values, documentLabelId) => {
      if (!verificationUserDocument?.userDocument?.rows?.length) {
        return loading && values[`file_${documentLabelId}`] ? <SpinnerSVG /> : t("submit");
      }

      const document = verificationUserDocument?.userDocument?.rows.find(
        (row) => row.documentLabelId === documentLabelId
      );

      if (loading) {
        return values[`file_${documentLabelId}`] ? (
          <SpinnerSVG />
        ) : document?.status === 4 || !document ? (
          t("submit")
        ) : (
          t("pending")
        );
      }
      return document?.status === 4 || !document ? t("submit") : t("pending");
    },
    [loading, t, verificationUserDocument]
  );

  const validationSchema = useCallback(
    (documentLabelId) =>
      Yup.object().shape({
        [`file_${documentLabelId}`]: Yup.mixed().required(t("required")),
      }),
    [t]
  );

  return (
    <KycWrapper theme={theme} className="content-wrapper">
      <Box className="content-wrapper">
        {isKycDocumentLoading || isKycLabelLoading ? (
          <Box className="content-loding-wrapper">
            {" "}
            <SpinnerSVG />{" "}
          </Box>
        ) : (
          <Grid container spacing={1.25} sx={{ rowGap: "20px" }}>
            {verificationLabels?.documentLabel.map((doc) => (
              <Grid item xs={12} lg={12} key={doc.documentLabelId}>
                <Formik
                  initialValues={{ [`file_${doc.documentLabelId}`]: null }}
                  validationSchema={validationSchema(doc.documentLabelId)}
                  onSubmit={(formValues) =>
                    handleSave(formValues, doc.documentLabelId)
                  }
                >
                  {({ values, setFieldValue, handleSubmit, errors }) => {
                    const document = verificationUserDocument?.userDocument?.rows?.find(
                      (row) => row.documentLabelId === doc.documentLabelId
                    );

                    const showFileUpload = !verificationUserDocument?.userDocument?.rows?.length ||
                      !isDocumentSubmitted(doc.documentLabelId) ||
                      document?.status === 4;

                    return (
                      <Form autoComplete="off">
                        <CustomFormLabel>{t(doc.name.EN)}</CustomFormLabel>
                        {showFileUpload && (
                          <Box
                            className="file-upload-box"
                            component="label"
                            htmlFor={`file_${doc.documentLabelId}`}
                          >
                            <CloudUploadIcon />
                            <CustomFileInput
                              id={`file_${doc.documentLabelId}`}
                              onChange={(event) => {
                                handleFileChange(event, doc.documentLabelId);
                                setFieldValue(
                                  `file_${doc.documentLabelId}`,
                                  event.target.files[0]
                                );
                              }}
                              error={!!errors[`file_${doc.documentLabelId}`]}
                              helperText={errors[`file_${doc.documentLabelId}`]}
                              fullWidth
                              accept=".pdf,.jpg,.jpeg,.png"
                            />
                          </Box>
                        )}
                        <ErrorMessage
                          component="div"
                          name={`file_${doc.documentLabelId}`}
                          className="error-message"
                        />
                        {!!files[doc.documentLabelId] && (
                          <FilePreview file={files[doc.documentLabelId]} />
                        )}
                        {getDocumentUrl(doc.documentLabelId) ? (
                          <>
                            <FilePreview
                              documentUrl={getDocumentUrl(doc.documentLabelId)}
                            />
                            {getReason(doc.documentLabelId) && (
                              <Typography variant="h3" className="title-text">
                                {t("kycStatus")}{" "}
                                <Typography>
                                  {getReason(doc.documentLabelId)}
                                </Typography>
                              </Typography>
                            )}
                          </>
                        ) : null}
                        <Box className="form-btn-box">
                          <Grid item xs={12} lg={4} margin="20px auto 0">
                            <CustomPrimaryButton
                              onClick={handleSubmit}
                              disabled={
                                loading ||
                                (isDocumentSubmitted(doc.documentLabelId) && document?.status !== 4)
                              }
                            >
                              {renderSubmitButton(
                                isDocumentSubmitted(doc.documentLabelId),
                                values,
                                doc.documentLabelId
                              )}
                            </CustomPrimaryButton>
                          </Grid>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </KycWrapper>
  );
};

export default React.memo(Kyc);
