import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@emotion/react'
import { Box, FormControl, Typography } from '@mui/material'
import { LobbyBannerWrapper } from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-creative'
import { Autoplay, EffectCreative, Pagination } from 'swiper/modules'
import { getProviderGameData } from 'redux-thunk/thunk/game.thunk'
import {
  getCasinoBannerList,
  getCasinoProviderList
} from 'redux-thunk/thunk/casino.thunk'
import Providers from './Providers/index'
import {
  setSelectedProviderData,
  setShowProviderGames,
  setShowTagGames
} from 'redux-thunk/redux/slices/gameSetting.slice'

const LobbyBanner = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { REACT_APP_S3_URL } = process.env
  const theme = useTheme()
  const bannerBgImage = theme.themeImages.bannerBgImage

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      const iconUrl = bannerList?.[index]?.bannerIcon ? `${REACT_APP_S3_URL}/${bannerList?.[index]?.bannerIcon}` : '/assets/images/stock-images/slider-icon-1.webp'
      return (
          // '<img src="/assets/images/stock-images/slider-icon-' +
          // (index + 1) +
          // '.webp" height="30px" width="30px" alt="slider-icon"/>' +
          `<span class="${className}"><img src="${iconUrl}" height="30px" width="30px" alt="slider-icon" /> </span>`
      )
    }
  }

  const { casinoProviderList, bannerList } = useSelector(
    (state) => state.casino
  )
  const { selectedProvierData, showTagsGames } = useSelector(
    (state) => state.gameSetting
  )
  const { selectedLanguage } = useSelector((state) => state.language)
  const [isChangeProviderModalOpen, setChangeProviderModalOpen] =
      useState(false)

  const [isSlidein, setIsSlideIn] = useState(true)

  const languageCode = selectedLanguage?.code.toUpperCase() || 'EN'

  useEffect(() => {
    dispatch(getCasinoProviderList())
    const data = {
      limit: 10,
      pageNo: 1
    }
    if (!bannerList) dispatch(getCasinoBannerList(data))
  }, [dispatch])

  const providerSelectHandler = (providerName, providerId) => {
    dispatch(setSelectedProviderData({ providerName, providerId }))
    dispatch(setShowProviderGames(true))
    if (showTagsGames) {
      dispatch(setShowTagGames(null))
    }
    const data = {
      provider: providerId
    }
    dispatch(getProviderGameData(data))
    setChangeProviderModalOpen(false)
    setIsSlideIn(true)
  }

  const handleImageError = (e) => {
    e.target.src = '/assets/images/stock-images/banner-error-img.png' // Replace with the path to your error image
  }

  return (
    <>
      <LobbyBannerWrapper theme={theme}>
        <img src={bannerBgImage} className='lobby-img' alt='Banner BG Img' />
        <Box className='slider-wrap'>
          <Swiper
            grabCursor
            effect='creative'
            pagination={pagination}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            creativeEffect={{
              prev: {
                shadow: true,
                origin: 'left center',
                translate: ['-5%', 0, -200],
                rotate: [0, 100, 0]
              },
              next: {
                origin: 'right center',
                translate: ['5%', 0, -200],
                rotate: [0, -100, 0]
              }
            }}
            modules={[Autoplay, EffectCreative, Pagination]}
            className='mySwiper'
          >
            {bannerList?.map(({ imageUrl, title, description, redirectUrl }, i) => {
              return (
                <SwiperSlide key={i}>
                  <img
                    src={`${REACT_APP_S3_URL}/${imageUrl}`}
                    className='banner-img'
                    alt='Banner Img'
                    onError={handleImageError}
                  />
                  <Box
                    className='text-box'
                    onClick={() => {
                      if (redirectUrl !== 'undefined' && !!redirectUrl) {
                        window.open(redirectUrl, '_blank', 'noreferrer')
                      }
                    }}
                  >
                    <Typography variant='h1' className='title-text'>{title?.[languageCode] || title?.EN || ''}</Typography>
                    <Typography variant='h1' className='pera-text'>
                      {description?.[languageCode] || description?.EN || ''}
                    </Typography>
                  </Box>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>

        <FormControl
          variant='outlined'
          className='providers-dropdown-wrap'
          onClick={() => {
            setIsSlideIn(false)
            setTimeout(() => {
              setChangeProviderModalOpen(true)
            }, 400)
          }}
        >
          <Box className='providers-popup-wrap'>
            {' '}
            {selectedProvierData?.providerName || t('provider')}
            <Box className='atm-card-animation-wrap'>
              <Box className='inner-box'>
                <Box className='animation-img-box'>
                  <img
                    src='/assets/images/stock-images/atm-card.png'
                      // className={`animated-img ${atTop ? '' : 'scroll-top-hide'}`}
                    className='animated-img'
                    alt='ATM Card'
                    style={isSlidein ? { transform: 'translateY(-3%) scale(1)' } : { transform: 'translateY(-150%) scale(0.4)' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </FormControl>
        <Providers
          dialogOpen={isChangeProviderModalOpen}
          handleClose={() => { setIsSlideIn(true); setChangeProviderModalOpen(false) }}
          casinoProviderList={casinoProviderList}
          providerSelectHandler={providerSelectHandler}
          selectedProvierData={selectedProvierData}
        />
      </LobbyBannerWrapper>
    </>
  )
}

export default React.memo(LobbyBanner)
