import { Box, styled } from '@mui/material'

export const Slider3DWrapper = styled(Box)(({ theme }) => {
  return {
    '& .slider-3d-wrap': {
      marginBottom: theme.spacing(1.25),
      '& .slider-3d-box': {
        '& .swiper': {
          padding: theme.spacing(1.5, 0, 2.5),
          '& .swiper-slide': {
            opacity: '0',
            filter: 'brightness(0.5)',
            transition: '300ms all',
            '&.swiper-slide-prev, &.swiper-slide-next': {
              opacity: '1'
            },
            '&.swiper-slide-active': {
              opacity: '1',
              filter: 'brightness(1)'
            }
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .slider-3d-wrap': {
        '& .animated-text': {
          fontSize: theme.spacing(1.5)
        },
        '& .slider-3d-box': {
          '& .swiper': {
            '& .swiper-wrapper': {
              '& .swiper-slide': {
                '& .MuiCard-root': {
                  '& .MuiCardActions-root': {
                    '& .MuiButtonBase-root': {
                      padding: theme.spacing(0.375, 0.5),
                      '& .inner-box': {
                        fontSize: theme.spacing(0.875),
                        padding: theme.spacing(0.5, 0.75)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
})
