import { createSlice } from '@reduxjs/toolkit'
import { getLocalWalletSetting, setLocalWalletSetting } from 'helpers/localstorage.helpers'
import { userLogout, verifyEmail } from 'redux-thunk/thunk/auth.thunk'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import {
  fetchUserInformation,
  getActiveJoiningBonus,
  getActiveReferralBonus,
  getUserPlacedBets,
  getWithdrawRequest,
  updateProfilePic,
  // getLoyaltyDetails,
  getDocumentsLabelRequest,
  getDocumentsInfoRequest,
  updateDocVerificationRequest
} from 'redux-thunk/thunk/user.thunk'

const initialState = {
  user: null,
  userWallet: [],
  selectedWallet: null,
  redirectToAfterLogin: '',
  transactions: {
    rows: [],
    count: 0
  },
  usersTransactions: null,
  topBetTransactions: {
    rows: [],
    count: 0
  },
  withdrawRequestData: null,
  qrCodeURl: null,
  showLoginOtp: false,
  siteInfo: null,
  roundData: null,
  showRoundHistoryModel: false,
  hideZeroCurrency: getLocalWalletSetting()?.hideZeroCurrency || false,
  displayFiatCurrency: getLocalWalletSetting()?.displayFiatCurrency || false,
  selectedFiat: getLocalWalletSetting()?.selectedFiat || 'USD',
  activeJoiningBonus: null,
  activeReferralBonus: null,
  emailVerificationError: null,
  loyaltyData: null,
  chat: [],
  verificationLabels: null,
  verificationUserDocument: null,
  verificationError: null,
  affiliateData: null,
  isKycLabelLoading: false,
  isKycDocumentLoading: false
}
const {
  actions: {
    setUserData,
    setQrcodeUrl,
    setSelectedWallet,
    setRedirectToAfterLogin,
    setAfterResetPassword,
    setTransactions,
    showLoginOtpHandle,
    setSiteInfo,
    setRoundData,
    setWalletSetting,
    setTopBetTransactions,
    setChat,
    setUsersTransactions,
    setLoyaltyDetails,
    setWalletData,
    setAffiliateData,
    setReferralLink
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setChat: (state, action) => {
      if (action.payload?.chatData) {
        const { chatData } = action.payload
        return {
          ...state,
          chat: [...state.chat, ...chatData]
        }
      }
      return {
        ...state,
        chat: [...state.chat, action.payload]
      }
    },
    setUserData: (state, action) => ({
      ...state,
      user: action.payload
    }),
    setQrcodeUrl: (state, action) => ({
      ...state,
      qrCodeURl: action.payload
    }),
    setSelectedWallet: (state, action) => ({
      ...state,
      selectedWallet: action.payload
    }),
    setRedirectToAfterLogin: (state, action) => ({
      ...state,
      redirectToAfterLogin: action.payload
    }),
    setAfterResetPassword: (state, action) => ({
      ...state,
      afterResetPassword: action.payload
    }),
    showLoginOtpHandle: (state, action) => ({
      ...state,
      showLoginOtp: action.payload
    }),
    setTransactions: (state, action) => ({
      ...state,
      transactions: action.payload
    }),
    setUsersTransactions: (state, action) => ({
      ...state,
      usersTransactions: action.payload
    }),
    setLoyaltyDetails: (state, action) => ({
      ...state,
      loyaltyData: action.payload
    }),

    setTopBetTransactions: (state, action) => ({
      ...state,
      topBetTransactions: action.payload
    }),
    setSiteInfo: (state, action) => ({
      ...state,
      siteInfo: action.payload
    }),
    setRoundData: (state, action) => ({
      ...state,
      roundData: action.payload,
      showRoundHistoryModel: !!action.payload
    }),
    setWalletSetting: (state, action) => {
      setLocalWalletSetting({
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
      return ({
        ...state,
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
    },
    setWalletData: (state, action) => {
      return ({
        ...state,
        userWallet: action.payload
      })
    },
    setAffiliateData: (state, action) => ({
      ...state,
      affiliateData: action.payload
    }),
    setReferralLink: (state, action) => ({
      ...state,
      referralLink: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload,
          userWallet: action?.payload?.userWallets[0],
          selectedWallet: action.payload?.wallets?.find(ele => ele.primary) || action?.payload?.userWallet?.amount
        }
      })
      .addCase(getAllSiteinfo.fulfilled, (state, action) => {
        return {
          ...state,
          siteInfo: action.payload?.cms
        }
      })
      .addCase(updateProfilePic.fulfilled, (state, action) => {
        return {
          ...state,
          user: { ...state.user, profileImageUrl: action.payload.location }
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getUserPlacedBets.fulfilled, (state, action) => {
        return {
          ...state,
          transactions: action.payload
        }
      })
      .addCase(getWithdrawRequest.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawRequestData: action.payload
        }
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload
        }
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload
        }
      })
      .addCase(getActiveJoiningBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeJoiningBonus: action.payload
        }
      })
      .addCase(getActiveReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeReferralBonus: action.payload
        }
      })
      .addCase(getDocumentsLabelRequest.pending, (state, action) => {
        return {
          ...state,
          isKycLabelLoading: true
        }
      })
      .addCase(getDocumentsLabelRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationLabels: action.payload,
          isKycLabelLoading: false
        }
      })
      .addCase(getDocumentsLabelRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload,
          isKycLabelLoading:false
        }
      })
      .addCase(getDocumentsInfoRequest.pending, (state, action) => {
        return {
          ...state,
          isKycDocumentLoading: true
        }
      })
      .addCase(getDocumentsInfoRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationUserDocument: action.payload,
          isKycDocumentLoading: false
        }
      })
      .addCase(getDocumentsInfoRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload,
          isKycDocumentLoading: false
        }
      })
      .addCase(updateDocVerificationRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationUserDocument: action.payload
        }
      })
      .addCase(updateDocVerificationRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload
        }
      })
  }
})

export default reducer
export {
  setUserData, setSelectedWallet, setRedirectToAfterLogin, setAfterResetPassword,
  setTransactions, setQrcodeUrl, showLoginOtpHandle, setSiteInfo, setRoundData,
  setWalletSetting, setTopBetTransactions, setChat, setUsersTransactions, setLoyaltyDetails, setWalletData, setAffiliateData, setReferralLink
}
