import { createAsyncThunk } from '@reduxjs/toolkit'
import { activateWageringService, forfeitBonusService, getAllBonusService, getUserBonusService, getBonusDetailsService, getLoyaltyLevelService, getUserBonusHistoryService, getUserActiveBonus, getUseBonusService, availBonusService } from 'network/services/bonus.service'
import { getLoyaltyLevelStart, getBonusDetails } from 'redux-thunk/redux/slices/bonus.slice'
import { getPromotionsService } from '../../network/services/bonus.service'
import { setActiveTabIndex } from 'redux-thunk/redux/slices/auth.slice'
export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const activateWagering = createAsyncThunk(
  'activate-bonus/wagering',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await activateWageringService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const forfeitBonus = createAsyncThunk(
  'forfiet/bonus',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await forfeitBonusService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getUserBonus = createAsyncThunk(
  'fetch/user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getActiveBonuses = createAsyncThunk(
  'fetch/active-user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserActiveBonus(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const activateBonus = createAsyncThunk(
  'fetch/activateBonus',
  async ({ data, setClaimed, setLoading, onSuccess }, thunkApi) => {
    try {
      const res = await getUseBonusService(data)
      setLoading(false)
      setClaimed(true)
      if (onSuccess) onSuccess()
      return res
    } catch (error) {
      setLoading(false)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getUserBonusHistory = createAsyncThunk(
  'fetch/user-bonus-history',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusHistoryService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const availBonus = createAsyncThunk(
  'fetch/apply-bonus',
  async ({ bonusCode, setLoading, handleSuccess }, thunkApi) => {
    try {
      const res = await availBonusService({ bonusCode })
      console.log(res)
      handleSuccess(res?.message || 'Bonus availed successfully')
      setActiveTabIndex(6)// redirect to history bonuses tab
      return res
    } catch (error) {
      setLoading(false)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getLoyaltyLevel = createAsyncThunk(
  'fetch/loyalty-level',
  async (_, thunkApi) => {
    try {
      const res = await getLoyaltyLevelService()
      thunkApi.dispatch(getLoyaltyLevelStart(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getBonusDetailsStart = createAsyncThunk(
  'fetch/user-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getBonusDetailsService(data)
      thunkApi.dispatch(getBonusDetails(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getPromotions = createAsyncThunk(
  'fetch/user-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getPromotionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
