import { Box, styled } from '@mui/material'

export const GamesSliderWrapper = styled(Box)(({ theme }) => {
  return {
    '& .games-slider-wrap': {
      marginBottom: theme.spacing(4.5),
      '& .game-slider-box': {
        position: 'relative',
        '& .swiper': {
          padding: theme.spacing(1.5, 0.5, 2.5),
          margin: theme.spacing(0, 3),
          position: 'static'
        }
      }
    },
    '& .swiper-button': {
      width: theme.spacing(3.625),
      height: theme.spacing(3.625),
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%) scale(0.95)',
      transition: '300ms all',
      '& .inner-box': {
        width: theme.spacing(2.625),
        height: theme.spacing(2.625),
        borderRadius: '50%'
      },
      '&.swiper-button-prev': {
        '& svg': {
          '& path': {
            transform: `translate(${theme.spacing(-0.125)})`
          }
        }
      },
      '&.swiper-button-next': {
        '& svg': {
          '& path': {
            transform: `translate(${theme.spacing(0.125)})`
          }
        }
      },
      '& svg': {
        width: theme.spacing(1.875),
        height: theme.spacing(1.875),
        fill: theme.palette.button.contrastTextMain,
        filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
      },
      '&:hover': {
        backgroundColor: theme.palette.solidColor.transparent
      },
      '&:active': {
        transform: 'scale(0.95) translateY(-50%)'
      },
      '&::after': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .games-slider-wrap': {
        '& .animated-text': {
          fontSize: theme.spacing(1.5)
        }
      }
    }
  }
})
