import { createTheme } from '@mui/material'
import '@fontsource/nunito-sans'
import '@fontsource/poppins'

export const darkTheme = createTheme({
  spacing: (factor) => `${1 * factor}rem`,

  palette: {
    mode: 'dark',

    success: {
      light: '#27D735',
      main: '#4EFF75',
      dark: '#31D34C'
    },

    danger: {
      main: '#ff3e33'
    },

    warning: {
      light: '#F4C471',
      main: '#FAD049',
      dark: '#EEC628'
    },

    lightShade: {
      main: '#FFFFFF',
      light_01: 'rgba(255, 255, 255, 0.1)',
      light_02: 'rgba(255, 255, 255, 0.2)',
      light_03: 'rgba(255, 255, 255, 0.3)',
      light_04: 'rgba(255, 255, 255, 0.4)',
      light_05: 'rgba(255, 255, 255, 0.5)',
      light_06: 'rgba(255, 255, 255, 0.6)',
      light_07: 'rgba(255, 255, 255, 0.7)',
      light_08: 'rgba(255, 255, 255, 0.8)',
      light_09: 'rgba(255, 255, 255, 0.9)'
    },

    darkShade: {
      main: '#000000',
      dark_01: 'rgba(0, 0, 0, 0.1)',
      dark_02: 'rgba(0, 0, 0, 0.2)',
      dark_03: 'rgba(0, 0, 0, 0.3)',
      dark_04: 'rgba(0, 0, 0, 0.4)',
      dark_05: 'rgba(0, 0, 0, 0.5)',
      dark_06: 'rgba(0, 0, 0, 0.6)',
      dark_07: 'rgba(0, 0, 0, 0.7)',
      dark_08: 'rgba(0, 0, 0, 0.8)',
      dark_09: 'rgba(0, 0, 0, 0.9)'
    },

    solidColor: {
      light: '#282072',
      main: '#2C1646',
      dark: '#0F0D23',
      solidColor_01: '#29226F',
      solidColor_02: '#332a6f',
      solidColor_03: '#351271',
      solidColor_04: '#F9F9F9',
      solidColor_05: '#472888',
      solidColor_06: '#1E1755',
      transparent: 'transparent',
      layoutBg: '#0F0D23',
      authModalContentBg: '#ABBEE3',
      bannerTextFill: '#FF4CE2'
    },

    gradientColor: {
      lightModeSwitch: 'linear-gradient(180deg, #FDF533 0%, #AC42FF 100%)',
      darkModeSwitch: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      gameCardOverlay:
        'linear-gradient(180deg, rgba(147, 34, 237, 0.74) 0%, rgba(22, 131, 210, 0.74) 100%)',
      coinAnimationStrip:
        'linear-gradient(180deg, rgba(255, 232, 24, 0.68) 0%, rgba(255, 246, 165, 0.19) 100%)',
      buttonImgBg: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      tabsActive: 'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
      DialogBg: 'linear-gradient(0deg, #338EFD 0%, #AC42FF 100%)',
      languageBgBox:
        'linear-gradient(0deg, rgba(14, 71, 153, 0) 0%, rgba(100, 34, 204, 1) 100%)',
      rankBox: 'linear-gradient(180deg, #02C415 0%, #56F05C 100%)',
      myAccountHeading:
        'linear-gradient(180deg, rgba(40, 32, 114, 0.50) 0%, rgba(89, 14, 178, 0.50) 100%)',
      footerTextBg:
        'linear-gradient(0deg, rgba(51, 142, 253, 0.00) 0%, #AC42FF 100%)',
      headerBg:
        'linear-gradient(180deg, rgba(40, 32, 114, 0.75) 0%, rgba(86, 10, 175, 0.75) 100%)',
      bannerText: 'linear-gradient(#FF4CE2 0%, #A433FD 100%)'
    },

    header: {
      light: 'rgb()',
      main: 'rgb()',
      dark: 'rgb()',
      borderLight: 'rgb()',
      borderMain: '#4D288D',
      borderDark: 'rgb()'
    },

    footer: {
      light: '#F6F6F6',
      main: '#FFFFFF',
      dark: 'rgb()',
      borderLight: 'rgb()',
      borderMain: 'rgb()',
      borderDark: 'rgb()'
    },

    border: {
      light: '#338EFD',
      main: '#4D288D',
      dark: '#403A79',
      active: '#29226F',
      border_01: '#CCB4FF',
      border_02: '#1E1B39',
      border_03: '#41C1F6',
      border_04: '#F16CE7',
      transparent: 'transparent'
    },

    button: {
      light: 'rgb()',
      main: 'rgb()',
      dark: 'rgb()',
      active: 'rgb()',
      borderLight: 'rgb()',
      borderMain: 'rgb()',
      borderDark: 'rgb()',
      borderActive: 'rgb()',
      contrastTextLight: 'rgb()',
      contrastTextMain: '#FFFFFF',
      contrastTextDark: 'rgb()',
      contrastTextActive: 'rgb()',
      transparent: 'transparent',
      mainGradient: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      mainGradientInner: 'linear-gradient(180deg, #42A2F6 0%, #56F0E0 100%)',
      mainBorderInner: '#BDF3FF',
      primaryGradient: 'linear-gradient(180deg, #2CD910 0%, #AC42FF 100%)',
      primaryGradientInner: 'linear-gradient(180deg, #02C415 0%, #56F05C 100%)',
      primaryBorderInner: '#BDF3FF',
      secondaryGradient: 'linear-gradient(180deg, #FD8933 0%, #FEA641 100%)',
      secondaryGradientInner:
        'linear-gradient(180deg, #FFD11E 0%, #FE3714 100%)',
      secondaryBorderInner: '#FAFFBD',
      tertiaryGradient: 'linear-gradient(180deg, #DE0909 0%, #FF4242 100%)',
      tertiaryGradientInner:
        'linear-gradient(180deg, #F64242 0%, #D70B0B 100%)',
      tertiaryBorderInner: '#FFBDBD',
      quaternaryGradient: 'linear-gradient(180deg, #A433FD 0%, #FF4CE2 100%)',
      quaternaryGradientInner:
        'linear-gradient(180deg, #F642D9 0%, #A221F1 100%)',
      quaternaryBorderInner: '#FABDFF',
      quinaryGradient: 'linear-gradient(180deg, #3B146B 0%, #7B02C8 100%)',
      quinaryGradientInner: 'linear-gradient(180deg, #0F0D23 0%, #8B1CCF 100%)',
      quinaryBorderInner: '#DE32FA',
      hoverGradient: 'linear-gradient(180deg, #8F22C7 0%, #8F22C7 100%)',
      hoverGradientInner: 'linear-gradient(180deg, #FFAA6A 0%, #901BD1 100%)',
      hoverBorderInner: '#BDF3FF'
    },

    formInput: {
      light: '#372d9e',
      main: '#29226F',
      dark: '#2C1646',
      active: 'rgb()',
      borderLight: '#CCB4FF',
      borderMain: '#FFFFFF',
      borderDark: '#4D288D',
      borderActive: 'rgb()',
      contrastTextLight: 'rgb()',
      contrastTextMain: '#FFFFFF',
      contrastTextDark: 'rgb()',
      contrastTextActive: 'rgb()',
      contrastTextWhite: '#FFFFFF',
      transparent: 'transparent',
      contrastPlaceholder: '#ABA1FF'
    },

    typographyText: {
      textLight: '#282072',
      textMain: '#FFFFFF',
      textDark: '#0F0D23',
      textActive: '#29226F',
      textWhite: '#FFFFFF',
      text_01: '#1E1B39',
      text_02: '#F19AFF',
      text_03: '#00C7FE',
      text_04: '#FFFFFF',
      sectionHeading: '#15fff1',
      containerHeading: '#FFFFFF',
      transparent: 'transparent'
    }
  },

  typography: {
    // fontFamily: 'Heavitas'
    fontFamily: ['Nunito Sans, sans-serif', 'Poppins, sans-serif'].join(',')
  },

  shadows: [
    'none',
    '0px 0px 0.625rem rgba(116, 62, 232, 0.65)',
    '0.063rem 0.125rem 0.125rem rgba(0, 0, 0, 0.53)',
    '0 0 3.125rem rgba(184, 125, 19, 0.65)',
    '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    '0.563rem 0rem 0.563rem rgba(0, 0, 0, 0.25)'
  ],

  textShadows: [
    'none',
    '0 0 0.188rem #ffffff, 0 0 0.625rem #15fff1, 0 0 1.25rem #15fff1',
    '0px 10px 9.1px rgba(36, 216, 255, 0.43)',
    '-5px -5px 10px #FF4CE2, 5px 5px 10px #A433FD',
    '-6px -4px 8px #0000FF, -5px 5px 10px #FF4CE2, 0px 0px 10px #ffffff', // banner text shadow
    '0 0 0.1rem #ffffff,0 0 0.25rem #15fff1,0 0 0.5rem #15fff1', // text-animation shadow for polish language
    '0px 3px 3px rgba(0, 0, 0, 0.25)',
    '0px 6px 5px rgba(0, 0, 0, 0.4)'
  ],

  breakpoints: {
    values: {
      xs: 0,
      sd: 425,
      sm: 768,
      md: 992,
      xm: 1070,
      lg: 1200,
      ml: 1396,
      xl: 1536,
      xxl: 1706,
      xxxl: 1920,
      customModal_lg: 1370,
      customModal_xm: 1286,
      customModal_sm: 640
    }
  },

  themeImages: {
    brandLogo: '/assets/images/logo/brand-logo2.png',
    layoutBgImage: '/assets/images/stock-images/bg-img.webp',
    bannerBgImage: '/assets/images/stock-images/banner-bg-img.webp',
    modalBgAtmImage: '/assets/images/stock-images/modal-bg-atm.png',
    matrixBG: '/assets/images/stock-images/matrix-dark-bg.png',
    atmMachineBG: '/assets/images/stock-images/dark-machine-bg.png',
    tournamentBorder: '/assets/images/stock-images/tournament-img-border.png',
    tournamentDetailsBorder:
      '/assets/images/stock-images/tournament-detailsimg-border.png',
    paymentModalBorder:
      '/assets/images/stock-images/payment-modal-bg-border.png',
    modalBorder: '/assets/images/stock-images/modal-bg-border.png'
  },

  layout: {
    maxWidth: '120rem'
  },

  components: {
    // SELECT MENU CUSTOMIZE
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#372d9e',
          backgroundImage: 'none',
          border: '2px solid #29226F',
          overflow: 'hidden',
          padding: '0.25rem 0.5rem'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '9.563rem',
          overflowY: 'auto',
          padding: '0',
          '&::-webkit-scrollbar': {
            width: '0.25rem',
            height: '0.25rem',
            background: 'rgba(0, 0, 0, 0.3)'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#F4C471'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          fontSize: '1rem',
          fontWeight: '500',
          lineHeight: 'normal'
        }
      }
    },

    // CHECKBOX CUSTOMIZE
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.checkbox-wrap': {
            '& .MuiFormControlLabel-root': {
              color: '#FFFFFF',
              fontSize: '0.875rem',
              fontWeight: '400',
              lineHeight: 'normal',
              alignItems: 'flex-start',
              margin: '0',
              '& .MuiButtonBase-root': {
                padding: '0.5rem',
                '& svg': {
                  width: '1.5rem',
                  height: '1.5rem'
                }
              },
              '& .MuiTypography-root': {
                marginTop: '0.5rem'
              }
            }
          }
        }
      }
    },

    // PAGINATION CUSTOMIZE
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: '1rem',
          marginBottom: '1rem'
        },
        ul: {
          justifyContent: 'center',
          flexWrap: 'nowrap',
          '& .MuiButtonBase-root': {
            width: '2.125rem',
            height: '2.75rem',
            backgroundColor: 'rgba(41, 34, 111, 0.30)',
            borderRadius: '2.125rem',
            color: 'rgba(255, 255, 255, 0.35)',
            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: 'normal',
            padding: '0.688rem 0.75rem',
            '&:hover': {
              backgroundColor: 'rgba(41, 34, 111, 0.30)',
              color: '#FFFFFF'
            },
            '&.Mui-selected': {
              backgroundColor: '#29226F',
              color: '#FFFFFF'
            },
            '&.Mui-disabled': {
              opacity: '1',
              color: 'rgba(255, 255, 255, 0.35)',
              '& svg': {
                fill: 'rgba(255, 255, 255, 0.35)'
              }
            },
            '& svg': {
              fill: '#FFFFFF'
            }
          },
          '& li:first-child': {
            marginRight: 'auto',
            '& .MuiButtonBase-root': {
              backgroundColor: 'transparent'
            }
          },
          '& li:last-child': {
            marginLeft: 'auto',
            '& .MuiButtonBase-root': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    },

    // DATE & TIME PICKER CUSTOMIZE
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          backgroundColor: '#372d9e',
          border: '2px solid #29226F',
          '& .MuiDivider-root': {
            borderColor: '#29226F'
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-today': {
            border: '1px solid #F4C471 !important',
            color: '#F4C471'
          },
          '& .MuiButtonBase-root, & .MuiPickersYear-yearButton, & .MuiPickersMonth-monthButton':
            {
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.08)'
              }
            },
          '& .MuiButtonBase-root.Mui-selected, & .MuiPickersYear-yearButton.Mui-selected, & .MuiPickersMonth-monthButton.Mui-selected':
            {
              background:
                'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
              color: '#29226F',
              fontWeight: '800'
            }
        }
      }
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiList-root:not(:first-of-type)': {
            borderLeft: '1px solid #29226F'
          },
          '& .MuiButtonBase-root': {
            fontSize: '12px',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08)'
            }
          },
          '& .MuiButtonBase-root.Mui-selected': {
            background:
              'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
            color: '#29226F',
            fontWeight: '800'
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            color: '#FFFFFF',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08)'
            }
          }
        }
      }
    },

    // TOOLTIP CUSTOMIZE
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'linear-gradient(to top right, #338EFD 0%, #AC42FF 100%)',
          color: '#FFFFFF',
          fontSize: '0.75rem'
        },
        arrow: {
          color: '#AC42FF'
        }
      }
    },

    // TOUCH RIPPLE CUSTOMIZE
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    }
  }
})

export const lightTheme = createTheme({
  spacing: (factor) => `${1 * factor}rem`,

  palette: {
    mode: 'light',

    success: {
      main: '#4EFF75',
      dark: '#31D34C'
    },

    danger: {
      main: '#ff3e33'
    },

    warning: {
      light: '#F4C471',
      main: '#FAD049',
      dark: '#EEC628'
    },

    lightShade: {
      main: '#FFFFFF',
      light_01: 'rgba(255, 255, 255, 0.1)',
      light_02: 'rgba(255, 255, 255, 0.2)',
      light_03: 'rgba(255, 255, 255, 0.3)',
      light_04: 'rgba(255, 255, 255, 0.4)',
      light_05: 'rgba(255, 255, 255, 0.5)',
      light_06: 'rgba(255, 255, 255, 0.6)',
      light_07: 'rgba(255, 255, 255, 0.7)',
      light_08: 'rgba(255, 255, 255, 0.8)',
      light_09: 'rgba(255, 255, 255, 0.9)'
    },

    darkShade: {
      main: '#000000',
      dark_01: 'rgba(0, 0, 0, 0.1)',
      dark_02: 'rgba(0, 0, 0, 0.2)',
      dark_03: 'rgba(0, 0, 0, 0.3)',
      dark_04: 'rgba(0, 0, 0, 0.4)',
      dark_05: 'rgba(0, 0, 0, 0.5)',
      dark_06: 'rgba(0, 0, 0, 0.6)',
      dark_07: 'rgba(0, 0, 0, 0.7)',
      dark_08: 'rgba(0, 0, 0, 0.8)',
      dark_09: 'rgba(0, 0, 0, 0.9)'
    },

    solidColor: {
      light: '#A08144',
      main: '#E9CA8D',
      dark: '#0F0D23',
      solidColor_01: '#87672a',
      solidColor_02: '#332a6f',
      solidColor_03: '#A08144',
      solidColor_04: '#F9F9F9',
      solidColor_05: '#472888',
      solidColor_06: '#ede0b3',
      transparent: 'transparent',
      layoutBg: '#FFFFFF',
      authModalContentBg: '#cdbe8b',
      bannerTextFill: '#FF4CE2'
    },

    gradientColor: {
      lightModeSwitch: 'linear-gradient(180deg, #FDF533 0%, #AC42FF 100%)',
      darkModeSwitch: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      gameCardOverlay:
        'linear-gradient(180deg, rgba(147, 34, 237, 0.74) 0%, rgba(22, 131, 210, 0.74) 100%)',
      coinAnimationStrip:
        'linear-gradient(180deg, rgba(255, 232, 24, 0.68) 0%, rgba(255, 246, 165, 0.19) 100%)',
      buttonImgBg: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      tabsActive: 'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
      DialogBg: 'linear-gradient(0deg, #A08144 0%, #cdbe8b 100%)',
      languageBgBox:
        'linear-gradient(0deg, rgba(205, 190, 139, 0) 0%, rgba(160, 129, 68, 1) 100%)',
      rankBox: 'linear-gradient(180deg, #02C415 0%, #56F05C 100%)',
      myAccountHeading:
        'linear-gradient(180deg, rgba(160, 129, 68, 0.75) 0%, rgba(205, 190, 139, 0.75) 100%)',
      footerTextBg:
        'linear-gradient(0deg, rgba(255, 245, 221, 0.00) 0%, #AC42FF 100%)',
      headerBg:
        'linear-gradient(180deg, rgba(160, 129, 68, 1) 0%, rgba(205, 190, 139, 1) 100%)',
      bannerText: 'linear-gradient(#FF4CE2 0%, #A433FD 100%)'
    },

    header: {
      light: 'rgb()',
      main: 'rgb()',
      dark: 'rgb()',
      borderLight: 'rgb()',
      borderMain: '#C6AB77',
      borderDark: 'rgb()'
    },

    footer: {
      light: '#F6F6F6',
      main: '#FFF5DD',
      dark: 'rgb()',
      borderLight: 'rgb()',
      borderMain: 'rgb()',
      borderDark: 'rgb()'
    },

    border: {
      light: '#664403',
      main: '#4D288D',
      dark: '#403A79',
      active: '#29226F',
      border_01: '#CCB4FF',
      border_02: '#1E1B39',
      border_03: '#A08144',
      border_04: '#664403',
      transparent: 'transparent'
    },

    button: {
      light: 'rgb()',
      main: 'rgb()',
      dark: 'rgb()',
      active: 'rgb()',
      borderLight: 'rgb()',
      borderMain: 'rgb()',
      borderDark: 'rgb()',
      borderActive: 'rgb()',
      contrastTextLight: 'rgb()',
      contrastTextMain: '#FFFFFF',
      contrastTextDark: 'rgb()',
      contrastTextActive: 'rgb()',
      transparent: 'transparent',
      mainGradient: 'linear-gradient(180deg, #338EFD 0%, #AC42FF 100%)',
      mainGradientInner: 'linear-gradient(180deg, #42A2F6 0%, #56F0E0 100%)',
      mainBorderInner: '#BDF3FF',
      primaryGradient: 'linear-gradient(180deg, #2CD910 0%, #AC42FF 100%)',
      primaryGradientInner: 'linear-gradient(180deg, #02C415 0%, #56F05C 100%)',
      primaryBorderInner: '#BDF3FF',
      secondaryGradient: 'linear-gradient(180deg, #FD8933 0%, #FEA641 100%)',
      secondaryGradientInner:
        'linear-gradient(180deg, #FFD11E 0%, #FE3714 100%)',
      secondaryBorderInner: '#FAFFBD',
      tertiaryGradient: 'linear-gradient(180deg, #DE0909 0%, #FF4242 100%)',
      tertiaryGradientInner:
        'linear-gradient(180deg, #F64242 0%, #D70B0B 100%)',
      tertiaryBorderInner: '#FFBDBD',
      quaternaryGradient: 'linear-gradient(180deg, #A433FD 0%, #FF4CE2 100%)',
      quaternaryGradientInner:
        'linear-gradient(180deg, #F642D9 0%, #A221F1 100%)',
      quaternaryBorderInner: '#FABDFF',
      quinaryGradient: 'linear-gradient(180deg, #3B146B 0%, #7B02C8 100%)',
      quinaryGradientInner: 'linear-gradient(180deg, #0F0D23 0%, #8B1CCF 100%)',
      quinaryBorderInner: '#DE32FA',
      hoverGradient: 'linear-gradient(180deg, #8F22C7 0%, #8F22C7 100%)',
      hoverGradientInner: 'linear-gradient(180deg, #FFAA6A 0%, #901BD1 100%)',
      hoverBorderInner: '#BDF3FF'
    },

    formInput: {
      light: '#372d9e',
      main: '#A08144',
      dark: '#A08144',
      active: 'rgb()',
      borderLight: '#E9CA8D',
      borderMain: '#E9CA8D',
      borderDark: '#E9CA8D',
      borderActive: 'rgb()',
      contrastTextLight: 'rgb()',
      contrastTextMain: '#664403',
      contrastTextDark: 'rgb()',
      contrastTextActive: 'rgb()',
      contrastTextWhite: '#FFFFFF',
      transparent: 'transparent',
      contrastPlaceholder: '#664403'
    },

    typographyText: {
      textLight: '#282072',
      textMain: '#664403',
      textDark: '#0F0D23',
      textActive: '#664403',
      textWhite: '#FFFFFF',
      text_01: '#664403',
      text_02: '#F19AFF',
      text_03: '#664403',
      text_04: '#000000',
      sectionHeading: '#9F8E6E',
      containerHeading: '#664403',
      transparent: 'transparent'
    }
  },

  typography: {
    // fontFamily: 'Heavitas'
    fontFamily: ['Nunito Sans, sans-serif', 'Poppins, sans-serif'].join(',')
  },

  shadows: [
    'none',
    '0px 0px 0.625rem rgba(116, 62, 232, 0.65)',
    '0.063rem 0.125rem 0.125rem rgba(0, 0, 0, 0.53)',
    '0 0 3.125rem rgba(184, 125, 19, 0.65)',
    '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    '0.563rem 0rem 0.563rem rgba(0, 0, 0, 0.25)'
  ],

  textShadows: [
    'none',
    '0 0 0.188rem #ffffff, 0 0 0.625rem #9F8E6E, 0 0 1.25rem #9F8E6E',
    '0px 10px 9.1px rgba(102, 68, 3, 0.43)',
    '-5px -5px 10px #FF4CE2, 5px 5px 10px #A433FD',
    '-6px -4px 8px #0000FF, -5px 5px 10px #FF4CE2, 0px 0px 10px #ffffff',
    '0 0 0.1rem #ffffff, 0 0 0.25rem #9F8E6E, 0 0 0.5rem #83704b', // text-animation shadow for polish language
    '0px 3px 3px rgba(0, 0, 0, 0.25)',
    '0px 6px 5px rgba(0, 0, 0, 0.4)'
  ],

  breakpoints: {
    values: {
      xs: 0,
      sd: 425,
      sm: 768,
      md: 992,
      xm: 1070,
      lg: 1200,
      ml: 1396,
      xl: 1536,
      xxl: 1706,
      xxxl: 1920,
      customModal_lg: 1370,
      customModal_xm: 1286,
      customModal_sm: 640
    }
  },

  themeImages: {
    brandLogo: '/assets/images/logo/brand-logo2-light.png',
    layoutBgImage: '/assets/images/stock-images/bg-img-light.webp',
    bannerBgImage: '/assets/images/stock-images/banner-bg-img-light.webp',
    modalBgAtmImage: '/assets/images/stock-images/modal-bg-atm-light.png',
    matrixBG: '/assets/images/stock-images/matrix-light-bg.png',
    atmMachineBG: '/assets/images/stock-images/light-machine-bg.png',
    tournamentBorder:
      '/assets/images/stock-images/tournament-img-border-light.png',
    tournamentDetailsBorder:
      '/assets/images/stock-images/tournament-detailsimg-border-light.png',
    paymentModalBorder:
      '/assets/images/stock-images/payment-modal-bg-border-light.png',
    modalBorder: '/assets/images/stock-images/modal-bg-border-light.png'
  },

  layout: {
    maxWidth: '120rem'
  },

  components: {
    // SELECT MENU CUSTOMIZE
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#372d9e',
          backgroundImage: 'none',
          border: '2px solid #29226F',
          overflow: 'hidden',
          padding: '0.25rem 0.5rem'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '9.563rem',
          overflowY: 'auto',
          padding: '0',
          '&::-webkit-scrollbar': {
            width: '0.25rem',
            height: '0.25rem',
            background: 'rgba(0, 0, 0, 0.3)'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#F4C471'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          fontSize: '1rem',
          fontWeight: '500',
          lineHeight: 'normal'
        }
      }
    },

    // CHECKBOX CUSTOMIZE
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.checkbox-wrap': {
            '& .MuiFormControlLabel-root': {
              color: '#664403',
              fontSize: '0.875rem',
              fontWeight: '400',
              lineHeight: 'normal',
              alignItems: 'flex-start',
              margin: '0',
              '& .MuiButtonBase-root': {
                padding: '0.5rem',
                '& svg': {
                  width: '1.5rem',
                  height: '1.5rem'
                }
              },
              '& .MuiTypography-root': {
                marginTop: '0.5rem'
              }
            }
          }
        }
      }
    },

    // PAGINATION CUSTOMIZE
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: '1rem',
          marginBottom: '1rem'
        },
        ul: {
          justifyContent: 'center',
          flexWrap: 'nowrap',
          '& .MuiButtonBase-root': {
            width: '2.125rem',
            height: '2.75rem',
            backgroundColor: 'rgba(120, 68, 3, 0.30)',
            borderRadius: '2.125rem',
            color: 'rgba(255, 255, 255, 0.35)',
            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: 'normal',
            padding: '0.688rem 0.75rem',
            '&:hover': {
              backgroundColor: 'rgba(120, 68, 3, 0.30)',
              color: '#FFFFFF'
            },
            '&.Mui-selected': {
              backgroundColor: '#664403',
              color: '#FFFFFF'
            },
            '&.Mui-disabled': {
              opacity: '1',
              color: 'rgba(255, 255, 255, 0.35)',
              '& svg': {
                fill: 'rgba(255, 255, 255, 0.35)'
              }
            },
            '& svg': {
              fill: '#FFFFFF'
            }
          },
          '& li:first-child': {
            marginRight: 'auto',
            '& .MuiButtonBase-root': {
              backgroundColor: 'transparent'
            }
          },
          '& li:last-child': {
            marginLeft: 'auto',
            '& .MuiButtonBase-root': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    },

    // DATE & TIME PICKER CUSTOMIZE
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          backgroundColor: '#cbbb87',
          border: '2px solid #A08144',
          '& .MuiDivider-root': {
            borderColor: '#A08144'
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-today': {
            border: '1px solid #F4C471 !important',
            color: '#F4C471'
          },
          '& .MuiButtonBase-root, & .MuiPickersYear-yearButton, & .MuiPickersMonth-monthButton':
            {
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.08)'
              }
            },
          '& .MuiButtonBase-root.Mui-selected, & .MuiPickersYear-yearButton.Mui-selected, & .MuiPickersMonth-monthButton.Mui-selected':
            {
              background:
                'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
              color: '#29226F',
              fontWeight: '800'
            }
        }
      }
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiList-root:not(:first-of-type)': {
            borderLeft: '1px solid #29226F'
          },
          '& .MuiButtonBase-root': {
            fontSize: '12px',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08)'
            }
          },
          '& .MuiButtonBase-root.Mui-selected': {
            background:
              'linear-gradient(90deg, #FFD964 12.09%, #DDAD1C 89.89%)',
            color: '#29226F',
            fontWeight: '800'
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            color: '#FFFFFF',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08)'
            }
          }
        }
      }
    },

    // TOOLTIP CUSTOMIZE
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'linear-gradient(to top right, #338EFD 0%, #AC42FF 100%)',
          color: '#FFFFFF',
          fontSize: '0.75rem'
        },
        arrow: {
          color: '#AC42FF'
        }
      }
    },

    // TOUCH RIPPLE CUSTOMIZE
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    }
  }
})

export const useThemeSwitch = (isDarkMode) => {
  return isDarkMode ? darkTheme : lightTheme
}
