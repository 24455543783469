import { Box, styled } from '@mui/material'

export const HomeWrapper = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    maxWidth: theme.spacing(85.25),
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .more-games-btn-box': {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(4, 0)
    },
    '& .footer-text-wrap': {
      minHeight: theme.spacing(25),
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(1.25),
      zIndex: '0',
      padding: theme.spacing(2, 2.875, 2, 1.25),
      marginTop: theme.spacing(8),
      position: 'relative',
      '& .footer-text-box': {
        background: theme.palette.gradientColor.footerTextBg,
        maskImage: 'url("/assets/images/stock-images/footer-text-bg-img-mask.png")',
        maskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        overflow: 'hidden',
        zIndex: '-1',
        position: 'absolute',
        inset: theme.spacing(0)
      },
      '& .logo-img': {
        width: '100%',
        maxWidth: theme.spacing(25.813),
        height: 'auto',
        zIndex: '1',
        position: 'absolute',
        top: theme.spacing(-2.5),
        left: '50%',
        transform: 'translateX(-50%)'
      },
      '& .cash-machine-img': {
        width: theme.spacing(18.125),
        maxWidth: '100%',
        height: '100%',
        transform: `translateY(${theme.spacing(-6)})`
      },
      '& .text-box': {
        paddingTop: theme.spacing(3),
        '& .MuiTypography-root': {
          fontFamily: 'Poppins, sans-serif',
          color: theme.palette.typographyText.text_04,
          fontSize: theme.spacing(1.25),
          fontWeight: '500',
          lineHeight: 'normal',
          '& .MuiTypography-caption': {
            color: theme.palette.warning.main,
            fontWeight: '700',
            display: 'inline-block'
          },
          '& .MuiLink-root': {
            color: theme.palette.typographyText.text_04,
            fontWeight: '500',
            textDecoration: 'underline',
            display: 'inline-block'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .footer-text-wrap': {
        minHeight: 'unset',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: theme.spacing(2, 1.25),
        '& .logo-img': {
          maxWidth: theme.spacing(20),
          top: theme.spacing(-2),
          left: 'auto',
          right: theme.spacing(2),
          transform: 'none'
        },
        '& .cash-machine-img': {
          width: theme.spacing(12),
          transform: `translateY(${theme.spacing(-5)})`
        },
        '& .text-box': {
          marginTop: theme.spacing(-8),
          '& .MuiTypography-root': {
            fontSize: theme.spacing(0.875)
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .footer-text-wrap': {
        '& .logo-img': {
          maxWidth: theme.spacing(10),
          top: theme.spacing(-1),
          left: 'auto',
          right: theme.spacing(1),
          transform: 'none'
        },
        '& .cash-machine-img': {
          width: theme.spacing(6),
          transform: `translateY(${theme.spacing(-3.75)})`
        },
        '& .text-box': {
          marginTop: theme.spacing(-7)
        }
      }
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 'calc(-100dvh + 8rem)'
    }
  }
})
