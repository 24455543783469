import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { Box, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { SideNavbarWrapper } from './style'
import { CustomAnimatedButton, CustomMainButton, CustomSecondaryButton } from 'components/common-ui/CustomButton/CustomButton'
import CustomMenuDropdown from 'components/common-ui/CustomMenuDropdown/CustomMenuDropdown'
import LanguageSwitch from '../LanguageSwitch/index'
import Lottie from 'lottie-react'
import roboflyAnimation from '../../../assets/lottie-animation-json/robofly.json'
import roboflyLightAnimation from '../../../assets/lottie-animation-json/robofly-light-new.json'
import { getCasinoGamesList, getFilteredGameData } from 'redux-thunk/thunk/game.thunk'
import { clearFilteredGames, clearSearchedGames, setGameCategoryId, setSearch } from 'redux-thunk/redux/slices/games.slice'
import { ROUTE_PATHS } from 'constants/index'
import { setSelectedProviderData, setShowFavoriteGames, setShowProviderGames, setShowRandomGames, setShowTagGames } from 'redux-thunk/redux/slices/gameSetting.slice'
import { useTranslation } from 'react-i18next'
// import AffiliateModal from 'pages/AffiliateModal/index'
// import ReferralModal from 'pages/ReferralModal/index'
import { setAuthModal, setUserModal } from 'redux-thunk/redux/slices/auth.slice'
// import { GameTagTitle } from 'config/game.config'

// const tagGameList = Object.keys(GameTagTitle)

const SideNavbar = ({ setDialogOpen, slideOpen, closeSlide }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categoryWiseGamesList, search } = useSelector((state) => state.games)
  const { isAuthenticated, isDarkMode } = useSelector((state) => state.gameSetting)
  const [anchorElGames, setAnchorElGames] = React.useState(null)
  const [liveAnchorGames, setLiveAnchorGames] = React.useState(null)
  const { selectedLanguage } = useSelector((state) => state.language)
  const languageCode = selectedLanguage?.code.toUpperCase() || 'EN'
  const { t } = useTranslation()
  const handleOpenGamesMenu = (event) => {
    setAnchorElGames(event.currentTarget)
  }
  const handleCloseGamesMenu = () => {
    setAnchorElGames(null)
  }

  const handleOpenLiveGamesMenu = (event) => {
    setLiveAnchorGames(event.currentTarget)
  }

  const handleCloseLiveGamesMenu = () => {
    setLiveAnchorGames(null)
  }

  const handleGamesMenuClick = (type, isActive, tagGameKey) => {
    if (isActive === false) {
      setAnchorElGames(null)
      setLiveAnchorGames(null)
      return
    }
    dispatch(setShowFavoriteGames(false))
    dispatch(setShowRandomGames(false))
    dispatch(setShowProviderGames(false))
    dispatch(setShowTagGames(null))
    dispatch(setSelectedProviderData(null))
    if (type === 'All Games') {
      dispatch(setGameCategoryId(null))
      dispatch(clearFilteredGames())

      setAnchorElGames(null)
      setLiveAnchorGames(null)
      if (window.location?.pathname !== '/') {
        navigate('/')
      }
    } else if (type === 'Tag Games') {
      dispatch(setShowTagGames({ tag: tagGameKey }))
      window.scrollTo({ top: 500, behavior: 'smooth' })
      setAnchorElGames(null)
    } else {
      dispatch(clearFilteredGames())
      const data = {
        limit: 15,
        pageNo: 1,
        category: type.id
      }
      dispatch(setGameCategoryId(type.id))
      dispatch(getFilteredGameData({ data, navigate }))
      setAnchorElGames(null)
      setLiveAnchorGames(null)
    }
    if (search) {
      dispatch(setSearch(''))
      dispatch(clearSearchedGames())
    }
    !isDesktop && closeSlide()
  }

  useEffect(() => {
    sessionStorage.removeItem('tab')
  }, [])

  useEffect(() => {
    if (!(categoryWiseGamesList && categoryWiseGamesList?.length > 0)) {
      const data = {
        categoryPageNo: 1,
        gameLimit: 12
      }
      dispatch(getCasinoGamesList({ data }))
    }
  }, [categoryWiseGamesList])

  const handleRandomGames = () => {
    dispatch(setSearch(''))
    dispatch(clearSearchedGames())
    dispatch(setShowRandomGames(true))
    dispatch(setGameCategoryId(null))
    dispatch(setShowFavoriteGames(false))
    dispatch(clearFilteredGames())
    dispatch(setShowProviderGames(false))
    dispatch(setSelectedProviderData(null))
    dispatch(setShowTagGames(null))
    !isDesktop && closeSlide()
  }

  const lobbyHandler = () => {
    dispatch(setSearch(''))
    dispatch(clearSearchedGames())
    dispatch(setShowFavoriteGames(false))
    dispatch(setShowRandomGames(false))
    dispatch(setShowProviderGames(false))
    dispatch(setSelectedProviderData(null))
    dispatch(setGameCategoryId(null))
    dispatch(clearFilteredGames())
    setAnchorElGames(null)
    setLiveAnchorGames(null)
    dispatch(setShowTagGames(null))
    navigate(ROUTE_PATHS.HOME)
  }

  const handleTournaments = () => {
    if (isAuthenticated) {
      !isDesktop && closeSlide()
      navigate(ROUTE_PATHS.TOURNAMENT)
    } else {
      dispatch(setAuthModal('login'))
    }
  }

  const handleBonus = () => {
    !isDesktop && closeSlide()
    navigate(ROUTE_PATHS.BONUSES)
    // if (isAuthenticated) {
    //   // dispatch(setActiveTabIndex(4)) // to open OfferBonuses Tab by default
    //   // dispatch(setUserModal('account'))
    //   !isDesktop && closeSlide()
    //   navigate(ROUTE_PATHS.BONUSES)
    // } else {
    //   dispatch(setAuthModal('login'))
    // }
  }

  const handleVipBonus = () => {
    !isDesktop && closeSlide()
    navigate('/bonus/vip')
  }
  const handleShop = () => {
    !isDesktop && closeSlide()
    navigate(ROUTE_PATHS.SHOP)
  }

  const navigateToCms = (cmsId) => {
    window.open(`/cms/${cmsId}`, '_blank')
  }

  const liveGames = categoryWiseGamesList.filter((games) =>
    games?.name?.EN?.includes('Live')
  )

  const nonLiveGames = categoryWiseGamesList.filter(
    (games) => !games?.name?.EN?.includes('Live')
  )

  // nonLiveGames.forEach((games, i) => {
  //   if (games.name === 'Crash Games') {
  //     const data = nonLiveGames[i]
  //     nonLiveGames[i] = {
  //       games: [],
  //       id: 0,
  //       name: 'Inatant Games',
  //       isActive: false
  //     }
  //     nonLiveGames[i + 1] = data
  //     nonLiveGames[i + 2] = {
  //       games: [],
  //       id: 0,
  //       name: 'Table Games',
  //       isActive: false
  //     }
  //   }
  // })

  const handleDeposit = (device) => {
    if (isAuthenticated) {
      if (device === 'mobile') dispatch(setUserModal('deposit'))
      else setDialogOpen('deposit')
    } else {
      dispatch(setAuthModal('login'))
    }
  }

  const mobileSidebar = (
    <Box className='mobile-navigation-wrap'>
      <Box className='mobile-navigation-box'>
        {/* <CustomMainButton onClick={() => lobbyHandler()}>
          {t('lobby')}
        </CustomMainButton> */}
        <CustomAnimatedButton onClick={() => handleDeposit('mobile')}>
          {t('deposit')}
        </CustomAnimatedButton>
        <CustomMainButton onClick={handleOpenGamesMenu}>
          {t('games')}
        </CustomMainButton>
        <CustomMenuDropdown
          id='menu-appbar'
          anchorEl={anchorElGames}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            horizontal: 'center'
          }}
          open={Boolean(anchorElGames)}
          onClose={handleCloseGamesMenu}
          className='games-dropdown-menu'
        >
          {/* {tagGameList.slice(0, 2).map(key =>
            <MenuItem
              className='user-menu-list-item'
              onClick={() => handleGamesMenuClick('Tag Games', undefined, key)}
              key={key}
            >
              <Typography>{t(GameTagTitle[key])}</Typography>
            </MenuItem>
          )} */}
          <MenuItem
            className='user-menu-list-item'
            onClick={() => handleGamesMenuClick('All Games')}
          >
            <Typography>{t('allGames')}</Typography>
          </MenuItem>
          {/* {tagGameList.slice(3).map(key =>
            <MenuItem
              className='user-menu-list-item'
              onClick={() => handleGamesMenuClick('Tag Games', undefined, key)}
              key={key}
            >
              <Typography>{t(GameTagTitle[key])}</Typography>
            </MenuItem>
          )} */}
          {isAuthenticated && (
            <MenuItem
              className='user-menu-list-item'
              onClick={() => {
                dispatch(setShowFavoriteGames(true))
                closeSlide()
              }}
            >
              <Typography>
                {' '}
                {t('favorite')} {t('games')}
              </Typography>
            </MenuItem>
          )}
          {nonLiveGames.map((gamesMenuItem) => (
            <MenuItem
              className='user-menu-list-item'
              key={gamesMenuItem.id}
              onClick={() =>
                handleGamesMenuClick(
                  gamesMenuItem,
                  gamesMenuItem.isActive
                )}
            >
              <Typography>{gamesMenuItem?.name?.[languageCode] || gamesMenuItem?.name?.EN || gamesMenuItem?.name}</Typography>
            </MenuItem>
          ))}
        </CustomMenuDropdown>
        <CustomMainButton onClick={handleOpenLiveGamesMenu}>
          {t('liveGames')}
        </CustomMainButton>
        <CustomMenuDropdown
          id='menu-appbar1'
          anchorEl={liveAnchorGames}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            horizontal: 'center'
          }}
          open={Boolean(liveAnchorGames)}
          onClose={handleCloseLiveGamesMenu}
          className='games-dropdown-menu'
        >
          <MenuItem
            className='user-menu-list-item'
            onClick={() => handleGamesMenuClick('All Games')}
          >
            <Typography>{t('allGames')}</Typography>
          </MenuItem>
          {liveGames.map((gamesMenuItem) => (
            <MenuItem
              className='user-menu-list-item'
              key={gamesMenuItem.id}
              onClick={() => handleGamesMenuClick(gamesMenuItem)}
            >
              <Typography>{gamesMenuItem?.name?.[languageCode] || gamesMenuItem?.name?.EN || gamesMenuItem?.name}</Typography>
            </MenuItem>
          ))}
        </CustomMenuDropdown>
        <CustomMainButton onClick={handleRandomGames}>
          {t('randomGames')}
        </CustomMainButton>
        <LanguageSwitch />
        <CustomMainButton onClick={handleBonus}>{t('tabsBonus')}</CustomMainButton>
        <CustomMainButton onClick={handleTournaments}>{t('tournaments')}</CustomMainButton>
        <CustomMainButton onClick={handleShop}>{t('shop')}</CustomMainButton>
        <CustomMainButton onClick={handleVipBonus}>{t('vip')}</CustomMainButton>
        <CustomSecondaryButton>{t('blog')}</CustomSecondaryButton>
        <CustomMainButton>{t('contacts')}</CustomMainButton>
      </Box>
    </Box>
  )

  return (
    <>
      {isDesktop
        ? (
          <SideNavbarWrapper theme={theme} className='side-navbar-wrapper'>
            {/* {isAffiliateModalOpen && <AffiliateModal dialogOpen={isAffiliateModalOpen} handleClose={() => setAffiliateModalOpen(false)} />}
            {isReferralModalOpen && <ReferralModal dialogOpen={isReferralModalOpen} handleClose={() => setReferralModalOpen(false)} />} */}
            <Box className='left-navigation-wrap'>
              <Box className='coin-animation-img-box'>
                <img
                  src='/assets/images/stock-images/coin-marker.png'
                  alt='coin-img'
                  loading='lazy'
                />
              </Box>
              <Box className='navigation-box'>
                <CustomMainButton onClick={() => lobbyHandler()}>
                  {t('lobby')}
                </CustomMainButton>
                <CustomAnimatedButton onClick={handleDeposit}>
                  {t('deposit')}
                </CustomAnimatedButton>
                {/* <CustomMainButton
              onClick={() => navigate(ROUTE_PATHS.PROMOTION)}
            >
              Promotions
            </CustomMainButton> */}
                <CustomMainButton onClick={handleOpenGamesMenu}>
                  {t('games')}
                </CustomMainButton>
                <CustomMenuDropdown
                  id='menu-appbar'
                  anchorEl={anchorElGames}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    horizontal: 'center'
                  }}
                  open={Boolean(anchorElGames)}
                  onClose={handleCloseGamesMenu}
                  className='games-dropdown-menu'
                >
                  {/* {tagGameList.slice(0, 2).map(key =>
                    <MenuItem
                      className='user-menu-list-item'
                      onClick={() => handleGamesMenuClick('Tag Games', undefined, key)}
                      key={key}
                    >
                      <Typography>{t(GameTagTitle[key])}</Typography>
                    </MenuItem>
                  )} */}
                  <MenuItem
                    className='user-menu-list-item'
                    onClick={() => handleGamesMenuClick('All Games')}
                  >
                    <Typography>{t('allGames')}</Typography>
                  </MenuItem>
                  {/* {tagGameList.slice(3).map(key =>
                    <MenuItem
                      className='user-menu-list-item'
                      onClick={() => handleGamesMenuClick('Tag Games', undefined, key)}
                      key={key}
                    >
                      <Typography>{t(GameTagTitle[key])}</Typography>
                    </MenuItem>
                  )} */}
                  {isAuthenticated && (
                    <MenuItem
                      className='user-menu-list-item'
                      onClick={() => dispatch(setShowFavoriteGames(true))}
                    >
                      <Typography>
                        {' '}
                        {t('favorite')} {t('games')}
                      </Typography>
                    </MenuItem>
                  )}
                  {nonLiveGames.map((gamesMenuItem) => (
                    <MenuItem
                      className='user-menu-list-item'
                      key={gamesMenuItem.id}
                      onClick={() =>
                        handleGamesMenuClick(
                          gamesMenuItem,
                          gamesMenuItem.isActive
                        )}
                    >
                      <Typography>{gamesMenuItem?.name?.[languageCode] || gamesMenuItem?.name?.EN || gamesMenuItem?.name}</Typography>
                    </MenuItem>
                  ))}
                </CustomMenuDropdown>

                {/* <CustomTertiaryButton>Live Games</CustomTertiaryButton> */}
                <CustomMainButton onClick={handleOpenLiveGamesMenu}>
                  {t('liveGames')}
                </CustomMainButton>
                <CustomMenuDropdown
                  id='menu-appbar1'
                  anchorEl={liveAnchorGames}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    horizontal: 'center'
                  }}
                  open={Boolean(liveAnchorGames)}
                  onClose={handleCloseLiveGamesMenu}
                  className='games-dropdown-menu'
                >
                  <MenuItem
                    className='user-menu-list-item'
                    onClick={() => handleGamesMenuClick('All Games')}
                  >
                    <Typography>{t('allGames')}</Typography>
                  </MenuItem>
                  {liveGames.map((gamesMenuItem) => (
                    <MenuItem
                      className='user-menu-list-item'
                      key={gamesMenuItem.id}
                      onClick={() => handleGamesMenuClick(gamesMenuItem)}
                    >
                      <Typography>{gamesMenuItem?.name?.[languageCode] || gamesMenuItem?.name?.EN || gamesMenuItem?.name}</Typography>
                    </MenuItem>
                  ))}
                </CustomMenuDropdown>
                <CustomMainButton
                  onClick={handleRandomGames}
                  fontSize={['DE', 'ES', 'PT', 'TR', 'FI', 'FR', 'RU'].includes(languageCode) ? 'smd' : ['UK', 'NL'].includes(languageCode) ? 'sm' : ''}
                >
                  {t('randomGames')}
                </CustomMainButton>
                <LanguageSwitch />
              </Box>
            </Box>

            <Box className='right-navigation-wrap'>
              <Box className='atm-animation-img-box'>
                <Lottie animationData={isDarkMode ? roboflyAnimation : roboflyLightAnimation} loop autoplay />
              </Box>

              <Box className='navigation-box'>
                <CustomMainButton onClick={handleBonus}>{t('tabsBonus')}</CustomMainButton>
                <CustomMainButton onClick={handleTournaments}>{t('tournaments')}</CustomMainButton>
                <CustomMainButton onClick={handleShop}>{t('shop')}</CustomMainButton>
                <CustomMainButton onClick={handleVipBonus}>{t('vip')}</CustomMainButton>
                {/* <CustomMainButton onClick={() => setAffiliateModalOpen(true)}>{t('vip')}</CustomMainButton> */}
                {/* {isAuthenticated && <CustomMainButton onClick={() => setReferralModalOpen(true)}>{t('blog')}</CustomMainButton>} */}
                <CustomSecondaryButton onClick={() => navigateToCms('responsible-gambling')}>{t('blog')}</CustomSecondaryButton>
                <CustomMainButton
                  fontSize={['DA'].includes(languageCode) ? 'smd' : ''}
                >{t('contacts')}
                </CustomMainButton>
              </Box>
            </Box>
          </SideNavbarWrapper>
          )
        : slideOpen ? mobileSidebar : null}
    </>
  )
}

export default React.memo(SideNavbar)
