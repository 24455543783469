import React, { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { BonusWrapper } from './style'
import { useSelector } from 'react-redux'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useTranslation } from 'react-i18next'
// import OfferBonusesOldDesign from 'components/layout/MyAccount/Bonuses/OffersBonuses/OfferBonusesOldDesign'
// import ActiveBonusesOldDesign from 'components/layout/MyAccount/Bonuses/ActiveBonuses/ActiveBonusesOldDesign'

// import VoucherBonuses from 'components/layout/MyAccount/Bonuses/VoucherBonuses/index'
// import HistoryBonuses from 'components/layout/MyAccount/Bonuses/HistoryBonuses/index'

import Offers from './components/Offers'
import PurchasedReward from './components/PurchasedReward/index'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const Shop = () => {
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isAuthenticated } = useSelector((state) => state.gameSetting)

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'undefined') {
      return
    }
    sessionStorage.setItem('tab', newValue)
    setValue(newValue)
  }

  const handleBack = () => {
    navigate(ROUTE_PATHS.HOME)
  }

  return (
    <BonusWrapper>
      {isAuthenticated
        ? (
          <Box className='bonus-wrap'>
            <CustomMainButton onClick={handleBack} mb={2}>
              {t('back')}
            </CustomMainButton>
            <Typography className='section-heading-main'>
              {t('shop')}
            </Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='fullWidth'
              aria-label='scrollable force tabs example'
            >
              <Tab
                label={
                  <Box className='inner-box' onClick={handleChange}>
                    {t('offers')}
                  </Box>
              }
              />
              <Tab label={<Box className='inner-box'>{t('purchasedReward')}</Box>} />
            </Tabs>

            {/* Offers */}
            <CustomTabPanel value={value} index={0}>
              <Offers from='offer' />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <PurchasedReward />
            </CustomTabPanel>

          </Box>
          )
        : (
          <Box className='bonus-wrap'>
            <CustomMainButton onClick={handleBack} mb={2}>
              {t('back')}
            </CustomMainButton>
            <Typography className='section-heading-main'>
              {t('shop')}
            </Typography>
            <Offers from='offer' />
          </Box>
          )}

      <Box className='about-text-wrap'>
        <Typography className='heading-text'>
          {t('shopDes')}
        </Typography>
      </Box>
    </BonusWrapper>
  )
}

export default Shop
