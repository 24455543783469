import React from 'react';
import { Box } from '@mui/material';

const FilePreview = ({ file, documentUrl }) => {
  if (!file && !documentUrl) return null;
  if (file) {
    const fileType = file.type.split('/')[0];
    const preview = fileType === 'image' ? URL.createObjectURL(file) : file.name;

    return (
      <Box mt={2} className='preview-box'>
        {fileType === 'image' ? (
          <img
            src={preview}
            alt='Document preview'
            width='100%'
            height='auto'
            style={{ maxHeight: '200px', objectFit: 'contain' }}
          />
        ) : (
          <Box>{file.name}</Box>
        )}
      </Box>
    );
  }

  if (documentUrl) {
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(documentUrl);

    return (
      <Box mt={2} className='preview-box'>
        {isImage ? (
          <img
            src={documentUrl}
            alt='Document preview'
            width='100%'
            height='auto'
            style={{ maxHeight: '200px', objectFit: 'contain' }}
          />
        ) : (
          <Box>{documentUrl.split('/').pop()}</Box>
        )}
      </Box>
    );
  }
};

export default FilePreview;
