// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { EditProfileWrapper } from './style'
import {
  CustomFormLabel,
  // CustomPasswordInput,
  CustomSelect,
  CustomTextField,
  CustomSearchSelect
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import CustomDatePicker from 'components/common-ui/CustomDateTimePicker/CustomDatePicker'
import { ChevronDownIcon, SpinnerSVG } from 'assets/icons/index'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { editProfileSchema } from 'components/layout/AuthContentSection/schema'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserProfile } from 'redux-thunk/thunk/auth.thunk'
import { formatDateMDY } from 'utils/dateFormatter'
import { LOADER_HANDLER_TYPES } from 'constants/index'
// import { phoneCode } from 'utils/phoneCodes'
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk'

const EditProfile = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { allCountries } = useSelector((state) => state.gameSetting)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  useEffect(() => {
    dispatch(fetchUserInformation())
  }, [])
  // const userDateofBirth = formatDateMDY(user?.dateOfBirth)?.split('-')

  // const date = userDateofBirth?.[0]
  // const month = userDateofBirth?.[1]
  // const year = userDateofBirth?.[2]

  // const [menuItemValue, setMenuItemValue] = React.useState('')
  // const handleChange = (event) => {
  //   setMenuItemValue(event.target.value)
  // }

  // const [showCurrentPassword, setShowCurrentPassword] = useState(false)

  // const countries = allCountries.rows[0].concat(allCountries.rows[0]).concat(allCountries.rows[0])

  const data = {
    id: user?.userId,
    username: user?.username,
    user: {
      name: user?.firstName,
      username: user?.username
    }
  }

  const handleEditUserSubmit = (formValues) => {
    const userData = {
      ...formValues,
      phone: formValues.phone.toString(),
      dateOfBirth: formatDateMDY(formValues.dateOfBirth)
    }
    dispatch(updateUserProfile(userData))
  }

  return (
    <>
      <EditProfileWrapper theme={theme} className='content-wrapper'>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            // phoneCode: user?.phoneCode || '',
            // phone: user?.phone || '',
            gender: user?.gender || '',
            dateOfBirth: user?.dateOfBirth || '',
            // day: date || '',
            // month: month || '',
            // year: year || '',
            countryCode: user?.countryCode || '',
            city: user?.city || '',
            zipCode: user?.zipCode || '',
            address: user?.address || '',
            ...data
          }}
          validationSchema={editProfileSchema(t)}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={(formValues) => handleEditUserSubmit(formValues)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors
          }) => {
            return (
              <Form autoComplete='off'>
                <Box component='form' noValidate>
                  <Grid container spacing={{ md: 1.25, xs: 0.75 }}>
                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('profileFName')}</CustomFormLabel>
                      <CustomTextField
                        fullWidth
                        type='text'
                        name='firstName'
                        placeholder={t('profileFName')}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      <ErrorMessage
                        component='div'
                        name='firstName'
                        key='firstName'
                        className='error-message'
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('profileLname')}</CustomFormLabel>
                      <CustomTextField
                        fullWidth
                        type='text'
                        name='lastName'
                        placeholder={t('profileLname')}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      <ErrorMessage
                        component='div'
                        name='lastName'
                        key='lastName'
                        className='error-message'
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('signupEmail')}</CustomFormLabel>
                      <CustomTextField
                        fullWidth
                        type='email'
                        name='email'
                        placeholder={t('signupEmail')}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      <ErrorMessage
                        component='div'
                        name='email'
                        key='email'
                        className='error-message'
                      />
                    </Grid>

                    {/* <Grid item xs={12} lg={6}>
                      <Grid container spacing={1.25}>
                        <Grid item xs={4} lg={3}>
                          <CustomFormLabel>Code</CustomFormLabel>
                          <CustomSearchSelect
                            fullWidth
                            type='text'
                            name='phoneCode'
                            value={phoneCode?.filter((item) => item?.dial_code === values.phoneCode)?.[0]}
                            options={phoneCode}
                            getOptionLabel={(option) => option.dial_code}
                            rennderOptions={(options) => (
                              <Box>{options.dial_code}</Box>
                            )}
                            onChange={(e, phone) => {
                              setFieldValue('phoneCode', phone?.dial_code)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <ErrorMessage
                            component='div'
                            name='phoneCode'
                            key='phoneCode'
                            className='error-message'
                          />
                        </Grid>

                        <Grid item xs={8} lg={9}>
                          <CustomFormLabel>{t('profilePhone')}</CustomFormLabel>
                          <CustomTextField
                            fullWidth
                            type='number'
                            placeholder='Mobile phone'
                            name='phone'
                            value={values.phone}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            component='div'
                            name='phone'
                            key='phone'
                            className='error-message'
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}

                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('profileDob')}</CustomFormLabel>
                      <Grid container spacing={1.25}>
                        <Grid item xs={12} lg={12}>
                          <CustomDatePicker
                            fullWidth
                            value={values.dateOfBirth}
                            onChange={(date) => setFieldValue('dateOfBirth', date)}
                            onBlur={handleBlur}
                            name='dateOfBirth'
                            maxDate={new Date()}
                          />
                          <ErrorMessage
                            component='div'
                            name='dateOfBirth'
                            key='dateOfBirth'
                            className='error-message'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Grid container spacing={1.25}>
                        <Grid item xs={4} lg={3}>
                          <CustomFormLabel>{t('signupGender')}</CustomFormLabel>
                          <CustomSelect
                            fullWidth
                            name='gender'
                            value={values.gender}
                            onChange={handleChange}
                            displayEmpty
                            IconComponent={ChevronDownIcon}
                          >
                            <MenuItem value='' disabled>
                              None
                            </MenuItem>
                            <MenuItem value='Male'>{t('male')}</MenuItem>
                            <MenuItem value='Female'>{t('female')}</MenuItem>
                            <MenuItem value='Others'>{t('signupOther')}</MenuItem>
                          </CustomSelect>
                          <ErrorMessage
                            component='div'
                            name='gender'
                            key='gender'
                            className='error-message'
                          />
                        </Grid>
                        <Grid item xs={8} lg={9}>
                          <CustomFormLabel>{t('signupPostcode')}</CustomFormLabel>
                          <CustomTextField
                            fullWidth
                            type='text'
                            placeholder='Zip Code'
                            name='zipCode'
                            value={values.zipCode}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            component='div'
                            name='zipCode'
                            key='zipCode'
                            className='error-message'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Grid container spacing={1.25}>
                        <Grid item xs={6} lg={6}>
                          <CustomFormLabel>{t('signupCounty')}</CustomFormLabel>
                          <CustomSearchSelect
                            disablePortal
                            id='combo-box-demo'
                            name='countryCode'
                            options={allCountries?.rows}
                            value={allCountries?.rows?.filter((item) => item?.code === values.countryCode)?.[0]}
                            getOptionLabel={(option) => option.name}
                            rennderOptions={(options) => (
                              <Box>{options.name}</Box>
                            )}
                            onChange={(e, country) => {
                              setFieldValue('countryCode', country?.code)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <ErrorMessage
                            component='div'
                            name='countryCode'
                            key='countryCode'
                            className='error-message'
                          />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <CustomFormLabel>{t('signupCity')}</CustomFormLabel>
                          <CustomTextField
                            fullWidth
                            name='city'
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            displayEmpty
                            IconComponent={ChevronDownIcon}
                          />
                          <ErrorMessage
                            component='div'
                            name='city'
                            key='city'
                            className='error-message'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('signupAddress')}</CustomFormLabel>
                      <CustomTextField
                        fullWidth
                        type='text'
                        name='address'
                        value={values.address}
                        placeholder='Address'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component='div'
                        name='address'
                        key='address'
                        className='error-message'
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={4}>
                          <Box className='form-btn-box'>
                            <CustomPrimaryButton type='submit' onClick={handleSubmit} fullWidth>
                              {/* Save */}
                              {!loading
                                ? t('save')
                                : <SpinnerSVG style={{ fill: 'white' }} />}
                            </CustomPrimaryButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </EditProfileWrapper>
    </>
  )
}

export default React.memo(EditProfile)
