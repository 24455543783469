import { SuccessMessage } from 'network/messages/successMessages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/login', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.login
  })
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/logout', {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    handlerEnabled: false
    // successMessage: SuccessMessage.logout
  })
}

export const signUpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/sign-up', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.signup
  })
}

export const googleSignUpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/sociallogin/login-with-google', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.signup
  })
}
export const verifyTokenService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/sociallogin/check-user-exist', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.signup
  })
}

export const verifyPromoCodeService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/verifyPromoCode', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false
    // successMessage: SuccessMessage.signup
  })
}

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/api/v1/user/change-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const updateUserProfileService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/api/v1/user/update-user-details', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.updateProfileMsg
  })
}

export const forgetPasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/forget-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.tokenVerify
  })
}
export const forgetVerifyPasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/api/v1/user/verify-forget-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const verifyEmailService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/api/v1/user/verify-email', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content,
    successMessage: SuccessMessage.emailVerify
  })
}

export const checkUserAddress = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/user/check-user-address', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const getMetaUser = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/user/login-with-metaMask', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.login
  // })
}
export const loginWithOtpService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/user/login', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const placeBetCrashGameService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/crash-game/place-bet-crash-game', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.BetPlacedSuccessfully
  // })
}
export const escapeCrashBetService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/crash-game/player-escape-crashGame', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.PlayerEscapedSuccessfully
  // })
}

export const cancelCrashBetService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/crash-game/cancel-bet-crash-game', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const getAllRoundHistoryService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-history', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.table,
  //   params: data
  // })
}

export const getAllBetDataService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-bets', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const getTotalWinningAmountService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-winning-amount', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const getCrashGameRoundDetails = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-history', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const getCrashGameRoundDetailService = (roundId) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-round-detail', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   params: { roundId }
  // })
}

export const getAllbetOfOneRoundService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/crash-game/all-placed-bets', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   params: data
  // })
}

export const getMyBetsService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-status', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const verifyMetaSignature = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/user/verify-metaSignature', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.changePassword
  // })
}

export const verifyProvableFairCrashService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/crash-game/check-provable-fair', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const updateEmailService = (data) => {
  // return axiosInstanceService(METHOD_TYPES.put, '/user/update-email', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit,
  //   successMessage: SuccessMessage.emailChangedSuccess
  // })
}

export const checkIsEmailUnique = (email) => {
  // return axiosInstanceService(METHOD_TYPES.get, `/api/user/check-unique-constraints?email=${email}`, {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const checkIsUsernameUnique = (username) => {
  // return axiosInstanceService(METHOD_TYPES.get, `/api/user/check-unique-constraints?username=${username}`, {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const addBrowserId = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/user/insert-unique-key', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}

export const resendEmailToken = (data) => {
  // return axiosInstanceService(METHOD_TYPES.post, '/api/user/refresh-email-token', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.submit
  // })
}
