import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllRewardService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/reward/get-reward', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getRewardDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/reward/shop-details', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const purchaseRewardService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/api/v1/reward/purchase', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Reward activated successfully'
  })
}

export const getUserActiveReward = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/reward/get-reward-user', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
