export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Aankomend',
  finished: 'Afgerond',
  prizePool: 'Prijzenpot',
  joinNow: 'Sluit u nu aan',
  joined: 'Aangesloten',
  startsIn: 'Begint binnen',
  endsIn: 'Eindigt in',
  ended: 'Toernooi geëindigd',
  started: 'Toernooi begonnen',
  seeMore: 'Bekijk meer',
  winPrizes: 'Win spannende prijzen',
  leaderBoard: 'Scorebord',
  prize: 'Prijs',
  aboutTournament: 'Over toernooi',
  congratulationsMessage: 'Bravo! ',
  payments: 'Betaling',
  tournaments: 'Toernooien',
  shop: 'Winkel',
  blog: 'Bloggen',
  referral: 'Verwijzing',
  contacts: 'Contacten',
  retry: 'Opnieuw proberen',
  incorrectCredsMessage: 'Uw gebruikersnaam of wachtwoord is onjuist. ',
  bonuses: 'Bonus',
  depositBonus: 'Stortingsbonus',
  voucher: 'Voucher',
  gamlings: 'Gokken',
  transactionHistory: 'Transactiegeschiedenis',
  offers: 'Aanbiedingen',
  editProfile: 'Bewerk profiel',
  continue: 'Doorgaan',
  apply: 'Toepassen',
  male: 'Mannelijk',
  female: 'Vrouwelijk',
  register: 'Register',
  login: 'Log in',
  grand: 'Groots',
  major: 'Belangrijk',
  minor: 'Minderjarige',
  mini: 'Mini',
  crashGameTitle: 'Botsing',
  betAmount: 'Inzetbedrag',
  profit: 'Winst',
  payout: 'Doeluitbetaling',
  chance: 'Win kans',
  betBtn: 'Inzet',
  autoCashout: 'Automatische uitbetaling',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Ingezet',
  nothingFound: 'niets gevonden',
  lost: 'Kwijt',
  won: 'Won',
  streak: 'Streep',
  highestWin: 'Hoogste overwinning',
  promotions: 'Promoties',
  latestPromotions: 'Nieuwste promoties',
  sponsorships: 'Sponsoring',
  highestLost: 'Hoogste verloren',
  highestBet: 'Hoogste inzet',
  maxStreak: 'Max Streak',
  maxProfit: 'Maximale winst: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Uw weddenschapsgeschiedenis is leeg',
  messagesCopyNotSupported: 'Uw browser ondersteunt het automatisch kopiëren naar klembord niet. Probeer de code handmatig te kopiëren.',
  messagesCopied: 'Adres gekopieerd.',
  deposit: 'Borg',
  statReset: 'Statistieken resetten',
  rollOver: 'Rol om',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Rug',
  inputFieldBtnMax: 'maximaal',
  buttonTextPlaceBet: 'Plaats een weddenschap',
  buttonTextCancelBet: 'Weddenschap annuleren',
  buttonTextEscapeBet: 'Uitbetalen',
  buttonTextStartAutoBet: 'Start Autobet',
  buttonTextCancelAutoBet: 'Autobet annuleren',
  buttonTextLoading: 'Bezig met laden',
  tabsMyBets: 'Mijn weddenschappen',
  tabsDeposit: 'Borg',
  tabsCashout: 'Uitbetalingen',
  tabsAllbets: 'Alle weddenschappen',
  tabsTopbets: 'Top weddenschappen',
  tabsBonus: 'Bonussen',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Geschiedenis van weddenschappen',
  timerTextCurrentPayout: 'Huidige uitbetaling',
  timerTextStarting: 'Beginnend over',
  timerTextSec: 'S',
  timerTextFlewAway: 'Gecrasht',
  timerTextWait: 'Even geduld aub..',
  timerTextPreparingRound: 'Ronde voorbereiden',
  timerTextLoadingRound: 'Ronde laden',
  comingSoon: 'Binnenkort beschikbaar',
  labelsProfitOnWin: 'Winst bij winst',
  inputsNoOfBets: 'Aantal weddenschappen',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Begin',
  inputsCancel: 'Annuleren',
  buttonsMin: 'Min',
  buttonsMax: 'Max',
  prediction: 'Voorspelling',
  luckyNumber: 'Geluksnummer',
  multiplier: 'Vermenigvuldiger',
  chanceToWin: 'Kans om te winnen',
  bets: 'Weddenschappen',
  depositBtn: 'Borg',
  rollUnder: 'Rol onder',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Geschiedenis',
  navBarMyBets: 'Mijn weddenschappen',
  navBarAllBets: 'Alle weddenschappen',
  navBarHighRollers: 'Hoge rollen',
  navBarRoundHistory: 'Ronde geschiedenis',
  navBarTotal: 'Totaal aantal weddenschappen',
  topHeaderBetId: 'Inzet-ID',
  topHeaderUser: 'Gebruiker',
  topHeaderPayout: 'Uitbetaling',
  topHeaderAmount: 'Hoeveelheid',
  topHeaderTime: 'Tijd',
  topHeaderProfit: 'Winst',
  topHeaderRoundCrashed: 'Gecrasht',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hasj',
  topHeaderRoundBet: 'Inzet',
  topHeaderRoundProfit: 'Winst',
  topHeaderRoundSignature: 'Handtekening',
  roundHistoryTitle: 'SPELINFORMATIE',
  roundHistoryDescGame: 'Spel',
  roundHistoryDescCrashAt: 'Neergestort bij:',
  roundHistoryDescDate: 'Datum :',
  roundHistoryDescBetHeader: 'Inzet',
  roundHistoryTableTitlePlayer: 'SPELER',
  roundHistoryTableTitleBet: 'INZET',
  roundHistoryTableTitleCashedOut: 'UITBETAALD',
  roundHistoryTableTitleProfit: 'WINST',
  roundHistoryTableTitleBetId: 'BET-ID',
  roundHistoryMessagesNoBetHistory: 'Uw weddenschapsgeschiedenis is leeg',
  placedBetsTabPlaced: 'Geplaatste weddenschap',
  placedBetsTabPrev: 'Vorige weddenschap',
  placedBetsTitleUser: 'Gebruiker',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Inzet',
  placedBetsTitleProfit: 'Winst',
  placedBetsTitleCashout: 'Uitbetalen',
  placedBetsNoPlacedBet: 'Geen geplaatste weddenschappen.',
  provablyFairContentCheckBtn: 'Vink Bewijsbaar Redelijk aan',
  provablyFairContentTitle: 'Aantoonbaar eerlijk',
  provablyFairContentDesc: 'De uitkomst van de vierzijdige voorsprong kan als eerlijk worden bewezen. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Crashpercentage',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Crashpercentage zonder x',
  provablyFairContentInputFieldsRoundHashTitle: 'Ronde hasj',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'bijv. ',
  provablyFairContentInputFieldsSubmitBtn: 'Handtekening genereren',
  provablyFairContentSignature: 'Ronde handtekening',
  provablyFairContentErrorsCrashRate: 'Voer het nummer in het veld Crash Rate in',
  resetPasswordTitle: 'Wachtwoord opnieuw instellen',
  resetPasswordNewPasswordTitle: 'nieuw paswoord',
  resetPasswordNewPasswordErrorsRequired: '*Een wachtwoord is verplicht.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimaal 8 tekens toegestaan.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximaal 16 tekens toegestaan.',
  resetPasswordNewPasswordErrorsPattern: '*Het wachtwoord moet 8 tot 16 alfanumerieke en speciale tekens bevatten. ',
  resetPasswordConfirmPasswordTitle: 'bevestig wachtwoord',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Wachtwoorden komen niet overeen.',
  resetPasswordResetBtn: 'Opnieuw instellen',
  passwordResetSuccess: 'Wachtwoord succesvol gewijzigd, log in om te genieten van gamen!',
  resetPasswordWentWrong: 'Er is iets fout gegaan!',
  levelGrand: 'GROOT',
  levelMajor: 'BELANGRIJK',
  levelMinor: 'MINDERJARIGE',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Win dagelijks $ 1000 ',
  bannerContentContest: 'Wedstrijden nu ',
  bannerContentjoin: 'Doe mee aan onze dagelijkse inzetwedstrijd en word de held!  ',
  bannerContentPlayNow: 'Nu afspelen',
  bannerContentTitle: 'Win een jackpot',
  bannerContentContent: 'Speel meer',
  bannerContentGameName: 'CRASH-SPEL',
  bannerContentGameDesc: 'Speel meer',
  partners: 'Partners',
  gamesGoldenDragon: 'gouden Draak',
  gamesCrashGame: 'Crash-spel',
  gamesDice: 'Dobbelsteen',
  gamesHilo: 'Hallo',
  allBetsTitle: 'Alle weddenschappen',
  allBetsBetId: 'Inzet-ID',
  allBetsUser: 'Gebruiker',
  allBetsPayout: 'Uitbetaling',
  allBetsAmount: 'Hoeveelheid',
  allBetsTime: 'Tijd',
  allBetsProfit: 'Winst',
  LoginwithOtp: 'Inloggen met Otp',
  EnterLoginOtp: 'Voer Inloggen Otp in',
  submit: 'Indienen',
  footerAboutUs: 'Over ons',
  footerFaq: 'Veelgestelde vragen',
  footerSitePolicy: 'Sitebeleid',
  footerResponsibleGambling: 'Verantwoord gokken',
  footerTerms: 'Voorwaarden',
  signinTitle: 'Log in',
  signinUsername: 'Gebruikersnaam / E-mailadres',
  signinPassword: 'Wachtwoord',
  signinLoginBtn: 'Log in',
  signinSignup: 'Aanmelden!',
  signinForgetPassword: 'Je wachtwoord vergeten?',
  forgotPasswordMsg: 'Voer het e-mailadres in dat aan uw account is gekoppeld en wij sturen u een link om uw wachtwoord opnieuw in te stellen.',
  emailLinkMsg: 'De link voor het opnieuw instellen van het wachtwoord is naar uw registratie-e-mailadres verzonden. Klik op de e-maillink en stel uw nieuwe wachtwoord in.',
  resetPasswardConfirm: 'Wachtwoord is succesvol gereset',
  signinDontHaveAccount: 'Heeft u geen account? ',
  signinCreateAccount: 'Account aanmaken',
  signinErrorsUserNameRequired: '*Vul gebruikersnaam/e-mailadres in.',
  signinErrorsUserNameMinLength: 'Minimaal 4 tekens toegestaan.',
  signinErrorsUserNameMaxLength: 'Maximaal 20 tekens toegestaan.',
  signinErrorsUserNamePattern: 'Voer een geldige gebruikersnaam in, alleen letters en cijfers zijn toegestaan.',
  signinErrorsPasswordRequired: '*Een wachtwoord is verplicht.',
  signinErrorsPasswordMinLength: 'Minimaal 8 tekens toegestaan.',
  signinErrorsPasswordMaxLength: 'Maximaal 16 tekens toegestaan.',
  signinErrorsPasswordPattern: '*Het wachtwoord moet 8 tot 16 alfanumerieke en speciale tekens bevatten. ',
  signinSuccessfullyLoggedIn: 'Succesvol ingelogd.',
  signinResetMailSent: 'Wachtwoord opnieuw instellen e-mail verzonden.',
  signinTryAgain: 'Er is iets mis gegaan, probeer het alstublieft nogmaals.',
  signinResetPasswordUsernameErr: 'Voer gebruikersnaam/e-mailadres in om het wachtwoord opnieuw in te stellen.',
  signinResetMailSentSuccessContent: 'We hebben u een link voor het opnieuw instellen van uw wachtwoord per e-mail gestuurd. ',
  signupTitle: 'Register',
  signupUsername: 'Gebruikersnaam',
  signupEmail: 'E-mail',
  signupTermsConditions: 'Voorwaarden',
  signupWalletConnect: 'Inloggen met Portemonnee ',
  signupPassword: 'Wachtwoord',
  signupEighteenPlus: 'Ik ben ouder dan 18 jaar en ga akkoord met de Servicevoorwaarden',
  signupRegisterBtn: 'Aanmelden',
  signupCreateNewAccount: 'Creëer een nieuw account',
  signupFullName: 'Voor-en achternaam',
  signupReferralCode: 'Verwijzingscode',
  signupConfirmPassword: 'bevestig wachtwoord',
  signupUseSocialAccount: 'Inloggen via sociale netwerken',
  signupFacebook: ' Facebook',
  signupGoogle: ' Googlen',
  signupHaveAccount: 'Een account hebben? ',
  signupLoginBtn: 'Log in',
  signupErrorsUserNameRequired: '*Vul gebruikersnaam in.',
  signupErrorsUserNameMinLength: 'Minimaal 4 tekens toegestaan.',
  signupErrorsUserNameMaxLength: 'Maximaal 20 tekens toegestaan.',
  signupErrorsUserNamePattern: 'Voer een geldige gebruikersnaam in, alleen letters en cijfers zijn toegestaan.',
  signupErrorsFullNameRequired: '*Vul de volledige naam in.',
  signupErrorsFullNamePattern: 'Voer een geldige volledige naam in, alleen letters en cijfers zijn toegestaan.',
  signupErrorsEmailRequired: '*Vul e-mailadres in.',
  signupErrorsEmailMinLength: 'Minimaal 4 tekens toegestaan.',
  signupErrorsEmailMaxLength: 'Maximaal 20 tekens toegestaan.',
  signupErrorsEmailPattern: 'Voer een geldig emailadres in.',
  signupErrorsPasswordRequired: '*Een wachtwoord is verplicht.',
  signupErrorsPasswordMinLength: 'Minimaal 8 tekens toegestaan.',
  signupErrorsPasswordMaxLength: 'Maximale storting: 16 tekens toegestaan.',
  signupErrorsPasswordPattern: '*Het wachtwoord moet 8 tot 16 alfanumerieke cijfers (hoofdletters en kleine letters) en speciale tekens bevatten. ',
  signupErrorsConfirmPasswordMatch: 'Wachtwoorden moeten overeenkomen.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'bijv. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Er is iets fout gegaan!',
  signupSignupGoogle: 'Meld u succesvol aan bij Google.',
  signupRegistered: 'Succesvol geregistreerd.',
  signupConfirmAge: 'Bevestig dat u 18 jaar oud bent.',
  signupSignupFacebook: 'Succesvol aanmelden met Facebook.',
  signupCookiesConsent: 'Sta cookies van derden toe via de instellingen van uw browser om Social Login te gebruiken.',
  signupRegisterEmailPopupContent: 'We hebben u een verificatie-e-mail gestuurd. ',
  accountMenuDeposit: 'Borg',
  accountMenuCashout: 'Uitbetalen',
  accountMenujackpotWinners: 'Jackpotwinnaars',
  accountMenuTransactions: 'Transacties',
  accountMenuPromotions: 'Promoties',
  accountMenuProfile: 'Profiel',
  accountMenuLogout: 'Uitloggen',
  accountMenuSound: 'Geluid',
  accountMenuMusic: 'Muziek',
  accountMenuWithdraw: 'Terugtrekken',
  pageNotFound: 'We kunnen de pagina die u zoekt niet vinden!',
  error: 'Fout',
  somethingWentWrong: 'Er is iets fout gegaan!',
  verifyEmailThankYou: 'Bedankt!',
  verifyEmailEmailIsVerified: 'Uw e-mailadres is geverifieerd. ',
  verifyEmailGoToLogin: 'Ga naar de inlogpagina',
  verifyEmailWentWrong: 'Er is iets fout gegaan!',
  verifyEmailTokenExpired: 'E-mail is al geverifieerd of de link is verlopen. Stuur de e-mailverificatielink opnieuw door hieronder uw geregistreerde e-mailadres op te geven. ',
  verifyEmailGoToHome: 'Ga naar homepagina',
  verifyEmailSuccessMsg: 'E-mail succesvol geverifieerd.',
  bonusSectionSpanText: 'presenteert de meest genereuze en gevarieerde',
  bonusSectionBonuses: 'Bonussen',
  bonusSectionSubtitleText: 'Speel met plezier en ontvang extra beloningen tijdens het spel. ',
  JoiningBonusTitle: 'Bonus aansluiten',
  JoiningBonusButton: 'Claim nu',
  JoiningBonusDesc: 'Dergelijke bonussen moedigen online casinospelers aan om te gokken met Bitcoin of andere crypto’s. ',
  depositBonusTitle: 'Stortingsbonus',
  depositBonusButton: 'Borg',
  depositBonusDesc: 'Dergelijke bonussen moedigen online casinospelers aan om te gokken met Bitcoin of andere crypto’s. ',
  refferBonusTitle: 'Verwijzingsbonus',
  refferBonusButton: 'Borg',
  refferBonusDesc: 'Dergelijke bonussen moedigen online casinospelers aan om te gokken met Bitcoin of andere crypto’s. ',
  aboutSectionSpanText2: 'In het online casino kun je je favoriete spellen gratis of voor cryptocurrency spelen. ',
  aboutSectionSpanText3: ' In deze virtuele wereld van Bitcoin-gokken kun je van veel mogelijkheden genieten: je favoriete casinospellen spelen, bonussen ontvangen, deelnemen aan wedstrijden en promoties.',
  verifiedSectionTitle: 'Geverifieerde en gelicentieerde software',
  verifiedSectionSpanText1: 'We kopiëren of adopteren geen software van andere websites. ',
  faqSectionTitle: 'Veelgestelde vragen van spelers',
  faqSectionForgetPasswordTitle: 'Ik ben mijn wachtwoord vergeten.',
  faqSectionForgetPasswordDesc: 'U heeft geen herstel-e-mailadres opgegeven voor uw account. ',
  faqSectionMyDepositCreditedTitle: 'Mijn aanbetaling is niet bijgeschreven.',
  faqSectionMyDepositCreditedDesc: ' Placeholder-inhoud voor deze accordeon, die bedoeld is om de klasse te demonstreren. ',
  SupportedCurrencyTitle: 'Ondersteunde valuta',
  SupportedCurrencySpanText1: 'We kopiëren of adopteren geen software van andere websites. ',
  CarouselSectionTitle: 'HODL-CRASH',
  CarouselSectionSubTitle: ' Speel meer games en maak kans om te winnen. ',
  WalletSettingTitle: 'Portemonnee-instelling',
  WalletSettingHideZero: 'Verberg nulsaldi',
  WalletSettingHideZeroInfo: 'Uw nulsaldo verschijnt niet in uw portemonnee',
  WalletSettingDisplayFiat: 'Geef crypto in fiat weer',
  WalletSettingDisplayFiatInfo: 'Alle weddenschappen',
  WalletSettingNoteForApprox: 'Houd er rekening mee dat dit valuta-schattingen zijn.',
  WalletSettingSave: 'Redden',
  WalletSettingNoWalletFound: 'Geen portemonnee gevonden',
  jackpotWinnersNoWinnerAvailable: 'Geen winnaars beschikbaar',
  jackpotWinnersNew: 'Nieuw',
  jackpotWinnersHeader: 'Jackpot-winnaar',
  jackpotDetailsHeader: 'Jackpotdetail',
  jackpotDetailsCampaignPeriod: 'Campagneperiode',
  jackpotDetailsFrom: 'Van',
  jackpotDetailsTo: 'Naar',
  jackpotDetailsBettingRule: 'Weddenschapsregel',
  jackpotDetailsMinBetAmount: 'Min. inzetbedrag',
  jackpotDetailsMaxBetAmount: 'Maximaal inzetbedrag',
  jackpotDetailsBetPercentage: 'Inzetpercentage',
  jackpotDetailsWinningRuleHeader: 'Winnende regel',
  jackpotDetailsWinningRule: 'Het plaatsen van weddenschappen met een hoger inzetbedrag vergroot de kans om te winnen.',
  jackpotDetailsProfitRule: 'Als het inzetbedrag tussen minBetAmount en maxBetAmount ligt, wordt betPercentage% van het inzetbedrag opgeteld bij het jackpotbedrag.',
  profileTitle: 'Persoonlijke gegevens',
  profileFName: 'Voornaam',
  profileLname: 'Achternaam',
  profileUserName: 'Gebruikersnaam',
  profileDob: 'Geboortedatum',
  profileEmail: 'E-mailadres',
  profilePhone: 'Telefoonnummer',
  profileChange: 'Wijziging',
  profileSave: 'Wijziging opslaan',
  profilePassword: 'Wachtwoord',
  profileSubmit: 'Indienen',
  profileUploadProfile: 'Profiel uploaden',
  profileCurrentPassword: 'huidig ​​wachtwoord',
  profileNewPassword: 'nieuw paswoord',
  profileConfirmPassword: 'bevestig wachtwoord',
  profileScanQRCode: 'Scan QR-code',
  profileEnterSecretCode: 'Voer geheime code in',
  profileErrorsFirstNameRequired: 'Voornaam is verplicht.',
  profileErrorsFirstNamePattern: 'Voer een geldige naam in.',
  profileErrorsFirstNameMinLength: 'Minimaal 3 tekens toegestaan.',
  profileErrorsFirstNameMaxLength: 'Maximaal 20 tekens toegestaan.',
  profileErrorsLastNameRequired: 'Achternaam is vereist.',
  profileErrorsLastNamePattern: 'Voer een geldige naam in.',
  profileErrorsLastNameMinLength: 'Minimaal 3 tekens toegestaan.',
  profileErrorsLastNameMaxLength: 'Maximaal 20 tekens toegestaan.',
  profileErrorsChangePasswordOldRequired: 'Oud wachtwoord is vereist.',
  profileErrorsChangePasswordOldPattern: 'Voer een geldig wachtwoord in.',
  profileErrorsChangePasswordOldMinLength: 'Minimaal 8 tekens toegestaan.',
  profileErrorsChangePasswordOldMaxLength: 'Maximaal 16 tekens toegestaan.',
  profileErrorsChangePasswordNewRequired: 'Nieuw wachtwoord is vereist.',
  profileErrorsChangePasswordNewPattern: 'Voer een geldig wachtwoord in.',
  profileErrorsChangePasswordNewMinLength: 'Minimaal 8 tekens toegestaan.',
  profileErrorsChangePasswordNewMaxLength: 'Maximaal 16 tekens toegestaan.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Wachtwoorden komen niet overeen.',
  profileErrorsChangePasswordNewPasswordMatched: 'Het nieuwe wachtwoord mag niet hetzelfde zijn als het huidige wachtwoord.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Bevestig dat het wachtwoord vereist is.',
  profileErrorsUserNameRequired: 'Gebruikersnaam is vereist.',
  profileErrorsUserNamePattern: 'Voer een geldige naam in.',
  profileErrorsUserNameMinLength: 'Minimaal 3 tekens toegestaan.',
  profileErrorsUserNameMaxLength: 'Maximaal 20 tekens toegestaan.',
  profileErrorsDobRequired: 'Geboortedatum is vereist.',
  profileErrorsEmailRequired: 'E-mail is vereist.',
  profileErrorsPostcodeRequired: 'Postcode is vereist.',
  profileErrorsCityRequired: 'Stad is vereist.',
  profileErrorsCurrencyRequired: 'Valuta is vereist.',
  profileErrorsAddressRequired: 'Adres is nodig.',
  profileErrorsCountryRequired: 'Land is vereist.',
  profileErrorsEmailPattern: 'Voer een geldig emailadres in.',
  profileErrorsEmailMinLength: 'Minimaal 3 tekens toegestaan.',
  profileErrorsEmailMaxLength: 'Maximaal 20 tekens toegestaan.',
  profileMessagesProfileUpdate: 'Profiel succesvol bijgewerkt.',
  profileMessagesChangePassword: 'wachtwoord succesvol bijgewerkt.',
  profileProfilePicProfilePicUpload: 'Profielafbeelding uploaden',
  profileNoNewChanges: 'Kan profiel niet updaten! ',
  profileLoggedInWithSocial: 'U bent ingelogd met sociale login.',
  profileSideNavigatorSound: 'Geluid',
  profileSideNavigatorMusic: 'Muziek',
  profileSideNavigatorProfile: 'Mijn profiel',
  profileSideNavigatorBetHistory: 'Wedgeschiedenis',
  profileSideNavigatorTransactions: 'Transacties',
  profileSideNavigatorDeposit: 'Borg',
  profileSideNavigatorWithdraw: 'Terugtrekken',
  profileSideNavigatorGameLimits: 'Spellimieten',
  profileSideNavigatorReferral: 'Verwijzing',
  profileSideNavigatorChangePassword: 'Verander wachtwoord',
  profileSideNavigatorTermsConditions: 'Voorwaarden',
  profileSideNavigatorLogOut: 'Uitloggen',
  profileSideNavigatorProvablyFair: 'Aantoonbaar eerlijk',
  WalletInfoSectionNotAvailable: 'Niet beschikbaar',
  WalletInfoSectionRealBalance: 'Echt evenwicht',
  GameLimitsMinimumBet: 'Minimale inzet',
  GameLimitsMaximumBet: 'Maximale inzet',
  GameLimitsMaxWinFor1Bet: 'Maximale winst bij één weddenschap',
  ProvablyFairSubTitle: 'Dit spel maakt gebruik van Provably Fair-technologie om het spelresultaat te bepalen. ',
  ProvablyFairMaximumBet: 'Maximale inzet:',
  ProvablyFairMaxWinFor1Bet: 'Maximale winst bij één weddenschap',
  promotionsTitle: 'Promoties',
  promotionsNoLosingData: 'Sorry, er zijn geen verliezende bonuspromoties beschikbaar voor ',
  promotionsNoDepositData: 'Sorry, er zijn geen stortingsbonuspromoties beschikbaar voor ',
  promotionsCurrency: ' munteenheid.',
  promotionsViewBtn: 'Weergave',
  promotionsClaimSuccess: 'Je hebt succesvol geclaimd',
  promotionsAvailability: 'Beschikbaarheid: ',
  promotionsAvailabilityTabLosing: 'Bonusverlies',
  promotionsAvailabilityTabDeposit: 'Stortingsbonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Percentage',
  promotionsDepositBonusTableMaxBonus: 'Maximale bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover-vermenigvuldiger',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Maximale rollover per weddenschap',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Tijd om het rolloverdoel te bereiken',
  promotionsBonusCode: 'BONUS CODE: ',
  promotionsLossesClaim: 'Verliezen kunnen worden geclaimd voor (binnen de campagneperiode): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Bonusstatus: ',
  promotionsBonusAmount: 'Bonusbedrag: ',
  promotionsRolloverTarget: 'Rollover-doel: ',
  promotionsRolloverAchieved: 'Behaalde roll-over: ',
  promotionsActivatebtn: 'ACTIVEREN',
  promotionsClaimBtn: 'CLAIM',
  promotionsCancelBtn: 'ANNULEREN',
  promotionsBackBtn: 'RUG',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Voorwaarden',
  promotionsClaimDepositBonusTitle: 'Stappen voor het claimen van de stortingsbonus',
  promotionsClaimDepositBonusFirst: 'Activeer Bonuscode',
  promotionsClaimDepositBonusSecond: 'Stort bedrag in portemonnee',
  promotionsClaimDepositBonusThird: 'Behaal het rolloverdoel voor het claimen van de bonus',
  promotionsClaimLosingBonusTitle: 'Stappen voor het claimen van een verliezende bonus',
  promotionsClaimLosingBonusFirst: 'Activeer Bonuscode',
  promotionsClaimLosingBonusSecond: 'Speel je favoriete spellen',
  promotionsClaimLosingBonusThird: 'Klik op ‘Verliezen claimen’ om verliezen binnen de campagneperiode te claimen',
  promotionsWentWrong: 'Er is iets fout gegaan!',
  transactionTitle: 'Transacties',
  transactionBetId: 'Inzet-ID',
  transactionUser: 'Gebruiker',
  transactionPayout: 'Uitbetaling',
  transactionAmount: 'Hoeveelheid',
  transactionProfit: 'Winst',
  transactionCashout: 'Uitbetalen',
  transactionBet: 'Inzet',
  transactionMultix: 'Multi.x',
  transactionWin: 'Winnen',
  transactionFairness: 'Eerlijkheid',
  transactionReferFriend: 'Verwijs een vriend',
  transactionTotal: 'Totaal aantal weddenschappen',
  transactionWins: 'wint',
  transactionRefresh: 'Vernieuwen',
  transactionFilterTopMultipliers: 'Topvermenigvuldigers',
  transactionFilterHugeWins: 'Enorme overwinningen',
  transactionFilterBiggestWins: 'Grootste overwinningen',
  transactionFilterMultipliers: 'Vermenigvuldigers',
  transactionRealBalance: 'Echt evenwicht',
  depositWithdrawTXDate: 'Datum',
  depositWithdrawTXAmount: 'Hoeveelheid',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transactie Type',
  depositWithdrawTXStatus: 'Toestand',
  depositWithdrawTXNoData: 'Geen gegevens beschikbaar.',
  depositTitle: 'Borg',
  depositHistory: 'Geschiedenis',
  depositSubTitle: 'De gebruiker kan geen fiatgeld storten of opnemen. ',
  depositCopyNotSupported: 'Uw browser ondersteunt het automatisch kopiëren naar klembord niet. Probeer de code handmatig te kopiëren.',
  depositCopied: 'Adres gekopieerd.',
  depositAddressCreated: 'Adres succesvol aangemaakt.',
  depositGenerateAddress: 'Adres genereren',
  depositWarningText: 'Gelieve NIET te storten via een cross-chain transfer',
  withdrawTitle: 'Terugtrekken',
  withdrawSubTitle: 'De gebruiker kan geen fiatgeld storten of opnemen. ',
  withdrawNoteDesc: 'Zorg ervoor dat u GEEN BEP2-, BEP20-adres (BSC) invoert als LTC-adresportefeuille voor opname',
  withdrawNoteSublist: ' Gelieve NIET terug te trekken als cross-chain overdracht',
  withdrawHistory: 'Geschiedenis',
  withdrawWithdrawBtn: 'Verzoek intrekken',
  withdrawFees: 'Opnamekosten {{fees}} {{token}}',
  withdrawWithdrawPending: 'Uw opnameverzoek is in behandeling. ',
  withdrawEnterFields: 'Voer het opnamebedrag en uw portemonnee-adres in.',
  withdrawSelectWallet: 'Selecteer een portemonnee om op te nemen.',
  withdrawAmountFieldTitle: 'Bedrag (min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Jouw {{wallet.currency.code}} adres',
  withdrawAddressFieldPlaceholder: 'Typ uw adres',
  withdrawNoBalance: 'Niet genoeg balans.',
  withdrawMinAmount: 'Voer een bedrag in dat groter is dan of gelijk is aan {{min}} .',
  fiatCurrencyInfo: 'De gebruiker kan geen fiatgeld storten of opnemen. ',
  paginationNextBtn: 'Volgende',
  paginationPrevBtn: 'Vorig',
  wentWrong: 'Er is iets fout gegaan.',
  referralTitle: 'Verwijzing',
  referralSubTitle: 'Verwijs uw vrienden',
  referralEarn: ' Verdien elk ₹ 150',
  referralReferAndEarn: 'Verwijs en verdien een bonus voor elke verwijzing',
  referralReferPara: ' Als u deze code naar een gebruiker verwijst',
  referralReferLink: 'Verwijs Link',
  referralCopyReferralCode: 'Kopieer de verwijzingscode',
  referralTotalRewards: 'Totale beloning',
  TwoWayAuthentication: 'Tweerichtingsauthenticatie',
  LoginRequired: 'Aanmelden vereist!',
  BetPlacedSuccessfully: 'Weddenschap succesvol geplaatst',
  BetAddedInQueueSuccessfully: 'Weddenschap succesvol toegevoegd aan de wachtrij',
  BetRemovedFromQueueSuccessfully: 'Weddenschap is succesvol uit de wachtrij verwijderd',
  BetCancelledSuccessfully: 'Weddenschap succesvol geannuleerd',
  PlayerEscapedSuccessfully: 'Speler is succesvol ontsnapt',
  CopiedSuccessfull: 'Gekopieerd',
  NotEnoughBalance: 'Niet genoeg balans',
  AutoBetStarted: 'Automatische weddenschap gestart ',
  AutoBetFinished: 'Automatische weddenschap voltooid ',
  IdCopied: 'ID gekopieerd',
  usersConsent: 'Door deze site te bezoeken, bevestig ik dat ik 18 jaar oud ben en de',
  termsOfService: 'Servicevoorwaarden',
  email: 'E-mail',
  playNow: 'Nu afspelen',
  orContinueWith: 'Of ga verder met',
  metamaskExtensionNotAvailable: 'Installeer de metaMask-extensie en vernieuw de pagina.',
  cashier: 'KASSA',
  account: 'REKENING',
  hello: 'Hallo',
  balance: 'Evenwicht',
  referrals: 'Verwijzingen',
  settings: 'Instellingen',
  withdrawals: 'Opnames',
  demoWallet: 'Demo-portemonnee',
  myAccount: 'Mijn rekening',
  wallet: 'Portemonnee',
  user: 'Gebruiker',
  USD: 'Amerikaanse Dollar',
  amountIsRequired: 'Bedrag is vereist',
  minDepositAmount: 'Het stortingsbedrag moet groter zijn dan {{min}}.',
  maxDepositAmount: 'Het stortingsbedrag moet lager zijn dan {{max}}.',
  minWithdrawAmount: 'Het opnamebedrag moet groter zijn dan {{min}}',
  maxWithdrawAmount: 'Het op te nemen bedrag moet minder zijn dan {{max}}',
  withdrawMustLessThanWallet: 'Het opnamebedrag moet lager zijn dan het bedrag in uw portemonnee',
  accountTypeIsRequired: 'Accounttype is vereist.',
  mobileNumberLength: 'Mobiel nummer moet van zijn {{length}} cijfers',
  phoneIsRequired: 'Telefoon is vereist',
  cpfError: 'CPF moet van zijn {{length}} karakters',
  cpfRequiredError: 'CPF is vereist',
  cnpjError: 'CNPJ moet van zijn {{length}} karakters',
  mainMenu: 'HOOFDMENU',
  casino: 'Casino',
  sports: 'Sport',
  crypto: 'Crypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'VERDIEN BELONINGEN',
  rankUp: 'Rang omhoog',
  inviteFriends: 'Nodig vrienden uit',
  partnerProgram: 'Partnerprogramma',
  home: 'Thuis',
  sportsbook: 'Sportboek',
  menu: 'Menu',
  liveBets: 'LIVE WEDDENSCHAPPEN',
  allBets: 'Alle weddenschappen',
  highRollers: 'Hoge rollen',
  luckyBets: 'Gelukkige weddenschappen',
  trade: 'Handel',
  game: 'SPEL',
  player: 'SPELER',
  time: 'TIJD',
  wager: 'WEDDEN',
  multiplierBan: 'VERMENIGVULDIGER',
  payoutText: 'UITBETALING',
  bannerHeadOne: 'Ervaar echte innovatie met het hoogste',
  bannerHeadTwo: 'beloningsprogramma binnen de branche.',
  registerNow: 'Registreer nu',
  liveWins: 'LIVE WINT',
  monthly: 'Maandelijks',
  weekly: 'Wekelijks',
  daily: 'Dagelijks',
  winner1: 'Winnaar 1',
  winner2: 'Winnaar 2',
  winner3: 'Winnaar 3',
  spin: 'Spin',
  spinsWon: 'Gewonnen Spins',
  joinedOn: 'Lid sinds',
  wageringAmount: 'Inzetbedrag',
  name: 'Naam',
  allGames: 'Alle spelen',
  allSports: 'Alle sporten',
  cryptoFutures: 'crypto-futures',
  tradeNow: 'Handel nu',
  allTables: 'Alle tafels',
  nft: 'NFT',
  viewAllGames: 'Bekijk alle spellen',
  viewAll: 'Alles zien',
  rewards: 'BELONINGEN',
  profile: 'Profiel',
  totalBets: 'Totaal aantal weddenschappen',
  totalWagered: 'Totaal ingezet',
  joinDate: 'Datum van deelname',
  displaySocialLinks: 'Gekoppelde sociale accounts weergeven',
  privateProfile: 'Privé profiel',
  rank: 'Rang',
  winner: 'Winnaars',
  nextRank: 'Volgende rang',
  changeUsername: 'Gebruikersnaam wijzigen',
  newPassword: 'Nieuw paswoord',
  changeName: 'Naam veranderen',
  linkSocialAccount: 'Koppel sociaal account',
  privateProfileTooltip: 'Nadat u uw profiel privé heeft gemaakt, zullen andere gebruikers uw gebruikersnaam niet zien naast weddenschappen, races, enz.',
  socialLinksTooltip: 'Inhoud niet beschikbaar',
  rankTooltip: 'Inhoud niet beschikbaar',
  balances: 'saldi',
  createReferralBalance: 'Creëer een verwijzingssaldo',
  total: 'Totaal',
  switchBalance: 'Wissel van balans',
  mainAccount: 'Hoofd account',
  tips: 'Tips',
  receivedAmount: 'Ontvangen bedrag',
  dateOrTime: 'Datum Tijd',
  view: 'Weergave',
  allChains: 'Alle ketens',
  creditAmount: 'Hoeveelheid krediet',
  setCustomReferralCode: 'Stel een aangepaste verwijzingscode in',
  changeEmail: 'Wijzig e-mailadres',
  newEmail: 'Nieuwe e-mail',
  createPassword: 'Maak een wachtwoord',
  newUsername: 'Nieuwe gebruikersnaam',
  username: 'Gebruikersnaam',
  changePassword: 'Verander wachtwoord',
  updateProfileMsg: 'profiel succesvol bijgewerkt',
  twoFactorAuthentication: 'tweefactorauthenticatie',
  twoFactorAuthenticationTitle: 'Het gebruik van tweefactorauthenticatie wordt ten zeerste aanbevolen, omdat het uw account beschermt met zowel uw wachtwoord als uw telefoon.',
  twoFactorAuthenticationSubTitle: 'Om 2FA te activeren moeten een e-mailadres en wachtwoord worden ingesteld. ',
  enable2fa: '2FA INSCHAKELEN',
  verifyIdentityByKyc: 'Controleer uw identiteit (kyc)',
  oldPassword: 'Oud Wachtwoord',
  live: 'Live',
  soccer: 'Voetbal',
  tennis: 'Tennis',
  fifa: 'FIFA',
  basketball: 'Basketbal',
  iceHockey: 'IJs Hokey',
  volleyball: 'Volleybal',
  tableTennis: 'Tafeltennis',
  baseball: 'Basketbal',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boksen',
  myBets: 'Mijn weddenschappen',
  favourites: 'Favorieten',
  americanFootball: 'Amerikaans voetbal',
  buyCrypto: 'Koop Crypto',
  oldEmail: 'Oude e-mail',
  loggedInSuccess: 'succesvol ingelogd',
  loggedOutSuccess: 'Succesvol uitgelogd',
  wagerLimit: 'Inzetlimiet succesvol ingesteld',
  lossLimit: 'Verlieslimiet succesvol ingesteld',
  accountDisable: 'Account is succesvol uitgeschakeld',
  sessionTimeout: 'Sessietime-out is succesvol ingesteld',
  signup: 'Succesvol aanmelden',
  depositLimit: 'Stortingslimiet succesvol ingesteld',
  tokenVerify: 'Token is naar uw e-mailadres verzonden. Controleer dit',
  emailVerify: 'E-mail was geverifieerd',
  emailChangedSuccess: 'E-mailadres is succesvol gewijzigd',
  profileUpdatedSuccess: 'Profiel geüpdatet',
  otpVerified: 'OTP geverifieerd',
  twoFADeactivated: '2FA Authenticatie gedeactiveerd',
  internalServerError: 'Interne Server Fout',
  unAuthorized: 'Ongeautoriseerd',
  dataNotFound: 'Data niet gevonden',
  setReferralCode: 'Verwijzingscode instellen...',
  ultimate: 'ULTIEM',
  notFound: 'Niet gevonden',
  casinoTransactions: 'Casinotransacties',
  sportsbookTransactions: 'Sportsbook-transacties',
  status: 'Toestand',
  success: 'Succes',
  failed: 'Mislukt',
  pending: 'In behandeling',
  date: 'Datum',
  to: 'Naar',
  gameName: 'Spel naam',
  transactionId: 'Transactie ID',
  roundId: 'Ronde ID',
  bet: 'Inzet',
  win: 'Winnen',
  transactionType: 'Transactie Type',
  at: 'bij',
  downloadAll: 'Download alles',
  gameText: 'Spel',
  betId: 'Inzet-ID',
  gameId: 'Spel ID',
  actionType: 'actie type',
  searchGames: 'Zoek spellen',
  provider: 'Aanbieders',
  search: 'Zoekopdracht',
  sortBy: 'Sorteer op',
  popular: 'Populair',
  challengePool: 'Uitdagingspool',
  loadMore: 'Meer laden',
  bitcoin: 'Bitcoin',
  chat: 'Chatten',
  trades: 'Transacties',
  battles: 'Gevechten',
  loginToChat: 'Log in om te chatten',
  rules: 'Reglement',
  chatRule1: 'Val andere gebruikers niet lastig en beledig ze niet',
  chatRule2: "Vraag niet om leningen, trivia's of tips",
  chatRule5: 'Maak geen reclame voor enige vorm van handels-, koop- of verkoopdiensten',
  chatRule6: 'Deel of maak geen reclame voor uw verwijzingscode',
  chatRule7: 'Vraag niet om medewerker te worden',
  chatRule8: 'alleen Engels',
  chatRule9: 'Respecteer mods, beheerders en andere gebruikers',
  send: 'Versturen',
  expand: 'Uitbreiden',
  signIn: 'Aanmelden',
  cross: 'Kruis',
  collapse: 'Instorten',
  emailPlaceholder: 'uw e-mailadres@domein.com',
  removeFromFavourite: 'Verwijder uit favorieten',
  addToFavourite: 'Toevoegen aan favorieten',
  footerAboutSite: `Laten we het even over wetenschap hebben? De website van Cash Machine Casino is een
deel van jou, omdat we allemaal uit dezelfde atomen bestaan. Jouw interactie ermee
bevestigt deze verbinding`,
  footerRightsReserved: 'Сash Machine Casino alle rechten voorbehouden.',
  signupFirstName: 'Voornaam',
  signupAddress: 'Adres',
  signupCity: 'Stad',
  signupPostcode: 'Postcode ',
  signupCounty: 'Land',
  signupCurrency: 'Munteenheid',
  signupGender: 'Geslacht',
  signupMan: 'Man',
  signupWomen: 'Vrouwen',
  signupOther: 'Ander',
  signupLoginDetails: 'Inloggen Details',
  signupPersonalDetails: 'Persoonlijke gegevens',
  signupConfirm: 'Bevestigen',
  signupPrivacyPolicy: 'Ik heb het privacybeleid gelezen en geaccepteerd',
  signupTermAndConditions: 'Ik ga akkoord met de verwerking van mijn persoonsgegevens. ',
  signupNewsLetter: 'Ik wil graag periodiek bonussen, promoties, nieuws of andere relevante informatie ontvangen via de nieuwsbrief.',
  signupSms: 'Ik wil graag periodiek bonussen, promoties, nieuws of andere relevante informatie per sms ontvangen.',
  initialHeaderContent: 'Ontvang een geweldige 100%',
  secoundaryHeaderContent: 'Welkomstbonus tot $ 100',
  playNowButton: 'Nu afspelen',
  registerButtonContent: 'Register',
  results: 'Resultaten',
  refresh: 'Vernieuwen',
  cancel: 'Annuleren',
  select: 'Selecteer',
  current: 'Huidig',
  set: 'Set',
  remove: 'Verwijderen',
  save: 'Redden',
  not: 'niet',
  before: 'Voor',
  after: 'Na',
  action: 'Actie',
  withdrawal: 'Opname',
  requested: 'Aangevraagd',
  withdraw: 'Terugtrekken',
  areYouSure: 'Weet je het zeker',
  yes: 'Ja',
  no: 'Nee',
  cancelled: 'Geannuleerd',
  approved: 'Goedgekeurd',
  refunded: 'Terugbetaald',
  chargeback: 'Terugvordering',
  processedOn: 'Verwerkt Aan',
  transaction: 'Transactie',
  dateTime: 'Datum Tijd',
  more: 'Meer',
  fromDate: 'Van datum',
  toDate: 'Daten',
  percentage: 'Percentage',
  info: 'Info',
  min: 'Min',
  max: 'Max',
  change: 'Wijziging',
  continueToSite: 'Ga verder naar de site',
  choose: 'Kiezen',
  enterWithdrawalAmount: 'Kies of voer uw opnamebedrag in',
  capDeposit: 'BORG',
  capWithdrawal: 'OPNAME',
  capAmount: 'HOEVEELHEID',
  minimum: 'Minimum',
  required: 'Vereist',
  of: 'van',
  isRequired: 'Is benodigd',
  isAllowed: 'is toegestaan',
  method: 'Methode',
  capWithdraw: 'TERUGTREKKEN',
  showLess: 'Laat minder zien',
  clearFilter: 'Filter wissen',
  quantity: 'Hoeveelheid',
  reset: 'Opnieuw instellen',
  incorrect: 'Niet correct',
  load: 'Laden',
  applied: 'Toegepast',
  ok: 'OK',
  list: 'Lijst',
  all: 'Alle',
  favorite: 'Favoriet',
  endPointNotFound: 'Er is iets mis met de netwerkverbinding',
  category: 'Categorie',
  emailUserNameRequired: 'E-mailadres/gebruikersnaam vereist',
  emailUserNamePlaceHolder: 'Voer uw e-mailadres of gebruikersnaam in',
  passwordRequired: 'Wachtwoord benodigd',
  accountFrozen: 'Uw account is bevroren tot',
  resendEmail: 'Email opnieuw verzenden',
  resendLink: 'LINK OPNIEUW VERZENDEN',
  userLoggedOut: 'Succesvol uitgelogd',
  emailAlreadyRegistered: 'Deze e-mail is al geregistreerd',
  userNameAlreadyTaken: 'Deze gebruikersnaam is al in gebruik',
  fillAllFields: 'Vul alle velden in voordat u naar de volgende stap gaat',
  pleaseAccept: 'Accepteer alstublieft',
  acceptPrivacyPolicy: 'Privacybeleid',
  acceptTerms: 'Algemene voorwaarden',
  modeOfComm: ' en wijze van communiceren',
  beforeNextStep: 'voordat u naar de volgende stap gaat',
  userNamePlaceholder: 'Vul je gebruikersnaam in',
  min8Characters: 'Minimaal 8 tekens',
  min1Number: 'Min. 1 nummer',
  min1LowerCase: 'Min. 1 kleine letter',
  min1UpperCase: 'Min. 1 hoofdletter',
  min1SpecialChar: 'Min. 1 speciaal karakter',
  passwordTip: 'Het wachtwoord moet minimaal acht tekens bevatten, met minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken',
  passwordPlaceholder: 'Voer wachtwoord in',
  confirmPasswordPlaceholder: 'Voer het wachtwoord in',
  acceptAll: 'Accepteer alles',
  firstNamePlaceholder: 'Vul voornaam in',
  lastNamePlaceholder: 'Voer achternaam in',
  addressPlaceholder: 'Adres invoeren',
  cityPlaceholder: 'Stad invoeren',
  postCodePlaceholder: 'Voer postcode in',
  phonePlaceholder: 'Voer telefoonnummer in',
  invalidEmail: 'Ongeldig e-mail',
  emailRequired: 'Email (verplicht',
  confirmPasswordRequired: 'Bevestig wachtwoord vereist',
  firstNameRequired: 'Voornaam (verplicht',
  lastNameRequired: 'Achternaam vereist',
  phoneRequired: 'Telefoon vereist',
  dateOfBirthRequired: 'Geboortedatum vereist',
  genderRequired: 'Geslacht vereist',
  userNameRequired: 'Gebruikersnaam (verplicht',
  addressRequired: 'Adres vereist',
  cityRequired: 'Stad Vereist',
  postCodeRequired: 'Postcode vereist',
  currencyCodeRequired: 'Valutacode vereist',
  countryRequired: 'Land vereist',
  matchPassword: 'Wachtwoorden moeten overeenkomen',
  fName3Chars: 'De voornaam moet minimaal 3 tekens lang zijn',
  onlyAlphabet: 'Alleen alfabetten toegestaan',
  lName3Chars: 'Achternaam moet minimaal 3 tekens lang zijn',
  mustBe18: 'Je moet 18 jaar oud zijn om je te kunnen registreren',
  mustBeUnder200: 'U moet jonger zijn dan 200 jaar om u te kunnen registreren',
  validDOB: 'Voer alstublieft een geldige geboortedatum in',
  max20Characters: 'Maximaal 20 tekens toegestaan',
  min3Characters: 'Minimaal 3 tekens vereist',
  max100Characters: 'Maximaal 100 tekens toegestaan',
  max50Characters: 'Maximaal 50 tekens toegestaan',
  invalidNumber: 'Ongeldig nummer',
  preferredLanguageRequired: 'Voorkeurstaal vereist',
  invalidPass: 'ongeldig wachtwoord',
  prevBtn: 'Vorig',
  dayRequired: 'Dag is vereist',
  monthRequired: 'Maand is vereist',
  yearRequired: 'Jaar is vereist',
  uploadImage: 'Afbeelding uploaden',
  removeImage: 'Afbeelding verwijderen',
  preferredLanguage: 'Voorkeurstaal',
  wantToChangePass: 'Wilt u het wachtwoord wijzigen?',
  selectCountryCode: 'Selecteer Landcode',
  selectPreferredLanguage: 'Selecteer Voorkeurstaal',
  fName50Chars: 'Voornaam moet maximaal 50 tekens lang zijn',
  lName50Chars: 'Achternaam moet maximaal 50 tekens lang zijn',
  invalidDate: 'Ongeldige datum',
  DOBEarlyThanToday: 'Geboortedatum moet eerder zijn dan vandaag',
  enterValidPhone: 'Voer een geldig telefoonnummer in',
  accountStatus: 'Account Status',
  kycVerified: 'Uw KYC is succesvol geverifieerd.',
  denied: 'Geweigerd',
  chooseFile: 'Kies bestand',
  upload: 'Uploaden',
  countryCantChange: 'U staat op het punt de KYC-verificatie te starten. ',
  proceed: 'Doorgaan',
  update: ' ',
  history: 'Geschiedenis',
  type: 'Type',
  amount: 'Hoeveelheid',
  forfeited: 'Verbeurd',
  expired: 'Verlopen',
  completed: 'Voltooid',
  zeroedOut: 'Op nul gezet',
  active: 'Actief',
  lapsed: 'Vervallen',
  yourActiveBonus: 'Uw actieve bonussen',
  currentActiveBonus: 'Huidige actieve bonussen',
  bonusStatus: 'Toestand',
  bonusWager: 'Inzet',
  bonusActivate: 'Activeren',
  bonusForfeit: 'Bonus verbeurd',
  notUsableBonus: 'Zo ja, dan kunt u deze bonus niet meer gebruiken.',
  bonusLapsed: 'Je had niet genoeg verliezen om cashback te krijgen. ',
  inProcess: 'In proces',
  claiming: 'Beweren',
  loyaltyPopover: 'Deze loyaliteitspunten kunnen worden gebruikt voor het verkrijgen van cashback. ',
  loyaltyPage: 'Loyaliteitspagina',
  loyaltyPerCurrency: 'Loyaliteit per valuta',
  maxLevelReached: 'Maximum level bereikt',
  pointsToReach: 'punten te bereiken ',
  loyaltyBannerHeading: 'U verdient onze gloednieuwe loyaliteit',
  loyaltyBannerDesc: '100% welkomstbonus tot $ 200',
  loyaltyHeadingOne: 'U wordt altijd beloond bij CashMachine',
  loyaltyHeadingOneDesc: 'Bij CashMachine tillen we loyaliteit naar een geheel nieuw niveau van opwinding, waarbij we u bij elke draai en bocht met beloningen overladen! ',
  loyaltySubHeadOne: 'Loyaliteitspunten verdienen',
  loyaltySubHeadOneDesc: 'Voor elke € 10 aan contante weddenschappen die u in ons casino plaatst, verdient u 1 loyaliteitspunt. ',
  loyaltySubHeadTwo: 'Het beklimmen van de loyaliteitsladder',
  loyaltySubHeadTwoDesc: 'Hoe meer punten je verzamelt, hoe hoger je stijgt in de loyaliteitsniveaus, waardoor je onderweg nog meer fantastische beloningen kunt ontgrendelen. ',
  loyaltySubHeadThree: 'Ga een level omhoog, word beloond ',
  loyaltySubHeadThreeDesc: 'Verbeter uw game-ervaring door onze verleidelijke beloningsniveaus te doorlopen, waarbij elk niveau hoger een opwindende reeks gratis spins ontgrendelt om uw winkansen te maximaliseren. ',
  loyaltyTestimonialHeadOne: 'BIJ CashMachine CASINO ZORGEN WIJ DAT',
  loyaltyTestimonialHeadTwo: 'Elke draai telt',
  loyaltyTestimonialDesc: 'Elke draai die je maakt aan de gokautomaten is niet alleen voor de lol, maar brengt je ook dichter bij een aantal zeer mooie beloningen! ',
  loyaltyPoints: 'Punten',
  headerLevel: 'niveau',
  loyaltyCashback: 'Geld terug',
  loyaltyBannerBtn: 'Controleer de voortgang',
  loyaltyHeadingTwo: 'Ontgrendel de ultieme game-ervaring: CashMachine VIP-programma',
  loyaltyHeadingTwoDesc: 'Zodra u niveau 6 in ons loyaliteitsprogramma bereikt, ontgrendelt u een wereld aan exclusieve privileges als lid van het prestigieuze CashMachine VIP-programma. ',
  loyaltySubHeadFour: 'Hogere stortings- en opnamelimieten',
  loyaltySubHeadFourDesc: 'Geniet als VIP-lid van de flexibiliteit van verhoogde stortings- en opnamelimieten.',
  loyaltySubHeadFive: 'Exclusieve bonussen en promoties',
  loyaltySubHeadFiveDesc: 'Krijg toegang tot speciale bonussen en promoties op maat gemaakt voor onze VIP-spelers',
  loyaltySubHeadSix: 'Persoonlijke Accountmanager',
  loyaltySubHeadSixDesc: 'Ontvang toegewijde ondersteuning en persoonlijke hulp van uw accountmanager.',
  loyaltySubHeadSeven: 'Snellere opnametijden',
  loyaltySubHeadSevenDesc: 'Als VIP profiteert u van versnelde opnametijden, zodat u nog sneller toegang heeft tot uw winsten.',
  loyaltySubHeadEight: 'Maandelijkse cashback-beloningen',
  loyaltySubHeadEightDesc: 'Geef uw bankroll een boost met royale maandelijkse cashbackbeloningen, exclusief voor onze VIP-leden.',
  loyaltySubHeadNine: 'Uitnodigingen voor exclusieve evenementen en toernooien',
  loyaltySubHeadNineDesc: "Ontvang felbegeerde uitnodigingen voor exclusieve evenementen en toernooien, waar u zich kunt mengen met mede-VIP's en kunt strijden om ongelooflijke prijzen.",
  loyaltySubHeadTen: 'Verjaardagsbonussen',
  loyaltySubHeadTenDesc: 'Vier uw speciale dag in stijl met een heerlijke verjaardagsbonus speciaal voor u!',
  loyaltySubHeadEleven: 'Luxe geschenken',
  loyaltySubHeadElevenDesc: 'Geniet van een voortreffelijke selectie luxe geschenken die zorgvuldig zijn uitgekozen om onze meest gewaardeerde spelers te belonen.',
  loyaltyTableHeading: 'Overzicht niveaus en beloningen',
  loyaltyTableHeaderOne: 'Loyaliteitsniveau',
  loyaltyTableHeaderTwo: 'Loyaliteitspunten',
  loyaltyTableHeaderThree: 'Dagelijkse cashback',
  promReadMore: 'Lees verder',
  currentPortalBlock: 'als u uw account alleen voor dit portaal wilt blokkeren.',
  allPortalBlock: 'om uw account van alle portalen te blokkeren.',
  limit24Reset: 'Zodra u inzet-, verlies- en stortingslimieten heeft ingesteld, kunnen deze na 24 uur worden bewerkt en verwijderd, maar de limieten kunnen onmiddellijk worden verlaagd.',
  enterAmount: 'Voer een bedrag in',
  limit: 'Begrenzing',
  loss: 'Verlies',
  takeABreak: 'Neem een ​​pauze',
  session: 'Sessie',
  selfExclusion: 'Zelfuitsluiting',
  used: 'Gebruikt',
  edit: 'Bewerking',
  updatedAt: 'Bijgewerkt op',
  cannotBeRemoved: 'Kan niet eerder worden verwijderd',
  timePeriod: 'Tijdsperiode',
  custom: 'Aangepast',
  hours: 'Uur',
  days: 'Dagen',
  timePeriodPlaceholder: 'Aantal dagen',
  months: 'Maanden',
  permanent: 'Permanent',
  onlyNumbers: 'Alleen nummers toegestaan',
  notNumbers: 'Nummers niet toegestaan',
  userNameAllowed: 'Moet beginnen met alfabet. ',
  timePeriodRequired: 'Tijdsperiode vereist',
  cannotBeIncreased: 'Kan niet eerder worden verhoogd',
  amountGTZero: 'Het bedrag moet groter zijn dan 0',
  amountEqualOrLess: 'Het bedrag moet gelijk zijn aan of lager zijn dan het wekelijkse en maandelijkse bedrag',
  amountInBetween: 'Het bedrag moet tussen dagelijks en maandelijks liggen (inclusief)',
  amountEqualOIrGreater: 'Het bedrag moet gelijk zijn aan of groter zijn dan het dagelijkse en maandelijkse bedrag',
  limitRemoved: 'Uw volgende limiet wordt verwijderd.',
  limitSet24Hrs: 'U staat op het punt de volgende limiet voor uw account in te stellen. ',
  takeABreakInfo: 'U staat op het punt de toegang tot uw account voor een vooraf ingestelde periode te blokkeren. ',
  sessionLimitInfo: 'U staat op het punt de volgende limiet voor uw account in te stellen. ',
  limitCantSetBefore: 'Uw sessielimiet kan niet eerder worden ingesteld',
  selfExclusionInfo: 'U staat op het punt de toegang tot uw account voor een vooraf ingestelde periode te blokkeren. ',
  quickSearch: 'Snelzoeken',
  cash: 'Contant geld',
  nonCash: 'Niet-contant',
  userDetail: 'Gebruikersdetail',
  gameIdentifier: 'Spelidentificatie',
  rollback: 'Terugrollen',
  rollbackBeforeBetWin: 'Terugdraaien voordat de weddenschap wint',
  freeSpins: 'Gratis spins',
  betInternal: 'Weddenschap intern',
  winInternal: 'Intern winnen',
  addMoney: 'Voeg geld toe',
  removeMoney: 'Geld verwijderen',
  addMoneyInternal: 'Voeg geld intern toe',
  removeMoneyInternal: 'Geld intern verwijderen',
  depositInternal: 'Storting intern',
  withdrawInternal: 'Intern intrekken',
  promotionTitle: 'Promotie titel',
  cancelWithdrawRequest: 'U wilt dit verzoek annuleren',
  rowsPerPage: 'Rijen per pagina',
  availedBonus: 'Je hebt al een actieve bonus. ',
  capForfeit: 'IN BESLAG NEMEN',
  itFirst: 'het eerst.',
  selectYourBonus: 'Selecteer uw bonus',
  skip: 'OVERSLAAN',
  with: 'met',
  without: 'zonder',
  selectPaymentMethod: 'Kies uw betaalmethode',
  volatility: 'Wisselvalligheid',
  paylines: 'Winlijnen',
  theme: 'Thema',
  tryForFree: 'Probeer gratis',
  resultsFound: 'Gevonden resultaten',
  games: 'Spellen',
  in: 'in',
  low: 'Laag',
  medium: 'Medium',
  high: 'Hoog',
  'medium-high': 'Gemiddeld hoog',
  'very-high': 'Heel hoog',
  'low-medium': 'Laag Gemiddeld',
  fantasy: 'Fantasie',
  ancient_civilizations: 'Oude beschavingen',
  fruits: 'Fruit',
  africa: 'Afrika',
  military: 'Leger',
  joker: 'grappenmaker',
  asia: 'Azië',
  luxurylife: 'Luxe leven',
  underwater_world: 'Onderwaterwereld',
  book_off: 'Boek af',
  western: 'Westers',
  retro: 'Retro',
  egypt: 'Egypte',
  party: 'Feest',
  st_patrick_day: 'Sint-Patrickdag',
  space: 'Ruimte',
  easter: 'Pasen',
  girls: 'Meisjes',
  branded: 'Gemerkt',
  x_mas_and_new_year: 'Kerstmis en Nieuwjaar',
  horrors: 'Verschrikkingen',
  other: 'Ander',
  loginFirst: 'Meld u eerst aan om het spel aan uw favorieten toe te voegen',
  loginFirstPlay: 'Log eerst in om dit spel te spelen',
  sport: 'Sport',
  pirates: 'Piraten',
  sweets: 'Snoepgoed',
  luxury_life: 'Luxe leven',
  st_valentines_day: 'Valentijnsdag',
  halloween: 'Halloween',
  food: 'Voedsel',
  lobby: 'lobby',
  favourite: 'Favoriet',
  playNGO: 'Speel N Go',
  loyaltySystem: 'Loyaliteitssysteem',
  progressionSystem: 'Voortgangssysteem',
  startPoint: 'Startpunt',
  endPoint: 'Eindpunt',
  loyaltyBonus: 'Loyaliteit bonus',
  pageRemoved: 'Deze pagina bestaat niet of is verwijderd',
  suggestToBackHome: 'Wij raden u aan terug naar huis te gaan',
  backToHome: 'Terug naar huis',
  goHome: 'Ga naar huis',
  oopps: 'Oeps',
  somethingNotWorking: 'Het spijt ons, er werkt hier iets niet!',
  tournament: 'Toernooi',
  none: 'Geen',
  validOnDays: 'Geldig op dagen',
  validTill: 'Geldig tot',
  yourRequest: 'Je aanvraag',
  withdrawalRequestPending: 'U heeft één opnameverzoek in behandeling. ',
  realBalance: 'Echt evenwicht',
  bonusInCasino: 'Bonus In Casino',
  bonusSelected: 'Bonus geselecteerd',
  payWith: 'Betaal met',
  reached: 'Bereikt',
  receiptOfYour: 'Ontvangst van uw',
  capEmail: 'E-MAIL',
  capCreditCard: 'CREDITCARDNUMMER',
  capExpiry: 'VERVALDAG',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY heeft niet het juiste formaat',
  min13Numbers: 'Minimaal 13 nummers vereist',
  max19Numbers: 'Maximaal 19 nummers toegestaan',
  min3Numbers: 'Minimaal 3 nummers vereist',
  max4Numbers: 'Maximaal 4 nummers toegestaan',
  Other: 'Ander',
  forfeit: 'In beslag nemen',
  withdrawRequestCreated: 'Intrekkingsverzoek succesvol aangemaakt',
  noBetHistory: 'Geen wedgeschiedenis gevonden',
  noPromotions: 'Geen promoties gevonden',
  noBonuses: 'Geen bonussen gevonden',
  noHistory: 'Geen geschiedenis gevonden',
  noWithdrawalHistory: 'Geen opnamegeschiedenis gevonden',
  noCMS: 'Geen CMS gevonden',
  noProvidersFound: 'Geen aanbieders gevonden',
  noDataFound: 'Geen data gevonden',
  countryBlocked: 'LAND GEBLOKKEERD',
  forbidden: 'VERBODEN',
  notAcceptingVisitors: 'Het spijt ons zeer, maar Shinywilds.com is niet beschikbaar in jouw land.',
  contactUsAt: 'Als u denkt dat dit ten onrechte aan u is getoond, neem dan contact op met onze ondersteuning via e-mail op',
  siteUnderMaintenance: 'Deze site wordt op dit moment onderhouden',
  weWillBeRightBack: 'We zijn zo terug!',
  serviceUnavailable: 'Onze service is tijdelijk niet beschikbaar. ',
  tryAgainLater: 'Probeer het later opnieuw.',
  checkYourEmail: 'Controleer uw e-mail',
  sentAVerification: 'We hebben een verificatielink gestuurd',
  toYourEmail: 'naar uw e-mail.',
  backToLogin: 'Terug naar Inloggen',
  goToLogin: 'Ga naar Inloggen',
  accountVerified: 'Account geverifieerd',
  yourAccountVerified: 'Uw account is geverifieerd',
  successfully: 'Met succes',
  verificationFailed: 'Verificatie mislukt',
  emailFailedContact: 'Verificatie mislukt. Als dit herhaaldelijk gebeurt, neem dan contact op met ',
  setNewPass: 'Stel een nieuw wachtwoord in',
  passResetLink: 'We hebben een link voor het opnieuw instellen van uw wachtwoord gestuurd naar',
  toYourAccount: 'Naar jouw rekening',
  sendPasswordResetLink: 'Verzoek om wachtwoord opnieuw in te stellen',
  gamePlayWagerComplete: 'Gefeliciteerd, u heeft met succes ingezet en het gewonnen bonusgeld is groter dan het maximaal toegestane winstbedrag. ',
  real: 'Echt',
  demo: 'Demo',
  money: 'Geld',
  formatRquired: '.png, .jpg, .jpeg-formaat vereist',
  chooseImage: 'Kies een afbeelding',
  imageSize3MB: 'Voer een afbeelding in die kleiner is dan 3 MB',
  noProfileImage: 'Er is geen profielafbeelding beschikbaar om te verwijderen',
  limitSetSuccess: 'limiet succesvol ingesteld',
  accountDisabled: 'Account succesvol uitgeschakeld',
  loginSuccess: 'Succesvol ingelogd',
  signupSuccess: 'Uw inschrijving was succesvol',
  linkExpired: 'Link verlopen',
  linkSent: 'Link verzonden',
  accAlreadyVerified: 'Account al geverifieerd. ',
  profileImage: 'Profielfoto',
  profileImageRemoved: 'Profielafbeelding succesvol verwijderd',
  docs: 'Documenten',
  updatedSuccess: 'succesvol geupdatet',
  MATCH: 'OVEREENKOMST',
  BALANCE: 'EVENWICHT',
  FREESPINS: 'FREESPINS',
  MATCH_1: 'OVEREENKOMST',
  DEPOSIT: 'BORG',
  WAGERING: 'WEDDEN',
  PROMOTION: 'PROMOTIE',
  CREDITCARD: 'KREDIETKAART',
  WALLET: 'PORTEMONNEE',
  CRYPTO: 'CRYPTO',
  OTHER: 'ANDER',
  INSTANTBANKING: 'DIRECT BANKEN',
  cookieTitle: 'Koekjes!',
  declineButton: 'Afwijzen',
  acceptButton: 'Aanvaarden',
  cookieDeclinedMessage: 'Voor de best mogelijke ervaring moeten cookies worden geaccepteerd om deze website te kunnen blijven gebruiken. ',
  cookieDescription: 'Welkom bij Shinywilds.com! ',
  forfeitMessageFirst: 'Je hebt momenteel de volgende actieve bonus:',
  forfeitMessageSecond: 'U moet deze bonus verspelen voordat u een nieuwe kunt claimen.',
  forfeitButton: 'In beslag nemen',
  pleaseVerifyFirstText: 'Om een ​​opname te kunnen aanvragen, moeten we eerst uw e-mailadres verifiëren. ',
  clickHere: 'Klik hier',
  pleaseVerifyLastText: 'zodat we u de verificatie-e-mail opnieuw kunnen sturen en verder kunnen gaan.',
  pleseWait: 'Wacht alstublieft op',
  toResend: 'om de link opnieuw te verzenden',
  notVerified: 'U moet uw e-mailadres verifiëren om geld op te nemen',
  verification: 'Verificatie',
  UPLOAD_IMG_MSG: 'Sleep bestanden hierheen, of klik om bestanden te selecteren',
  affiliate: 'Partner',
  affiliateTitle: 'Affiliate-programma',
  affiliateDescription: ' Verwijs ons merk en verdien geld door deel te nemen aan het Cash Machine Affiliate Programma! ',
  refferalTitle: 'Verwijzingsprogramma',
  refferalDescription: 'U kunt deze verwijzingslink met anderen delen en een bonus verdienen als de gebruiker zich registreert via de door u opgegeven link',
  affiliateButtonText: 'Word partner',
  affiliateLink: 'Partnerlink',
  affiliateStatus: 'Uw affiliate-aanvraag is succesvol verzonden',
  currentStatus: 'en de huidige Affiliate-status is {{status}}',
  referralLink: 'referentie link',
  copyTitleMessage: 'Kopieer deze link',
  copiedMessage: 'Link gekopieerd',
  searchedGame: 'Spel gezocht',
  favoriteGame: 'Favoriete spellen',
  randomGames: 'Willekeurige spellen',
  poolPrize: 'Prijzenpot',
  chooseYourWelcomeBonus: 'Kies uw welkomstbonus',
  useNow: 'Gebruik nu',
  readTheTerms: 'Lees de voorwaarden',
  proceedWithoutWelcomeBonus: 'Ga verder zonder welkomstbonus',
  doYouAlreadyHaveAccount: 'Heeft u al een account? ',
  registerViaSocialNetworks: 'Registreer via sociale netwerken',
  iconfirmIm18YearsOlder: 'Ik bevestig dat ik 18 jaar of ouder ben',
  iAgreeWithTermsAndConditions: 'Ik ga akkoord met de algemene voorwaarden',
  thankYouSuccessfullyRegisteringCashMachine: 'Bedankt voor uw succesvolle registratie bij Geldautomaat.',
  verifyEmailMessage: 'Wij verzoeken u uw e-mailadres te verifiëren door op de verificatielink te klikken die naar uw e-mailadres is verzonden om succesvol in te loggen op de site.',
  gotIt: 'Begrepen',
  phonecodeRequired: 'Telefooncode is vereist',
  cpNotAllow: 'kopiëren/plakken is niet toegestaan',
  playFun: 'Speel plezier',
  playReal: 'Speel echt',
  yourRealBalance: 'Uw echte saldo is:',
  chooseYourPaymentMethod: 'Kies uw betaalmethode',
  balanceHistory: 'Saldogeschiedenis',
  liveChat: 'Live chat',
  mail: 'Mail',
  chooseEnterYourDepositAmounts: 'Kies of voer uw stortingsbedragen in',
  noBonusDataAvailable: 'Geen bonusgegevens beschikbaar',
  nowTryPlayForRealBets: 'Probeer nu voor echte weddenschappen te spelen',
  registerAndPlayForRealBets: 'Registreer en speel voor echte weddenschappen',
  tournamentDataNotAvailable: 'Toernooigegevens niet beschikbaar',
  startDate: 'Begin datum',
  endDate: 'Einddatum',
  merchant: 'Handelaar',
  seachHere: 'Zoek hier',
  show: 'Show',
  betResults: 'Wedresultaten:',
  noTransactionsFoundForselectedDate: 'Geen transacties gevonden voor de geselecteerde datum',
  loginError: 'Login fout',
  errorMessageIncorrectlyAuthorization: 'E-mail/login of wachtwoord onjuist ingevoerd Autorisatiefout.',
  profileSaved: 'Profiel opgeslagen',
  addInfo: 'Voeg informatie toe',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Ik heb de beperkingen voor betalingssystemen gelezen en ga ermee akkoord*',
  paymentOptions: 'Betalingsmogelijkheden',
  language: 'Taal',
  betTime: 'Wedden tijd',
  noActiveBonusFound: 'Geen actieve bonus gevonden',
  bonusType: 'Bonustype',
  requiredWageringAmount: 'Vereist inzetbedrag',
  currentWageringAmount: 'Huidig ​​inzetbedrag',
  validUpto: 'Geldig tot',
  bonusCode: 'Bonus code',
  wageringProgressBar: 'Voortgangsbalk voor inzetten',
  noGamesFound: 'Geen spellen gevonden',
  noteYouMayOptfreeSpin: 'Opmerking: u kunt voor elk van deze spellen kiezen voor FREESPIN',
  viewOfferDetails: 'Bekijk aanbiedingsdetails',
  viewFreespinGames: 'Bekijk Freespin-spellen',
  depositBonusPercentage: 'Stortingsbonuspercentage: ',
  validity: 'Geldigheid',
  activated: 'Geactiveerd',
  bonusName: 'Bonusnaam',
  freeRounds: 'Gratis rondes',
  wagering: 'Inzetten',
  ended2: 'Beëindigd',
  searchBonusTitle: 'Zoek op bonustitel',
  claimed: 'Geclaimd',
  noBonusHistoryFoundselectedDates: 'Geen bonusgeschiedenis gevonden voor geselecteerde datums',
  noBonusDetailsFound: 'Geen bonusdetails gevonden',
  enterCode: 'Voer code in',
  transactionTime: 'Transactietijd',
  credit: 'Credit',
  debit: 'Debiteren',
  // bonusInfoOfHeading: 'Bonussen zijn extra betalingen die uw prestaties belonen',
  bonusInfoOfHeading: 'Bonussen zijn aanvullende beloningen die uw activiteit en prestaties erkennen.',
  onlineCasino: 'online casino',
  isTheTop: ' is de bovenkant ',
  bonusInfoPara0: 'Op zoek naar de allerbeste casinospellen online? ',
  tournamentsHistory: 'Geschiedenis van toernooien',

  loginWelcomeBonusInfo: `Cash Machine verwelkomt nieuwe spelers met een welkomstbonus - 100% van
   de storting + 100 gratis spins in het spel "Pat’s Heroes" (Platipus).
   Het minimale stortingsbedrag is EUR 20 / ARS 900 / AUD 30 / CZK 500
   / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70
   / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,

  loginDepositBonusInfo: `Maximum bonusbedrag: 100% van het stortingsbedrag, maar niet meer
   dan EUR 100 / ARS 4000 / AUD 150 / CZK 2500 / DKK 750 / NOK 1000
   / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45 000 /
   TJS 1000 / UZS 1 000 000 / TRY 600`,

  loginFreespinBonusInfo: `Gratis spins worden binnen 5 dagen na de bonusactivatie uitgegeven,
   Dagelijks 20 gratis spins. Al het geld dat met gratis spins wordt gewonnen, wordt bijgeschreven
   het bonusaccount en zijn beschikbaar voor het spelen/inzetten van de
   bonus. Uitbetaling mogelijk na inzet van X40. Bonusinzettijd
   bedraagt 7 dagen.`,

  loginMaximumBetLimitInfo: `Maximale inzetlimiet tijdens inzetten: EUR 1 / ARS 45 / AUD 1,5 / CZK
   25 / DKK 7 / NOK 10 / NZD 1,5 / PLN 5 / USD 1 / ZAR 15 / GEL 3,5 /
   KZT 450 / TJS 10 / UZS 10 000 / TRY 6`,

  bonusInfoPara1: ", met een groot aanbod aan fantastische spellen en schitterende speciale aanbiedingen. Onze gokspellen zijn verkrijgbaar in een breed scala aan thema's, waarbij gokspellen met progressieve jackpot spelers een lucratieve kans bieden om een grote overwinning te behalen. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt zien geschud∂ en gedeeld! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst oefenen in de demomodus om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen.",
  bonusInfoPara2: "Onze gokspellen zijn verkrijgbaar in een breed scala aan thema's, waarbij gokspellen met progressieve jackpot een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt schudden en uitdelen! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst in de demomodus oefenen om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen. Onze gokspellen zijn verkrijgbaar in een breed scala aan thema's, waarbij progressieve jackpot-gokspellen spelers een lucratieve kans bieden om te scoren een grote overwinning. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt schudden en uitdelen! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst oefenen in de demomodus om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen.",
  bonusInfoPara3: "Onze gokspellen zijn verkrijgbaar in een grote verscheidenheid aan thema's, waarbij gokspellen met progressieve jackpot spelers een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt schudden en uitdelen! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst oefenen in de demomodus om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen.",
  bonusInfoPara4: "Onze gokspellen zijn verkrijgbaar in een grote verscheidenheid aan thema's, waarbij gokspellen met progressieve jackpot spelers een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt schudden en uitdelen! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst in de demomodus oefenen om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen. Onze gokspellen zijn verkrijgbaar in een breed scala aan thema's, waarbij progressieve jackpot-gokspellen spelers een lucratieve kans bieden om te scoren een grote overwinning. We bieden ook spannende live dealer-spellen waarbij u uw kaarten in realtime kunt schudden en uitdelen! Weet u niet zeker welk spel het beste bij u past? Maak je geen zorgen: je kunt eerst in de demomodus oefenen om een idee te krijgen van elk spel voordat je ervoor kiest om in het echt te spelen.",
  cashMachineFooterPara1: 'Op zoek naar het beste online casino? Welkom bij ',
  cashMachineFooterPara2: ` waar we spannende
gameplay, royale bonussen en exclusieve toernooien combineren om je het beste op het
gebied van online entertainment te bieden. Wil je de jackpot winnen op onze progressieve
slots, strijden om prijzen in toernooien of je geluk beproeven bij live dealer games? We
hebben alles! Wat maakt ons uniek? Bij `,
  cashMachineFooterPara3: ` kun je niet alleen geldprijzen
winnen, maar ook echte fysieke beloningen! We zijn er trots op de keuze te zijn van spelers
over de hele wereld. Klaar om te verkennen? Speel elk spel dat beschikbaar is in jouw regio
en geniet van ongeëvenaarde spanning. Ervaar het beste, speel met de besten — alleen bij `,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: 'Live Casino, Slots en Drops and wins casino Toernooien',
  tournamentDescTitle: 'Toernooien zijn een geweldige kans om tegen andere spelers te concurreren en genereuze prijzen te winnen.',
  tournamentPara1: 'Op zoek naar de beste online casinospellen? Dan ben je hier aan het juiste adres.',
  tournamentPara2: ' met een ruim aanbod aan fantastische spellen en verbluffende speciale aanbiedingen. Onze gokkastspellen zijn er in een breed scala aan thema\'s, met progressieve jackpot-gokkastspellen die spelers een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer spellen waar je kunt zien hoe je kaarten worden geschud en gedeeld in realtime! Weet je niet zeker welk spel geschikt is voor jou? Maak je geen zorgen - je kunt eerst oefenen in demomodus om een gevoel te krijgen voor elk spel voordat je ervoor kiest om voor echt geld te spelen.',
  tournamentPara3: 'Onze gokkastspellen zijn er in een breed scala aan thema\'s, met progressieve jackpot-gokkastspellen die spelers een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer spellen waar je kunt zien hoe je kaarten worden geschud en gedeeld in realtime! Weet je niet zeker welk spel geschikt is voor jou? Maak je geen zorgen - je kunt eerst oefenen in demomodus om een gevoel te krijgen voor elk spel voordat je ervoor kiest om voor echt geld te spelen. Onze gokkastspellen zijn er in een breed scala aan thema\'s, met progressieve jackpot-gokkastspellen die spelers een lucratieve kans bieden om een grote winst te behalen. We bieden ook spannende live dealer spellen waar je kunt zien hoe je kaarten worden geschud en gedeeld in realtime! Weet je niet zeker welk spel geschikt is voor jou? Maak je geen zorgen - je kunt eerst oefenen in demomodus om een gevoel te krijgen voor elk spel voordat je ervoor kiest om voor echt geld te spelen.',
  'New Games': 'Nieuwe Spellen',
  'Popular Games': 'Populaire Spellen',
  'Games Of The Month': 'Spellen Van De Maand',
  Exclusive: 'Exclusief',
  'Bonus Buy': 'Bonuskoop',
  'Jackpot Games': 'Jackpotspellen',
  lightMode: 'Lichtmodus',
  darkMode: 'Donkere modus',
  searchProvider: 'Zoekprovider',
  tournamentHistoryDataNotAvailable: 'Toernooigeschiedenisgegevens niet beschikbaar',
  viewMore: 'Bekijk meer',
  description: 'Beschrijving',
  codeCopied: 'Code gekopieerd',
  tournamentGames: 'Toernooi spellen',
  freeSpinGames: 'Gratis spinspellen',
  promoCode: 'Promotiecode',
  paymentNote: 'Opmerking: Sluit of vernieuw het venster niet terwijl de transactie wordt verwerkt',
  liveGames: 'Live Spellen',
  play: 'SPELEN',
  demo1: 'DEMO',
  registration: 'REGISTRATIE',
  confirmation: 'Bevestiging',
  forfeitBonusMessage: 'Als je deze bonus activeert, gaat elke oude actieve bonus verloren.',
  bonusConfirmationMessage: 'Weet je zeker dat je deze bonus wilt activeren?',

  forfeitRewardMessage: 'Als u deze beloning activeert, gaat elke oude actieve bonus verloren.',
  rewardConfirmationMessage: 'Weet je zeker dat je deze beloning wilt activeren?',
  reward: 'Beloning',
  purchasedReward: 'Gekochte beloning',
  noActiveRewardFound: 'Geen actieve beloning gevonden',
  noRewardDetailsFound: 'Geen beloning gevonden',
  betDate: 'Inzetdatum',
  shopDes: 'De winkel is waar u uw verzamelde punten kunt inruilen voor verschillende beloningen, bonussen en prijzen.',
  vipInfoHeading: 'VIP-bonussen zijn exclusieve aanbiedingen voor onze trouwste spelers.'
}
