export const REWARD_TYPE = {
  freespin: 'Freespin',
  cash: 'Cash',
  prize: 'Prize'
}

export const STATUS = {
  inProcess: 'IN-PROCESS',
  success: 'SUCCESS',
  claimed: 'CLAIMED'
}
