export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Предстоящие',
  finished: 'Законченный',
  prizePool: 'Призовой фонд',
  joinNow: 'Присоединяйся сейчас',
  joined: 'Присоединился',
  startsIn: 'Начинается через',
  endsIn: 'Заканчивается',
  ended: 'Турнир завершен',
  started: 'Турнир начался',
  seeMore: 'Узнать больше',
  winPrizes: 'Выиграйте захватывающие призы',
  leaderBoard: 'Таблица лидеров',
  prize: 'Приз',
  aboutTournament: 'О турнире',
  congratulationsMessage: 'Браво! ',
  payments: 'Оплата',
  tournaments: 'Турниры',
  shop: 'Магазин',
  blog: 'Блог',
  referral: 'Направления',
  contacts: 'Контакты',
  retry: 'Повторить попытку',
  incorrectCredsMessage: 'Ваше имя пользователя или пароль неверны. ',
  bonuses: 'Бонус',
  depositBonus: 'Бонус на Депозит',
  voucher: 'Ваучер',
  gamlings: 'Азартные игры',
  transactionHistory: 'История транзакций',
  offers: 'Предложения',
  editProfile: 'Редактировать профиль',
  continue: 'Продолжать',
  apply: 'Применять',
  male: 'Мужской',
  female: 'Женский',
  register: 'регистр',
  login: 'Авторизоваться',
  grand: 'Гранд',
  major: 'Главный',
  minor: 'Незначительный',
  mini: 'Мини',
  crashGameTitle: 'Крушение',
  betAmount: 'Сумма ставки',
  profit: 'Выгода',
  payout: 'Целевая выплата',
  chance: 'шанс выиграть',
  betBtn: 'Делать ставку',
  autoCashout: 'Автоматический вывод средств',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Макс',
  wagered: 'Ставка',
  nothingFound: 'Ничего не найдено',
  lost: 'Потерянный',
  won: 'Выиграл',
  streak: 'Полоса',
  highestWin: 'Самый высокий выигрыш',
  promotions: 'Акции',
  latestPromotions: 'Последние акции',
  sponsorships: 'Спонсорство',
  highestLost: 'Самый высокий потерянный',
  highestBet: 'Самая высокая ставка',
  maxStreak: 'Макс Стрик',
  maxProfit: 'Максимальная прибыль: ',
  bal: 'Бал: ',
  messagesNoBetHistory: 'История ваших ставок пуста',
  messagesCopyNotSupported: 'Ваш браузер не поддерживает автоматическое копирование в буфер обмена. Попробуйте скопировать код вручную.',
  messagesCopied: 'Адрес скопирован.',
  deposit: 'Депозит',
  statReset: 'Сбросить статистику',
  rollOver: 'Перекатывать',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Назад',
  inputFieldBtnMax: 'Макс',
  buttonTextPlaceBet: 'Сделать ставку',
  buttonTextCancelBet: 'Отменить ставку',
  buttonTextEscapeBet: 'Обналичить',
  buttonTextStartAutoBet: 'Запустить автоставку',
  buttonTextCancelAutoBet: 'Отменить автоставку',
  buttonTextLoading: 'Загрузка',
  tabsMyBets: 'Мои ставки',
  tabsDeposit: 'Депозит',
  tabsCashout: 'Выплаты',
  tabsAllbets: 'Все ставки',
  tabsTopbets: 'Лучшие ставки',
  tabsBonus: 'Бонусы',
  tabsjackpot: 'Джекпот',
  tabsBetHistory: 'История ставок',
  timerTextCurrentPayout: 'Текущая выплата',
  timerTextStarting: 'Начиная с',
  timerTextSec: 'с',
  timerTextFlewAway: 'Разбился',
  timerTextWait: 'Пожалуйста, подождите..',
  timerTextPreparingRound: 'Подготовка раунда',
  timerTextLoadingRound: 'Загрузка раунда',
  comingSoon: 'Вскоре',
  labelsProfitOnWin: 'Прибыль от победы',
  inputsNoOfBets: 'Количество ставок',
  inputsAuto: 'Авто',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Начинать',
  inputsCancel: 'Отмена',
  buttonsMin: 'Мин',
  buttonsMax: 'Макс',
  prediction: 'Прогноз',
  luckyNumber: 'Счастливое число',
  multiplier: 'Множитель',
  chanceToWin: 'Шанс на победу',
  bets: 'Пари',
  depositBtn: 'Депозит',
  rollUnder: 'Катиться под',
  symbolsX: 'Икс',
  symbolsPercent: '%',
  navBarTitle: 'История',
  navBarMyBets: 'Мои ставки',
  navBarAllBets: 'Все ставки',
  navBarHighRollers: 'Хайроллеры',
  navBarRoundHistory: 'История раунда',
  navBarTotal: 'Всего ставок',
  topHeaderBetId: 'Идентификатор ставки',
  topHeaderUser: 'Пользователь',
  topHeaderPayout: 'Выплата',
  topHeaderAmount: 'Количество',
  topHeaderTime: 'Время',
  topHeaderProfit: 'Выгода',
  topHeaderRoundCrashed: 'Разбился',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Хэш',
  topHeaderRoundBet: 'Делать ставку',
  topHeaderRoundProfit: 'Выгода',
  topHeaderRoundSignature: 'Подпись',
  roundHistoryTitle: 'ИНФОРМАЦИЯ ПО ИГРЕ',
  roundHistoryDescGame: 'Игра',
  roundHistoryDescCrashAt: 'Разбился в:',
  roundHistoryDescDate: 'Дата :',
  roundHistoryDescBetHeader: 'Делать ставку',
  roundHistoryTableTitlePlayer: 'ИГРОК',
  roundHistoryTableTitleBet: 'ДЕЛАТЬ СТАВКУ',
  roundHistoryTableTitleCashedOut: 'ОБНАЛИЧЕНО',
  roundHistoryTableTitleProfit: 'ВЫГОДА',
  roundHistoryTableTitleBetId: 'Идентификатор ставки',
  roundHistoryMessagesNoBetHistory: 'История ваших ставок пуста',
  placedBetsTabPlaced: 'Размещенная ставка',
  placedBetsTabPrev: 'Предыдущая ставка',
  placedBetsTitleUser: 'Пользователь',
  placedBetsTitleEscape: 'Мульти.х',
  placedBetsTitleBet: 'Делать ставку',
  placedBetsTitleProfit: 'Выгода',
  placedBetsTitleCashout: 'Обналичить',
  placedBetsNoPlacedBet: 'Нет сделанных ставок.',
  provablyFairContentCheckBtn: 'Проверьте Provable Fair',
  provablyFairContentTitle: 'Доказуемо справедливо',
  provablyFairContentDesc: 'Результат четырехстороннего лидерства можно признать справедливым. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Частота сбоев',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Частота сбоев без x',
  provablyFairContentInputFieldsRoundHashTitle: 'Круглый хэш',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'например ',
  provablyFairContentInputFieldsSubmitBtn: 'Создать подпись',
  provablyFairContentSignature: 'Круглая подпись',
  provablyFairContentErrorsCrashRate: 'пожалуйста, введите число в поле частоты сбоев',
  resetPasswordTitle: 'Сброс пароля',
  resetPasswordNewPasswordTitle: 'Новый пароль',
  resetPasswordNewPasswordErrorsRequired: '*Необходим пароль.',
  resetPasswordNewPasswordErrorsMinLength: 'Разрешено минимум 8 символов.',
  resetPasswordNewPasswordErrorsMaxLength: 'Разрешено максимум 16 символов.',
  resetPasswordNewPasswordErrorsPattern: '*Пароль должен состоять из 8–16 букв, цифр и специальных символов. ',
  resetPasswordConfirmPasswordTitle: 'Подтвердите пароль',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Пароли не совпадают.',
  resetPasswordResetBtn: 'Перезагрузить',
  passwordResetSuccess: 'Пароль успешно изменен, пожалуйста, войдите в систему, чтобы наслаждаться игрой!',
  resetPasswordWentWrong: 'Что-то пошло не так!',
  levelGrand: 'ГРАНД',
  levelMajor: 'ГЛАВНЫЙ',
  levelMinor: 'НЕЗНАЧИТЕЛЬНЫЙ',
  levelMini: 'МИНИ',
  bannerContentWinDaily: 'Выигрывайте ежедневно $1000 ',
  bannerContentContest: 'Конкурсы сейчас ',
  bannerContentjoin: 'Присоединяйтесь к нашему ежедневному конкурсу ставок и станьте героем!  ',
  bannerContentPlayNow: 'Играть сейчас',
  bannerContentTitle: 'Выиграй джекпот',
  bannerContentContent: 'Играй больше',
  bannerContentGameName: 'АВАРИЙНАЯ ИГРА',
  bannerContentGameDesc: 'Играй больше',
  partners: 'Партнеры',
  gamesGoldenDragon: 'Золотой дракон',
  gamesCrashGame: 'Краш-игра',
  gamesDice: 'Игральная кость',
  gamesHilo: 'Хило',
  allBetsTitle: 'Все ставки',
  allBetsBetId: 'Идентификатор ставки',
  allBetsUser: 'Пользователь',
  allBetsPayout: 'Выплата',
  allBetsAmount: 'Количество',
  allBetsTime: 'Время',
  allBetsProfit: 'Выгода',
  LoginwithOtp: 'Войти через OTP',
  EnterLoginOtp: 'Введите логин',
  submit: 'Представлять на рассмотрение',
  footerAboutUs: 'О нас',
  footerFaq: 'Часто задаваемые вопросы',
  footerSitePolicy: 'Политика сайта',
  footerResponsibleGambling: 'Ответственная игра',
  footerTerms: 'Условия и положения',
  signinTitle: 'Авторизоваться',
  signinUsername: 'Имя пользователя / адрес электронной почты',
  signinPassword: 'Пароль',
  signinLoginBtn: 'Авторизоваться',
  signinSignup: 'Зарегистрироваться!',
  signinForgetPassword: 'Забыли пароль?',
  forgotPasswordMsg: 'Введите адрес электронной почты, связанный с вашей учетной записью, и мы вышлем вам ссылку для сброса пароля.',
  emailLinkMsg: 'Ссылка для сброса пароля была отправлена ​​на ваш регистрационный адрес электронной почты. Нажмите на ссылку электронной почты и установите новый пароль.',
  resetPasswardConfirm: 'Пароль успешно сброшен',
  signinDontHaveAccount: 'У вас нет учетной записи? ',
  signinCreateAccount: 'Зарегистрироваться',
  signinErrorsUserNameRequired: '*Пожалуйста, введите имя пользователя/адрес электронной почты.',
  signinErrorsUserNameMinLength: 'Разрешено минимум 4 символа.',
  signinErrorsUserNameMaxLength: 'Разрешено максимум 20 символов.',
  signinErrorsUserNamePattern: 'Пожалуйста, введите действительное имя пользователя, разрешены только буквы и цифры.',
  signinErrorsPasswordRequired: '*Необходим пароль.',
  signinErrorsPasswordMinLength: 'Разрешено минимум 8 символов.',
  signinErrorsPasswordMaxLength: 'Разрешено максимум 16 символов.',
  signinErrorsPasswordPattern: '*Пароль должен состоять из 8–16 букв, цифр и специальных символов. ',
  signinSuccessfullyLoggedIn: 'Успешно авторизован.',
  signinResetMailSent: 'Письмо для сброса пароля отправлено.',
  signinTryAgain: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
  signinResetPasswordUsernameErr: 'Пожалуйста, введите имя пользователя/адрес электронной почты, чтобы сбросить пароль.',
  signinResetMailSentSuccessContent: 'Мы отправили вам ссылку для сброса пароля на вашу электронную почту. ',
  signupTitle: 'регистр',
  signupUsername: 'Имя пользователя',
  signupEmail: 'Электронная почта',
  signupTermsConditions: 'Условия',
  signupWalletConnect: 'Войти с помощью кошелька ',
  signupPassword: 'Пароль',
  signupEighteenPlus: 'Мне исполнилось 18 лет, и я согласен с Условиями обслуживания.',
  signupRegisterBtn: 'Зарегистрироваться',
  signupCreateNewAccount: 'Создать новый аккаунт',
  signupFullName: 'Полное имя',
  signupReferralCode: 'Промо-код',
  signupConfirmPassword: 'Подтвердите пароль',
  signupUseSocialAccount: 'Вход через социальные сети',
  signupFacebook: ' Фейсбук',
  signupGoogle: ' Google',
  signupHaveAccount: 'Иметь аккаунт? ',
  signupLoginBtn: 'Авторизоваться',
  signupErrorsUserNameRequired: '*Пожалуйста, введите имя пользователя.',
  signupErrorsUserNameMinLength: 'Разрешено минимум 4 символа.',
  signupErrorsUserNameMaxLength: 'Разрешено максимум 20 символов.',
  signupErrorsUserNamePattern: 'Пожалуйста, введите действительное имя пользователя, разрешены только буквы и цифры.',
  signupErrorsFullNameRequired: '*Пожалуйста, введите полное имя.',
  signupErrorsFullNamePattern: 'Пожалуйста, введите действительное полное имя, разрешены только буквы и цифры.',
  signupErrorsEmailRequired: '*Пожалуйста, введите адрес электронной почты.',
  signupErrorsEmailMinLength: 'Разрешено минимум 4 символа.',
  signupErrorsEmailMaxLength: 'Разрешено максимум 20 символов.',
  signupErrorsEmailPattern: 'Пожалуйста, введите действительный адрес электронной почты.',
  signupErrorsPasswordRequired: '*Необходим пароль.',
  signupErrorsPasswordMinLength: 'Разрешено минимум 8 символов.',
  signupErrorsPasswordMaxLength: 'Максимальный депозит: 16 символов.',
  signupErrorsPasswordPattern: '*Пароль должен состоять из 8–16 цифр, буквенно-цифровых (прописных и строчных букв) и специальных символов. ',
  signupErrorsConfirmPasswordMatch: 'Пароли должны совпадать.',
  signupPlaceholderUserName: 'Мин. ',
  signupPlaceholderEmail: 'например ',
  signupPlaceholderPassword: 'Мин. ',
  signupSomethingWentWrong: 'Что-то пошло не так!',
  signupSignupGoogle: 'Успешная регистрация в Google.',
  signupRegistered: 'Успешно зарегистрирован.',
  signupConfirmAge: 'Пожалуйста, подтвердите, что вам 18 лет.',
  signupSignupFacebook: 'Успешная регистрация через Facebook.',
  signupCookiesConsent: 'Разрешите сторонним файлам cookie в настройках вашего браузера использовать вход через социальную сеть.',
  signupRegisterEmailPopupContent: 'Мы отправили вам письмо с подтверждением. ',
  accountMenuDeposit: 'Депозит',
  accountMenuCashout: 'Обналичить',
  accountMenujackpotWinners: 'Победители джекпота',
  accountMenuTransactions: 'Транзакции',
  accountMenuPromotions: 'Акции',
  accountMenuProfile: 'Профиль',
  accountMenuLogout: 'Выйти',
  accountMenuSound: 'Звук',
  accountMenuMusic: 'Музыка',
  accountMenuWithdraw: 'Отзывать',
  pageNotFound: 'Мы не можем найти страницу, которую вы ищете!',
  error: 'Ошибка',
  somethingWentWrong: 'Что-то пошло не так!',
  verifyEmailThankYou: 'Спасибо!',
  verifyEmailEmailIsVerified: 'Ваш адрес электронной почты подтвержден. ',
  verifyEmailGoToLogin: 'Перейти на страницу входа',
  verifyEmailWentWrong: 'Что-то пошло не так!',
  verifyEmailTokenExpired: 'Электронная почта уже подтверждена или срок действия ссылки истек. Отправьте ссылку для подтверждения электронной почты повторно, указав ниже свой зарегистрированный адрес электронной почты. ',
  verifyEmailGoToHome: 'Вернуться на домашнюю страницу',
  verifyEmailSuccessMsg: 'Электронная почта успешно подтверждена.',
  bonusSectionSpanText: 'представляет самые щедрые и разнообразные',
  bonusSectionBonuses: 'Бонусы',
  bonusSectionSubtitleText: 'Играйте с удовольствием и получайте дополнительные награды во время игры. ',
  JoiningBonusTitle: 'Бонус за присоединение',
  JoiningBonusButton: 'Подать заявку сейчас',
  JoiningBonusDesc: 'Такие бонусы побуждают игроков онлайн-казино играть с использованием биткойнов или других криптовалют. ',
  depositBonusTitle: 'Бонус на Депозит',
  depositBonusButton: 'Депозит',
  depositBonusDesc: 'Такие бонусы побуждают игроков онлайн-казино играть с использованием биткойнов или других криптовалют. ',
  refferBonusTitle: 'Реферальный бонус',
  refferBonusButton: 'Депозит',
  refferBonusDesc: 'Такие бонусы побуждают игроков онлайн-казино играть с использованием биткойнов или других криптовалют. ',
  aboutSectionSpanText2: 'Онлайн-казино позволяет вам играть в любимые игры бесплатно или за криптовалюту. ',
  aboutSectionSpanText3: ' В этом виртуальном мире биткойн-гемблинга вы можете насладиться множеством возможностей — играть в любимые игры казино, получать бонусы, участвовать в конкурсах и акциях.',
  verifiedSectionTitle: 'Проверенное и лицензионное программное обеспечение',
  verifiedSectionSpanText1: 'Мы не копируем и не внедряем программное обеспечение с других веб-сайтов. ',
  faqSectionTitle: 'Часто задаваемые вопросы от игроков',
  faqSectionForgetPasswordTitle: 'Я забыл мой пароль.',
  faqSectionForgetPasswordDesc: 'Вы не предоставили резервный адрес электронной почты для своей учетной записи. ',
  faqSectionMyDepositCreditedTitle: 'Мой депозит не был зачислен.',
  faqSectionMyDepositCreditedDesc: ' Содержимое-заполнитель для этого аккордеона, предназначенное для демонстрации класса. ',
  SupportedCurrencyTitle: 'Поддерживаемая валюта',
  SupportedCurrencySpanText1: 'Мы не копируем и не внедряем программное обеспечение с других веб-сайтов. ',
  CarouselSectionTitle: 'ХОДЛ АВАРИЯ',
  CarouselSectionSubTitle: ' Играйте еще больше игр и получите шанс на победу. ',
  WalletSettingTitle: 'Настройка кошелька',
  WalletSettingHideZero: 'Скрыть нулевой баланс',
  WalletSettingHideZeroInfo: 'Ваш нулевой баланс не появится в вашем кошельке',
  WalletSettingDisplayFiat: 'Отображать криптовалюту в фиате',
  WalletSettingDisplayFiatInfo: 'Все ставки',
  WalletSettingNoteForApprox: 'Обратите внимание, что это приблизительные значения валют.',
  WalletSettingSave: 'Сохранять',
  WalletSettingNoWalletFound: 'Кошелек не найден',
  jackpotWinnersNoWinnerAvailable: 'Победителей нет',
  jackpotWinnersNew: 'Новый',
  jackpotWinnersHeader: 'Победитель джекпота',
  jackpotDetailsHeader: 'Детали джекпота',
  jackpotDetailsCampaignPeriod: 'Период кампании',
  jackpotDetailsFrom: 'От',
  jackpotDetailsTo: 'К',
  jackpotDetailsBettingRule: 'Правило ставок',
  jackpotDetailsMinBetAmount: 'Минимальная сумма ставки',
  jackpotDetailsMaxBetAmount: 'Максимальная сумма ставки',
  jackpotDetailsBetPercentage: 'Процент ставки',
  jackpotDetailsWinningRuleHeader: 'Правило победы',
  jackpotDetailsWinningRule: 'Размещение ставок на более высокую сумму увеличит шансы на выигрыш.',
  jackpotDetailsProfitRule: 'Если сумма ставки находится между minBetAmount и maxBetAmount, то к сумме джекпота будет добавлен betPercentage% от суммы ставки.',
  profileTitle: 'Личная информация',
  profileFName: 'Имя',
  profileLname: 'Фамилия',
  profileUserName: 'Имя пользователя',
  profileDob: 'Дата рождения',
  profileEmail: 'Адрес электронной почты',
  profilePhone: 'Номер телефона',
  profileChange: 'Изменять',
  profileSave: 'Сохранить изменения',
  profilePassword: 'Пароль',
  profileSubmit: 'Представлять на рассмотрение',
  profileUploadProfile: 'Загрузить профиль',
  profileCurrentPassword: 'Текущий пароль',
  profileNewPassword: 'Новый пароль',
  profileConfirmPassword: 'Подтвердите пароль',
  profileScanQRCode: 'Сканировать QR-код',
  profileEnterSecretCode: 'Введите секретный код',
  profileErrorsFirstNameRequired: 'Требуется имя.',
  profileErrorsFirstNamePattern: 'Пожалуйста, введите действительное имя.',
  profileErrorsFirstNameMinLength: 'Разрешено минимум 3 символа.',
  profileErrorsFirstNameMaxLength: 'Разрешено максимум 20 символов.',
  profileErrorsLastNameRequired: 'Требуется фамилия.',
  profileErrorsLastNamePattern: 'Пожалуйста, введите действительное имя.',
  profileErrorsLastNameMinLength: 'Разрешено минимум 3 символа.',
  profileErrorsLastNameMaxLength: 'Разрешено максимум 20 символов.',
  profileErrorsChangePasswordOldRequired: 'Требуется старый пароль.',
  profileErrorsChangePasswordOldPattern: 'Пожалуйста, введите действительный пароль.',
  profileErrorsChangePasswordOldMinLength: 'Разрешено минимум 8 символов.',
  profileErrorsChangePasswordOldMaxLength: 'Разрешено максимум 16 символов.',
  profileErrorsChangePasswordNewRequired: 'Требуется новый пароль.',
  profileErrorsChangePasswordNewPattern: 'Пожалуйста, введите действительный пароль.',
  profileErrorsChangePasswordNewMinLength: 'Разрешено минимум 8 символов.',
  profileErrorsChangePasswordNewMaxLength: 'Разрешено максимум 16 символов.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Пароли не совпадают.',
  profileErrorsChangePasswordNewPasswordMatched: 'Новый пароль не может совпадать с текущим паролем.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Требуется подтверждение пароля.',
  profileErrorsUserNameRequired: 'Имя пользователя требуется.',
  profileErrorsUserNamePattern: 'Пожалуйста, введите действительное имя.',
  profileErrorsUserNameMinLength: 'Разрешено минимум 3 символа.',
  profileErrorsUserNameMaxLength: 'Разрешено максимум 20 символов.',
  profileErrorsDobRequired: 'Требуется дата рождения.',
  profileErrorsEmailRequired: 'Требуется электронная почта.',
  profileErrorsPostcodeRequired: 'Требуется почтовый индекс.',
  profileErrorsCityRequired: 'Город обязателен.',
  profileErrorsCurrencyRequired: 'Требуется валюта.',
  profileErrorsAddressRequired: 'Требуется адрес.',
  profileErrorsCountryRequired: 'Страна обязательна.',
  profileErrorsEmailPattern: 'Пожалуйста, введите действительный адрес электронной почты.',
  profileErrorsEmailMinLength: 'Разрешено минимум 3 символа.',
  profileErrorsEmailMaxLength: 'Разрешено максимум 20 символов.',
  profileMessagesProfileUpdate: 'Профиль успешно обновлен.',
  profileMessagesChangePassword: 'Пароль успешно обновлен.',
  profileProfilePicProfilePicUpload: 'Загрузить изображение профиля',
  profileNoNewChanges: 'Не удалось обновить профиль! ',
  profileLoggedInWithSocial: 'Вы вошли в систему с помощью социальной сети.',
  profileSideNavigatorSound: 'Звук',
  profileSideNavigatorMusic: 'Музыка',
  profileSideNavigatorProfile: 'Мой профайл',
  profileSideNavigatorBetHistory: 'История ставок',
  profileSideNavigatorTransactions: 'Транзакции',
  profileSideNavigatorDeposit: 'Депозит',
  profileSideNavigatorWithdraw: 'Отзывать',
  profileSideNavigatorGameLimits: 'Ограничения игры',
  profileSideNavigatorReferral: 'Направления',
  profileSideNavigatorChangePassword: 'Изменить пароль',
  profileSideNavigatorTermsConditions: 'Условия',
  profileSideNavigatorLogOut: 'Выйти',
  profileSideNavigatorProvablyFair: 'Доказуемо справедливо',
  WalletInfoSectionNotAvailable: 'Нет в наличии',
  WalletInfoSectionRealBalance: 'Реальный баланс',
  GameLimitsMinimumBet: 'Минимальная ставка',
  GameLimitsMaximumBet: 'Максимальная ставка',
  GameLimitsMaxWinFor1Bet: 'Максимальный выигрыш для одной ставки',
  ProvablyFairSubTitle: 'В этой игре для определения результата игры используется технология Provability Fair. ',
  ProvablyFairMaximumBet: 'Максимальная ставка:',
  ProvablyFairMaxWinFor1Bet: 'Максимальный выигрыш для одной ставки',
  promotionsTitle: 'Акции',
  promotionsNoLosingData: 'К сожалению, бонусные акции «Без проигрыша» доступны для ',
  promotionsNoDepositData: 'К сожалению, бездепозитные бонусы доступны для ',
  promotionsCurrency: ' валюта.',
  promotionsViewBtn: 'Вид',
  promotionsClaimSuccess: 'Вы успешно заявили права',
  promotionsAvailability: 'Доступность: ',
  promotionsAvailabilityTabLosing: 'Проигрышный бонус',
  promotionsAvailabilityTabDeposit: 'Бонус на депозит',
  promotionsDepositBonusTableCode: 'Код',
  promotionsDepositBonusTableMinDeposit: 'Мин. ',
  promotionsDepositBonusTablePercentage: 'Процент',
  promotionsDepositBonusTableMaxBonus: 'Максимальный бонус',
  promotionsDepositBonusTableRolloverMultipler: 'Множитель ролловера',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Максимальный ролловер на ставку',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Время достижения цели опрокидывания',
  promotionsBonusCode: 'БОНУС КОД: ',
  promotionsLossesClaim: 'Убытки могут быть заявлены за (в течение периода кампании): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Процент',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Мин. ',
  promotionsBonusStatus: 'Бонусный статус: ',
  promotionsBonusAmount: 'Сумма бонуса: ',
  promotionsRolloverTarget: 'Цель ролловера: ',
  promotionsRolloverAchieved: 'Переворот достигнут: ',
  promotionsActivatebtn: 'АКТИВИРОВАТЬ',
  promotionsClaimBtn: 'ТРЕБОВАТЬ',
  promotionsCancelBtn: 'ОТМЕНА',
  promotionsBackBtn: 'НАЗАД',
  promotionsBonus: 'Бонус',
  promotionsTermAndConditions: ' Условия',
  promotionsClaimDepositBonusTitle: 'Шаги для получения бонуса на депозит',
  promotionsClaimDepositBonusFirst: 'Активировать бонусный код',
  promotionsClaimDepositBonusSecond: 'Сумма депозита на кошелек',
  promotionsClaimDepositBonusThird: 'Достичь целевого показателя для получения бонуса',
  promotionsClaimLosingBonusTitle: 'Шаги для получения бонуса за проигрыш',
  promotionsClaimLosingBonusFirst: 'Активировать бонусный код',
  promotionsClaimLosingBonusSecond: 'Играйте в свои любимые игры',
  promotionsClaimLosingBonusThird: 'Нажмите «Заявить убытки», чтобы заявить об убытках в течение периода кампании.',
  promotionsWentWrong: 'Что-то пошло не так!',
  transactionTitle: 'Транзакции',
  transactionBetId: 'Идентификатор ставки',
  transactionUser: 'Пользователь',
  transactionPayout: 'Выплата',
  transactionAmount: 'Количество',
  transactionProfit: 'Выгода',
  transactionCashout: 'Обналичить',
  transactionBet: 'Делать ставку',
  transactionMultix: 'Мульти.х',
  transactionWin: 'Победить',
  transactionFairness: 'Справедливость',
  transactionReferFriend: 'Приведи друга',
  transactionTotal: 'Всего ставок',
  transactionWins: 'Победы',
  transactionRefresh: 'Обновить',
  transactionFilterTopMultipliers: 'Лучшие множители',
  transactionFilterHugeWins: 'Огромные победы',
  transactionFilterBiggestWins: 'Крупнейшие победы',
  transactionFilterMultipliers: 'Множители',
  transactionRealBalance: 'Реальный баланс',
  depositWithdrawTXDate: 'Дата',
  depositWithdrawTXAmount: 'Количество',
  depositWithdrawTXTxId: 'ТxID',
  depositWithdrawTXTxType: 'Тип операции',
  depositWithdrawTXStatus: 'Положение дел',
  depositWithdrawTXNoData: 'Данные недоступны.',
  depositTitle: 'Депозит',
  depositHistory: 'История',
  depositSubTitle: 'Пользователь не может вносить или снимать фиатную валюту. ',
  depositCopyNotSupported: 'Ваш браузер не поддерживает автоматическое копирование в буфер обмена. Попробуйте скопировать код вручную.',
  depositCopied: 'Адрес скопирован.',
  depositAddressCreated: 'Адрес успешно создан.',
  depositGenerateAddress: 'Создать адрес',
  depositWarningText: 'Пожалуйста, НЕ вносите депозит через кросс-чейн перевод.',
  withdrawTitle: 'Отзывать',
  withdrawSubTitle: 'Пользователь не может вносить или снимать фиатную валюту. ',
  withdrawNoteDesc: 'Пожалуйста, убедитесь, что вы НЕ вводите адрес BEP2, BEP20 (BSC) в качестве адреса для вывода LTC-кошельков.',
  withdrawNoteSublist: ' Пожалуйста, НЕ выводите средства через кросс-чейн перевод.',
  withdrawHistory: 'История',
  withdrawWithdrawBtn: 'Запрос на вывод средств',
  withdrawFees: 'Комиссия за снятие средств {{fees}} {{token}}',
  withdrawWithdrawPending: 'Ваш запрос на вывод средств находится на рассмотрении. ',
  withdrawEnterFields: 'Введите сумму вывода и адрес вашего кошелька.',
  withdrawSelectWallet: 'Пожалуйста, выберите кошелек для вывода.',
  withdrawAmountFieldTitle: 'Сумма (Мин. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Твой {{wallet.currency.code}} адрес',
  withdrawAddressFieldPlaceholder: 'Введите свой адрес',
  withdrawNoBalance: 'Недостаточно баланса.',
  withdrawMinAmount: 'Пожалуйста, введите сумму больше или равную {{min}} .',
  fiatCurrencyInfo: 'Пользователь не может вносить или снимать фиатную валюту. ',
  paginationNextBtn: 'Следующий',
  paginationPrevBtn: 'Предыдущий',
  wentWrong: 'Что-то пошло не так.',
  referralTitle: 'Направления',
  referralSubTitle: 'Пригласите своих друзей',
  referralEarn: ' Получите по 150 ₹ каждый',
  referralReferAndEarn: 'Приглашайте и получайте бонусы за каждого реферала',
  referralReferPara: ' Если вы отнесете этот код любому пользователю',
  referralReferLink: 'См. ссылку',
  referralCopyReferralCode: 'Скопировать реферальный код',
  referralTotalRewards: 'Общая награда',
  TwoWayAuthentication: 'Двусторонняя аутентификация',
  LoginRequired: 'Требуется вход!',
  BetPlacedSuccessfully: 'Ставка сделана успешно',
  BetAddedInQueueSuccessfully: 'Ставка успешно добавлена ​​в очередь',
  BetRemovedFromQueueSuccessfully: 'Ставка успешно удалена из очереди',
  BetCancelledSuccessfully: 'Ставка успешно отменена',
  PlayerEscapedSuccessfully: 'Игрок успешно сбежал',
  CopiedSuccessfull: 'Скопировано успешно',
  NotEnoughBalance: 'Недостаточно баланса',
  AutoBetStarted: 'Автоставка началась ',
  AutoBetFinished: 'Автоставка завершена ',
  IdCopied: 'Идентификатор скопирован',
  usersConsent: 'Заходя на этот сайт, я подтверждаю, что мне 18 лет, и я ознакомился, понял и согласен с',
  termsOfService: 'Условия использования',
  email: 'Электронная почта',
  playNow: 'Играть сейчас',
  orContinueWith: 'Или продолжить с',
  metamaskExtensionNotAvailable: 'Пожалуйста, установите расширение MetaMask и обновите страницу.',
  cashier: 'КАССА',
  account: 'СЧЕТ',
  hello: 'Привет',
  balance: 'Баланс',
  referrals: 'Рефералы',
  settings: 'Настройки',
  withdrawals: 'Вывод средств',
  demoWallet: 'Демо-кошелек',
  myAccount: 'Мой счет',
  wallet: 'Кошелек',
  user: 'Пользователь',
  USD: 'доллар США',
  amountIsRequired: 'Требуется сумма',
  minDepositAmount: 'Сумма вклада должна быть больше {{min}}.',
  maxDepositAmount: 'Сумма вклада должна быть меньше {{max}}.',
  minWithdrawAmount: 'Сумма вывода должна быть больше {{min}}',
  maxWithdrawAmount: 'Сумма снятия должна быть меньше, чем {{max}}',
  withdrawMustLessThanWallet: 'Сумма вывода должна быть меньше суммы вашего кошелька.',
  accountTypeIsRequired: 'Требуется тип учетной записи.',
  mobileNumberLength: 'Номер мобильного телефона должен быть {{length}} цифры',
  phoneIsRequired: 'Требуется телефон',
  cpfError: 'CPF должен иметь {{length}} персонажи',
  cpfRequiredError: 'Требуется CPF.',
  cnpjError: 'CNPJ должен иметь {{length}} персонажи',
  mainMenu: 'ГЛАВНОЕ МЕНЮ',
  casino: 'Казино',
  sports: 'Виды спорта',
  crypto: 'Крипто',
  NFT: 'НФТ',
  poker: 'Покер',
  earnRewards: 'ЗАРАБАТЫВАТЬ НАГРАДЫ',
  rankUp: 'На ранг выше',
  inviteFriends: 'Пригласить друзей',
  partnerProgram: 'Партнерская программа',
  home: 'Дом',
  sportsbook: 'Букмекерская контора',
  menu: 'Меню',
  liveBets: 'LIVE-СТАВКИ',
  allBets: 'Все ставки',
  highRollers: 'Хайроллеры',
  luckyBets: 'Счастливые ставки',
  trade: 'Торговля',
  game: 'ИГРА',
  player: 'ИГРОК',
  time: 'ВРЕМЯ',
  wager: 'СТАВКА',
  multiplierBan: 'МНОЖИТЕЛЬ',
  payoutText: 'ВЫПЛАТА',
  bannerHeadOne: 'Испытайте настоящие инновации с высочайшим',
  bannerHeadTwo: 'Программа вознаграждений внутри отрасли.',
  registerNow: 'Зарегистрироваться',
  liveWins: 'ЖИВЫЕ ПОБЕДЫ',
  monthly: 'Ежемесячно',
  weekly: 'Еженедельно',
  daily: 'Ежедневно',
  winner1: 'Победитель 1',
  winner2: 'Победитель 2',
  winner3: 'Победитель 3',
  spin: 'Вращение',
  spinsWon: 'Выигранные вращения',
  joinedOn: 'Присоединился',
  wageringAmount: 'Сумма ставки',
  name: 'Название',
  allGames: 'Все игры',
  allSports: 'Все виды спорта',
  cryptoFutures: 'криптовалютные фьючерсы',
  tradeNow: 'Торгуйте сейчас',
  allTables: 'Все столы',
  nft: 'НФТ',
  viewAllGames: 'Посмотреть все игры',
  viewAll: 'Увидеть все',
  rewards: 'НАГРАДЫ',
  profile: 'Профиль',
  totalBets: 'Всего ставок',
  totalWagered: 'Всего поставлено',
  joinDate: 'Дате вступления',
  displaySocialLinks: 'Отображение связанных учетных записей социальных сетей',
  privateProfile: 'Частный профиль',
  rank: 'Классифицировать',
  winner: 'Победители',
  nextRank: 'Следующий ранг',
  changeUsername: 'Изменение имени пользователя',
  newPassword: 'Новый пароль',
  changeName: 'Изменить имя',
  linkSocialAccount: 'Привязать аккаунт в соц.',
  privateProfileTooltip: 'После того как вы сделаете свой профиль закрытым, другие пользователи не увидят ваше имя пользователя рядом со ставками, скачками и т. д.',
  socialLinksTooltip: 'Контент недоступен',
  rankTooltip: 'Контент недоступен',
  balances: 'балансы',
  createReferralBalance: 'Создать реферальный баланс',
  total: 'Общий',
  switchBalance: 'Переключить баланс',
  mainAccount: 'Основной аккаунт',
  tips: 'Советы',
  receivedAmount: 'Полученная сумма',
  dateOrTime: 'Дата/Время',
  view: 'Вид',
  allChains: 'Все сети',
  creditAmount: 'Сумма кредита',
  setCustomReferralCode: 'Установить собственный реферальный код',
  changeEmail: 'Изменить e-mail',
  newEmail: 'Новый E-mail',
  createPassword: 'Придумайте пароль',
  newUsername: 'Новое имя пользователя',
  username: 'Имя пользователя',
  changePassword: 'Изменить пароль',
  updateProfileMsg: 'Профиль успешно обновлен',
  twoFactorAuthentication: 'двухфакторная аутентификация',
  twoFactorAuthenticationTitle: 'Настоятельно рекомендуется использовать двухфакторную аутентификацию, поскольку она защищает вашу учетную запись как паролем, так и телефоном.',
  twoFactorAuthenticationSubTitle: 'Для активации 2FA необходимо указать адрес электронной почты и пароль. ',
  enable2fa: 'ВКЛЮЧИТЬ 2ФА',
  verifyIdentityByKyc: 'Подтвердите свою личность (kyc)',
  oldPassword: 'Старый пароль',
  live: 'Жить',
  soccer: 'Футбольный',
  tennis: 'Большой теннис',
  fifa: 'ФИФА',
  basketball: 'Баскетбол',
  iceHockey: 'Хоккей на льду',
  volleyball: 'Волейбол',
  tableTennis: 'Настольный теннис',
  baseball: 'Бейсбол',
  rubgy: 'Регби',
  golf: 'Гольф',
  boxing: 'Заниматься боксом',
  myBets: 'Мои ставки',
  favourites: 'Избранное',
  americanFootball: 'американский футбол',
  buyCrypto: 'Купить криптовалюту',
  oldEmail: 'Старая электронная почта',
  loggedInSuccess: 'Вы успешно вошли в систему',
  loggedOutSuccess: 'Вышел из системы успешно',
  wagerLimit: 'Лимит ставок успешно установлен',
  lossLimit: 'Лимит убытков успешно установлен',
  accountDisable: 'Аккаунт успешно отключен',
  sessionTimeout: 'Тайм-аут сеанса успешно установлен',
  signup: 'Регистрация прошла успешно',
  depositLimit: 'Лимит депозита успешно установлен',
  tokenVerify: 'Токен был отправлен на ваш адрес электронной почты, пожалуйста, подтвердите',
  emailVerify: 'Электронная почта была подтверждена',
  emailChangedSuccess: 'Электронная почта успешно изменена',
  profileUpdatedSuccess: 'Профиль обновлен',
  otpVerified: 'OTP проверен',
  twoFADeactivated: '2FA-аутентификация отключена',
  internalServerError: 'Внутренняя ошибка сервера',
  unAuthorized: 'Несанкционированный',
  dataNotFound: 'Данные не найдены',
  setReferralCode: 'Установить реферальный код...',
  ultimate: 'ОКОНЧАТЕЛЬНЫЙ',
  notFound: 'Не найдено',
  casinoTransactions: 'Транзакции в казино',
  sportsbookTransactions: 'Транзакции в букмекерских конторах',
  status: 'Положение дел',
  success: 'Успех',
  failed: 'Неуспешный',
  pending: 'В ожидании',
  date: 'Дата',
  to: 'К',
  gameName: 'Название игры',
  transactionId: 'ID транзакции',
  roundId: 'Идентификатор раунда',
  bet: 'Делать ставку',
  win: 'Победить',
  transactionType: 'Тип операции',
  at: 'в',
  downloadAll: 'Скачать все',
  gameText: 'Игра',
  betId: 'Идентификатор ставки',
  gameId: 'Идентификатор игры',
  actionType: 'Тип действия',
  searchGames: 'Поиск игр',
  provider: 'Провайдеры',
  search: 'Поиск',
  sortBy: 'Сортировать по',
  popular: 'Популярный',
  challengePool: 'Пул вызовов',
  loadMore: 'Загрузи больше',
  bitcoin: 'Биткойн',
  chat: 'Чат',
  trades: 'Сделки',
  battles: 'Битвы',
  loginToChat: 'Войдите в чат',
  rules: 'Правила',
  chatRule1: 'Не оскорбляйте и не оскорбляйте других пользователей',
  chatRule2: 'Не попрошайничайте, не просите кредитов, мелочей или советов.',
  chatRule5: 'Не рекламируйте какие-либо формы торговли, покупки или продажи услуг.',
  chatRule6: 'Не делитесь и не рекламируйте свой реферальный код.',
  chatRule7: 'Не просите стать сотрудником',
  chatRule8: 'только английский',
  chatRule9: 'Уважайте модеров, администраторов и других пользователей.',
  send: 'Отправлять',
  expand: 'Расширять',
  signIn: 'Войти',
  cross: 'Крест',
  collapse: 'Крах',
  emailPlaceholder: 'ваша почта@domain.com',
  removeFromFavourite: 'Удалить из избранного',
  addToFavourite: 'Добавить в избранное',
  footerAboutSite: `Поговорим немного о науке? Сайт Cash Machine Casino — это часть вас, ведь мы все
состоим из одних и тех же атомов. Ваше взаимодействие с ним подтверждает эту
связь.`,
  footerRightsReserved: 'Сash Machine Casino все права защищены.',
  signupFirstName: 'Имя',
  signupAddress: 'Адрес',
  signupCity: 'Город',
  signupPostcode: 'Почтовый индекс ',
  signupCounty: 'Страна',
  signupCurrency: 'Валюта',
  signupGender: 'Пол',
  signupMan: 'Мужчина',
  signupWomen: 'Женщины',
  signupOther: 'Другой',
  signupLoginDetails: 'Войти в детали',
  signupPersonalDetails: 'Личная информация',
  signupConfirm: 'Подтверждать',
  signupPrivacyPolicy: 'Я прочитал и принял Политику конфиденциальности',
  signupTermAndConditions: 'Я согласен на обработку моих персональных данных. ',
  signupNewsLetter: 'Я хотел бы периодически получать бонусы, рекламные акции, новости или другую соответствующую информацию посредством новостной рассылки.',
  signupSms: 'Я хотел бы периодически получать SMS-сообщения о бонусах, акциях, новостях или другой актуальной информации.',
  initialHeaderContent: 'Получите потрясающее 100%',
  secoundaryHeaderContent: 'Приветственный бонус до $100',
  playNowButton: 'Играть сейчас',
  registerButtonContent: 'регистр',
  results: 'Полученные результаты',
  refresh: 'Обновить',
  cancel: 'Отмена',
  select: 'Выбирать',
  current: 'Текущий',
  set: 'Набор',
  remove: 'Удалять',
  save: 'Сохранять',
  not: 'нет',
  before: 'До',
  after: 'После',
  action: 'Действие',
  withdrawal: 'Снятие',
  requested: 'Запрошено',
  withdraw: 'Отзывать',
  areYouSure: 'Вы уверены',
  yes: 'Да',
  no: 'Нет',
  cancelled: 'Отменено',
  approved: 'Одобренный',
  refunded: 'Возвращено',
  chargeback: 'Возвратный платеж',
  processedOn: 'Обработано',
  transaction: 'Сделка',
  dateTime: 'Дата/Время',
  more: 'Более',
  fromDate: 'С даты',
  toDate: 'На сегодняшний день',
  percentage: 'Процент',
  info: 'Информация',
  min: 'Мин',
  max: 'Макс',
  change: 'Изменять',
  continueToSite: 'Продолжить переход на сайт',
  choose: 'Выбирать',
  enterWithdrawalAmount: 'Выберите или введите сумму вывода',
  capDeposit: 'ДЕПОЗИТ',
  capWithdrawal: 'СНЯТИЕ',
  capAmount: 'КОЛИЧЕСТВО',
  minimum: 'Минимум',
  required: 'Необходимый',
  of: 'из',
  isRequired: 'требуется',
  isAllowed: 'позволено',
  method: 'Метод',
  capWithdraw: 'ОТЗЫВАТЬ',
  showLess: 'Показывай меньше',
  clearFilter: 'Очистить фильтр',
  quantity: 'Количество',
  reset: 'Перезагрузить',
  incorrect: 'Неправильный',
  load: 'Нагрузка',
  applied: 'Применяемый',
  ok: 'ХОРОШО',
  list: 'Список',
  all: 'Все',
  favorite: 'Любимый',
  endPointNotFound: 'Что-то не так с сетевым подключением',
  category: 'Категория',
  emailUserNameRequired: 'Требуется адрес электронной почты/имя пользователя',
  emailUserNamePlaceHolder: 'Введите свой адрес электронной почты или имя пользователя',
  passwordRequired: 'Требуется пароль',
  accountFrozen: 'Ваш аккаунт заморожен до',
  resendEmail: 'Отправить письмо повторно',
  resendLink: 'ОТПРАВИТЬ ССЫЛКУ ПОВТОРНО',
  userLoggedOut: 'Вышел из системы успешно',
  emailAlreadyRegistered: 'Этот адрес уже зарегистрирован',
  userNameAlreadyTaken: 'Это имя пользователя уже занято',
  fillAllFields: 'Заполните все поля, прежде чем перейти к следующему шагу',
  pleaseAccept: 'Пожалуйста примите',
  acceptPrivacyPolicy: 'политика конфиденциальности',
  acceptTerms: 'Сроки и условия',
  modeOfComm: ' и способ связи',
  beforeNextStep: 'прежде чем перейти к следующему шагу',
  userNamePlaceholder: 'Введите имя пользователя',
  min8Characters: 'Мин. 8 символов',
  min1Number: 'Мин. 1 номер',
  min1LowerCase: 'Мин. 1 символ нижнего регистра',
  min1UpperCase: 'Мин. 1 символ верхнего регистра',
  min1SpecialChar: 'Мин. 1 специальный символ',
  passwordTip: 'Пароль должен содержать не менее восьми символов, включая хотя бы одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.',
  passwordPlaceholder: 'Введите пароль',
  confirmPasswordPlaceholder: 'Введите подтверждение пароля',
  acceptAll: 'Принять все',
  firstNamePlaceholder: 'Введите имя',
  lastNamePlaceholder: 'Введите фамилию',
  addressPlaceholder: 'Введите адрес',
  cityPlaceholder: 'Введите город',
  postCodePlaceholder: 'Введите почтовый индекс',
  phonePlaceholder: 'Введите номер телефона',
  invalidEmail: 'Неверный адрес электронной почты',
  emailRequired: 'Требуется электронная почта',
  confirmPasswordRequired: 'Требуется подтверждение пароля',
  firstNameRequired: 'Требуется имя',
  lastNameRequired: 'Требуется фамилия',
  phoneRequired: 'Требуется телефон',
  dateOfBirthRequired: 'Требуется дата рождения',
  genderRequired: 'Пол Требуется',
  userNameRequired: 'Требуется имя пользователя',
  addressRequired: 'Требуется адрес',
  cityRequired: 'Город Требуется',
  postCodeRequired: 'Требуется почтовый индекс',
  currencyCodeRequired: 'Требуется код валюты',
  countryRequired: 'Страна Требуется',
  matchPassword: 'Пароли должны совпадать',
  fName3Chars: 'Имя должно состоять как минимум из 3 символов.',
  onlyAlphabet: 'Разрешены только алфавиты',
  lName3Chars: 'Фамилия должна содержать не менее 3 символов.',
  mustBe18: 'Вам должно быть 18 лет, чтобы зарегистрироваться',
  mustBeUnder200: 'Для регистрации вам должно быть меньше 200 лет.',
  validDOB: 'Пожалуйста, введите верную дату рождения',
  max20Characters: 'Разрешено максимум 20 символов',
  min3Characters: 'Требуется минимум 3 символа',
  max100Characters: 'Разрешено максимум 100 символов',
  max50Characters: 'Разрешено максимум 50 символов',
  invalidNumber: 'Неправильный номер',
  preferredLanguageRequired: 'Требуемый предпочитаемый язык',
  invalidPass: 'Неверный пароль',
  prevBtn: 'Предыдущий',
  dayRequired: 'Укажите день',
  monthRequired: 'Укажите месяц',
  yearRequired: 'Укажите год',
  uploadImage: 'Загрузить изображение',
  removeImage: 'Удалить изображение',
  preferredLanguage: 'предпочтительный язык',
  wantToChangePass: 'Вы хотите изменить пароль?',
  selectCountryCode: 'Выберите код страны',
  selectPreferredLanguage: 'Выберите предпочитаемый язык',
  fName50Chars: 'Имя должно содержать не более 50 символов.',
  lName50Chars: 'Фамилия должна содержать не более 50 символов.',
  invalidDate: 'Недействительная дата',
  DOBEarlyThanToday: 'Дата рождения должна быть раньше, чем сегодня',
  enterValidPhone: 'Пожалуйста введите правильный номер телефона',
  accountStatus: 'Статус аккаунта',
  kycVerified: 'Ваш KYC успешно проверен.',
  denied: 'Отклонен',
  chooseFile: 'Выберите файл',
  upload: 'Загрузить',
  countryCantChange: 'Вы собираетесь начать проверку KYC. ',
  proceed: 'Продолжить',
  update: ' ',
  history: 'История',
  type: 'Тип',
  amount: 'Количество',
  forfeited: 'Конфискован',
  expired: 'Истекший',
  completed: 'Завершенный',
  zeroedOut: 'Обнулено',
  active: 'Активный',
  lapsed: 'истекший',
  yourActiveBonus: 'Ваши активные бонусы',
  currentActiveBonus: 'Текущие активные бонусы',
  bonusStatus: 'Положение дел',
  bonusWager: 'Ставка',
  bonusActivate: 'Активировать',
  bonusForfeit: 'Бонусная потеря',
  notUsableBonus: 'Если да, вы больше не сможете использовать этот бонус.',
  bonusLapsed: 'Вам не хватило проигрыша для получения кэшбэка. ',
  inProcess: 'В процессе',
  claiming: 'Утверждение',
  loyaltyPopover: 'Эти баллы лояльности можно использовать для получения кэшбэка. ',
  loyaltyPage: 'Страница лояльности',
  loyaltyPerCurrency: 'Лояльность по валюте',
  maxLevelReached: 'Достигнут максимальный уровень',
  pointsToReach: 'точки достижения ',
  loyaltyBannerHeading: 'Вы заслуживаете нашей совершенно новой лояльности',
  loyaltyBannerDesc: '100% приветственный бонус до $200',
  loyaltyHeadingOne: 'Вы всегда вознаграждены в CashMachine',
  loyaltyHeadingOneDesc: 'В CashMachine мы поднимаем лояльность на совершенно новый уровень азарта, осыпая вас наградами на каждом шагу! ',
  loyaltySubHeadOne: 'Заработок баллов лояльности',
  loyaltySubHeadOneDesc: 'За каждые 10 евро ставок, сделанных в нашем казино, вы получаете 1 балл лояльности. ',
  loyaltySubHeadTwo: 'Восхождение по лестнице лояльности',
  loyaltySubHeadTwoDesc: 'Чем больше очков вы наберете, тем выше вы подниметесь по уровням лояльности, открывая по пути еще более фантастические награды. ',
  loyaltySubHeadThree: 'Повышайте уровень, получайте вознаграждение ',
  loyaltySubHeadThreeDesc: 'Повысьте свой игровой опыт, продвигаясь по нашим заманчивым уровням вознаграждений, где каждый уровень открывает захватывающий набор бесплатных вращений, чтобы максимизировать ваши шансы на выигрыш. ',
  loyaltyTestimonialHeadOne: 'В КАЗИНО CashMachine МЫ УБЕЖДАЕМСЯ, ЧТО',
  loyaltyTestimonialHeadTwo: 'Каждое вращение имеет значение',
  loyaltyTestimonialDesc: 'Каждое вращение, которое вы делаете в игровых автоматах, приносит вам не только удовольствие, но и приближает вас к очень приятным наградам! ',
  loyaltyPoints: 'Точки',
  headerLevel: 'уровень',
  loyaltyCashback: 'Возврат наличных',
  loyaltyBannerBtn: 'Проверить прогресс',
  loyaltyHeadingTwo: 'Откройте для себя незабываемые впечатления от игры: VIP-программа CashMachine',
  loyaltyHeadingTwoDesc: 'Достигнув 6-го уровня в нашей программе лояльности, вы откроете мир эксклюзивных привилегий в качестве участника престижной VIP-программы CashMachine. ',
  loyaltySubHeadFour: 'Более высокие лимиты на депозит и снятие средств',
  loyaltySubHeadFourDesc: 'Наслаждайтесь гибкостью увеличенных лимитов на депозиты и снятие средств в качестве VIP-члена.',
  loyaltySubHeadFive: 'Эксклюзивные бонусы и акции',
  loyaltySubHeadFiveDesc: 'Получите доступ к специальным бонусам и акциям, специально разработанным для наших VIP-игроков.',
  loyaltySubHeadSix: 'Персональный менеджер по работе с клиентами',
  loyaltySubHeadSixDesc: 'Получите специальную поддержку и персонализированную помощь от вашего менеджера по работе с клиентами.',
  loyaltySubHeadSeven: 'Ускоренное время вывода средств',
  loyaltySubHeadSevenDesc: 'Будучи VIP, вы сможете насладиться ускоренным выводом средств, гарантируя, что вы сможете получить доступ к своим выигрышам еще быстрее.',
  loyaltySubHeadEight: 'Ежемесячные кэшбэк-награды',
  loyaltySubHeadEightDesc: 'Увеличьте свой банкролл с помощью щедрых ежемесячных вознаграждений в виде кэшбэка исключительно для наших VIP-членов.',
  loyaltySubHeadNine: 'Приглашения на эксклюзивные мероприятия и турниры',
  loyaltySubHeadNineDesc: 'Получите желанные приглашения на эксклюзивные мероприятия и турниры, где вы сможете пообщаться с другими VIP-персонами и побороться за невероятные призы.',
  loyaltySubHeadTen: 'Бонусы на день рождения',
  loyaltySubHeadTenDesc: 'Отпразднуйте свой особенный день стильно, получив восхитительный бонус на день рождения специально для вас!',
  loyaltySubHeadEleven: 'Роскошные подарки',
  loyaltySubHeadElevenDesc: 'Побалуйте себя изысканным выбором роскошных подарков, специально подобранных для награждения наших самых ценных игроков.',
  loyaltyTableHeading: 'Обзор уровней и наград',
  loyaltyTableHeaderOne: 'Уровень лояльности',
  loyaltyTableHeaderTwo: 'Баллы лояльности',
  loyaltyTableHeaderThree: 'Ежедневный кэшбэк',
  promReadMore: 'Читать далее',
  currentPortalBlock: 'если вы хотите заблокировать свою учетную запись только для этого портала.',
  allPortalBlock: 'заблокировать вашу учетную запись на всех порталах.',
  limit24Reset: 'После того, как вы установите лимиты ставок, проигрышей и депозитов, их можно будет редактировать и удалять через 24 часа, но лимиты можно будет уменьшить немедленно.',
  enterAmount: 'Введите сумму',
  limit: 'Лимит',
  loss: 'Потеря',
  takeABreak: 'Сделайте перерыв',
  session: 'Сессия',
  selfExclusion: 'Самоисключение',
  used: 'Использовал',
  edit: 'Редактировать',
  updatedAt: 'Обновлено в',
  cannotBeRemoved: 'Раньше удалить нельзя',
  timePeriod: 'Временной период',
  custom: 'Обычай',
  hours: 'Часы',
  days: 'Дни',
  timePeriodPlaceholder: 'Количество дней',
  months: 'Месяцы',
  permanent: 'Постоянный',
  onlyNumbers: 'Разрешены только цифры',
  notNumbers: 'Номера не разрешены',
  userNameAllowed: 'Должно начинаться с алфавита. ',
  timePeriodRequired: 'Требуемый период времени',
  cannotBeIncreased: 'Не может быть увеличено раньше',
  amountGTZero: 'Сумма должна быть больше 0',
  amountEqualOrLess: 'Сумма должна быть равна или меньше еженедельной и ежемесячной.',
  amountInBetween: 'Сумма должна быть между ежедневной и ежемесячной (включительно).',
  amountEqualOIrGreater: 'Сумма должна быть равна или больше дневной и ежемесячной.',
  limitRemoved: 'Ваш следующий лимит будет удален.',
  limitSet24Hrs: 'Вы собираетесь установить следующий лимит для своей учетной записи. ',
  takeABreakInfo: 'Вы собираетесь заблокировать доступ к своей учетной записи на заданный период времени. ',
  sessionLimitInfo: 'Вы собираетесь установить следующий лимит для своей учетной записи. ',
  limitCantSetBefore: 'Лимит сеансов не может быть установлен раньше',
  selfExclusionInfo: 'Вы собираетесь заблокировать доступ к своей учетной записи на заданный период времени. ',
  quickSearch: 'Быстрый поиск',
  cash: 'Наличные',
  nonCash: 'Безналичный расчет',
  userDetail: 'Сведения о пользователе',
  gameIdentifier: 'Идентификатор игры',
  rollback: 'Откат',
  rollbackBeforeBetWin: 'Откат до выигрыша ставки',
  freeSpins: 'Бесплатные вращения',
  betInternal: 'Ставка внутренняя',
  winInternal: 'Выиграть внутреннюю',
  addMoney: 'Добавить деньги',
  removeMoney: 'Удалить деньги',
  addMoneyInternal: 'Добавить деньги внутри',
  removeMoneyInternal: 'Удалить деньги внутри',
  depositInternal: 'Депозит внутренний',
  withdrawInternal: 'Вывод средств Внутренний',
  promotionTitle: 'Название акции',
  cancelWithdrawRequest: 'Вы хотите отменить этот запрос',
  rowsPerPage: 'Строков на странице',
  availedBonus: 'У вас уже есть активный бонус. ',
  capForfeit: 'ЛИШЕНИЕ',
  itFirst: 'это первое.',
  selectYourBonus: 'Выберите свой бонус',
  skip: 'ПРОПУСКАТЬ',
  with: 'с',
  without: 'без',
  selectPaymentMethod: 'Пожалуйста, выберите способ оплаты',
  volatility: 'Волатильность',
  paylines: 'Линии выплат',
  theme: 'Тема',
  tryForFree: 'Попробуй бесплатно',
  resultsFound: 'Результаты найдены',
  games: 'Игры',
  in: 'в',
  low: 'Низкий',
  medium: 'Середина',
  high: 'Высокий',
  'medium-high': 'Средней высоты',
  'very-high': 'Очень высоко',
  'low-medium': 'Низкий Средний',
  fantasy: 'Фантазия',
  ancient_civilizations: 'Древние цивилизации',
  fruits: 'Фрукты',
  africa: 'Африка',
  military: 'Военный',
  joker: 'Джокер',
  asia: 'Азия',
  luxurylife: 'Роскошная жизнь',
  underwater_world: 'Подводный мир',
  book_off: 'Бронирование',
  western: 'Западный',
  retro: 'Ретро',
  egypt: 'Египет',
  party: 'Вечеринка',
  st_patrick_day: 'День Святого Патрика',
  space: 'Космос',
  easter: 'Пасха',
  girls: 'Девушки',
  branded: 'Фирменный',
  x_mas_and_new_year: 'Рождество и Новый год',
  horrors: 'Ужасы',
  other: 'Другой',
  loginFirst: 'Пожалуйста, войдите в систему, чтобы добавить игру в избранное',
  loginFirstPlay: 'Пожалуйста, сначала войдите в систему, чтобы играть в эту игру',
  sport: 'Спорт',
  pirates: 'Пираты',
  sweets: 'Сладости',
  luxury_life: 'Роскошная жизнь',
  st_valentines_day: 'День Святого Валентина',
  halloween: 'Хэллоуин',
  food: 'Еда',
  lobby: 'лобби',
  favourite: 'Любимый',
  playNGO: 'Играй Н Гоу',
  loyaltySystem: 'Система лояльности',
  progressionSystem: 'Система прогресса',
  startPoint: 'Начальная точка',
  endPoint: 'Конечная точка',
  loyaltyBonus: 'Бонус лояльности',
  pageRemoved: 'Эта страница не существует или была удалена',
  suggestToBackHome: 'Мы предлагаем вам вернуться домой',
  backToHome: 'Вернуться домой',
  goHome: 'Иди домой',
  oopps: 'упс',
  somethingNotWorking: 'Сожалеем, что-то здесь не работает!',
  tournament: 'Турнир',
  none: 'Никто',
  validOnDays: 'Действует в дни',
  validTill: 'Годен до',
  yourRequest: 'Ваш запрос',
  withdrawalRequestPending: 'У вас есть один запрос на вывод средств в состоянии ожидания. ',
  realBalance: 'Реальный баланс',
  bonusInCasino: 'Бонус в казино',
  bonusSelected: 'Бонус выбран',
  payWith: 'Оплатить с',
  reached: 'Достиг',
  receiptOfYour: 'Получение вашего',
  capEmail: 'ЭЛЕКТРОННАЯ ПОЧТА',
  capCreditCard: 'НОМЕР КРЕДИТНОЙ КАРТЫ',
  capExpiry: 'СРОК ГОДНОСТИ',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY имеет неверный формат.',
  min13Numbers: 'Требуется минимум 13 номеров',
  max19Numbers: 'Разрешено максимум 19 номеров',
  min3Numbers: 'Требуется минимум 3 номера',
  max4Numbers: 'Разрешено максимум 4 номера',
  Other: 'Другой',
  forfeit: 'Неустойка',
  withdrawRequestCreated: 'Запрос на снятие успешно создан',
  noBetHistory: 'История ставок не найдена',
  noPromotions: 'Акции не найдены',
  noBonuses: 'Бонусы не найдены',
  noHistory: 'История не найдена',
  noWithdrawalHistory: 'История вывода средств не найдена',
  noCMS: 'Система управления контентом не найдена',
  noProvidersFound: 'Поставщики не найдены',
  noDataFound: 'Данные не найдены',
  countryBlocked: 'СТРАНА ЗАБЛОКИРОВАНА',
  forbidden: 'ЗАПРЕЩЕННЫЙ',
  notAcceptingVisitors: 'Мы глубоко сожалеем об этом, но сайт Shinywilds.com недоступен в вашей стране.',
  contactUsAt: 'Если вы считаете, что это было показано вам по ошибке, свяжитесь с нашей службой поддержки по электронной почте:',
  siteUnderMaintenance: 'На сайте проводятся технические работы',
  weWillBeRightBack: 'Мы скоро вернемся!',
  serviceUnavailable: 'Наш сервис временно недоступен. ',
  tryAgainLater: 'Пожалуйста, повторите попытку позже.',
  checkYourEmail: 'Проверьте свою электронную почту',
  sentAVerification: 'Мы отправили ссылку для подтверждения',
  toYourEmail: 'на вашу электронную почту.',
  backToLogin: 'Вернуться на страницу авторизации',
  goToLogin: 'Перейти к входу',
  accountVerified: 'Аккаунт проверен',
  yourAccountVerified: 'Ваша учетная запись была подтверждена',
  successfully: 'Успешно',
  verificationFailed: 'Проверка не удалась',
  emailFailedContact: 'Проверка не удалась. Если это происходит регулярно, обратитесь в службу поддержки. ',
  setNewPass: 'Установить новый пароль',
  passResetLink: 'Мы отправили ссылку для сброса пароля на',
  toYourAccount: 'На ваш счет',
  sendPasswordResetLink: 'Запрос на сброс пароля',
  gamePlayWagerComplete: 'Поздравляем, вы успешно завершили отыгрыш, и выигранные бонусные деньги превышают максимально допустимую сумму выигрыша. ',
  real: 'Настоящий',
  demo: 'Демо',
  money: 'Деньги',
  formatRquired: 'Требуется формат .png, .jpg, .jpeg',
  chooseImage: 'Пожалуйста, выберите изображение',
  imageSize3MB: 'Введите изображение размером менее 3 МБ.',
  noProfileImage: 'Нет изображения профиля, которое можно было бы удалить.',
  limitSetSuccess: 'лимит успешно установлен',
  accountDisabled: 'Аккаунт успешно отключен',
  loginSuccess: 'Авторизация успешна',
  signupSuccess: 'Регистрация прошла успешно',
  linkExpired: 'Срок действия ссылки истек',
  linkSent: 'Ссылка отправлена',
  accAlreadyVerified: 'Аккаунт уже подтвержден. ',
  profileImage: 'Изображение профиля',
  profileImageRemoved: 'Изображение профиля успешно удалено',
  docs: 'Документы',
  updatedSuccess: 'Успешно Обновлено',
  MATCH: 'СООТВЕТСТВОВАТЬ',
  BALANCE: 'БАЛАНС',
  FREESPINS: 'ФРИСПИНЫ',
  MATCH_1: 'СООТВЕТСТВОВАТЬ',
  DEPOSIT: 'ДЕПОЗИТ',
  WAGERING: 'СТАВКИ',
  PROMOTION: 'ПОВЫШЕНИЕ',
  CREDITCARD: 'КРЕДИТНАЯ КАРТА',
  WALLET: 'КОШЕЛЕК',
  CRYPTO: 'КРИПТО',
  OTHER: 'ДРУГОЙ',
  INSTANTBANKING: 'МГНОВЕННЫЙ БАНКОВСКИЙ БАНК',
  cookieTitle: 'Печенье!',
  declineButton: 'Отклонить',
  acceptButton: 'Принимать',
  cookieDeclinedMessage: 'Для получения наилучших результатов, чтобы продолжить использование этого веб-сайта, необходимо принять файлы cookie. ',
  cookieDescription: 'Добро пожаловать на Shinywilds.com! ',
  forfeitMessageFirst: 'В настоящее время у вас есть следующий активный бонус:',
  forfeitMessageSecond: 'Вам необходимо отказаться от этого бонуса, прежде чем претендовать на новый.',
  forfeitButton: 'Неустойка',
  pleaseVerifyFirstText: 'Чтобы иметь возможность запросить вывод средств, нам сначала необходимо подтвердить ваш адрес электронной почты. ',
  clickHere: 'Кликните сюда',
  pleaseVerifyLastText: 'чтобы мы могли повторно отправить вам электронное письмо с подтверждением и продолжить.',
  pleseWait: 'Пожалуйста, подождите',
  toResend: 'чтобы повторно отправить ссылку',
  notVerified: 'Вы должны подтвердить свой адрес электронной почты, чтобы вывести деньги',
  verification: 'Проверка',
  UPLOAD_IMG_MSG: 'Перетащите файлы сюда или щелкните, чтобы выбрать файлы.',
  affiliate: 'Партнер',
  affiliateTitle: 'Партнерская программа',
  affiliateDescription: ' Приглашайте наш бренд и зарабатывайте деньги, участвуя в партнерской программе Cash Machine! ',
  refferalTitle: 'Реферальная программа',
  refferalDescription: 'Вы можете поделиться этой реферальной ссылкой с другими и заработать бонус, если пользователь зарегистрируется по вашей ссылке.',
  affiliateButtonText: 'Стать партнером',
  affiliateLink: 'Партнерская ссылка',
  affiliateStatus: 'Ваш партнерский запрос успешно отправлен',
  currentStatus: 'и текущий партнерский статус: {{status}}',
  referralLink: 'Реферальная ссылка',
  copyTitleMessage: 'Пожалуйста, скопируйте эту ссылку',
  copiedMessage: 'Ссылка скопирована',
  searchedGame: 'Поисковая игра',
  favoriteGame: 'Любимые игры',
  randomGames: 'Случайные игры',
  poolPrize: 'Призовой фонд',
  chooseYourWelcomeBonus: 'Выберите свой приветственный бонус',
  useNow: 'Использовать сейчас',
  readTheTerms: 'Прочтите условия',
  proceedWithoutWelcomeBonus: 'Продолжить без приветственного бонуса',
  doYouAlreadyHaveAccount: 'У вас уже есть аккаунт? ',
  registerViaSocialNetworks: 'Зарегистрируйтесь через социальные сети',
  iconfirmIm18YearsOlder: 'Я подтверждаю, что мне 18 лет или больше',
  iAgreeWithTermsAndConditions: 'Я согласен с Условиями использования',
  thankYouSuccessfullyRegisteringCashMachine: 'Благодарим вас за успешную регистрацию на Cash Machine.',
  verifyEmailMessage: 'Мы просим вас подтвердить свой адрес электронной почты, нажав на ссылку подтверждения, отправленную на ваш адрес электронной почты, для успешного входа на сайт.',
  gotIt: 'Понятно',
  phonecodeRequired: 'Требуется телефонный код',
  cpNotAllow: 'копирование/вставка запрещены',
  playFun: 'Играйте весело',
  playReal: 'Играйте по-настоящему',
  yourRealBalance: 'Ваш реальный баланс:',
  chooseYourPaymentMethod: 'Выберите метод оплаты',
  balanceHistory: 'История баланса',
  liveChat: 'Живой чат',
  mail: 'Почта',
  chooseEnterYourDepositAmounts: 'Выберите или введите сумму депозита',
  noBonusDataAvailable: 'Нет данных о бонусах',
  nowTryPlayForRealBets: 'Теперь попробуйте сыграть на реальные ставки',
  registerAndPlayForRealBets: 'Зарегистрируйтесь и играйте на реальные ставки',
  tournamentDataNotAvailable: 'Данные о турнире недоступны.',
  startDate: 'Дата начала',
  endDate: 'Дата окончания',
  merchant: 'Торговец',
  seachHere: 'Поищи здесь',
  show: 'Показывать',
  betResults: 'Результаты ставок:',
  noTransactionsFoundForselectedDate: 'На выбранную дату транзакций не найдено',
  loginError: 'Ошибка входа',
  errorMessageIncorrectlyAuthorization: 'Неверно указан адрес электронной почты/логин или пароль. Ошибка авторизации.',
  profileSaved: 'Профиль сохранен.',
  addInfo: 'Добавить информацию',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Я прочитал и согласен с ограничениями платежных систем*',
  paymentOptions: 'Варианты оплаты',
  language: 'Язык',
  betTime: 'Время ставки',
  noActiveBonusFound: 'Активный бонус не найден',
  bonusType: 'Тип бонуса',
  requiredWageringAmount: 'Требуемая сумма ставки',
  currentWageringAmount: 'Текущая сумма ставки',
  validUpto: 'Действительно до',
  bonusCode: 'Бонус код',
  wageringProgressBar: 'Индикатор прогресса ставок',
  noGamesFound: 'Игры не найдены',
  noteYouMayOptfreeSpin: 'Примечание. Вы можете выбрать FREESPIN в любой из этих игр.',
  viewOfferDetails: 'Посмотреть детали предложения',
  viewFreespinGames: 'Посмотреть игры с бесплатными вращениями',
  depositBonusPercentage: 'Процент бонуса на депозит: ',
  validity: 'Период действия',
  activated: 'Активировано',
  bonusName: 'Название бонуса',
  freeRounds: 'Бесплатные раунды',
  wagering: 'Ставки',
  ended2: 'Закончено',
  searchBonusTitle: 'Поиск по названию бонуса',
  claimed: 'Заявлено',
  noBonusHistoryFoundselectedDates: 'История бонусов для выбранных дат не найдена',
  noBonusDetailsFound: 'Детали бонуса не найдены',
  enterCode: 'Введите код',
  transactionTime: 'Время транзакции',
  credit: 'Кредит',
  debit: 'Дебет',
  // bonusInfoOfHeading: 'Бонусы — это дополнительные выплаты, которые вознаграждают за вашу работу.',
  bonusInfoOfHeading: 'Бонусы — это дополнительные награды, которые признают вашу активность и достижения.',
  onlineCasino: 'онлайн-казино',
  isTheTop: ' это вершина ',
  bonusInfoPara0: 'Ищете самые лучшие игры онлайн-казино? ',
  tournamentsHistory: 'История турниров',

  loginWelcomeBonusInfo: `Cash Machine приветствует новых игроков приветственным бонусом - 100%
   Депозит + 100 фриспинов в игре «Pat’s Heroes» (Platipus).
   Минимальная сумма вклада составляет 20 евро / 900 аргентинских песо / 30 австралийских долларов / 500 чешских крон.
   / 150 датских крон / 200 норвежских крон / 30 новозеландских долларов / 80 злотых / 20 долларов США / 300 южноафриканских рандов / 70 лари
   / 9 000 тенге / 200 сомони / 200 000 сум / 130 турецких лир`,
  loginDepositBonusInfo: `Максимальная сумма бонуса: 100% от суммы депозита, но не более
   чем 100 евро / 4 000 аргентинских песо / 150 австралийских долларов / 2 500 чешских крон / 750 датских крон / 1 000 норвежских крон
   / 150 новозеландских долларов / 400 злотых / 100 долларов США / 1500 южноафриканских рандов / 350 лари / 45 000 тенге /
   1000 сомони / 1 000 000 сум / 600 турецких лир`,
  loginFreespinBonusInfo: `Фриспины выдаются в течение 5 дней с момента активации бонуса,
   20 бесплатных вращений ежедневно. Все средства, выигранные на бесплатных вращениях, зачисляются на
   бонусный счет и доступны для игры/отыгрыша
   бонус. Вывод доступен после отыгрыша X40. Время отыгрыша бонуса
   составляет 7 дней.`,
  loginMaximumBetLimitInfo: `Максимальный лимит ставки при отыгрыше: 1 евро / 45 австралийских долларов / 1,5 австралийских долларов / чешских крон.
   25 / 7 датских крон / 10 норвежских крон / 1,5 новозеландских долларов / 5 злотых / 1 доллар США / 15 южноафриканских рандов / 3,5 лари /
   450 тенге / 10 сомони / 10 000 сум / 6 турецких лир`,

  BonusInfoPara1: ' с большим выбором фантастических игр и потрясающими специальными предложениями. Наши игровые автоматы представлены в самых разных тематиках, а игровые автоматы с прогрессивным джекпотом предлагают игрокам выгодный шанс выиграть крупный выигрыш. Мы также предлагаем захватывающие игры с живыми дилерами, в которых вы можете видеть, как ваши карты тасуются∂ и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: вы можете сначала потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем решите играть по-настоящему.',
  BonusInfoPara2: ' Наши игровые автоматы представлены в самых разных тематиках, причем игровые автоматы с прогрессивным джекпотом предлагают выгодный шанс выиграть крупный выигрыш. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как ваши карты тасуются и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: вы можете сначала потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем играть по-настоящему. Наши игровые автоматы представлены в самых разных темах, а игровые автоматы с прогрессивным джекпотом предлагают игрокам выгодный шанс выиграть. большая победа. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как ваши карты тасуются и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: вы можете сначала потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем решите играть по-настоящему.',
  BonusInfoPara3: ' Наши игровые автоматы представлены в самых разных тематиках: игровые автоматы с прогрессивным джекпотом предлагают игрокам выгодный шанс выиграть крупный выигрыш. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как ваши карты тасуются и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: вы можете сначала потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем решите играть по-настоящему.',
  BonusInfoPara4: ' Наши игровые автоматы представлены в самых разных тематиках: игровые автоматы с прогрессивным джекпотом предлагают игрокам выгодный шанс выиграть крупный выигрыш. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как ваши карты тасуются и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: вы можете сначала потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем играть по-настоящему. Наши игровые автоматы представлены в самых разных темах, а игровые автоматы с прогрессивным джекпотом предлагают игрокам выгодный шанс выиграть. большая победа. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как ваши карты тасуются и раздаются в реальном времени! Не знаете, какая игра подойдет именно вам? Не волнуйтесь: сначала вы можете потренироваться в демо-режиме, чтобы почувствовать каждую игру, прежде чем решите играть по-настоящему.',
  cashMachineFooterPara1: 'Ищете лучшее казино онлайн? Добро пожаловать в',
  cashMachineFooterPara2: ` где мы
объединяем захватывающий игровой процесс, щедрые бонусы и эксклюзивные
турниры, чтобы предложить вам лучшее в мире онлайн-развлечений. Хотите сорвать
джекпот на наших прогрессивных слотах, побороться за призы в турнирах или
испытать удачу в живых играх с дилерами? У нас есть всё! Что делает нас
уникальными? В`,
  cashMachineFooterPara3: `вы можете выиграть не только денежные призы, но и
настоящие физические награды! Мы с гордостью становимся выбором игроков по
всему миру. Готовы исследовать? Играйте в любую доступную для вашего региона игру
и наслаждайтесь непревзойденным азартом. Ощутите лучшее, играйте с лучшими —
только в `,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: ' Живое казино, игровые автоматы и турниры в казино Drops and wins',
  tournamentDescTitle: 'Турниры — отличная возможность посоревноваться с другими игроками и получить щедрые призы.',
  tournamentPara1: 'Ищете лучшие онлайн-казино? Тогда вы попали по адресу.',
  tournamentPara2: 'с широким выбором фантастических игр и ослепительных специальных предложений. Наши игровые автоматы представлены в широком ассортименте тем, с прогрессивными джекпотами, предлагающими игрокам выгодный шанс выиграть большой приз. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как вашу карты перемешиваются и раздаются в реальном времени! Не уверены, какая игра подходит вам? Не волнуйтесь - сначала вы можете попрактиковаться в демо-режиме, чтобы понять каждую игру, прежде чем решить играть на реальные деньги.',
  tournamentPara3: 'Наши игровые автоматы представлены в широком ассортименте тем, с прогрессивными джекпотами, предлагающими игрокам выгодный шанс выиграть большой приз. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как вашу карты перемешиваются и раздаются в реальном времени! Не уверены, какая игра подходит вам? Не волнуйтесь - сначала вы можете попрактиковаться в демо-режиме, чтобы понять каждую игру, прежде чем решить играть на реальные деньги. Наши игровые автоматы представлены в широком ассортименте тем, с прогрессивными джекпотами, предлагающими игрокам выгодный шанс выиграть большой приз. Мы также предлагаем захватывающие игры с живыми дилерами, где вы можете видеть, как вашу карты перемешиваются и раздаются в реальном времени! Не уверены, какая игра подходит вам? Не волнуйтесь - сначала вы можете попрактиковаться в демо-режиме, чтобы понять каждую игру, прежде чем решить играть на реальные деньги.',
  'New Games': 'Новые игры',
  'Popular Games': 'Популярные игры',
  'Games Of The Month': 'Игры месяца',
  Exclusive: 'Эксклюзивный',
  'Bonus Buy': 'Бонусная покупка',
  'Jackpot Games': 'Игры с джекпотом',
  lightMode: 'Светлый режим',
  darkMode: 'Темный режим',
  searchProvider: 'Поисковый провайдер',
  tournamentHistoryDataNotAvailable: 'Данные истории турниров недоступны.',
  viewMore: 'Посмотреть больше',
  description: 'Описание',
  codeCopied: 'Код скопирован',
  tournamentGames: 'Турнирные игры',
  freeSpinGames: 'Бесплатные спин-игры',
  promoCode: 'Промо-код',
  paymentNote: 'Примечание: Пожалуйста, не закрывайте и не обновляйте окно во время обработки транзакции',
  liveGames: 'Live Игры',
  play: 'ИГРАТЬ',
  demo1: 'ДЕМО',
  registration: 'РЕГИСТРАЦИЯ',
  confirmation: 'Подтверждение',
  forfeitBonusMessage: 'Если вы активируете этот бонус, все старые активные бонусы будут утрачены.',
  bonusConfirmationMessage: 'Вы уверены, что хотите активировать этот бонус?',

  forfeitRewardMessage: 'Если вы активируете эту награду, все старые активные бонусы будут аннулированы.',
  rewardConfirmationMessage: 'Вы уверены, что хотите активировать эту награду?',
  reward: 'Награда',
  purchasedReward: 'Купленная награда',
  noActiveRewardFound: 'Активная награда не найдена',
  noRewardDetailsFound: 'Награда не найдена',
  betDate: 'Дата ставки',
  shopDes: 'Магазин — это место, где вы можете обменять накопленные очки на различные награды, бонусы и призы.',
  vipInfoHeading: 'VIP бонусы — это эксклюзивные предложения для наших самых преданных игроков.'
}
