import { Box, styled } from '@mui/material'

export const SideNavbarWrapper = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    // maxWidth: theme.layout.maxWidth,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '10',
    pointerEvents: 'none',
    padding: theme.spacing(0, 0.5, 2),
    position: 'sticky',
    top: '8rem',
    left: '0%',
    height: 'calc(100dvh - 8rem)',
    transform: 'translateX(0%)',
    '& .left-navigation-wrap, & .right-navigation-wrap': {
      width: '100%',
      // maxWidth: theme.spacing(17.375),
      maxWidth: theme.spacing(19),
      '& .navigation-box': {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(1)} !important`,
        '& .MuiButtonBase-root:nth-child(odd)': {
          marginLeft: 'auto'
        }
      }
    },
    '& .left-navigation-wrap': {
      marginRight: 'auto'
    },
    '& .right-navigation-wrap': {
      marginLeft: 'auto'
    },
    '& .MuiButtonBase-root': {
      width: 'fit-content',
      // maxWidth: `${theme.spacing(17.375)} !important`,
      maxWidth: `${theme.spacing(19)} !important`,
      minWidth: `${theme.spacing(13)} !important`,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      pointerEvents: 'all'
    },
    '& .coin-animation-img-box': {
      width: theme.spacing(9.688),
      height: theme.spacing(9.688),
      borderRadius: '100%',
      aspectRatio: '1/1',
      overflow: 'hidden',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
      position: 'relative',
      filter: `drop-shadow(${theme.shadows[3]})`,
      '&::before': {
        content: '""',
        width: theme.spacing(2.5),
        height: '200%',
        background: theme.palette.gradientColor.coinAnimationStrip,
        display: 'block',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%) rotate(-30deg)',
        animationName: 'coinShine',
        animationDuration: '5s',
        animationTimingFunction: 'ease',
        animationIterationCount: 'infinite',
        animationDirection: 'forward',
        '@keyframes coinShine': {
          '0%': {
            left: '-180%'
          },
          '100%': {
            left: '180%'
          }
        }
      },
      '& img': {
        width: '100%',
        maxWidth: '100%',
        height: 'auto'
      }
    },
    '& .atm-animation-img-box': {
      width: theme.spacing(17.375),
      height: theme.spacing(18.563),
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(-1.25),
      '& svg': {
        width: '100%',
        height: '100%',
        transform: 'scale(1.15) !important'
      }
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      height: `calc(100dvh - ${theme.spacing(10.375)})`,
      display: 'none',
      flexDirection: 'column',
      gap: theme.spacing(1),
      overflowY: 'auto',
      padding: theme.spacing(0, 1.25, 1.25),
      position: 'static',
      transform: 'none',
      '& .left-navigation-wrap, & .right-navigation-wrap': {
        width: '100%',
        maxWidth: 'unset',
        '& .navigation-box': {
          '& .MuiButtonBase-root': {
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        }
      },
      '& .left-navigation-wrap': {
        marginLeft: theme.spacing(0)
      },
      '& .right-navigation-wrap': {
        marginRight: theme.spacing(0)
      }
    }
  }
})
