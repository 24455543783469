import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box
  , useMediaQuery
} from '@mui/material'
import { HeadingTypographyNeon, SectionHeadingWrapper } from './style'
import { useSelector } from 'react-redux'
import { CustomPrimaryButton } from '../CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'

export default function SectionHeading ({ headingNameNeon, handleSeeAllGames, isFiltered, handleBack, isSearched, showRandomGames, hideViewBtn = false }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:640px)')

  const id = Math.random().toString(36).substr(2, 9)
  const languageCode = useSelector((state) => state?.language?.selectedLanguage?.code)

  useEffect(() => {
    const text = document.querySelector(`.animated-text-${id}`)
    const letters = text.textContent.split('')
    // console.log(letters)
    text.textContent = '' // Clear the original text

    // Generate spans for each letter
    letters.forEach((letter) => {
      if (letter === ' ' && isMobile) {
        const br = document.createElement('br')
        br.textContent = letter
        text.appendChild(br)
      } else {
        const span = document.createElement('span')
        span.textContent = letter
        text.appendChild(span)
      }
    })

    const spans = document.querySelectorAll(`.animated-text-${id} span`)

    function animateText () {
      const randomIndex = Math.floor(Math.random() * spans.length)

      spans[randomIndex].style.opacity = 0

      setTimeout(() => {
        spans[randomIndex].style.opacity = 1
      }, 300) // Adjust the delay as needed
    }

    // Start the animation loop
    function startAnimation () {
      const animationInterval = setInterval(animateText, 200) // Adjust the interval as needed

      // Stop the animation loop after 10 seconds
      setTimeout(() => {
        clearInterval(animationInterval)

        // Restart the animation loop after 5 seconds
        setTimeout(() => {
          startAnimation()
        }, 5000)
      }, 5000)
    }

    // Start the animation loop with a delay
    setTimeout(() => {
      startAnimation()
    }, 4000) // Adjust the delay before starting the animation loop

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval()
  }, [isMobile]) // Empty dependency array means it will run once after the initial render

  return (
    <>
      <SectionHeadingWrapper theme={theme}>
        <Box className='text-box'>
          <HeadingTypographyNeon variant='h1' className={`animated-text animated-text-${id} ${languageCode === 'pl' ? 'polish-animation' : ''}`}>
            {headingNameNeon}
          </HeadingTypographyNeon>
        </Box>
        <Box className='btn-box'>
          {!hideViewBtn &&
            <CustomPrimaryButton onClick={isFiltered ? handleBack : isSearched ? handleBack : showRandomGames ? handleBack : handleSeeAllGames}>
              {isFiltered ? (t('back')) : isSearched ? (t('back')) : showRandomGames ? (t('back')) : isMobile ? (t('viewAll')) : (t('viewAllGames'))}
            </CustomPrimaryButton>}
        </Box>
      </SectionHeadingWrapper>
    </>
  )
}
