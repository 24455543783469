import { createAsyncThunk } from '@reduxjs/toolkit'
import { LANGUAGE } from 'constants/index'
import { getLocalLanguage, setItem } from 'helpers/localstorage.helpers'
import { getCurrencyDataService, getGeneralService, getSiteDataService, getSiteSettingDataService, getSponsorDataService, ipAddressService } from 'network/services/general.service'
import { getCurrencyDataSuccess, getGeneralDataSuccess, getIpDataSuccess, getSettingDataSuccess, getSiteDataSuccess, getSponsorDataSuccess } from 'redux-thunk/redux/slices/general.slice'

export const getGeneralData = createAsyncThunk(
  '/getGeneralData',
  async (data, thunkApi) => {
    try {
      const res = await getGeneralService()
      thunkApi.dispatch(getGeneralDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getSiteData = createAsyncThunk(
  '/getSiteData',
  async (data, thunkApi) => {
    try {
      const res = await getSiteDataService()
      thunkApi.dispatch(getSiteDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getSiteSettingData = createAsyncThunk(
  '/getSiteSettingData',
  async (data, thunkApi) => {
    try {
      const res = await getSiteSettingDataService()
      thunkApi.dispatch(getSettingDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getIPData = createAsyncThunk(
  '/getIPData',
  async (data, thunkApi) => {
    try {
      const res = await ipAddressService()
      setItem(LANGUAGE, String(res?.country).toLocaleLowerCase())
      thunkApi.dispatch(getIpDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getCurrencyData = createAsyncThunk(
  '/getCurrencyData',
  async (data, thunkApi) => {
    try {
      const res = await getCurrencyDataService()
      thunkApi.dispatch(getCurrencyDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getSponsorData = createAsyncThunk(
  '/getSponsorData',
  async (data, thunkApi) => {
    try {
      const res = await getSponsorDataService(data)
      thunkApi.dispatch(getSponsorDataSuccess(res?.sponsor))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
