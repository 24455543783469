import { Box, styled } from '@mui/material'

export const KycWrapper = styled(Box)(({ theme }) => {
  return {
    '& .file-upload-box': {
      backgroundColor: '#ffffff21',
      display: 'block',
      padding: '40px',
      border: '1px dashed #fff',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',

      '& input': {
        display: 'none'
      },

      '& svg': {
        fontSize: '42px'
      }
    },

    '& .preview-box': {
      borderRadius: '8px',
      overflow: ' hidden',
      backgroundColor: '#ffffff21',
      border: '1px solid #fff'
    },

    '& .pending-box': {
      borderRadius: '8px',
      overflow: ' hidden',
      backgroundColor: '#ffffff21',
      border: '1px solid #fff',
      padding: '40px',
      textAlign: 'center',

      '& svg': {
        fontSize: '42px',
        fill: '#fff',
        color: '#fff'
      }
    },

    '& .content-wrapper': {

      '& .form-btn-box': {
        margin: '0px auto 0 !important'
      }
    },

    '& .error-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: '#970505 !important',
      fontSize: '15px !important',
      fontWeight: '500',
      margin: '2px 0px'
    },
    '& .title-text': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textMain,
      fontSize: theme.spacing(1.5),
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: theme.spacing(0.875),
      '& .MuiTypography-root': {
        color: theme.palette.warning.main,
        fontSize: theme.spacing(1.5),
        fontWeight: '600',
        display: 'inline-block'
      }
    },
    '& .subtitle-text': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textMain,
      fontSize: theme.spacing(1.5),
      fontWeight: '500',
      textAlign: 'center',
      margin: theme.spacing(0.875, 0)
    },
    '& .content-loding-wrapper':{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:'100%',
      '& svg':{
       width:'50px',
       height:'50px',
       fill:'#fff'
      }
    }
  }
})
