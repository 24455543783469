import React, { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OffersBonusesWrapper } from "./style";
import { getUserBonus } from "redux-thunk/thunk/bonus.thunk";
import { SpinnerSVG } from "assets/icons/index";
import BonusCard from "pages/Bonus/BonusCard";

const FALLBACK_IMAGE = "/assets/images/stock-images/offers/offer-thumbnail.png";
const { REACT_APP_S3_URL } = process.env;

const OffersBonuses = ({ from, isVip }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bonus = useSelector((state) => state?.bonus?.userBonus?.bonus);
  const { bonusLoading } = useSelector((state) => state?.bonus);

  useEffect(() => {
    dispatch(getUserBonus());
  }, [dispatch]);

  const renderBonusCard = useCallback(
    (bonusData) => {
      const {
        bonusId,
        bonusTitle,
        bonusCode,
        bonusSlug,
        description,
        depositBonusPercent,
        quantity,
        validFrom,
        validTo,
        termCondition,
        imageUrl,
        isClaimed,
        isVisibleInPromotions,
      } = bonusData;

      const shouldRender =
        (isVip && isVisibleInPromotions) || (!isVip && !isVisibleInPromotions);

      if (!shouldRender) return null;

      return (
        <BonusCard
          key={bonusId}
          data={bonusData}
          bonusId={bonusId}
          bonusTitle={bonusTitle}
          bonusCode={bonusCode}
          bonusType={bonusSlug}
          description={
            typeof description === "string"
              ? JSON.parse(description)?.EN || "NA"
              : description?.EN || "NA"
          }
          depositBonusPercent={depositBonusPercent}
          freeSpinsQty={quantity}
          validFrom={validFrom}
          validTo={validTo}
          termCondition={
            typeof termCondition === "string"
              ? JSON.parse(termCondition)?.EN || "NA"
              : termCondition?.EN || "NA"
          }
          bonusImg={
            imageUrl ? `${REACT_APP_S3_URL}/${imageUrl}` : FALLBACK_IMAGE
          }
          from={from}
          isClaimed={isClaimed}
        />
      );
    },
    [isVip, from]
  );

  const OffersBonusElement = useMemo(
    () => (
      <OffersBonusesWrapper theme={theme} className="content-wrapper">
        <Box className="offer-wrap">{bonus?.rows?.map(renderBonusCard)}</Box>
      </OffersBonusesWrapper>
    ),
    [bonus, theme, renderBonusCard]
  );

  if (bonusLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SpinnerSVG style={{ fill: "white" }} />
      </div>
    );
  }

  if (!bonus?.count) {
    return (
      <Typography variant="h3" className="form-title">
        {t("noBonusDetailsFound")}
      </Typography>
    );
  }

  return OffersBonusElement;
};

export default React.memo(OffersBonuses);
