import { combineReducers } from '@reduxjs/toolkit'
import user from './slices/user.slice'
import gameSetting from './slices/gameSetting.slice'
import auth from './slices/auth.slice'
import games from './slices/games.slice'
import settings from './slices/settings.slice'
import wallet from './slices/wallet.slice'
import lobby from './slices/lobby.slice'
import bonus from './slices/bonus.slice'
import transactions from './slices/usersTransaction.slice'
import loader from './slices/loader.slice'
import casino from './slices/casino.slice'
import searchPopup from './slices/searchPopup.slice'
import language from './slices/language.slice'
import general from './slices/general.slice'
import limits from './slices/limits.slice'
import tournaments from './slices/tournaments.slice'
import payment from './slices/payment.slice'
import shop from './slices/shop.slice'

const rootReducer = combineReducers({
  user,
  settings,
  gameSetting,
  auth,
  games,
  wallet,
  lobby,
  bonus,
  transactions,
  loader,
  casino,
  searchPopup,
  language,
  general,
  limits,
  tournaments,
  payment,
  shop
})

export default rootReducer
