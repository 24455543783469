
import { AppBar, Switch, styled } from '@mui/material'
import SunDim from '../../../assets/icons/SunDim.svg'
import MoonStar from '../../../assets/icons/MoonStars.svg'

export const HeaderWrapper = styled(AppBar)(({ theme }) => {
  return {
    height: theme.spacing(5.75),
    background: theme.palette.gradientColor.headerBg,
    borderBottom: `3px solid ${theme.palette.header.borderMain}`,
    backdropFilter: 'blur(4px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 1.375),
    position: 'fixed',
    top: theme.spacing(0),
    left: '50%',
    transform: 'translateX(-50%)',
    '& .MuiToolbar-root': {
      width: '100%',
      maxWidth: theme.layout.maxWidth,
      minHeight: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // gap: theme.spacing(1.375),
      padding: theme.spacing(0),
      '& .brand-logo-wrap': {
        display: 'flex',
        alignItems: 'center',
        '& .brand-logo': {
          width: '50vw',
          maxWidth: theme.spacing(25.75),
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          transform: `translateY(${theme.spacing(0.75)})`
        }
      },
      '& .navigation-wrap': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(0.875),
        flexGrow: '1',
        '& .search-wrap, & .MuiFormControl-root': {
          width: '100%',
          // maxWidth: theme.spacing(45),
          maxWidth: theme.spacing(42),
          marginRight: 'auto',
          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.formInput.dark,
            borderRadius: theme.spacing(0.5),
            '& .MuiInputBase-input': {
              height: theme.spacing(1.188),
              color: theme.palette.formInput.contrastTextMain,
              fontSize: theme.spacing(1),
              fontWeight: '500',
              padding: theme.spacing(1.032, 0.875),
              '&::placeholder': {
                color: theme.palette.formInput.contrastTextWhite,
                fontSize: theme.spacing(1),
                fontWeight: '500',
                opacity: '1',
                fontFamily: 'Poppins, sans-serif'
              }
            },
            '& .MuiInputAdornment-root': {
              '& .MuiIconButton-root': {
                fill: theme.palette.formInput.contrastTextWhite,
                padding: theme.spacing(0.5),
                '& svg': {
                  width: theme.spacing(1.5),
                  height: theme.spacing(1.5)
                }
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.formInput.borderDark}`
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.formInput.borderMain
              }
            },
            '&.Mui-focused': {
              '& + .currency-note-animation-wrap': {
                // opacity: '0 !important'
                '& .inner-box .animation-img-box img': {
                  transform: 'translateY(-150%) !important'
                }
              }
            }
          },
          '& .currency-note-animation-wrap': {
            width: '97%',
            display: 'flex',
            justifyContent: 'flex-end',
            pointerEvents: 'none',
            padding: theme.spacing(0, 3.125, 0, 0.5),
            position: 'absolute',
            top: '50%',
            left: theme.spacing(1),
            transition: '300ms all',
            transform: 'translateY(-50%)',
            // '&.has-value': {
            //   opacity: '0'
            // },
            '& .inner-box': {
              width: `calc(100% - ${theme.spacing(7.5)})`,
              height: theme.spacing(1),
              backgroundColor: theme.palette.solidColor.dark,
              borderRadius: theme.spacing(1),
              position: 'relative',
              '& .animation-img-box': {
                width: `calc(100% - ${theme.spacing(1)})`,
                aspectRatio: '162/75',
                overflow: 'hidden',
                pointerEvents: 'all',
                cursor: 'pointer',
                position: 'absolute',
                top: theme.spacing(0.5),
                left: '50%',
                transform: 'translateX(-50%)',
                '&.click-disable': {
                  pointerEvents: 'none'
                },
                '& img': {
                  width: '100%',
                  maxWidth: '100%',
                  height: 'auto',
                  pointerEvents: 'none',
                  transform: 'translateY(-150%)',
                  transition: '500ms all',
                  // '&.animated-img': {
                  //   animationName: 'currencyNote',
                  //   animationDuration: '1s',
                  //   animationTimingFunction: 'linear',
                  //   animationIterationCount: '1',
                  //   animationDirection: 'alternate',
                  //   '@keyframes currencyNote': {
                  //     '0%': {
                  //       transform: 'translateY(-150%)'
                  //     },
                  //     '100%': {
                  //       transform: 'translateY(0%)'
                  //     }
                  //   }
                  // },
                  '&.scroll-top-hide': {
                    opacity: '0',
                    transition: '300ms all'
                  }
                }
              }
            }
          }
        },
        '& .navigation-box': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexGrow: '1',
          gap: theme.spacing(0.875),
          '& .wallet-balance-wrap': {
            width: '100%',
            maxWidth: theme.spacing(24.875),
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: theme.spacing(0.75),
            '& .wallet-box': {
              height: theme.spacing(3.25),
              backgroundColor: theme.palette.formInput.dark,
              border: `2px solid ${theme.palette.formInput.borderDark}`,
              borderRadius: theme.spacing(0.5),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(0.75),
              flex: '1',
              padding: theme.spacing(0.5, 1),
              '& svg': {
                width: theme.spacing(2),
                height: theme.spacing(2),
                fill: theme.palette.warning.main,
                marginRight: 'auto'
              },
              '& .MuiTypography-root': {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.formInput.contrastTextWhite,
                fontSize: theme.spacing(1.5),
                fontWeight: '500',
                lineHeight: theme.spacing(1)
              }
            }
          }
        },
        '& .currency-icon': {
          height: theme.spacing(1.65),
          width: theme.spacing(1.65),
          borderRadius: theme.spacing(2),
          backgroundColor: '#EEC628',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          textAlign: 'center',
          '& .MuiTypography-root': {
            color: '#2C1646 !important',
            fontFamily: 'sans-serif !important',
            fontSize: `${theme.spacing(0.8)}!important`,
            marginTop: theme.spacing(0.25),
            fontWeight: '300'
          }
        },
        '& .auth-btn-wrap': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: theme.spacing(0.875),
          '& .MuiButtonBase-root': {
            minWidth: theme.spacing(12.125)
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(3.625),
      padding: theme.spacing(0, 1),
      '& .MuiToolbar-root': {
        '& .brand-logo-wrap': {
          '& .brand-logo': {
            maxWidth: theme.spacing(12.813)
          }
        },
        '& .navigation-wrap': {
          '& .search-wrap, & .MuiFormControl-root': {
            '& .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                height: theme.spacing(0.875),
                padding: theme.spacing(0.75, 0.875),
                '&::placeholder': {
                  fontSize: theme.spacing(0.75)
                }
              },
              '& .MuiInputAdornment-root': {
                '& .MuiIconButton-root': {
                  '& svg': {
                    width: theme.spacing(1.125),
                    height: theme.spacing(1.125)
                  }
                }
              }
            },
            '& .currency-note-animation-wrap': {
              padding: theme.spacing(0, 2.625, 0, 0.5),
              '& .inner-box': {
                width: `calc(100% - ${theme.spacing(6)})`,
                height: theme.spacing(0.5),
                borderRadius: theme.spacing(0.5),
                '& .animation-img-box': {
                  top: theme.spacing(0.25)
                }
              }
            }
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiToolbar-root': {
        '& .brand-logo-wrap': {
          display: 'none',
          '& .brand-logo': {
            transform: `translateY(${theme.spacing(0.5)})`
          }
        },
        '& .navigation-wrap': {
          justifyContent: 'flex-end',
          '& .search-wrap, & .MuiFormControl-root': {
            marginRight: theme.spacing(0)
          }
        },
        '&.search-wrap-shrink': {
          '& .brand-logo-wrap': {
            display: 'block'
          },
          '& .navigation-wrap': {
            '& .search-wrap, & .MuiFormControl-root': {
              width: theme.spacing(2.75),
              '& .MuiInputBase-root': {
                '& .MuiInputBase-input': {
                  width: theme.spacing(0),
                  padding: theme.spacing(0.75, 0)
                },
                '& .MuiInputAdornment-root': {
                  marginLeft: '6px'
                },
                '&:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.formInput.borderDark
                  }
                }
              }
            }
          }
        }
      }
    }
  }
})

export const DarkLightSwitch = styled(Switch)(({ theme, content }) => {
  return {
    width: theme.spacing(7.625),
    height: theme.spacing(3.25),
    backgroundColor: theme.palette.formInput.dark,
    border: `2px solid ${theme.palette.formInput.borderDark}`,
    borderRadius: theme.spacing(3.25),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0),

    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.formInput.transparent,
      borderRadius: theme.spacing(3.25),
      opacity: '1',
      position: 'relative',
      transform: `translateX(${theme.spacing(0)})`,
      transition: '500ms all',
      '&::after': {
        content: '""',
        width: '100%',
        maxWidth: theme.spacing(2.625),
        height: '100%',
        maxHeight: theme.spacing(2.625),
        backgroundImage: `url(${SunDim})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80%',
        backgroundPosition: 'center',
        position: 'absolute',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)'
      }
    },

    '& .MuiSwitch-switchBase': {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
      height: theme.spacing(3),
      padding: theme.spacing(0),
      position: 'static',
      transform: `translateX(${theme.spacing(0)})`,
      transition: '500ms all',
      '&:hover': {
        backgroundColor: theme.palette.formInput.transparent
      },

      '& .MuiSwitch-thumb': {
        width: theme.spacing(2.75),
        height: theme.spacing(2.75),
        background: theme.palette.gradientColor.darkModeSwitch,
        boxShadow: theme.shadows[0],
        '&::before': {
          content: '""',
          width: '100%',
          maxWidth: theme.spacing(2.625),
          height: '100%',
          maxHeight: theme.spacing(2.625),
          backgroundImage: `url(${MoonStar})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '80%',
          backgroundPosition: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }
      },

      '&.Mui-checked': {
        transform: `translateX(${theme.spacing(4.375)}) rotate(360deg)`,
        '& .MuiSwitch-thumb': {
          background: theme.palette.gradientColor.lightModeSwitch,
          '&::before': {
            backgroundImage: `url(${SunDim})`
          }
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.formInput.transparent,
          opacity: 1,
          transform: `translateX(${theme.spacing(-2.938)}) rotateY(360deg)`,
          '&::after': {
            content: '""',
            width: '100%',
            maxWidth: theme.spacing(2.625),
            height: '100%',
            maxHeight: theme.spacing(2.625),
            backgroundImage: `url(${MoonStar})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '80%',
            backgroundPosition: 'center',
            position: 'absolute',
            top: '50%',
            left: '35%',
            transform: 'translate(-50%, -50%)'
          }
        }
      }
    }
  }
})
