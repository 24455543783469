// import { METHOD_TYPES } from 'constants/index'
// import axiosInstance from 'network/apis/index'
// import { microServices } from 'network/apis/microservice/index'

import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import axios from 'axios'
const IPUrl = 'https://ipinfo.io/json'

export const getGeneralService = (data) => {
  // return axiosInstance(METHOD_TYPES.get, '/api/game-category', {}, {
  //   server: microServices.SERVICE_URL_1
  // })
}

export const getSiteDataService = (data) => {
  // return axiosInstance(METHOD_TYPES.get, '/api/site-detail', {}, {
  //   server: microServices.SERVICE_URL_1
  // })
}

export const getSiteSettingDataService = (data) => {
  // return axiosInstance(METHOD_TYPES.get, '/api/settings', {}, {
  //   server: microServices.SERVICE_URL_1
  // })
}

export const ipAddressService = async () => {
  try {
    // Skipped the axiosInstance because of response data format in response handler
    const response = await axios.get(IPUrl)
    return response.data
  } catch (error) {
    console.error('Error in ipAddressService:', error)
    throw error
  }
}

export const getCurrencyDataService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/api/v1/get-currency', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getSponsorDataService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/api/v1/sponsors', {}, {
    server: microServices.SERVICE_URL_1
  })
}
