import { createAsyncThunk } from '@reduxjs/toolkit'
import { googleLogout } from '@react-oauth/google'
import { loginService, signUpService, logoutService, updatePasswordService, updateUserProfileService, forgetPasswordService, forgetVerifyPasswordService, verifyEmailService, updateEmailService, resendEmailToken, googleSignUpService, verifyTokenService } from 'network/services/auth.service'
import { signIn, signOut } from 'helpers/cookie.helpers'
import { fetchUserInformation } from './user.thunk'
import { setAuthStatus, setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { setAuthModal, setUserModal } from 'redux-thunk/redux/slices/auth.slice'
/**
 * User Login Thunk
 */

export const userLogin = createAsyncThunk('api/v1/user/login',
  async ({ userNameOrEmail, password, onLoginSuccess, onLoginError, deviceType }, thunkApi) => {
    try {
      const res = await loginService({ email: userNameOrEmail, password, deviceType })
      signIn({
        token: res.accessToken
      })
      onLoginSuccess()
      thunkApi.dispatch(setAuthStatus(!!res.accessToken))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      onLoginError(error[0]?.description)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const userSignUp = createAsyncThunk('api/v1/user/signup',
  async ({ userDetails, setIsSignupLoading, signUpSuccess }, thunkApi) => {
    try {
      const res = await signUpService(userDetails)
      localStorage.removeItem('stag')
      localStorage.removeItem('referCode')
      signUpSuccess()
      return res
      // }
    } catch (error) {
      setIsSignupLoading(false)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const googleSingup = createAsyncThunk('api/v1/user/googlesignup',
  async ({ code, payload, signUpSuccess, handleGoogleLoginSuccess, isLogin }, thunkApi) => {
    try {
      let res = {}
      if (isLogin) {
        res = await googleSignUpService({ code: code })
      } else {
        res = await googleSignUpService(payload)
        signUpSuccess()
      }
      thunkApi.dispatch(setAuthModal(''))
      localStorage.removeItem('isSocialLogin')
      localStorage.removeItem('stag')
      localStorage.removeItem('referCode')
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setAuthStatus(!!res.accessToken))
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const tokenVerify = createAsyncThunk('verify/token',
  async ({ payload, navigate, t }, thunkApi) => {
    try {
      const res = await verifyTokenService(payload)
      if (!res.userAlreadyExist) {
        localStorage.setItem('isSocialLogin', 'true')
        navigate(`/social-login?token=${payload.code}`)
      } else {
        openErrorToaster({ message: t('userExists') })
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

/**
 * Operator Logout Thunk
 */
export const userLogout = createAsyncThunk('user/logout',
  async (thunkApi) => {
    try {
      const res = await logoutService()
      localStorage.removeItem('isSocialLogin')
      googleLogout()
      signOut()
      return res
    } catch (error) {
      signOut()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

/**
 * Operator Change Password Thunk
 */
export const updatePassword = createAsyncThunk('update/password',
  async ({ password, newPassword }, thunkApi) => {
    try {
      const res = await updatePasswordService({ password, newPassword })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const updateUserProfile = createAsyncThunk('update/password',
  async (formValues, thunkApi) => {
    try {
      const res = await updateUserProfileService(formValues)
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const forgetPassword = createAsyncThunk('forget/password',
  async ({ email, setEmailSent, setTokenSentStatus }, thunkApi) => {
    try {
      const res = await forgetPasswordService({ email })
      setEmailSent(true)
      setTokenSentStatus(true)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const VerifyPasswordToken = createAsyncThunk('verifyforget/password',
  async ({ params, setEmailSent, handleVerificationSuccess, t }, thunkApi) => {
    try {
      const res = await forgetVerifyPasswordService(params)
      setEmailSent(true)
      setTimeout(() => {
        handleVerificationSuccess()
      }, 5000)
      thunkApi.dispatch(setShowLoginPopup(true))
      thunkApi.dispatch(openSuccessToaster({ message: t('passwordResetSuccess') }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const verifyEmail = createAsyncThunk('verifyEmail/emailToken',
  async ({ param, handleVerificationSuccess }, thunkApi) => {
    try {
      const res = await verifyEmailService(param)
      signIn({
        token: res?.loginDetails?.accessToken
      })
      handleVerificationSuccess()
      thunkApi.dispatch(setAuthStatus(!!res?.loginDetails?.accessToken))
      thunkApi.dispatch(fetchUserInformation())
      thunkApi.dispatch(setUserModal('deposit'))

      return res
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const updateEmail = createAsyncThunk('update/email',
  async ({ newEmail, oldEmail }, thunkApi) => {
    try {
      const res = await updateEmailService({ newEmail, oldEmail })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const resendVerificationLink = createAsyncThunk('update/email',
  async ({ email, setLinkStatus }, thunkApi) => {
    try {
      const res = await resendEmailToken({ email })
      setLinkStatus(true)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })
