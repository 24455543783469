export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'Por vir',
  finished: 'Finalizado',
  prizePool: 'Conjunto de prêmios',
  joinNow: 'Entrar',
  joined: 'Ingressou',
  startsIn: 'Começa em',
  endsIn: 'Termina em',
  ended: 'Torneio encerrado',
  started: 'Torneio iniciado',
  seeMore: 'Ver mais',
  winPrizes: 'Ganhe prêmios emocionantes',
  leaderBoard: 'Entre os melhores',
  prize: 'Prêmio',
  aboutTournament: 'Sobre o torneio',
  congratulationsMessage: 'Bravo! ',
  payments: 'Pagamento',
  tournaments: 'Torneios',
  shop: 'Comprar',
  blog: 'Blogue',
  referral: 'Referência',
  contacts: 'Contatos',
  retry: 'Tentar novamente',
  incorrectCredsMessage: 'Seu nome de usuário ou senha está incorreto. ',
  bonuses: 'Bônus',
  depositBonus: 'Bônus de Depósito',
  voucher: 'Comprovante',
  gamlings: 'Jogos de azar',
  transactionHistory: 'Histórico de transações',
  offers: 'Ofertas',
  editProfile: 'Editar Perfil',
  continue: 'Continuar',
  apply: 'Aplicar',
  male: 'Macho',
  female: 'Fêmea',
  register: 'Registro',
  login: 'Conecte-se',
  grand: 'Grande',
  major: 'Principal',
  minor: 'Menor',
  mini: 'Mini',
  crashGameTitle: 'Colidir',
  betAmount: 'Valor da aposta',
  profit: 'Lucro',
  payout: 'Pagamento alvo',
  chance: 'Chance de ganhar',
  betBtn: 'Aposta',
  autoCashout: 'Saque automático',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Máx.',
  wagered: 'Apostado',
  nothingFound: 'Nada encontrado',
  lost: 'Perdido',
  won: 'Ganho',
  streak: 'Onda',
  highestWin: 'Maior vitória',
  promotions: 'Promoções',
  latestPromotions: 'Últimas Promoções',
  sponsorships: 'Patrocínios',
  highestLost: 'Maior perda',
  highestBet: 'Aposta mais alta',
  maxStreak: 'Sequência máxima',
  maxProfit: 'Lucro máximo: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Seu histórico de apostas está vazio',
  messagesCopyNotSupported: 'Seu navegador não suporta cópia automática para a área de transferência. Tente copiar o código manualmente.',
  messagesCopied: 'Endereço copiado.',
  deposit: 'Depósito',
  statReset: 'Redefinir estatísticas',
  rollOver: 'Rolar',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Voltar',
  inputFieldBtnMax: 'máx.',
  buttonTextPlaceBet: 'Fazer aposta',
  buttonTextCancelBet: 'Cancelar aposta',
  buttonTextEscapeBet: 'Sacar',
  buttonTextStartAutoBet: 'Iniciar aposta automática',
  buttonTextCancelAutoBet: 'Cancelar aposta automática',
  buttonTextLoading: 'Carregando',
  tabsMyBets: 'Minhas apostas',
  tabsDeposit: 'Depósito',
  tabsCashout: 'Saques',
  tabsAllbets: 'Todas as apostas',
  tabsTopbets: 'Melhores apostas',
  tabsBonus: 'Bônus',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Histórico de apostas',
  timerTextCurrentPayout: 'Pagamento atual',
  timerTextStarting: 'Começando em',
  timerTextSec: 'é',
  timerTextFlewAway: 'Falha',
  timerTextWait: 'Por favor, aguarde..',
  timerTextPreparingRound: 'Preparando rodada',
  timerTextLoadingRound: 'Carregando Rodada',
  comingSoon: 'Em breve',
  labelsProfitOnWin: 'Lucro na vitória',
  inputsNoOfBets: 'Número de apostas',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Começar',
  inputsCancel: 'Cancelar',
  buttonsMin: 'Mínimo',
  buttonsMax: 'Máx.',
  prediction: 'Predição',
  luckyNumber: 'Número da sorte',
  multiplier: 'Multiplicador',
  chanceToWin: 'Chance de ganhar',
  bets: 'Apostas',
  depositBtn: 'Depósito',
  rollUnder: 'Role para baixo',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'História',
  navBarMyBets: 'Minhas apostas',
  navBarAllBets: 'Todas as apostas',
  navBarHighRollers: 'Grandes apostadores',
  navBarRoundHistory: 'História da Rodada',
  navBarTotal: 'Apostas totais',
  topHeaderBetId: 'ID da aposta',
  topHeaderUser: 'Do utilizador',
  topHeaderPayout: 'Pagamento',
  topHeaderAmount: 'Quantia',
  topHeaderTime: 'Tempo',
  topHeaderProfit: 'Lucro',
  topHeaderRoundCrashed: 'Falha',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Cerquilha',
  topHeaderRoundBet: 'Aposta',
  topHeaderRoundProfit: 'Lucro',
  topHeaderRoundSignature: 'Assinatura',
  roundHistoryTitle: 'INFORMAÇÕES DO JOGO',
  roundHistoryDescGame: 'Jogo',
  roundHistoryDescCrashAt: 'Travou em:',
  roundHistoryDescDate: 'Data :',
  roundHistoryDescBetHeader: 'Aposta',
  roundHistoryTableTitlePlayer: 'JOGADOR',
  roundHistoryTableTitleBet: 'APOSTA',
  roundHistoryTableTitleCashedOut: 'SAQUEADO',
  roundHistoryTableTitleProfit: 'LUCRO',
  roundHistoryTableTitleBetId: 'ID DA APOSTA',
  roundHistoryMessagesNoBetHistory: 'Seu histórico de apostas está vazio',
  placedBetsTabPlaced: 'Aposta colocada',
  placedBetsTabPrev: 'Aposta Anterior',
  placedBetsTitleUser: 'Do utilizador',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Aposta',
  placedBetsTitleProfit: 'Lucro',
  placedBetsTitleCashout: 'Sacar',
  placedBetsNoPlacedBet: 'Nenhuma aposta feita.',
  provablyFairContentCheckBtn: 'Verifique a feira comprovável',
  provablyFairContentTitle: 'Provavelmente Justo',
  provablyFairContentDesc: 'O resultado da liderança de quatro lados pode ser comprovado como justo. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Taxa de falhas',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Taxa de falhas sem x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hash redondo',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'por exemplo. ',
  provablyFairContentInputFieldsSubmitBtn: 'Gerar assinatura',
  provablyFairContentSignature: 'Assinatura Redonda',
  provablyFairContentErrorsCrashRate: 'insira o número no campo de taxa de falhas',
  resetPasswordTitle: 'Redefinir senha',
  resetPasswordNewPasswordTitle: 'Nova Senha',
  resetPasswordNewPasswordErrorsRequired: '*Senha requerida.',
  resetPasswordNewPasswordErrorsMinLength: 'Mínimo 8 caracteres permitidos.',
  resetPasswordNewPasswordErrorsMaxLength: 'Máximo de 16 caracteres permitidos.',
  resetPasswordNewPasswordErrorsPattern: '*A senha deve ter de 8 a 16 caracteres alfanuméricos e especiais. ',
  resetPasswordConfirmPasswordTitle: 'Confirme sua senha',
  resetPasswordConfirmPasswordErrorsNotMatched: 'As senhas não coincidem.',
  resetPasswordResetBtn: 'Reiniciar',
  passwordResetSuccess: 'Senha alterada com sucesso, faça login para aproveitar o jogo!',
  resetPasswordWentWrong: 'Algo deu errado!',
  levelGrand: 'GRANDE',
  levelMajor: 'PRINCIPAL',
  levelMinor: 'MENOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Ganhe diariamente $ 1000 ',
  bannerContentContest: 'Concursos agora ',
  bannerContentjoin: 'Participe do nosso concurso diário de apostas e torne-se o Herói!  ',
  bannerContentPlayNow: 'Jogue agora',
  bannerContentTitle: 'Ganhe Jackpot',
  bannerContentContent: 'Jogue mais',
  bannerContentGameName: 'JOGO DE CRASH',
  bannerContentGameDesc: 'Jogue mais',
  partners: 'Parceiros',
  gamesGoldenDragon: 'Dragão dourado',
  gamesCrashGame: 'Jogo de colisão',
  gamesDice: 'Dados',
  gamesHilo: 'Olá',
  allBetsTitle: 'Todas as apostas',
  allBetsBetId: 'ID da aposta',
  allBetsUser: 'Do utilizador',
  allBetsPayout: 'Pagamento',
  allBetsAmount: 'Quantia',
  allBetsTime: 'Tempo',
  allBetsProfit: 'Lucro',
  LoginwithOtp: 'Faça login com Otp',
  EnterLoginOtp: 'Digite Login Otp',
  submit: 'Enviar',
  footerAboutUs: 'Sobre nós',
  footerFaq: 'Perguntas frequentes',
  footerSitePolicy: 'Política do Site',
  footerResponsibleGambling: 'Jogo Responsável',
  footerTerms: 'Termos e Condições',
  signinTitle: 'Conecte-se',
  signinUsername: 'Nome de usuário/e-mail',
  signinPassword: 'Senha',
  signinLoginBtn: 'Conecte-se',
  signinSignup: 'Inscrever-se!',
  signinForgetPassword: 'Esqueceu sua senha?',
  forgotPasswordMsg: 'Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.',
  emailLinkMsg: 'O link de redefinição de senha foi enviado para seu e-mail de registro. Clique no link do e-mail e defina sua nova senha.',
  resetPasswardConfirm: 'A senha foi redefinida com sucesso',
  signinDontHaveAccount: 'Não tem uma conta? ',
  signinCreateAccount: 'Criar uma conta',
  signinErrorsUserNameRequired: '*Por favor, insira o nome de usuário/e-mail.',
  signinErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signinErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  signinErrorsUserNamePattern: 'Por favor insira um nome de usuário válido, apenas letras e números são permitidos.',
  signinErrorsPasswordRequired: '*Senha requerida.',
  signinErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signinErrorsPasswordMaxLength: 'Máximo de 16 caracteres permitidos.',
  signinErrorsPasswordPattern: '*A senha deve ter de 8 a 16 caracteres alfanuméricos e especiais. ',
  signinSuccessfullyLoggedIn: 'Login realizado com sucesso.',
  signinResetMailSent: 'E-mail de redefinição de senha enviado.',
  signinTryAgain: 'Alguma coisa deu errado. Por favor tente outra vez.',
  signinResetPasswordUsernameErr: 'Por favor, digite o nome de usuário/e-mail para redefinir a senha.',
  signinResetMailSentSuccessContent: 'Enviamos a você um link de redefinição de senha em seu e-mail. ',
  signupTitle: 'Registro',
  signupUsername: 'Nome de usuário',
  signupEmail: 'E-mail',
  signupTermsConditions: 'Termos',
  signupWalletConnect: 'Faça login com carteira ',
  signupPassword: 'Senha',
  signupEighteenPlus: 'Tenho mais de 18 anos e concordo com os Termos de Serviço',
  signupRegisterBtn: 'Inscrever-se',
  signupCreateNewAccount: 'Criar nova conta',
  signupFullName: 'Nome completo',
  signupReferralCode: 'Código de Referencia',
  signupConfirmPassword: 'Confirme sua senha',
  signupUseSocialAccount: 'Faça login através das redes sociais',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Ter uma conta? ',
  signupLoginBtn: 'Conecte-se',
  signupErrorsUserNameRequired: '*Por favor, insira o nome de usuário.',
  signupErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  signupErrorsUserNamePattern: 'Por favor, insira um nome de usuário válido, apenas letras e números são permitidos.',
  signupErrorsFullNameRequired: '*Insira o nome completo.',
  signupErrorsFullNamePattern: 'Por favor insira um nome completo válido, apenas letras e números são permitidos.',
  signupErrorsEmailRequired: '*Por favor, insira o e-mail.',
  signupErrorsEmailMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsEmailMaxLength: 'Máximo de 20 caracteres permitidos.',
  signupErrorsEmailPattern: 'Por favor insira um e-mail válido.',
  signupErrorsPasswordRequired: '*Senha requerida.',
  signupErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signupErrorsPasswordMaxLength: 'Depósito máximo de 16 caracteres permitidos.',
  signupErrorsPasswordPattern: '*A senha deverá ter de 8 a 16 dígitos alfanuméricos (maiúsculas e minúsculas) e caracteres especiais. ',
  signupErrorsConfirmPasswordMatch: 'A senha deve corresponder.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'por exemplo. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Algo deu errado!',
  signupSignupGoogle: 'Inscreva-se com sucesso no Google.',
  signupRegistered: 'Registrado com sucesso.',
  signupConfirmAge: 'Por favor, confirme que você tem 18 anos.',
  signupSignupFacebook: 'Cadastre-se com sucesso no Facebook.',
  signupCookiesConsent: 'Permita que cookies de terceiros nas configurações do seu navegador usem o login social.',
  signupRegisterEmailPopupContent: 'Enviamos a você um e-mail de verificação. ',
  accountMenuDeposit: 'Depósito',
  accountMenuCashout: 'Sacar',
  accountMenujackpotWinners: 'Vencedores do jackpot',
  accountMenuTransactions: 'Transações',
  accountMenuPromotions: 'Promoções',
  accountMenuProfile: 'Perfil',
  accountMenuLogout: 'Sair',
  accountMenuSound: 'Som',
  accountMenuMusic: 'Música',
  accountMenuWithdraw: 'Retirar',
  pageNotFound: 'Não encontramos a página que você procura!',
  error: 'Erro',
  somethingWentWrong: 'Algo deu errado!',
  verifyEmailThankYou: 'Obrigado!',
  verifyEmailEmailIsVerified: 'Seu e-mail foi verificado. ',
  verifyEmailGoToLogin: 'Vá para a página de login',
  verifyEmailWentWrong: 'Algo deu errado!',
  verifyEmailTokenExpired: 'E-mail já verificado ou link expirado, reenvie o link de verificação por e-mail, fornecendo seu e-mail registrado abaixo. ',
  verifyEmailGoToHome: 'Vá para a página inicial',
  verifyEmailSuccessMsg: 'E-mail verificado com sucesso.',
  bonusSectionSpanText: 'apresenta os mais generosos e variados',
  bonusSectionBonuses: 'Bônus',
  bonusSectionSubtitleText: 'Jogue com prazer e ganhe recompensas adicionais durante o jogo. ',
  JoiningBonusTitle: 'Bônus de adesão',
  JoiningBonusButton: 'Reivindique agora',
  JoiningBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  depositBonusTitle: 'Bônus de Depósito',
  depositBonusButton: 'Depósito',
  depositBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  refferBonusTitle: 'Bônus de indicação',
  refferBonusButton: 'Depósito',
  refferBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  aboutSectionSpanText2: 'O cassino online permite que você jogue seus jogos favoritos gratuitamente ou com criptomoeda. ',
  aboutSectionSpanText3: ' Neste mundo virtual de jogos de azar com Bitcoin, você pode aproveitar muitas oportunidades – jogar seus jogos de cassino favoritos, receber bônus, participar de concursos e promoções.',
  verifiedSectionTitle: 'Software verificado e licenciado',
  verifiedSectionSpanText1: 'Não copiamos nem adotamos software de outros sites. ',
  faqSectionTitle: 'Perguntas frequentes dos jogadores',
  faqSectionForgetPasswordTitle: 'Esqueci a minha senha.',
  faqSectionForgetPasswordDesc: 'Você não forneceu um endereço de e-mail de recuperação para sua conta. ',
  faqSectionMyDepositCreditedTitle: 'Meu depósito não foi creditado.',
  faqSectionMyDepositCreditedDesc: ' Conteúdo de espaço reservado para este acordeão, que se destina a demonstrar a aula. ',
  SupportedCurrencyTitle: 'Moeda Suportada',
  SupportedCurrencySpanText1: 'Não copiamos nem adotamos software de outros sites. ',
  CarouselSectionTitle: 'QUEDA DE HODL',
  CarouselSectionSubTitle: ' Jogue mais jogos e tenha a chance de ganhar. ',
  WalletSettingTitle: 'Configuração de carteira',
  WalletSettingHideZero: 'Ocultar saldo zero',
  WalletSettingHideZeroInfo: 'Seu saldo zero não aparecerá em sua carteira',
  WalletSettingDisplayFiat: 'Exibir criptografia em moeda fiduciária',
  WalletSettingDisplayFiatInfo: 'Todas as apostas',
  WalletSettingNoteForApprox: 'Observe que estas são aproximações de moeda.',
  WalletSettingSave: 'Salvar',
  WalletSettingNoWalletFound: 'Nenhuma carteira encontrada',
  jackpotWinnersNoWinnerAvailable: 'Nenhum vencedor disponível',
  jackpotWinnersNew: 'Novo',
  jackpotWinnersHeader: 'Vencedor do jackpot',
  jackpotDetailsHeader: 'Detalhe do jackpot',
  jackpotDetailsCampaignPeriod: 'Período da campanha',
  jackpotDetailsFrom: 'De',
  jackpotDetailsTo: 'Para',
  jackpotDetailsBettingRule: 'Regra de apostas',
  jackpotDetailsMinBetAmount: 'Valor mínimo da aposta',
  jackpotDetailsMaxBetAmount: 'Valor máximo da aposta',
  jackpotDetailsBetPercentage: 'Porcentagem de aposta',
  jackpotDetailsWinningRuleHeader: 'Regra vencedora',
  jackpotDetailsWinningRule: 'Fazer apostas com valores maiores aumentará as chances de ganhar.',
  jackpotDetailsProfitRule: 'Se o valor da aposta estiver entre minBetAmount e maxBetAmount, então betPercentage% do valor da aposta será adicionado ao valor do jackpot.',
  profileTitle: 'Detalhes pessoais',
  profileFName: 'Primeiro nome',
  profileLname: 'Sobrenome',
  profileUserName: 'Nome de usuário',
  profileDob: 'Data de nascimento',
  profileEmail: 'Endereço de email',
  profilePhone: 'Número de telefone',
  profileChange: 'Mudar',
  profileSave: 'Salvar as mudanças',
  profilePassword: 'Senha',
  profileSubmit: 'Enviar',
  profileUploadProfile: 'Carregar perfil',
  profileCurrentPassword: 'Senha atual',
  profileNewPassword: 'Nova Senha',
  profileConfirmPassword: 'Confirme sua senha',
  profileScanQRCode: 'Digitalize o código QR',
  profileEnterSecretCode: 'Digite o código secreto',
  profileErrorsFirstNameRequired: 'O primeiro nome é necessário.',
  profileErrorsFirstNamePattern: 'Insira um nome válido.',
  profileErrorsFirstNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsFirstNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsLastNameRequired: 'O sobrenome é obrigatório.',
  profileErrorsLastNamePattern: 'Insira um nome válido.',
  profileErrorsLastNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsLastNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsChangePasswordOldRequired: 'É necessária uma senha antiga.',
  profileErrorsChangePasswordOldPattern: 'Por favor insira uma senha válida.',
  profileErrorsChangePasswordOldMinLength: 'Mínimo 8 caracteres permitidos.',
  profileErrorsChangePasswordOldMaxLength: 'Máximo de 16 caracteres permitidos.',
  profileErrorsChangePasswordNewRequired: 'É necessária uma nova senha.',
  profileErrorsChangePasswordNewPattern: 'Por favor insira uma senha válida.',
  profileErrorsChangePasswordNewMinLength: 'Mínimo 8 caracteres permitidos.',
  profileErrorsChangePasswordNewMaxLength: 'Máximo de 16 caracteres permitidos.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'As senhas não coincidem.',
  profileErrorsChangePasswordNewPasswordMatched: 'A nova senha não pode ser igual à senha atual.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'A confirmação da senha é necessária.',
  profileErrorsUserNameRequired: 'Nome de usuário é requerido.',
  profileErrorsUserNamePattern: 'Insira um nome válido.',
  profileErrorsUserNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsDobRequired: 'A data de nascimento é obrigatória.',
  profileErrorsEmailRequired: 'O e-mail é obrigatório.',
  profileErrorsPostcodeRequired: 'O código postal é obrigatório.',
  profileErrorsCityRequired: 'A cidade é necessária.',
  profileErrorsCurrencyRequired: 'A moeda é obrigatória.',
  profileErrorsAddressRequired: 'Endereço é necessário.',
  profileErrorsCountryRequired: 'O país é obrigatório.',
  profileErrorsEmailPattern: 'Por favor insira um e-mail válido.',
  profileErrorsEmailMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsEmailMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileMessagesProfileUpdate: 'Perfil atualizado com sucesso.',
  profileMessagesChangePassword: 'Senha atualizada com sucesso.',
  profileProfilePicProfilePicUpload: 'Carregar imagem do perfil',
  profileNoNewChanges: 'Não foi possível atualizar o perfil! ',
  profileLoggedInWithSocial: 'Você fez login com login social.',
  profileSideNavigatorSound: 'Som',
  profileSideNavigatorMusic: 'Música',
  profileSideNavigatorProfile: 'Meu perfil',
  profileSideNavigatorBetHistory: 'Histórico de apostas',
  profileSideNavigatorTransactions: 'Transações',
  profileSideNavigatorDeposit: 'Depósito',
  profileSideNavigatorWithdraw: 'Retirar',
  profileSideNavigatorGameLimits: 'Limites do jogo',
  profileSideNavigatorReferral: 'Referência',
  profileSideNavigatorChangePassword: 'Alterar a senha',
  profileSideNavigatorTermsConditions: 'Termos',
  profileSideNavigatorLogOut: 'Sair',
  profileSideNavigatorProvablyFair: 'Provavelmente Justo',
  WalletInfoSectionNotAvailable: 'Não disponível',
  WalletInfoSectionRealBalance: 'Saldo Real',
  GameLimitsMinimumBet: 'Aposta mínima',
  GameLimitsMaximumBet: 'Aposta máxima',
  GameLimitsMaxWinFor1Bet: 'Vitória máxima para uma aposta',
  ProvablyFairSubTitle: 'Este jogo usa a tecnologia Provably Fair para determinar o resultado do jogo. ',
  ProvablyFairMaximumBet: 'Aposta máxima:',
  ProvablyFairMaxWinFor1Bet: 'Vitória máxima para uma aposta',
  promotionsTitle: 'Promoções',
  promotionsNoLosingData: 'Desculpe, promoções de bônus sem perda disponíveis para ',
  promotionsNoDepositData: 'Desculpe, promoções de bônus sem depósito disponíveis para ',
  promotionsCurrency: ' moeda.',
  promotionsViewBtn: 'Visualizar',
  promotionsClaimSuccess: 'Você reivindicou com sucesso',
  promotionsAvailability: 'Disponibilidade: ',
  promotionsAvailabilityTabLosing: 'Perdendo bônus',
  promotionsAvailabilityTabDeposit: 'Bônus de depósito',
  promotionsDepositBonusTableCode: 'Código',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Percentagem',
  promotionsDepositBonusTableMaxBonus: 'Bônus máximo',
  promotionsDepositBonusTableRolloverMultipler: 'Multiplicador de rollover',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover máximo por aposta',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Hora de atingir a meta de rollover',
  promotionsBonusCode: 'CÓDIGO BÔNUS: ',
  promotionsLossesClaim: 'As perdas podem ser reivindicadas (dentro do período da campanha): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentagem',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Status do bônus: ',
  promotionsBonusAmount: 'Valor do bônus: ',
  promotionsRolloverTarget: 'Meta de rolagem: ',
  promotionsRolloverAchieved: 'Rollover alcançado: ',
  promotionsActivatebtn: 'ATIVAR',
  promotionsClaimBtn: 'ALEGAR',
  promotionsCancelBtn: 'CANCELAR',
  promotionsBackBtn: 'VOLTAR',
  promotionsBonus: 'Bônus',
  promotionsTermAndConditions: ' Termos',
  promotionsClaimDepositBonusTitle: 'Passos para reivindicar bônus de depósito',
  promotionsClaimDepositBonusFirst: 'Ativar código bônus',
  promotionsClaimDepositBonusSecond: 'Valor do depósito na carteira',
  promotionsClaimDepositBonusThird: 'Alcançar a meta de rollover para reivindicar bônus',
  promotionsClaimLosingBonusTitle: 'Passos para reivindicar bônus perdidos',
  promotionsClaimLosingBonusFirst: 'Ativar código bônus',
  promotionsClaimLosingBonusSecond: 'Jogue seus jogos favoritos',
  promotionsClaimLosingBonusThird: "Clique em 'Reivindicar perdas' para reivindicar perdas dentro do período da campanha",
  promotionsWentWrong: 'Algo deu errado!',
  transactionTitle: 'Transações',
  transactionBetId: 'ID da aposta',
  transactionUser: 'Do utilizador',
  transactionPayout: 'Pagamento',
  transactionAmount: 'Quantia',
  transactionProfit: 'Lucro',
  transactionCashout: 'Sacar',
  transactionBet: 'Aposta',
  transactionMultix: 'Multi.x',
  transactionWin: 'Ganhar',
  transactionFairness: 'Justiça',
  transactionReferFriend: 'Indique um Amigo',
  transactionTotal: 'Apostas totais',
  transactionWins: 'Vitórias',
  transactionRefresh: 'Atualizar',
  transactionFilterTopMultipliers: 'Principais multiplicadores',
  transactionFilterHugeWins: 'Grandes vitórias',
  transactionFilterBiggestWins: 'Maiores vitórias',
  transactionFilterMultipliers: 'Multiplicadores',
  transactionRealBalance: 'Saldo Real',
  depositWithdrawTXDate: 'Data',
  depositWithdrawTXAmount: 'Quantia',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Tipo de transação',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Não há dados disponíveis.',
  depositTitle: 'Depósito',
  depositHistory: 'História',
  depositSubTitle: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  depositCopyNotSupported: 'Seu navegador não suporta cópia automática para a área de transferência. Tente copiar o código manualmente.',
  depositCopied: 'Endereço copiado.',
  depositAddressCreated: 'Endereço criado com sucesso.',
  depositGenerateAddress: 'Gerar endereço',
  depositWarningText: 'Por favor, NÃO deposite via transferência entre cadeias',
  withdrawTitle: 'Retirar',
  withdrawSubTitle: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  withdrawNoteDesc: 'Certifique-se de NÃO inserir o endereço BEP2, BEP20 (BSC) como endereço de retirada de carteiras LTC',
  withdrawNoteSublist: ' Por favor, NÃO retire como transferência entre cadeias',
  withdrawHistory: 'História',
  withdrawWithdrawBtn: 'Solicitação de retirada',
  withdrawFees: 'Taxa de retirada {{fees}} {{token}}',
  withdrawWithdrawPending: 'Seu pedido de saque está pendente. ',
  withdrawEnterFields: 'Insira o valor da retirada e o endereço da sua carteira.',
  withdrawSelectWallet: 'Selecione a carteira para sacar.',
  withdrawAmountFieldTitle: 'Quantidade (mín. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Seu {{wallet.currency.code}} endereço',
  withdrawAddressFieldPlaceholder: 'Digite seu endereço',
  withdrawNoBalance: 'Não há equilíbrio suficiente.',
  withdrawMinAmount: 'Insira um valor maior ou igual a {{min}} .',
  fiatCurrencyInfo: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  paginationNextBtn: 'Próximo',
  paginationPrevBtn: 'Anterior',
  wentWrong: 'Algo deu errado.',
  referralTitle: 'Referência',
  referralSubTitle: 'Indique seus amigos',
  referralEarn: ' Ganhe ₹ 150 cada',
  referralReferAndEarn: 'Indique e ganhe bônus por cada indicação',
  referralReferPara: ' Se você encaminhar este código para qualquer usuário',
  referralReferLink: 'Consulte Link',
  referralCopyReferralCode: 'Copiar código de referência',
  referralTotalRewards: 'Recompensa total',
  TwoWayAuthentication: 'Autenticação bidirecional',
  LoginRequired: 'Login necessário!',
  BetPlacedSuccessfully: 'Aposta realizada com sucesso',
  BetAddedInQueueSuccessfully: 'Aposta adicionada na fila com sucesso',
  BetRemovedFromQueueSuccessfully: 'Aposta removida da fila com sucesso',
  BetCancelledSuccessfully: 'Aposta cancelada com sucesso',
  PlayerEscapedSuccessfully: 'Jogador escapou com sucesso',
  CopiedSuccessfull: 'Copiado com sucesso',
  NotEnoughBalance: 'Equilíbrio insuficiente',
  AutoBetStarted: 'Aposta automática iniciada ',
  AutoBetFinished: 'Aposta automática concluída ',
  IdCopied: 'Eu copiei',
  usersConsent: 'Ao acessar este site, confirmo que tenho 18 anos de idade e que li, entendi e consenti com os',
  termsOfService: 'Termos de serviço',
  email: 'E-mail',
  playNow: 'Jogue agora',
  orContinueWith: 'Ou continue com',
  metamaskExtensionNotAvailable: 'Instale a extensão metaMask e atualize a página.',
  cashier: 'CAIXA',
  account: 'CONTA',
  hello: 'Olá',
  balance: 'Equilíbrio',
  referrals: 'Referências',
  settings: 'Configurações',
  withdrawals: 'Retiradas',
  demoWallet: 'Carteira de demonstração',
  myAccount: 'Minha conta',
  wallet: 'Carteira',
  user: 'Do utilizador',
  USD: 'USD',
  amountIsRequired: 'O valor é obrigatório',
  minDepositAmount: 'O valor do depósito deve ser maior que {{min}}.',
  maxDepositAmount: 'O valor do depósito deve ser inferior a {{max}}.',
  minWithdrawAmount: 'O valor da retirada deve ser maior que {{min}}',
  maxWithdrawAmount: 'O valor do saque deve ser menor que {{max}}',
  withdrawMustLessThanWallet: 'O valor do saque deve ser menor que o valor da sua carteira',
  accountTypeIsRequired: 'O tipo de conta é obrigatório.',
  mobileNumberLength: 'O número do celular deve ser de {{length}} dígitos',
  phoneIsRequired: 'O telefone é obrigatório',
  cpfError: 'O CPF deve ser de {{length}} personagens',
  cpfRequiredError: 'CPF é obrigatório',
  cnpjError: 'O CNPJ deve ser de {{length}} personagens',
  mainMenu: 'MENU PRINCIPAL',
  casino: 'Cassino',
  sports: 'Esportes',
  crypto: 'Criptografia',
  NFT: 'NFT',
  poker: 'Pôquer',
  earnRewards: 'GANHE RECOMPENSAS',
  rankUp: 'Promover',
  inviteFriends: 'Convide amigos',
  partnerProgram: 'Programa de parceria',
  home: 'Lar',
  sportsbook: 'Apostas esportivas',
  menu: 'Cardápio',
  liveBets: 'APOSTAS AO VIVO',
  allBets: 'Todas as apostas',
  highRollers: 'Grandes apostadores',
  luckyBets: 'Apostas de sorte',
  trade: 'Troca',
  game: 'JOGO',
  player: 'JOGADOR',
  time: 'TEMPO',
  wager: 'APOSTA',
  multiplierBan: 'MULTIPLICADOR',
  payoutText: 'PAGAMENTO',
  bannerHeadOne: 'Experimente a verdadeira inovação com o mais alto',
  bannerHeadTwo: 'programa de recompensas dentro do setor.',
  registerNow: 'Registrar agora',
  liveWins: 'VITÓRIAS AO VIVO',
  monthly: 'Mensalmente',
  weekly: 'Semanalmente',
  daily: 'Diariamente',
  winner1: 'Vencedor 1',
  winner2: 'Vencedor 2',
  winner3: 'Vencedor 3',
  spin: 'Rodar',
  spinsWon: 'Rodadas Ganhas',
  joinedOn: 'Entrou em',
  wageringAmount: 'Valor Apostado',
  name: 'Nome',
  allGames: 'Todos os jogos',
  allSports: 'Todos os esportes',
  cryptoFutures: 'futuros de criptografia',
  tradeNow: 'Negocie agora',
  allTables: 'Todas as tabelas',
  nft: 'NFT',
  viewAllGames: 'Ver todos os jogos',
  viewAll: 'Ver tudo',
  rewards: 'RECOMPENSAS',
  profile: 'Perfil',
  totalBets: 'Apostas totais',
  totalWagered: 'Total apostado',
  joinDate: 'Data de afiliação',
  displaySocialLinks: 'Exibir contas sociais vinculadas',
  privateProfile: 'Perfil Privado',
  rank: 'Classificação',
  winner: 'Vencedores',
  nextRank: 'Próxima classificação',
  changeUsername: 'Mudar nome de usuário',
  newPassword: 'Nova Senha',
  changeName: 'Mude o nome',
  linkSocialAccount: 'Vincular conta social',
  privateProfileTooltip: 'Depois de tornar seu perfil privado, outros usuários não verão seu nome de usuário junto com apostas, corridas, etc.',
  socialLinksTooltip: 'Conteúdo não disponível',
  rankTooltip: 'Conteúdo não disponível',
  balances: 'saldos',
  createReferralBalance: 'Criar saldo de referência',
  total: 'Total',
  switchBalance: 'Alternar equilíbrio',
  mainAccount: 'Conta principal',
  tips: 'Pontas',
  receivedAmount: 'Valor recebido',
  dateOrTime: 'Data hora',
  view: 'Visualizar',
  allChains: 'Todas as cadeias',
  creditAmount: 'Quantidade de crédito',
  setCustomReferralCode: 'Definir código de referência personalizado',
  changeEmail: 'Mude o e-mail',
  newEmail: 'Novo Email',
  createPassword: 'Criar senha',
  newUsername: 'Novo usuário',
  username: 'Nome de usuário',
  changePassword: 'Alterar a senha',
  updateProfileMsg: 'Perfil atualizado com sucesso',
  twoFactorAuthentication: 'autenticação de dois fatores',
  twoFactorAuthenticationTitle: 'O uso da autenticação de dois fatores é altamente recomendado porque protege sua conta com sua senha e seu telefone.',
  twoFactorAuthenticationSubTitle: 'Um e-mail e uma senha devem ser definidos para ativar o 2FA. ',
  enable2fa: 'ATIVAR 2FA',
  verifyIdentityByKyc: 'Verifique sua identidade (kyc)',
  oldPassword: 'Senha Antiga',
  live: 'Ao vivo',
  soccer: 'Futebol',
  tennis: 'tênis',
  fifa: 'Fifa',
  basketball: 'Basquetebol',
  iceHockey: 'Gelo Hokey',
  volleyball: 'Voleibol',
  tableTennis: 'Tênis de mesa',
  baseball: 'Beisebol',
  rubgy: 'Rúgbi',
  golf: 'Golfe',
  boxing: 'Boxe',
  myBets: 'Minhas apostas',
  favourites: 'Favoritos',
  americanFootball: 'futebol americano',
  buyCrypto: 'Compre criptografia',
  oldEmail: 'E-mail velho',
  loggedInSuccess: 'Conectado com sucesso',
  loggedOutSuccess: 'Desconectado com sucesso',
  wagerLimit: 'Limite de aposta definido com sucesso',
  lossLimit: 'Limite de perda definido com sucesso',
  accountDisable: 'Conta desativada com sucesso',
  sessionTimeout: 'Tempo limite da sessão definido com sucesso',
  signup: 'Inscrição com sucesso',
  depositLimit: 'Limite de depósito definido com sucesso',
  tokenVerify: 'O token foi enviado para seu e-mail, verifique',
  emailVerify: 'O e-mail foi verificado',
  emailChangedSuccess: 'O e-mail foi alterado com sucesso',
  profileUpdatedSuccess: 'perfil atualizado',
  otpVerified: 'OTP verificado',
  twoFADeactivated: 'Autenticação 2FA desativada',
  internalServerError: 'Erro do Servidor Interno',
  unAuthorized: 'Não autorizado',
  dataNotFound: 'Dados não encontrados',
  setReferralCode: 'Definir código de referência...',
  ultimate: 'FINAL',
  notFound: 'Não encontrado',
  casinoTransactions: 'Transações de Cassino',
  sportsbookTransactions: 'Transações de apostas esportivas',
  status: 'Status',
  success: 'Sucesso',
  failed: 'Fracassado',
  pending: 'Pendente',
  date: 'Data',
  to: 'Para',
  gameName: 'Nome do jogo',
  transactionId: 'ID da transação',
  roundId: 'ID da rodada',
  bet: 'Aposta',
  win: 'Ganhar',
  transactionType: 'Tipo de transação',
  at: 'no',
  downloadAll: 'Baixar tudo',
  gameText: 'Jogo',
  betId: 'ID da aposta',
  gameId: 'ID do jogo',
  actionType: 'Tipo de acão',
  searchGames: 'Pesquisar jogos',
  provider: 'Provedores',
  search: 'Procurar',
  sortBy: 'Ordenar por',
  popular: 'Popular',
  challengePool: 'Conjunto de Desafios',
  loadMore: 'Carregue mais',
  bitcoin: 'Bitcoin',
  chat: 'Bater papo',
  trades: 'Negociações',
  battles: 'Batalhas',
  loginToChat: 'Faça login para conversar',
  rules: 'Regras',
  chatRule1: 'Não assedie ou insulte outros usuários',
  chatRule2: 'Não implore, peça empréstimos, curiosidades ou gorjetas',
  chatRule5: 'Não anuncie qualquer forma de negociação, compra ou venda de serviços',
  chatRule6: 'Não compartilhe ou anuncie seu código de referência',
  chatRule7: 'Não peça para se tornar um membro da equipe',
  chatRule8: 'apenas inglês',
  chatRule9: 'Respeite mods, administradores e outros usuários',
  send: 'Enviar',
  expand: 'Expandir',
  signIn: 'Entrar',
  cross: 'Cruzar',
  collapse: 'Colapso',
  emailPlaceholder: 'seuemail@domínio.com',
  removeFromFavourite: 'Remova dos favoritos',
  addToFavourite: 'Adicionar aos favoritos',
  footerAboutSite: `Vamos falar um pouco sobre ciência? O site do Cash Machine Casino faz parte de você,
pois todos nós somos feitos dos mesmos átomos. Sua interação com ele confirma essa
conexão.`,
  footerRightsReserved: 'Сash Machine Casino todos os direitos reservados',
  signupFirstName: 'Primeiro nome',
  signupAddress: 'Endereço',
  signupCity: 'Cidade',
  signupPostcode: 'Código postal ',
  signupCounty: 'País',
  signupCurrency: 'Moeda',
  signupGender: 'Gênero',
  signupMan: 'Homem',
  signupWomen: 'Mulheres',
  signupOther: 'Outro',
  signupLoginDetails: 'Login em detalhes',
  signupPersonalDetails: 'Detalhes pessoais',
  signupConfirm: 'confirme',
  signupPrivacyPolicy: 'Li e aceito a Política de Privacidade',
  signupTermAndConditions: 'Concordo com o tratamento dos meus dados pessoais. ',
  signupNewsLetter: 'Desejo receber bônus, promoções, novidades ou outras informações relevantes periodicamente por News Letter.',
  signupSms: 'Desejo receber bônus, promoções, novidades ou outras informações relevantes periodicamente por SMS.',
  initialHeaderContent: 'Obtenha incríveis 100%',
  secoundaryHeaderContent: 'Bônus de boas-vindas de até $ 100',
  playNowButton: 'Jogue agora',
  registerButtonContent: 'Registro',
  results: 'Resultados',
  refresh: 'Atualizar',
  cancel: 'Cancelar',
  select: 'Selecione',
  current: 'Atual',
  set: 'Definir',
  remove: 'Remover',
  save: 'Salvar',
  not: 'não',
  before: 'Antes',
  after: 'Depois',
  action: 'Ação',
  withdrawal: 'Cancelamento',
  requested: 'Requeridos',
  withdraw: 'Retirar',
  areYouSure: 'Tem certeza',
  yes: 'Sim',
  no: 'Não',
  cancelled: 'Cancelado',
  approved: 'Aprovado',
  refunded: 'Devolveu',
  chargeback: 'Estorno',
  processedOn: 'Processado em',
  transaction: 'Transação',
  dateTime: 'Data hora',
  more: 'Mais',
  fromDate: 'Da data',
  toDate: 'A data',
  percentage: 'Percentagem',
  info: 'Informações',
  min: 'Mínimo',
  max: 'Máx.',
  change: 'Mudar',
  continueToSite: 'Continuar para o site',
  choose: 'Escolher',
  enterWithdrawalAmount: 'Escolha ou insira seu valor de retirada',
  capDeposit: 'DEPÓSITO',
  capWithdrawal: 'CANCELAMENTO',
  capAmount: 'QUANTIA',
  minimum: 'Mínimo',
  required: 'Obrigatório',
  of: 'de',
  isRequired: 'é necessário',
  isAllowed: 'é permitido',
  method: 'Método',
  capWithdraw: 'RETIRAR',
  showLess: 'Mostre menos',
  clearFilter: 'Filtro limpo',
  quantity: 'Quantidade',
  reset: 'Reiniciar',
  incorrect: 'Incorreta',
  load: 'Carregar',
  applied: 'Aplicado',
  ok: 'OK',
  list: 'Lista',
  all: 'Todos',
  favorite: 'Favorito',
  endPointNotFound: 'Algo está errado com a conexão de rede',
  category: 'Categoria',
  emailUserNameRequired: 'E-mail/nome de usuário obrigatório',
  emailUserNamePlaceHolder: 'Digite seu e-mail ou nome de usuário',
  passwordRequired: 'Senha requerida',
  accountFrozen: 'Sua conta está congelada até',
  resendEmail: 'Reenviar email',
  resendLink: 'REENVIAR LINK',
  userLoggedOut: 'Desconectado com sucesso',
  emailAlreadyRegistered: 'Este e-mail já está registado',
  userNameAlreadyTaken: 'Este nome de usuário já está em uso',
  fillAllFields: 'Preencha todos os campos antes de passar para a próxima etapa',
  pleaseAccept: 'Por favor aceite',
  acceptPrivacyPolicy: 'política de Privacidade',
  acceptTerms: 'Termos e Condições',
  modeOfComm: ' e modo de comunicação',
  beforeNextStep: 'antes de passar para a próxima etapa',
  userNamePlaceholder: 'Insira nome de usuário',
  min8Characters: 'Mínimo 8 caracteres',
  min1Number: 'Número mínimo 1',
  min1LowerCase: 'Mínimo de 1 caractere minúsculo',
  min1UpperCase: 'Mínimo de 1 caractere maiúsculo',
  min1SpecialChar: 'Mínimo de 1 caractere especial',
  passwordTip: 'A senha deve ter pelo menos oito caracteres com pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
  passwordPlaceholder: 'Digite a senha',
  confirmPasswordPlaceholder: 'Digite Confirmar Senha',
  acceptAll: 'Aceitar tudo',
  firstNamePlaceholder: 'Introduza o primeiro nome',
  lastNamePlaceholder: 'Insira o último nome',
  addressPlaceholder: 'Insira o endereço',
  cityPlaceholder: 'Digite a cidade',
  postCodePlaceholder: 'Insira o código postal',
  phonePlaceholder: 'Digite o número de telefone',
  invalidEmail: 'E-mail inválido',
  emailRequired: 'Email (obrigatório',
  confirmPasswordRequired: 'Confirmar senha obrigatória',
  firstNameRequired: 'Primeiro nome (necessário',
  lastNameRequired: 'Sobrenome obrigatório',
  phoneRequired: 'Telefone obrigatório',
  dateOfBirthRequired: 'Data de nascimento obrigatória',
  genderRequired: 'Sexo obrigatório',
  userNameRequired: 'Nome de usuário (necessário',
  addressRequired: 'Endereço obrigatório',
  cityRequired: 'Cidade obrigatória',
  postCodeRequired: 'Código postal obrigatório',
  currencyCodeRequired: 'Código de moeda obrigatório',
  countryRequired: 'País obrigatório',
  matchPassword: 'A senha deve corresponder',
  fName3Chars: 'O nome deve ter pelo menos 3 caracteres',
  onlyAlphabet: 'Somente alfabetos permitidos',
  lName3Chars: 'O sobrenome deve ter pelo menos 3 caracteres',
  mustBe18: 'Você deve ter 18 anos para se registrar',
  mustBeUnder200: 'Você deve ter menos de 200 anos para se registrar',
  validDOB: 'Por favor, insira uma data de nascimento válida',
  max20Characters: 'Máximo de 20 caracteres permitidos',
  min3Characters: 'Mínimo de 3 caracteres necessários',
  max100Characters: 'Máximo de 100 caracteres permitidos',
  max50Characters: 'Máximo de 50 caracteres permitidos',
  invalidNumber: 'Número inválido',
  preferredLanguageRequired: 'Idioma preferido obrigatório',
  invalidPass: 'senha inválida',
  prevBtn: 'Anterior',
  dayRequired: 'O dia é obrigatório',
  monthRequired: 'O mês é obrigatório',
  yearRequired: 'O ano é obrigatório',
  uploadImage: 'Enviar Imagem',
  removeImage: 'Remover imagem',
  preferredLanguage: 'língua preferida',
  wantToChangePass: 'Deseja alterar a senha?',
  selectCountryCode: 'Selecione o código do país',
  selectPreferredLanguage: 'Selecione o idioma preferido',
  fName50Chars: 'O nome deve ter no máximo 50 caracteres',
  lName50Chars: 'O sobrenome deve ter no máximo 50 caracteres',
  invalidDate: 'Data inválida',
  DOBEarlyThanToday: 'A data de nascimento deve ser anterior a hoje',
  enterValidPhone: 'Por favor insira um número de telefone válido',
  accountStatus: 'Status da Conta',
  kycVerified: 'Seu KYC foi verificado com sucesso.',
  denied: 'Negado',
  chooseFile: 'Escolher arquivo',
  upload: 'Carregar',
  countryCantChange: 'Você está prestes a iniciar a verificação KYC. ',
  proceed: 'Continuar',
  update: ' ',
  history: 'História',
  type: 'Tipo',
  amount: 'Quantia',
  forfeited: 'Perdido',
  expired: 'Expirado',
  completed: 'Concluído',
  zeroedOut: 'Zerado',
  active: 'Ativo',
  lapsed: 'Caducado',
  yourActiveBonus: 'Seus bônus ativos',
  currentActiveBonus: 'Bônus ativos atuais',
  bonusStatus: 'Status',
  bonusWager: 'Aposta',
  bonusActivate: 'Ativar',
  bonusForfeit: 'Perda de bônus',
  notUsableBonus: 'Se sim, você não poderá usar este bônus novamente.',
  bonusLapsed: 'Você não teve perdas suficientes para receber o reembolso. ',
  inProcess: 'Em processo',
  claiming: 'Reivindicando',
  loyaltyPopover: 'Esses pontos de fidelidade podem ser usados ​​para obter reembolso. ',
  loyaltyPage: 'Página de fidelidade',
  loyaltyPerCurrency: 'Lealdade por moeda',
  maxLevelReached: 'Nível máximo alcançado',
  pointsToReach: 'pontos a alcançar ',
  loyaltyBannerHeading: 'Você merece nossa nova lealdade',
  loyaltyBannerDesc: 'Bônus de boas-vindas de 100% até $ 200',
  loyaltyHeadingOne: 'Você é sempre recompensado no CashMachine',
  loyaltyHeadingOneDesc: 'No CashMachine, levamos a lealdade a um nível totalmente novo de emoção, enchendo você de recompensas a cada reviravolta! ',
  loyaltySubHeadOne: 'Ganhando pontos de fidelidade',
  loyaltySubHeadOneDesc: 'Por cada 10€ em apostas a dinheiro feitas no nosso casino, você ganhará 1 ponto de fidelidade. ',
  loyaltySubHeadTwo: 'Subindo a escada da lealdade',
  loyaltySubHeadTwoDesc: 'Quanto mais pontos você acumular, mais alto você subirá nos níveis de fidelidade, desbloqueando recompensas ainda mais fantásticas ao longo do caminho. ',
  loyaltySubHeadThree: 'Suba de nível e seja recompensado ',
  loyaltySubHeadThreeDesc: 'Eleve sua experiência de jogo progredindo em nossos atraentes níveis de recompensa, onde cada nível acima desbloqueia uma variedade emocionante de rodadas grátis para maximizar suas chances de ganhar. ',
  loyaltyTestimonialHeadOne: 'NO CashMachine CASINO GARANTIAMOS QUE',
  loyaltyTestimonialHeadTwo: 'Cada giro conta',
  loyaltyTestimonialDesc: 'Cada rodada que você dá nos caça-níqueis não é apenas para diversão, mas também deixa você mais perto de algumas recompensas realmente incríveis! ',
  loyaltyPoints: 'Pontos',
  headerLevel: 'nível',
  loyaltyCashback: 'Dinheiro de volta',
  loyaltyBannerBtn: 'Verifique o progresso',
  loyaltyHeadingTwo: 'Desbloqueie a melhor experiência de jogo: Programa CashMachine VIP',
  loyaltyHeadingTwoDesc: 'Ao atingir o nível 6 em nosso programa de fidelidade, você desbloqueará um mundo de privilégios exclusivos como membro do prestigiado Programa CashMachine VIP. ',
  loyaltySubHeadFour: 'Limites mais altos de depósito e retirada',
  loyaltySubHeadFourDesc: 'Aproveite a flexibilidade dos limites aumentados de depósito e saque como membro VIP.',
  loyaltySubHeadFive: 'Bônus e promoções exclusivas',
  loyaltySubHeadFiveDesc: 'Tenha acesso a bônus e promoções especiais feitas sob medida para nossos jogadores VIP',
  loyaltySubHeadSix: 'Gerente de conta pessoal',
  loyaltySubHeadSixDesc: 'Receba suporte dedicado e assistência personalizada do seu gerente de conta.',
  loyaltySubHeadSeven: 'Tempos de retirada mais rápidos',
  loyaltySubHeadSevenDesc: 'Como VIP, você desfrutará de tempos de saque rápidos, garantindo acesso aos seus ganhos ainda mais rápido.',
  loyaltySubHeadEight: 'Recompensas mensais de reembolso',
  loyaltySubHeadEightDesc: 'Aumente seu saldo com generosas recompensas mensais de cashback exclusivas para nossos membros VIP.',
  loyaltySubHeadNine: 'Convites para eventos e torneios exclusivos',
  loyaltySubHeadNineDesc: 'Obtenha convites cobiçados para eventos e torneios exclusivos, onde você pode se misturar com outros VIPs e competir por prêmios incríveis.',
  loyaltySubHeadTen: 'Bônus de aniversário',
  loyaltySubHeadTenDesc: 'Comemore seu dia especial em grande estilo com um delicioso bônus de aniversário só para você!',
  loyaltySubHeadEleven: 'Presentes de luxo',
  loyaltySubHeadElevenDesc: 'Delicie-se com uma seleção requintada de presentes luxuosos escolhidos a dedo para recompensar nossos jogadores mais valiosos.',
  loyaltyTableHeading: 'Visão geral dos níveis e recompensas',
  loyaltyTableHeaderOne: 'Nível de fidelidade',
  loyaltyTableHeaderTwo: 'Pontos de fidelidade',
  loyaltyTableHeaderThree: 'Reembolso Diário',
  promReadMore: 'consulte Mais informação',
  currentPortalBlock: 'se você deseja bloquear sua conta apenas para este portal.',
  allPortalBlock: 'para bloquear sua conta de todos os portais.',
  limit24Reset: 'Depois de definir os limites de apostas, perdas e depósitos, eles poderão ser editados e removidos após 24 horas, mas os limites podem ser reduzidos imediatamente.',
  enterAmount: 'Insira o valor',
  limit: 'Limite',
  loss: 'Perda',
  takeABreak: 'Dar um tempo',
  session: 'Sessão',
  selfExclusion: 'Autoexclusão',
  used: 'Usado',
  edit: 'Editar',
  updatedAt: 'Atualizado em',
  cannotBeRemoved: 'Não pode ser removido antes',
  timePeriod: 'Período de tempo',
  custom: 'Personalizado',
  hours: 'Horas',
  days: 'Dias',
  timePeriodPlaceholder: 'Número de dias',
  months: 'Meses',
  permanent: 'Permanente',
  onlyNumbers: 'Somente números permitidos',
  notNumbers: 'Números não permitidos',
  userNameAllowed: 'Deve começar com o alfabeto. ',
  timePeriodRequired: 'Período de tempo necessário',
  cannotBeIncreased: 'Não pode ser aumentado antes',
  amountGTZero: 'O valor deve ser maior que 0',
  amountEqualOrLess: 'O valor deve ser igual ou menor que semanal e mensal',
  amountInBetween: 'O valor deve estar entre diário e mensal (inclusive)',
  amountEqualOIrGreater: 'O valor deve ser igual ou superior ao diário e mensal',
  limitRemoved: 'Seu seguinte limite será removido.',
  limitSet24Hrs: 'Você está prestes a definir o seguinte limite para sua conta. ',
  takeABreakInfo: 'Você está prestes a bloquear o acesso à sua conta por um período predefinido. ',
  sessionLimitInfo: 'Você está prestes a definir o seguinte limite para sua conta. ',
  limitCantSetBefore: 'Seu limite de sessão não pode ser definido antes',
  selfExclusionInfo: 'Você está prestes a bloquear o acesso à sua conta por um período predefinido. ',
  quickSearch: 'Pesquisa rápida',
  cash: 'Dinheiro',
  nonCash: 'Não-dinheiro',
  userDetail: 'Detalhes do usuário',
  gameIdentifier: 'Identificador do jogo',
  rollback: 'Reverter',
  rollbackBeforeBetWin: 'Reverter antes de ganhar a aposta',
  freeSpins: 'Rodadas Grátis',
  betInternal: 'Aposta Interna',
  winInternal: 'Ganhe interno',
  addMoney: 'Adicionar dinheiro',
  removeMoney: 'Remover dinheiro',
  addMoneyInternal: 'Adicionar dinheiro interno',
  removeMoneyInternal: 'Remover dinheiro interno',
  depositInternal: 'Depósito Interno',
  withdrawInternal: 'Retirar Interno',
  promotionTitle: 'Título da promoção',
  cancelWithdrawRequest: 'Você deseja cancelar esta solicitação',
  rowsPerPage: 'Linhas por página',
  availedBonus: 'Você já tem um bônus ativo. ',
  capForfeit: 'PERDIDO',
  itFirst: 'primeiro.',
  selectYourBonus: 'Selecione seu bônus',
  skip: 'PULAR',
  with: 'com',
  without: 'sem',
  selectPaymentMethod: 'Por favor escolha o seu método de pagamento',
  volatility: 'Volatilidade',
  paylines: 'Linhas de pagamento',
  theme: 'Tema',
  tryForFree: 'Experimentar gratuitamente',
  resultsFound: 'Resultados encontrados',
  games: 'Jogos',
  in: 'em',
  low: 'Baixo',
  medium: 'Médio',
  high: 'Alto',
  'medium-high': 'Médio Alto',
  'very-high': 'Muito alto',
  'low-medium': 'Médio baixo',
  fantasy: 'Fantasia',
  ancient_civilizations: 'Civilizações antigas',
  fruits: 'Frutas',
  africa: 'África',
  military: 'Militares',
  joker: 'Palhaço',
  asia: 'Ásia',
  luxurylife: 'Vida de luxúria',
  underwater_world: 'Mundo Subaquático',
  book_off: 'Reserva',
  western: 'Ocidental',
  retro: 'Retrô',
  egypt: 'Egito',
  party: 'Festa',
  st_patrick_day: 'Dia de São Patrício',
  space: 'Espaço',
  easter: 'Páscoa',
  girls: 'Garotas',
  branded: 'De marca',
  x_mas_and_new_year: 'Natal e Ano Novo',
  horrors: 'Horrores',
  other: 'Outro',
  loginFirst: 'Faça login primeiro para adicionar o jogo aos favoritos',
  loginFirstPlay: 'Faça login primeiro para jogar este jogo',
  sport: 'Esporte',
  pirates: 'Piratas',
  sweets: 'Doces',
  luxury_life: 'Vida de luxúria',
  st_valentines_day: 'Dia de São Valentim',
  halloween: 'dia das Bruxas',
  food: 'Comida',
  lobby: 'salão',
  favourite: 'Favorito',
  playNGO: 'Jogue e vá',
  loyaltySystem: 'Sistema de Fidelidade',
  progressionSystem: 'Sistema de progressão',
  startPoint: 'Ponto de partida',
  endPoint: 'Ponto final',
  loyaltyBonus: 'Bônus de lealdade',
  pageRemoved: 'Esta página não existe ou foi removida',
  suggestToBackHome: 'Sugerimos que você volte para casa',
  backToHome: 'De volta para casa',
  goHome: 'Ir para casa',
  oopps: 'Ops',
  somethingNotWorking: 'Lamentamos, algo não está funcionando aqui!',
  tournament: 'Torneio',
  none: 'Nenhum',
  validOnDays: 'Válido em dias',
  validTill: 'Válida até',
  yourRequest: 'Seu pedido',
  withdrawalRequestPending: 'Você tem uma solicitação de saque em estado pendente. ',
  realBalance: 'Saldo Real',
  bonusInCasino: 'Bônus no cassino',
  bonusSelected: 'Bônus selecionado',
  payWith: 'Pagar com',
  reached: 'Alcançado',
  receiptOfYour: 'Recebimento do seu',
  capEmail: 'E-MAIL',
  capCreditCard: 'NÚMERO DO CARTÃO DE CRÉDITO',
  capExpiry: 'TERMO',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY não está no formato correto',
  min13Numbers: 'Mínimo 13 números necessários',
  max19Numbers: 'Máximo de 19 números permitidos',
  min3Numbers: 'Mínimo 3 números necessários',
  max4Numbers: 'Máximo de 4 números permitidos',
  Other: 'Outro',
  forfeit: 'Perder',
  withdrawRequestCreated: 'Solicitação de retirada criada com sucesso',
  noBetHistory: 'Nenhum histórico de apostas encontrado',
  noPromotions: 'Nenhuma promoção encontrada',
  noBonuses: 'Nenhum bônus encontrado',
  noHistory: 'Nenhum histórico encontrado',
  noWithdrawalHistory: 'Nenhum histórico de retirada encontrado',
  noCMS: 'Nenhum CMS encontrado',
  noProvidersFound: 'Nenhum provedor encontrado',
  noDataFound: 'Nenhum dado encontrado',
  countryBlocked: 'PAÍS BLOQUEADO',
  forbidden: 'PROIBIDO',
  notAcceptingVisitors: 'Lamentamos profundamente por isso, mas brilhantewilds.com não está disponível em seu país.',
  contactUsAt: 'Se você acha que isso foi mostrado a você por engano, entre em contato com nosso suporte por e-mail em',
  siteUnderMaintenance: 'Site em manutenção',
  weWillBeRightBack: 'Nós voltaremos logo!',
  serviceUnavailable: 'Nosso serviço está temporariamente indisponível. ',
  tryAgainLater: 'Por favor, tente novamente mais tarde.',
  checkYourEmail: 'Verifique seu e-mail',
  sentAVerification: 'Enviamos um link de verificação',
  toYourEmail: 'para o seu e-mail.',
  backToLogin: 'Volte ao login',
  goToLogin: 'Vá para fazer login',
  accountVerified: 'Conta verificada',
  yourAccountVerified: 'Sua conta foi verificada',
  successfully: 'Com sucesso',
  verificationFailed: 'Falha na verificação',
  emailFailedContact: 'Falha na verificação. Se isso estiver acontecendo de forma consistente, entre em contato ',
  setNewPass: 'Defina uma nova senha',
  passResetLink: 'Enviamos um link de redefinição de senha para',
  toYourAccount: 'Para sua conta',
  sendPasswordResetLink: 'Solicitação para redefinir senha',
  gamePlayWagerComplete: 'Parabéns, você concluiu as apostas com sucesso e o dinheiro do bônus ganho é maior do que o valor máximo de ganho permitido. ',
  real: 'Real',
  demo: 'Demonstração',
  money: 'Dinheiro',
  formatRquired: 'Formato .png, .jpg, .jpeg necessário',
  chooseImage: 'Escolha uma imagem',
  imageSize3MB: 'Insira a imagem com tamanho inferior a 3 MB',
  noProfileImage: 'Nenhuma imagem de perfil disponível para remoção',
  limitSetSuccess: 'limite definido com sucesso',
  accountDisabled: 'Conta desativada com sucesso',
  loginSuccess: 'Login bem-sucedido',
  signupSuccess: 'Cadastro bem sucedido',
  linkExpired: 'Link expirado',
  linkSent: 'Link enviado',
  accAlreadyVerified: 'Conta já verificada. ',
  profileImage: 'Imagem de perfil',
  profileImageRemoved: 'Imagem de perfil removida com sucesso',
  docs: 'Documentos',
  updatedSuccess: 'Atualizado com sucesso',
  MATCH: 'CORRESPONDER',
  BALANCE: 'EQUILÍBRIO',
  FREESPINS: 'RODADAS GRATUITAS',
  MATCH_1: 'CORRESPONDER',
  DEPOSIT: 'DEPÓSITO',
  WAGERING: 'APOSTAS',
  PROMOTION: 'PROMOÇÃO',
  CREDITCARD: 'CARTÃO DE CRÉDITO',
  WALLET: 'CARTEIRA',
  CRYPTO: 'CRIPTO',
  OTHER: 'OUTRO',
  INSTANTBANKING: 'BANCO INSTANTÂNEO',
  cookieTitle: 'Biscoitos!',
  declineButton: 'Declínio',
  acceptButton: 'Aceitar',
  cookieDeclinedMessage: 'Para a melhor experiência possível, os cookies devem ser aceitos para continuar a utilizar este site. ',
  cookieDescription: 'Bem-vindo ao Shinywilds.com! ',
  forfeitMessageFirst: 'Atualmente você tem o seguinte bônus ativo:',
  forfeitMessageSecond: 'Você precisa desistir deste bônus antes de reivindicar um novo.',
  forfeitButton: 'Perder',
  pleaseVerifyFirstText: 'Para poder solicitar um saque, primeiro precisamos verificar seu endereço de e-mail. ',
  clickHere: 'Clique aqui',
  pleaseVerifyLastText: 'para que possamos reenviar o e-mail de verificação e prosseguir.',
  pleseWait: 'Por favor espere por',
  toResend: 'para reenviar o link',
  notVerified: 'Você deve verificar seu endereço de e-mail para sacar dinheiro',
  verification: 'Verificação',
  UPLOAD_IMG_MSG: 'Arraste e solte arquivos aqui ou clique para selecionar os arquivos',
  affiliate: 'Afiliado',
  affiliateTitle: 'Programa de Afiliados',
  affiliateDescription: ' Indique nossa marca e ganhe dinheiro participando do Programa de Afiliados de Caixa Eletrônico! ',
  refferalTitle: 'Programa de referência',
  refferalDescription: 'Você pode compartilhar este link de indicação com outras pessoas e ganhar algum bônus se o usuário se registrar usando o link fornecido',
  affiliateButtonText: 'Torne-se Afiliado',
  affiliateLink: 'Link de afiliado',
  affiliateStatus: 'Sua solicitação de Afiliado foi enviada com sucesso',
  currentStatus: 'e o status atual de afiliado é {{status}}',
  referralLink: 'Link de referência',
  copyTitleMessage: 'Por favor copie este link',
  copiedMessage: 'Link copiado',
  searchedGame: 'Jogo pesquisado',
  favoriteGame: 'Jogos favoritos',
  randomGames: 'Jogos Aleatórios',
  poolPrize: 'Conjunto de prêmios',
  chooseYourWelcomeBonus: 'Escolha seu bônus de boas-vindas',
  useNow: 'Use agora',
  readTheTerms: 'Leia os termos',
  proceedWithoutWelcomeBonus: 'Prossiga sem bônus de boas-vindas',
  doYouAlreadyHaveAccount: 'Você já tem uma conta? ',
  registerViaSocialNetworks: 'Cadastre-se através das redes sociais',
  iconfirmIm18YearsOlder: 'Confirmo que tenho 18 anos ou mais',
  iAgreeWithTermsAndConditions: 'Eu concordo com os termos e condições',
  thankYouSuccessfullyRegisteringCashMachine: 'Obrigado por se registrar com sucesso no Cash Machine.',
  verifyEmailMessage: 'Solicitamos que você verifique seu e-mail clicando no link de verificação enviado para seu e-mail para fazer login com sucesso no site.',
  gotIt: 'Entendi',
  phonecodeRequired: 'O código do telefone é obrigatório',
  cpNotAllow: 'copiar/colar não é permitido',
  playFun: 'Divirta-se',
  playReal: 'Jogue de verdade',
  yourRealBalance: 'Seu saldo real é:',
  chooseYourPaymentMethod: 'Escolha o seu método de pagamento',
  balanceHistory: 'Histórico de saldo',
  liveChat: 'Bate-papo ao vivo',
  mail: 'Correspondência',
  chooseEnterYourDepositAmounts: 'Escolha ou insira os valores do seu depósito',
  noBonusDataAvailable: 'Nenhum dado de bônus disponível',
  nowTryPlayForRealBets: 'Agora tente jogar com apostas reais',
  registerAndPlayForRealBets: 'Registre-se e jogue com apostas reais',
  tournamentDataNotAvailable: 'Dados do torneio não disponíveis',
  startDate: 'Data de início',
  endDate: 'Data final',
  merchant: 'Comerciante',
  seachHere: 'Procure aqui',
  show: 'Mostrar',
  betResults: 'Resultados das apostas:',
  noTransactionsFoundForselectedDate: 'Nenhuma transação encontrada para a data selecionada',
  loginError: 'Erro de login',
  errorMessageIncorrectlyAuthorization: 'E-mail/login ou senha digitados incorretamente. Erro de autorização.',
  profileSaved: 'Perfil salvo',
  addInfo: 'Adicionar informações',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Li e concordo com as restrições dos sistemas de pagamento*',
  paymentOptions: 'Opções de pagamento',
  language: 'Linguagem',
  betTime: 'Hora da aposta',
  noActiveBonusFound: 'Nenhum bônus ativo encontrado',
  bonusType: 'Tipo de bônus',
  requiredWageringAmount: 'Valor de aposta necessário',
  currentWageringAmount: 'Valor atual da aposta',
  validUpto: 'Válido até',
  bonusCode: 'Código bônus',
  wageringProgressBar: 'Barra de progresso de apostas',
  noGamesFound: 'Nenhum jogo encontrado',
  noteYouMayOptfreeSpin: 'Nota: Você pode optar pelo FREESPIN em qualquer um desses jogos',
  viewOfferDetails: 'Ver detalhes da oferta',
  viewFreespinGames: 'Ver jogos Freespin',
  depositBonusPercentage: 'Porcentagem de bônus de depósito: ',
  validity: 'Validade',
  activated: 'ativado',
  bonusName: 'Nome do bônus',
  freeRounds: 'Rodadas Grátis',
  wagering: 'Apostas',
  ended2: 'Terminou',
  searchBonusTitle: 'Pesquise por título de bônus',
  claimed: 'Reivindicado',
  noBonusHistoryFoundselectedDates: 'Nenhum histórico de bônus encontrado para as datas selecionadas',
  noBonusDetailsFound: 'Nenhum detalhe de bônus encontrado',
  enterCode: 'Coloque o código',
  transactionTime: 'Tempo de transação',
  credit: 'Crédito',
  debit: 'Débito',
  // bonusInfoOfHeading: 'Bônus são pagamentos extras que recompensam seu desempenho',
  bonusInfoOfHeading: 'Os bônus são recompensas adicionais que reconhecem sua atividade e conquistas.',
  onlineCasino: 'cassino on-line',
  isTheTop: ' é o topo ',
  bonusInfoPara0: 'Procurando os melhores jogos de cassino online? ',
  tournamentsHistory: 'História dos torneios',

  loginWelcomeBonusInfo: `Cash Machine dá as boas-vindas a novos jogadores com um bônus de boas-vindas - 100% de
   o Depósito + 100 rodadas grátis no jogo "Pat’s Heroes" (Platipus).
   O valor mínimo do depósito é EUR 20 / ARS 900 / AUD 30 / CZK 500
   / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70
   / KZT 9 000 / TJS 200 / UZS 200 000 / TENTE 130`,
  loginDepositBonusInfo: `Valor máximo do bônus: 100% do valor do depósito, mas não mais
   superior a 100 euros / ARS 4.000 / AUD 150 / CZK 2.500 / DKK 750 / NOK 1.000
   / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45 000 /
   TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `As rodadas grátis são emitidas dentro de 5 dias desde a ativação do bônus,
   20 rodadas grátis diariamente. Todos os fundos ganhos em rodadas grátis são creditados em
   a conta de bônus e estão disponíveis para jogar/apostar
   bônus. Retirada disponível após apostar X40. Tempo de aposta bônus
   é de 7 dias.`,
  loginMaximumBetLimitInfo: `Limite máximo de aposta durante as apostas: EUR 1 / ARS 45 / AUD 1,5 / CZK
   25 / DKK 7 / NOK 10 / NZD 1,5 / PLN 5 / USD 1 / ZAR 15 / GEL 3,5 /
   KZT 450 / TJS 10 / UZS 10 000 / TENTE 6`,

  bonusInfoPara1: ', com uma grande variedade de jogos fantásticos e ofertas especiais deslumbrantes. Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo que oferecem aos jogadores uma chance lucrativa de obter uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas∂ e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade.',
  bonusInfoPara2: 'Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo oferecendo uma chance lucrativa de obter uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade. Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo que oferecem aos jogadores uma chance lucrativa de ganhar pontos. uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade.',
  bonusInfoPara3: 'Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo que oferecem aos jogadores uma chance lucrativa de obter uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade.',
  bonusInfoPara4: 'Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo que oferecem aos jogadores uma chance lucrativa de obter uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade. Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis com jackpot progressivo que oferecem aos jogadores uma chance lucrativa de ganhar pontos. uma grande vitória. Também oferecemos emocionantes jogos com crupiê ao vivo, onde você pode ver suas cartas embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é certo para você? Não se preocupe – você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de decidir jogar de verdade.',
  cashMachineFooterPara1: 'Procurando o melhor cassino online? Bem-vindo ao ',
  cashMachineFooterPara2: ` onde combinamos
jogabilidade emocionante, bônus generosos e torneios exclusivos para trazer a você o
melhor entretenimento online. Quer ganhar o jackpot em nossos slots progressivos,
competir por prêmios em torneios ou testar sua sorte em jogos ao vivo com dealers? Temos
tudo! O que nos torna únicos? No `,
  cashMachineFooterPara3: `você pode ganhar não apenas prêmios
em dinheiro, mas também recompensas físicas reais! Temos orgulho de ser a escolha dos
jogadores em todo o mundo. Pronto para explorar? Jogue qualquer jogo disponível na sua
região e aproveite a emoção incomparável. Experimente o melhor, jogue com os melhores
— só no `,
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  // tournamentDescTitle: 'Cassino ao vivo, Caça-níqueis e Torneios de cassino Drops and wins',
  tournamentDescTitle: 'Os torneios são uma ótima oportunidade para competir com outros jogadores e ganhar prêmios generosos.',
  tournamentPara1: 'Procurando pelos melhores jogos de cassino online? Então você veio ao lugar certo.',
  tournamentPara2: 'com uma grande variedade de jogos fantásticos e ofertas especiais deslumbrantes. Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com jogos de caça-níqueis de jackpot progressivo oferecendo aos jogadores uma chance lucrativa de marcar uma grande vitória. Também oferecemos emocionantes jogos de crupiê ao vivo onde você pode ver suas cartas sendo embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é o certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de escolher jogar de verdade.',
  tournamentPara3: 'Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com progressivos jogos de caça-níqueis de jackpot oferecendo aos jogadores uma chance lucrativa de marcar um grande vitória. Também oferecemos emocionantes jogos de crupiê ao vivo onde você pode ver suas cartas sendo embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é o certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de escolher jogar de verdade. Nossos jogos de caça-níqueis vêm em uma ampla variedade de temas, com progressivos jogos de caça-níqueis de jackpot oferecendo aos jogadores uma chance lucrativa de marcar um grande vitória. Também oferecemos emocionantes jogos de crupiê ao vivo onde você pode ver suas cartas sendo embaralhadas e distribuídas em tempo real! Não tem certeza de qual jogo é o certo para você? Não se preocupe - você pode praticar primeiro no modo de demonstração para ter uma ideia de cada jogo antes de escolher jogar de verdade.',
  'New Games': 'Novos Jogos',
  'Popular Games': 'Jogos Populares',
  'Games Of The Month': 'Jogos do mês',
  Exclusive: 'Exclusivo',
  'Bonus Buy': 'Compra de bônus',
  'Jackpot Games': 'Jogos de jackpot',
  lightMode: 'Modo claro',
  darkMode: 'Modo escuro',
  searchProvider: 'Provedor de pesquisa',
  tournamentHistoryDataNotAvailable: 'Dados do histórico do torneio não disponíveis',
  viewMore: 'Veja mais',
  description: 'Descrição',
  codeCopied: 'Código copiado',
  tournamentGames: 'Jogos de torneio',
  freeSpinGames: 'Jogos de giro grátis',
  promoCode: 'Código promocional',
  paymentNote: 'Nota: Por favor, não feche ou atualize a janela enquanto a transação estiver sendo processada',
  liveGames: 'Jogos ao Vivo',
  play: 'JOGAR',
  demo1: 'DEMO',
  registration: 'REGISTRO',
  confirmation: 'Confirmação',
  forfeitBonusMessage: 'Se você ativar este bônus, qualquer bônus ativo antigo será perdido.',
  bonusConfirmationMessage: 'Você tem certeza de que deseja ativar este bônus?',

  forfeitRewardMessage: 'Se você ativar esta recompensa, qualquer bônus ativo antigo será perdido.',
  rewardConfirmationMessage: 'Tem certeza de que deseja ativar esta recompensa?',
  reward: 'Recompensa',
  purchasedReward: 'Recompensa comprada',
  noActiveRewardFound: 'Nenhuma recompensa ativa encontrada',
  noRewardDetailsFound: 'Nenhuma recompensa encontrada',
  betDate: 'Data da Aposta',
  shopDes: 'A loja é onde você pode trocar seus pontos acumulados por várias recompensas, bônus e prêmios.',
  vipInfoHeading: 'Os bônus VIP são ofertas exclusivas para nossos jogadores mais leais.'
}
