import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material'
import { HeaderWrapper, DarkLightSwitch } from './style'
import {
  CoinVerticalIcon,
  CurrencyEurIcon,
  // GiftIcon,
  SearchIcon,
  SettingIcon
} from 'assets/icons/index'
import {
  CustomIconButton,
  CustomMainButton
} from 'components/common-ui/CustomButton/CustomButton'
import { ROUTE_PATHS } from 'constants/index'
import { NavLink, useLocation, useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import logoAnimation from '../../../assets/lottie-animation-json/logo.json'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk'
import { getSearchedGameData } from 'redux-thunk/thunk/game.thunk'
import { clearRandomGames, clearSearchedGames, setSearch } from 'redux-thunk/redux/slices/games.slice'
// import DepositWithdrawal from '../DepositWithdrawal/index'
// import AuthLoginError from '../AuthLoginError/AuthLoginError'
import { debounce } from 'utils/helper'
import { useTranslation } from 'react-i18next'
import { getAllCountriesStart } from 'redux-thunk/thunk/gameSetting'
import {
  setAuthModal,
  setUserModal
} from 'redux-thunk/redux/slices/auth.slice'
import MobileMenu from './MobileMenu/MobileMenu'
import { setShowRandomGames } from 'redux-thunk/redux/slices/gameSetting.slice'
import MyAccount from '../MyAccount/index'
import AuthModal from '../AuthContentSection/AuthModal'
import DepositWithdrawal from '../DepositWithdrawal/index'
import AuthLoginError from '../AuthLoginError/AuthLoginError'
import { setWalletData } from 'redux-thunk/redux/slices/user.slice'
import { getAuthToken } from 'helpers/cookie.helpers'
import useWebSocket from 'socket-resources/hooks/useWebSocket'
import NAMESPACES from 'socket-resources/namespaces/index'
import appConfig from 'config/app.config'
import { WALLET_UPDATE_EVENT } from 'socket-resources/event-constants/wallet/index'
import { getCurrencyData, getIPData, getSponsorData } from 'redux-thunk/thunk/general.thunk'

const Header = ({ toggleTheme }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const token = getAuthToken()
  const ref = useRef(null)
  const { search } = useSelector((state) => state.games)
  const { userWallet, user } = useSelector((state) => state.user)
  const { authModal, userModal } = useSelector((state) => state.auth)
  const { isAuthenticated, allCountries, isDarkMode } = useSelector((state) => state.gameSetting)
  const [atTop, setAtTop] = useState(true)
  const [authLoginError, setAuthLoginError] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [isSearchFocus, setSearchFocus] = useState(false)
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(window.location.search).get('stag')
  const referSearchParams = new URLSearchParams(window.location.search).get(
    'referCode'
  )
  const isSocialLoginRoute = location.pathname === '/social-login'

  const handleSocketUpdate = (message) => {
    dispatch(setWalletData(message?.data))
  }

  const socketInstance = useWebSocket(
    `${appConfig.SOCKET_URL}${NAMESPACES.WALLET}`,
    WALLET_UPDATE_EVENT,
    handleSocketUpdate,
    token
  )

  useEffect(() => {
    if (search === '') {
      dispatch(setSearch(''))
      dispatch(clearSearchedGames())
    }
  }, [search])

  useEffect(() => {
    if (location.pathname !== '/') {
      setShowSearch(false)
    } else {
      setShowSearch(true)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' }
    )
    if (isSocialLoginRoute) {
      dispatch(setAuthModal('social-login'))
    }
  }, [location?.pathname])

  useEffect(() => {
    if (searchParams !== null) {
      localStorage.setItem('stag', searchParams)
      setSearchParams('')
    } else if (referSearchParams !== null) {
      localStorage.setItem('referCode', referSearchParams)
      setSearchParams('')
    }
    if (!allCountries) dispatch(getAllCountriesStart())
    if (isAuthenticated && !user) {
      dispatch(fetchUserInformation())
    }
    const handleScroll = () => {
      const isAtTop = window.scrollY < 200
      setAtTop(isAtTop)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    dispatch(getIPData())
    dispatch(getSponsorData())
    dispatch(getCurrencyData())
    if (isSocialLoginRoute) {
      dispatch(setAuthModal('social-login'))
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      if (socketInstance?.isConnected) {
        socketInstance.disconnectSocket()
        socketInstance.startConnection()
      } else {
        socketInstance.startConnection()
      }
    } else if (!isAuthenticated && socketInstance && socketInstance?.isConnected) {
      socketInstance.disconnectSocket()
    }

    return () => {
      if (socketInstance?.isConnected) {
        socketInstance.disconnectSocket()
      }
    }
  }, [isAuthenticated])

  const handleGameSearchInputChange = (searchText) => {
    if (searchText) {
      const data = {
        limit: 20,
        pageNo: 1,
        search: searchText
      }
      dispatch(clearSearchedGames())
      dispatch(getSearchedGameData(data))
    }
  }

  const gameSearchHandler = useCallback(debounce(handleGameSearchInputChange, 300), [])

  // eslint-disable-next-line no-unused-vars
  // const handleSearch = () => {
  //   dispatch(clearSearchedGames())
  //   const data = {
  //     search: inputValue
  //   }
  //   dispatch(getSearchedGameData(data))
  //   setInputValue('')
  // }

  const userElement = (
    <Box className='navigation-box'>
      {/* <CustomIconButton>
        <GiftIcon />
      </CustomIconButton> */}

      <Box className='wallet-balance-wrap'>
        <Box className='wallet-box'>
          {userWallet?.currency?.symbol
            ? (
              <Box className='currency-icon'>
                <Typography variant='h6'>{userWallet?.currency?.symbol}</Typography>
              </Box>)
            : <CurrencyEurIcon />}
          <Typography variant='h6'>{userWallet?.amount ? parseFloat(userWallet?.amount).toFixed(2) : 0.00}</Typography>
        </Box>
        <Box className='wallet-box'>
          <Typography variant='h6'>+{userWallet?.loyaltyPoint ? parseFloat(userWallet?.loyaltyPoint).toFixed(2) : 0.00} </Typography>
        </Box>
        <Box className='wallet-box'>
          <CoinVerticalIcon />
          <Typography variant='h6'>{userWallet?.nonCashAmount ? parseFloat(userWallet?.nonCashAmount).toFixed(2) : 0.00}</Typography>
        </Box>
      </Box>

      <CustomIconButton
        onClick={() => {
          dispatch(setUserModal('account'))
        }}
      >
        <SettingIcon />
      </CustomIconButton>
    </Box>
  )

  const loginElement = (
    <Box className='auth-btn-wrap'>
      <CustomMainButton
        onClick={() => {
          dispatch(setAuthModal('login'))
        }}
      >
        {t('login')}
      </CustomMainButton>
      <CustomMainButton
        onClick={() => {
          dispatch(setAuthModal('signup'))
        }}
      >
        {t('registration')}
      </CustomMainButton>
    </Box>
  )

  const [isToggled, setIsToggled] = useState(false)
  const handleClick = () => {
    setIsToggled((prevState) => !prevState)
  }

  useEffect(() => {
    setIsToggled(false)
  }, [window.location.href])

  const [isShrink, setIsShrink] = useState(false)
  const toggleShrink = () => {
    setIsShrink(!isShrink)
  }

  const resetRandomGames = () => {
    dispatch(setShowRandomGames(false))
    dispatch(clearRandomGames())
  }

  return (
    <>
      <HeaderWrapper theme={theme}>
        <Toolbar className={`${isShrink ? '' : 'search-wrap-shrink'}`}>
          <Box className='brand-logo-wrap' onClick={resetRandomGames}>
            <NavLink to={ROUTE_PATHS.HOME} className='brand-logo'>
              <Lottie
                animationData={logoAnimation}
                loop
                autoplay
                className='brand-logo'
              />
            </NavLink>
          </Box>

          <Box className='navigation-wrap'>
            {isDesktop
              ? (
                <FormGroup>
                  <FormControlLabel
                    sx={{ margin: '0' }}
                    control={<DarkLightSwitch content={isDarkMode ? t('lightMode') : t('darkMode')} onChange={toggleTheme} />}
                  />
                </FormGroup>)
              : null}

            {showSearch && (
              <FormControl variant='outlined' className='search-wrap'>
                <OutlinedInput
                  type='text'
                  value={search}
                  onChange={(e) => {
                    gameSearchHandler(e.target.value)
                    dispatch(setSearch(e.target.value))
                  }}
                  onFocus={() => {
                    setTimeout(() => setSearchFocus(true), 300)
                    setIsToggled(true)
                  }}
                  onBlur={() => {
                    setSearchFocus(false)
                    setTimeout(() => setIsToggled(false), 300)
                  }}
                  placeholder={t('search')}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={toggleShrink}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!isSearchFocus &&
                  <Box
                    // className={`currency-note-animation-wrap ${
                    //   inputValue ? "has-value" : ""
                    // }`}
                    className='currency-note-animation-wrap'
                  >
                    <Box className='inner-box'>
                      <Box
                        className={`animation-img-box ${atTop ? '' : 'click-disable'}`}
                        // className='animation-img-box'
                        onClick={handleClick}
                        ref={ref}
                      >
                        <img
                          src='/assets/images/stock-images/dollar-note.png'
                          // className={`animated-img ${atTop ? '' : 'scroll-top-hide'}`}
                          className='animated-img'
                          alt='Currency Note'
                          style={{
                            transform: isToggled || !atTop
                              ? 'translateY(-150%)'
                              : 'translateY(0%)',
                            height: '100%',
                            width: '100%'
                          }}
                          loading='lazy'
                        />
                      </Box>
                    </Box>
                  </Box>}
              </FormControl>
            )}

            {isDesktop
              ? (
                <>{isAuthenticated ? userElement : loginElement}</>)
              : (
                <MobileMenu toggleTheme={toggleTheme} />)}
          </Box>
        </Toolbar>
      </HeaderWrapper>

      <MyAccount
        dialogOpen={userModal}
        handleClose={() => dispatch(setUserModal(''))}
      />
      <DepositWithdrawal
        dialogOpen={userModal}
        handleClose={() => dispatch(setUserModal(''))}
      />
      <AuthModal
        dialogOpen={authModal}
        handleClose={(event, reason) => {
          if (reason && reason === 'backdropClick') { return }
          dispatch(setAuthModal(''))
        }}
      />
      <AuthLoginError
        dialogOpen={authLoginError}
        handleClose={() => setAuthLoginError(false)}
      />
    </>
  )
}

export default React.memo(Header)
