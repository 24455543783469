import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Pagination, PaginationItem, Typography, Grid, MenuItem } from '@mui/material'
import { HistoryBonusesWrapper } from './style'
import CustomTable from 'components/common-ui/CustomTable/index'
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, SpinnerSVG } from 'assets/icons/index'
import { useDispatch, useSelector } from 'react-redux'
import { getUserBonusHistory } from 'redux-thunk/thunk/bonus.thunk'
import { formatDateDMY } from 'utils/dateFormatter'
import dayjs from 'dayjs'
import { CustomFormLabel, CustomSelect, CustomTextField } from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import CustomDateTimePicker from 'components/common-ui/CustomDateTimePicker/CustomDateTimePicker'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { useTranslation } from 'react-i18next'

const columns = (t) => ([
  {
    id: 'bonusName',
    label: <Typography textAlign='left'>{t('bonusName')}</Typography>
  },
  {
    id: 'balance',
    label: <Typography textAlign='center'>{t('balance')}</Typography>
  },
  {
    id: 'type',
    label: <Typography textAlign='center'>{t('bonusType')}</Typography>
  },
  {
    id: 'freeRounds',
    label: <Typography textAlign='center'>{t('freeRounds')}</Typography>
  },
  {
    id: 'wagering',
    label: <Typography textAlign='left'>{t('wagering')}</Typography>
  },
  {
    id: 'ended',
    label: <Typography textAlign='left'>{t('ended2')}</Typography>
  },
  {
    id: 'status',
    label: <Typography textAlign='left'>{t('status')}</Typography>
  }
])

const HistoryBonuses = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const bonusHistory = useSelector(state => state.bonus?.userBonusHistory?.bonusHistory)
  const limit = 5
  const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(7, 'day'))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [bonusType, setBonusType] = useState('all')
  const [status, setStatus] = useState('ALL')
  const [search, setSearch] = useState('')
  const { [LOADER_HANDLER_TYPES.content]: loading } = useSelector(state => state.loader)

  const { t } = useTranslation()

  const langCode = useSelector((state) => state.language.selectedLanguage?.code?.toUpperCase())
  useEffect(() => {
    const params = {
      limit: limit,
      validToStartDate: startDate.$d.toISOString().split('T')[0],
      validToEndDate: endDate.$d.toISOString().split('T')[0],
      bonusType: bonusType,
      pageNo: page,
      search: search,
      status: status
    }
    dispatch(getUserBonusHistory(params))
  }, [dispatch])

  const handleShow = () => {
    const params = {
      limit: 5,
      validToStartDate: startDate.$d.toISOString().split('T')[0],
      validToEndDate: endDate.$d.toISOString().split('T')[0],
      bonusType: bonusType,
      pageNo: page,
      search: search,
      status: status
    }
    dispatch(getUserBonusHistory(params))
  }

  useEffect(() => {
    const params = {
      limit: limit,
      validToStartDate: startDate.$d.toISOString().split('T')[0],
      validToEndDate: endDate.$d.toISOString().split('T')[0],
      bonusType: bonusType,
      pageNo: page,
      search: search,
      status: status
    }
    dispatch(getUserBonusHistory(params))
  }, [page])

  const formattedData = bonusHistory?.rows?.map((item) => {
    const title = item?.bonus?.bonusTitle
    return {
      bonusName: <Typography textAlign='left'>{title ? title[langCode] || title?.EN : '-'}</Typography>,
      balance: <Typography textAlign='center'>{item?.cashAmount || '-'}</Typography>,
      type: <Typography textAlign='center'>{item?.bonusType || '-'}</Typography>,
      freeRounds: <Typography textAlign='center'>{item?.bonus?.quantity || '-'}</Typography>,
      wagering: <Typography textAlign='left'>{item?.wagering || '-'}</Typography>,
      ended: <Typography textAlign='left'>{formatDateDMY(item?.bonus?.Ended) || '-'}</Typography>,
      status: <Typography textAlign='left'>{item?.status || '-'}</Typography>
    }
  })

  const bonusHistoryTable = (
    <>
      <CustomTable columns={columns(t)} data={formattedData} />
      <Pagination
        count={(bonusHistory?.count / limit) < 1 ? 1 : Math.ceil(bonusHistory?.count / limit)}
        onChange={(e, value) => setPage(value)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />
    </>
  )
  return (
    <>
      <HistoryBonusesWrapper theme={theme} className='content-wrapper'>
        <Box component='form' noValidate>
          <Grid container spacing={1.25}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('startDate')}</CustomFormLabel>
              <CustomDateTimePicker value={startDate} handleChange={setStartDate} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('endDate')}</CustomFormLabel>
              <CustomDateTimePicker value={endDate} handleChange={setEndDate} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>{t('bonusType')}</CustomFormLabel>
              <CustomSelect
                fullWidth
                value={bonusType}
                onChange={(e) => setBonusType(e.target.value)}
                displayEmpty
                IconComponent={ChevronDownIcon}
              >
                <MenuItem value='all'>{t('all')}</MenuItem>
                {/* <MenuItem value='cash'>Cash</MenuItem> */}
                <MenuItem value='freespin'>{t('freeSpins')}</MenuItem>
                <MenuItem value='deposit'>{t('deposit')}</MenuItem>
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>{t('search')}</CustomFormLabel>
              <CustomTextField
                fullWidth
                type='text'
                placeholder={t('searchBonusTitle')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>{t('status')}</CustomFormLabel>
              <CustomSelect
                fullWidth
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                displayEmpty
                IconComponent={ChevronDownIcon}
              >
                <MenuItem value='ALL'>{t('all')}</MenuItem>
                <MenuItem value='CLAIMED'>{t('claimed')}</MenuItem>
                <MenuItem value='PENDING'>{t('pending')}</MenuItem>
                <MenuItem value='FORFEITED'>{t('Forfeited')}</MenuItem>
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                <Grid item xs={12} lg={4}>
                  <Box className='form-btn-box'>
                    <CustomPrimaryButton fullWidth onClick={handleShow}>
                      {!loading
                        ? t('show')
                        : <SpinnerSVG style={{ fill: 'white' }} />}
                    </CustomPrimaryButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {bonusHistory?.count > 0 ? bonusHistoryTable : <Typography className='history-results-text'>{t('noBonusHistoryFoundselectedDates')}</Typography>}
      </HistoryBonusesWrapper>
    </>
  )
}

export default React.memo(HistoryBonuses)
