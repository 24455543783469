export default {
  404: '404',
  vip: 'كبار الشخصيات',
  upcoming: 'القادمة',
  finished: 'انتهى',
  prizePool: 'مجموع جوائز',
  joinNow: 'نضم الان',
  joined: 'انضم',
  startsIn: 'يبدأ في',
  endsIn: 'ينتهي في',
  ended: 'انتهت البطولة',
  started: 'بدأت البطولة',
  seeMore: 'شاهد المزيد',
  winPrizes: 'فوز بجوائز مثيرة',
  leaderBoard: 'لوحة المتصدرين',
  prize: 'جائزة',
  aboutTournament: 'حول البطولة',
  congratulationsMessage: 'أحسنت! ',
  payments: 'قسط',
  tournaments: 'البطولات',
  shop: 'محل',
  blog: 'مدونة',
  referral: 'الإحالة',
  contacts: 'جهات الاتصال',
  retry: 'أعد المحاولة',
  incorrectCredsMessage: 'اسم المستخدم أو كلمة المرور غير صحيحة. ',
  bonuses: 'علاوة',
  depositBonus: 'مكافأة الإيداع',
  voucher: 'فاتورة',
  gamlings: 'القمار',
  transactionHistory: 'تاريخ المعاملات',
  offers: 'عروض',
  editProfile: 'تعديل الملف الشخصي',
  continue: 'يكمل',
  apply: 'يتقدم',
  male: 'ذكر',
  female: 'أنثى',
  register: 'يسجل',
  login: 'تسجيل الدخول',
  grand: 'كبير',
  major: 'رئيسي',
  minor: 'صغير',
  mini: 'ميني',
  crashGameTitle: 'يتحطم',
  betAmount: 'مبلغ الرهان',
  profit: 'ربح',
  payout: 'الدفع المستهدف',
  chance: 'فرصة الفوز',
  betBtn: 'رهان',
  autoCashout: 'السحب التلقائي',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'الأعلى',
  wagered: 'راهن',
  nothingFound: 'لم يتم العثور على شيء',
  lost: 'ضائع',
  won: 'فاز',
  streak: 'أثَر',
  highestWin: 'أعلى فوز',
  promotions: 'الترقيات',
  latestPromotions: 'أحدث العروض الترويجية',
  sponsorships: 'الرعاية',
  highestLost: 'أعلى خسارة',
  highestBet: 'أعلى رهان',
  maxStreak: 'ماكس ستريك',
  maxProfit: 'أقصى ربح: ',
  bal: 'بال: ',
  messagesNoBetHistory: 'سجل الرهانات الخاصة بك فارغ',
  messagesCopyNotSupported: 'متصفحك لا يدعم النسخ التلقائي إلى الحافظة. يرجى محاولة نسخ الرمز يدويًا.',
  messagesCopied: 'تم نسخ العنوان.',
  deposit: 'إيداع',
  statReset: 'إعادة ضبط الإحصائيات',
  rollOver: 'يتدحرج',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'خلف',
  inputFieldBtnMax: 'الأعلى',
  buttonTextPlaceBet: 'ضع رهان',
  buttonTextCancelBet: 'إلغاء الرهان',
  buttonTextEscapeBet: 'المصروفات',
  buttonTextStartAutoBet: 'ابدأ الرهان التلقائي',
  buttonTextCancelAutoBet: 'قم بإلغاء الرهان التلقائي',
  buttonTextLoading: 'تحميل',
  tabsMyBets: 'رهاني',
  tabsDeposit: 'إيداع',
  tabsCashout: 'السحب',
  tabsAllbets: 'جميع الرهانات',
  tabsTopbets: 'أفضل الرهانات',
  tabsBonus: 'المكافآت',
  tabsjackpot: 'الفوز بالجائزة الكبرى',
  tabsBetHistory: 'تاريخ الرهانات',
  timerTextCurrentPayout: 'الدفع الحالي',
  timerTextStarting: 'يبدأ في',
  timerTextSec: 'س',
  timerTextFlewAway: 'تحطم',
  timerTextWait: 'انتظر من فضلك..',
  timerTextPreparingRound: 'جولة التحضير',
  timerTextLoadingRound: 'تحميل الجولة',
  comingSoon: 'قريباً',
  labelsProfitOnWin: 'الربح على الفوز',
  inputsNoOfBets: 'عدد الرهانات',
  inputsAuto: 'آلي',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'يبدأ',
  inputsCancel: 'يلغي',
  buttonsMin: 'دقيقة',
  buttonsMax: 'الأعلى',
  prediction: 'تنبؤ',
  luckyNumber: 'رقم الحظ',
  multiplier: 'المضاعف',
  chanceToWin: 'فرصة للفوز',
  bets: 'الرهانات',
  depositBtn: 'إيداع',
  rollUnder: 'لفة تحت',
  symbolsX: 'س',
  symbolsPercent: '%',
  navBarTitle: 'تاريخ',
  navBarMyBets: 'رهاني',
  navBarAllBets: 'جميع الرهانات',
  navBarHighRollers: 'بكرات عالية',
  navBarRoundHistory: 'تاريخ الجولة',
  navBarTotal: 'إجمالي الرهانات',
  topHeaderBetId: 'معرف الرهان',
  topHeaderUser: 'مستخدم',
  topHeaderPayout: 'سيصرف',
  topHeaderAmount: 'كمية',
  topHeaderTime: 'وقت',
  topHeaderProfit: 'ربح',
  topHeaderRoundCrashed: 'تحطم',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'التجزئة',
  topHeaderRoundBet: 'رهان',
  topHeaderRoundProfit: 'ربح',
  topHeaderRoundSignature: 'إمضاء',
  roundHistoryTitle: 'معلومات اللعبة',
  roundHistoryDescGame: 'لعبة',
  roundHistoryDescCrashAt: 'تحطمت في:',
  roundHistoryDescDate: 'تاريخ :',
  roundHistoryDescBetHeader: 'رهان',
  roundHistoryTableTitlePlayer: 'لاعب',
  roundHistoryTableTitleBet: 'رهان',
  roundHistoryTableTitleCashedOut: 'سحبت',
  roundHistoryTableTitleProfit: 'ربح',
  roundHistoryTableTitleBetId: 'معرف الرهان',
  roundHistoryMessagesNoBetHistory: 'سجل الرهانات الخاصة بك فارغ',
  placedBetsTabPlaced: 'الرهان الموضوع',
  placedBetsTabPrev: 'الرهان السابق',
  placedBetsTitleUser: 'مستخدم',
  placedBetsTitleEscape: 'متعدد.x',
  placedBetsTitleBet: 'رهان',
  placedBetsTitleProfit: 'ربح',
  placedBetsTitleCashout: 'المصروفات',
  placedBetsNoPlacedBet: 'لا توجد رهانات موضوعة.',
  provablyFairContentCheckBtn: 'تحقق من المعرض الذي يمكن إثباته',
  provablyFairContentTitle: 'عادل على ما يبدو',
  provablyFairContentDesc: 'يمكن إثبات نتائج الأطراف الأربعة على أنها عادلة. ',
  provablyFairContentInputFieldsCrashRateTitle: 'معدل الأعطال',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'معدل الأعطال بدون x',
  provablyFairContentInputFieldsRoundHashTitle: 'جولة التجزئة',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'على سبيل المثال ',
  provablyFairContentInputFieldsSubmitBtn: 'إنشاء التوقيع',
  provablyFairContentSignature: 'التوقيع الدائري',
  provablyFairContentErrorsCrashRate: 'يرجى إدخال الرقم في حقل معدل الأعطال',
  resetPasswordTitle: 'إعادة تعيين كلمة المرور',
  resetPasswordNewPasswordTitle: 'كلمة المرور الجديدة',
  resetPasswordNewPasswordErrorsRequired: '*كلمة المرور مطلوبة.',
  resetPasswordNewPasswordErrorsMinLength: 'الحد الأدنى المسموح به هو 8 أحرف.',
  resetPasswordNewPasswordErrorsMaxLength: 'الحد الأقصى المسموح به هو 16 حرفًا.',
  resetPasswordNewPasswordErrorsPattern: '*يجب أن تتكون كلمة المرور من 8 إلى 16 حرفًا أبجديًا رقميًا وخاصًا. ',
  resetPasswordConfirmPasswordTitle: 'تأكيد كلمة المرور',
  resetPasswordConfirmPasswordErrorsNotMatched: 'كلمة المرور غير مطابقة.',
  resetPasswordResetBtn: 'إعادة ضبط',
  passwordResetSuccess: 'تم تغيير كلمة المرور بنجاح، يرجى تسجيل الدخول للاستمتاع باللعب!',
  resetPasswordWentWrong: 'هناك خطأ ما!',
  levelGrand: 'كبير',
  levelMajor: 'رئيسي',
  levelMinor: 'صغير',
  levelMini: 'ميني',
  bannerContentWinDaily: 'اربح يوميا 1000 دولار ',
  bannerContentContest: 'المسابقات الآن ',
  bannerContentjoin: 'انضم إلى مسابقة الرهان اليومية لدينا وكن البطل!  ',
  bannerContentPlayNow: 'العب الان',
  bannerContentTitle: 'فوز بالجائزة الكبرى',
  bannerContentContent: 'العب اكثر',
  bannerContentGameName: 'لعبة تحطم',
  bannerContentGameDesc: 'العب اكثر',
  partners: 'الشركاء',
  gamesGoldenDragon: 'التنين الذهبي',
  gamesCrashGame: 'لعبة تحطم',
  gamesDice: 'حجر النرد',
  gamesHilo: 'هيلو',
  allBetsTitle: 'جميع الرهانات',
  allBetsBetId: 'معرف الرهان',
  allBetsUser: 'مستخدم',
  allBetsPayout: 'سيصرف',
  allBetsAmount: 'كمية',
  allBetsTime: 'وقت',
  allBetsProfit: 'ربح',
  LoginwithOtp: 'تسجيل الدخول باستخدام Otp',
  EnterLoginOtp: 'أدخل تسجيل الدخول Otp',
  submit: 'يُقدِّم',
  footerAboutUs: 'معلومات عنا',
  footerFaq: 'الأسئلة الشائعة',
  footerSitePolicy: 'سياسة الموقع',
  footerResponsibleGambling: 'المقامرة المسؤولة',
  footerTerms: 'الأحكام والشروط',
  signinTitle: 'تسجيل الدخول',
  signinUsername: 'اسم المستخدم / البريد الإلكتروني',
  signinPassword: 'كلمة المرور',
  signinLoginBtn: 'تسجيل الدخول',
  signinSignup: 'اشتراك!',
  signinForgetPassword: 'نسيت كلمة السر؟',
  forgotPasswordMsg: 'أدخل عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.',
  emailLinkMsg: 'تم إرسال رابط إعادة تعيين كلمة المرور إلى البريد الإلكتروني المسجل الخاص بك، يرجى النقر على رابط البريد الإلكتروني وتعيين كلمة المرور الجديدة.',
  resetPasswardConfirm: 'تمت إعادة تعيين كلمة المرور بنجاح',
  signinDontHaveAccount: 'ليس لديك حساب؟ ',
  signinCreateAccount: 'إنشاء حساب',
  signinErrorsUserNameRequired: '*الرجاء إدخال اسم المستخدم/البريد الإلكتروني.',
  signinErrorsUserNameMinLength: 'الحد الأدنى المسموح به هو 4 أحرف.',
  signinErrorsUserNameMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  signinErrorsUserNamePattern: 'الرجاء إدخال اسم مستخدم صالح، مسموح فقط بالأحرف والأرقام.',
  signinErrorsPasswordRequired: '*كلمة المرور مطلوبة.',
  signinErrorsPasswordMinLength: 'الحد الأدنى المسموح به هو 8 أحرف.',
  signinErrorsPasswordMaxLength: 'الحد الأقصى المسموح به هو 16 حرفًا.',
  signinErrorsPasswordPattern: '*يجب أن تتكون كلمة المرور من 8 إلى 16 حرفًا أبجديًا رقميًا وخاصًا. ',
  signinSuccessfullyLoggedIn: 'تم تسجيل الدخول بنجاح.',
  signinResetMailSent: 'إعادة تعيين كلمة المرور المرسلة بالبريد الإلكتروني.',
  signinTryAgain: 'حدث خطأ ما. أعد المحاولة من فضلك.',
  signinResetPasswordUsernameErr: 'الرجاء إدخال اسم المستخدم/البريد الإلكتروني لإعادة تعيين كلمة المرور.',
  signinResetMailSentSuccessContent: 'لقد أرسلنا لك رابط إعادة تعيين كلمة المرور على بريدك الإلكتروني. ',
  signupTitle: 'يسجل',
  signupUsername: 'اسم المستخدم',
  signupEmail: 'بريد إلكتروني',
  signupTermsConditions: 'شروط',
  signupWalletConnect: 'تسجيل الدخول باستخدام المحفظة ',
  signupPassword: 'كلمة المرور',
  signupEighteenPlus: 'عمري يزيد عن 18 عامًا وأوافق على شروط الخدمة',
  signupRegisterBtn: 'اشتراك',
  signupCreateNewAccount: 'انشاء حساب جديد',
  signupFullName: 'الاسم الكامل',
  signupReferralCode: 'كود الإحالة',
  signupConfirmPassword: 'تأكيد كلمة المرور',
  signupUseSocialAccount: 'تسجيل الدخول عبر الشبكات الاجتماعية',
  signupFacebook: ' فيسبوك',
  signupGoogle: ' جوجل',
  signupHaveAccount: 'هل لديك حساب؟ ',
  signupLoginBtn: 'تسجيل الدخول',
  signupErrorsUserNameRequired: '*الرجاء إدخال اسم المستخدم.',
  signupErrorsUserNameMinLength: 'الحد الأدنى المسموح به هو 4 أحرف.',
  signupErrorsUserNameMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  signupErrorsUserNamePattern: 'الرجاء إدخال اسم مستخدم صالح، مسموح فقط بالأحرف والأرقام.',
  signupErrorsFullNameRequired: '*الرجاء إدخال الاسم الكامل.',
  signupErrorsFullNamePattern: 'الرجاء إدخال الاسم الكامل الصحيح، يُسمح فقط بالأحرف والأرقام.',
  signupErrorsEmailRequired: '*الرجاء إدخال البريد الإلكتروني.',
  signupErrorsEmailMinLength: 'الحد الأدنى المسموح به هو 4 أحرف.',
  signupErrorsEmailMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  signupErrorsEmailPattern: 'الرجاء إدخال بريد إلكتروني صحيح.',
  signupErrorsPasswordRequired: '*كلمة المرور مطلوبة.',
  signupErrorsPasswordMinLength: 'الحد الأدنى المسموح به هو 8 أحرف.',
  signupErrorsPasswordMaxLength: 'الحد الأقصى المسموح به للإيداع هو 16 حرفًا.',
  signupErrorsPasswordPattern: '*يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا أبجديًا رقميًا (أحرف كبيرة وصغيرة) وأحرف خاصة. ',
  signupErrorsConfirmPasswordMatch: 'كلمة المرور يجب ان تتطابق.',
  signupPlaceholderUserName: 'دقيقة. ',
  signupPlaceholderEmail: 'على سبيل المثال ',
  signupPlaceholderPassword: 'دقيقة. ',
  signupSomethingWentWrong: 'هناك خطأ ما!',
  signupSignupGoogle: 'تم الاشتراك بنجاح مع جوجل.',
  signupRegistered: 'سجلت بنجاح.',
  signupConfirmAge: 'يرجى تأكيد أن عمرك 18 عامًا.',
  signupSignupFacebook: 'تم التسجيل بنجاح عبر الفيسبوك.',
  signupCookiesConsent: 'يرجى السماح لملفات تعريف الارتباط الخاصة بالطرف الثالث من إعدادات المتصفح الخاص بك باستخدام تسجيل الدخول الاجتماعي.',
  signupRegisterEmailPopupContent: 'لقد أرسلنا لك رسالة التحقق بالبريد الإلكتروني. ',
  accountMenuDeposit: 'إيداع',
  accountMenuCashout: 'المصروفات',
  accountMenujackpotWinners: 'الفائزين بالجائزة الكبرى',
  accountMenuTransactions: 'المعاملات',
  accountMenuPromotions: 'الترقيات',
  accountMenuProfile: 'حساب تعريفي',
  accountMenuLogout: 'تسجيل خروج',
  accountMenuSound: 'صوت',
  accountMenuMusic: 'موسيقى',
  accountMenuWithdraw: 'ينسحب',
  pageNotFound: 'لا يمكننا العثور على الصفحة التي تبحث عنها!',
  error: 'خطأ',
  somethingWentWrong: 'هناك خطأ ما!',
  verifyEmailThankYou: 'شكرًا لك!',
  verifyEmailEmailIsVerified: 'تم التحقق من بريدك الإلكتروني. ',
  verifyEmailGoToLogin: 'انتقل إلى صفحة تسجيل الدخول',
  verifyEmailWentWrong: 'هناك خطأ ما!',
  verifyEmailTokenExpired: 'تم التحقق من البريد الإلكتروني بالفعل أو انتهت صلاحية الرابط، يرجى إعادة إرسال رابط التحقق من البريد الإلكتروني من خلال تقديم بريدك الإلكتروني المسجل أدناه. ',
  verifyEmailGoToHome: 'اذهب إلى الصفحة الرئيسية',
  verifyEmailSuccessMsg: 'تم التحقق من البريد الإلكتروني بنجاح.',
  bonusSectionSpanText: 'يقدم الأكثر سخاء ومتنوعة',
  bonusSectionBonuses: 'المكافآت',
  bonusSectionSubtitleText: 'العب بسرور واحصل على مكافآت إضافية أثناء اللعبة. ',
  JoiningBonusTitle: 'مكافأة الانضمام',
  JoiningBonusButton: 'يدعون الآن',
  JoiningBonusDesc: 'تشجع مثل هذه المكافآت لاعبي الكازينو عبر الإنترنت على المقامرة باستخدام عملة البيتكوين أو العملات المشفرة الأخرى. ',
  depositBonusTitle: 'مكافأة الإيداع',
  depositBonusButton: 'إيداع',
  depositBonusDesc: 'تشجع مثل هذه المكافآت لاعبي الكازينو عبر الإنترنت على المقامرة باستخدام عملة البيتكوين أو العملات المشفرة الأخرى. ',
  refferBonusTitle: 'مكافأة الإحالة',
  refferBonusButton: 'إيداع',
  refferBonusDesc: 'تشجع مثل هذه المكافآت لاعبي الكازينو عبر الإنترنت على المقامرة باستخدام عملة البيتكوين أو العملات المشفرة الأخرى. ',
  aboutSectionSpanText2: 'يسمح لك الكازينو عبر الإنترنت بلعب ألعابك المفضلة إما مجانًا أو بالعملة المشفرة. ',
  aboutSectionSpanText3: ' في هذا العالم الافتراضي للمقامرة بالبيتكوين، يمكنك الاستمتاع بالعديد من الفرص — لعب ألعاب الكازينو المفضلة لديك، والحصول على المكافآت، والمشاركة في المسابقات والعروض الترويجية.',
  verifiedSectionTitle: 'برامج تم التحقق منها ومرخصة',
  verifiedSectionSpanText1: 'نحن لا نقوم بنسخ أو اعتماد البرامج من مواقع الويب الأخرى. ',
  faqSectionTitle: 'الأسئلة الشائعة من اللاعبين',
  faqSectionForgetPasswordTitle: 'لقد نسيت كلمة المرور.',
  faqSectionForgetPasswordDesc: 'لم تقدم عنوان بريد إلكتروني مخصصًا للطوارئ لحسابك. ',
  faqSectionMyDepositCreditedTitle: 'لم يتم إضافة الوديعة الخاصة بي.',
  faqSectionMyDepositCreditedDesc: ' محتوى العنصر النائب لهذا الأكورديون، والذي يهدف إلى توضيح الفصل الدراسي. ',
  SupportedCurrencyTitle: 'العملة المدعومة',
  SupportedCurrencySpanText1: 'نحن لا نقوم بنسخ أو اعتماد البرامج من مواقع الويب الأخرى. ',
  CarouselSectionTitle: 'تحطم هودل',
  CarouselSectionSubTitle: ' العب المزيد من الألعاب واحصل على فرصة للفوز. ',
  WalletSettingTitle: 'إعداد المحفظة',
  WalletSettingHideZero: 'إخفاء الأرصدة الصفرية',
  WalletSettingHideZeroInfo: 'لن يظهر رصيدك الصفري في محفظتك',
  WalletSettingDisplayFiat: 'عرض التشفير بالعملة الورقية',
  WalletSettingDisplayFiatInfo: 'جميع الرهانات',
  WalletSettingNoteForApprox: 'يرجى ملاحظة أن هذه تقريبية للعملة.',
  WalletSettingSave: 'يحفظ',
  WalletSettingNoWalletFound: 'لم يتم العثور على المحفظة',
  jackpotWinnersNoWinnerAvailable: 'لا يوجد فائزون متاحون',
  jackpotWinnersNew: 'جديد',
  jackpotWinnersHeader: 'الفائز بالجائزة الكبرى',
  jackpotDetailsHeader: 'تفاصيل الفوز بالجائزة الكبرى',
  jackpotDetailsCampaignPeriod: 'فترة الحملة',
  jackpotDetailsFrom: 'من',
  jackpotDetailsTo: 'ل',
  jackpotDetailsBettingRule: 'قاعدة الرهان',
  jackpotDetailsMinBetAmount: 'الحد الأدنى لمبلغ الرهان',
  jackpotDetailsMaxBetAmount: 'الحد الأقصى لمبلغ الرهان',
  jackpotDetailsBetPercentage: 'نسبة الرهان',
  jackpotDetailsWinningRuleHeader: 'قاعدة الفوز',
  jackpotDetailsWinningRule: 'إن وضع الرهانات بمبلغ رهان أعلى سيزيد من فرص الفوز.',
  jackpotDetailsProfitRule: 'إذا كان مبلغ الرهان بين minBetAmount وmaxBetAmount، فسيتم إضافة betPercentage% من مبلغ الرهان إلى مبلغ الجائزة الكبرى.',
  profileTitle: 'تفاصيل شخصية',
  profileFName: 'الاسم الأول',
  profileLname: 'اسم العائلة',
  profileUserName: 'اسم المستخدم',
  profileDob: 'تاريخ الميلاد',
  profileEmail: 'عنوان البريد الإلكتروني',
  profilePhone: 'رقم التليفون',
  profileChange: 'يتغير',
  profileSave: 'حفظ التغيير',
  profilePassword: 'كلمة المرور',
  profileSubmit: 'يُقدِّم',
  profileUploadProfile: 'تحميل الملف الشخصي',
  profileCurrentPassword: 'كلمة السر الحالية',
  profileNewPassword: 'كلمة المرور الجديدة',
  profileConfirmPassword: 'تأكيد كلمة المرور',
  profileScanQRCode: 'مسح رمز الاستجابة السريعة',
  profileEnterSecretCode: 'أدخل الرمز السري',
  profileErrorsFirstNameRequired: 'الإسم الأول مطلوب.',
  profileErrorsFirstNamePattern: 'الرجاء إدخال اسم صالح.',
  profileErrorsFirstNameMinLength: 'الحد الأدنى المسموح به هو 3 أحرف.',
  profileErrorsFirstNameMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  profileErrorsLastNameRequired: 'إسم العائلة مطلوب.',
  profileErrorsLastNamePattern: 'الرجاء إدخال اسم صالح.',
  profileErrorsLastNameMinLength: 'الحد الأدنى المسموح به هو 3 أحرف.',
  profileErrorsLastNameMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  profileErrorsChangePasswordOldRequired: 'كلمة المرور القديمة مطلوبة.',
  profileErrorsChangePasswordOldPattern: 'الرجاء إدخال كلمة مرور صالحة.',
  profileErrorsChangePasswordOldMinLength: 'الحد الأدنى المسموح به هو 8 أحرف.',
  profileErrorsChangePasswordOldMaxLength: 'الحد الأقصى المسموح به هو 16 حرفًا.',
  profileErrorsChangePasswordNewRequired: 'كلمة المرور الجديدة مطلوبة.',
  profileErrorsChangePasswordNewPattern: 'الرجاء إدخال كلمة مرور صالحة.',
  profileErrorsChangePasswordNewMinLength: 'الحد الأدنى المسموح به هو 8 أحرف.',
  profileErrorsChangePasswordNewMaxLength: 'الحد الأقصى المسموح به هو 16 حرفًا.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'كلمة المرور غير مطابقة.',
  profileErrorsChangePasswordNewPasswordMatched: 'كلمة المرور الجديدة لا يمكن أن تكون نفس كلمة المرور الحالية.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'تأكيد كلمة المرور مطلوب.',
  profileErrorsUserNameRequired: 'اسم المستخدم مطلوب.',
  profileErrorsUserNamePattern: 'الرجاء إدخال اسم صالح.',
  profileErrorsUserNameMinLength: 'الحد الأدنى المسموح به هو 3 أحرف.',
  profileErrorsUserNameMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  profileErrorsDobRequired: 'تاريخ الميلاد مطلوب.',
  profileErrorsEmailRequired: 'البريد الالكتروني مطلوب.',
  profileErrorsPostcodeRequired: 'الرمز البريدي مطلوب.',
  profileErrorsCityRequired: 'المدينة مطلوبة.',
  profileErrorsCurrencyRequired: 'العملة مطلوبة.',
  profileErrorsAddressRequired: 'العنوان مطلوب.',
  profileErrorsCountryRequired: 'الدولة مطلوبة.',
  profileErrorsEmailPattern: 'الرجاء إدخال بريد إلكتروني صحيح.',
  profileErrorsEmailMinLength: 'الحد الأدنى المسموح به هو 3 أحرف.',
  profileErrorsEmailMaxLength: 'الحد الأقصى المسموح به هو 20 حرفًا.',
  profileMessagesProfileUpdate: 'تم تحديث الملف الشخصي بنجاح.',
  profileMessagesChangePassword: 'تم تحديث كلمة السر بنجاح.',
  profileProfilePicProfilePicUpload: 'تحميل صورة الملف الشخصي',
  profileNoNewChanges: 'لا يمكن تحديث الملف الشخصي! ',
  profileLoggedInWithSocial: 'لقد قمت بتسجيل الدخول باستخدام تسجيل الدخول الاجتماعي.',
  profileSideNavigatorSound: 'صوت',
  profileSideNavigatorMusic: 'موسيقى',
  profileSideNavigatorProfile: 'ملفي',
  profileSideNavigatorBetHistory: 'تاريخ الرهان',
  profileSideNavigatorTransactions: 'المعاملات',
  profileSideNavigatorDeposit: 'إيداع',
  profileSideNavigatorWithdraw: 'ينسحب',
  profileSideNavigatorGameLimits: 'حدود اللعبة',
  profileSideNavigatorReferral: 'الإحالة',
  profileSideNavigatorChangePassword: 'تغيير كلمة المرور',
  profileSideNavigatorTermsConditions: 'شروط',
  profileSideNavigatorLogOut: 'تسجيل خروج',
  profileSideNavigatorProvablyFair: 'عادل على ما يبدو',
  WalletInfoSectionNotAvailable: 'غير متاح',
  WalletInfoSectionRealBalance: 'التوازن الحقيقي',
  GameLimitsMinimumBet: 'الحد الأدنى للرهان',
  GameLimitsMaximumBet: 'الحد الأقصى للرهان',
  GameLimitsMaxWinFor1Bet: 'الحد الأقصى للفوز برهان واحد',
  ProvablyFairSubTitle: 'تستخدم هذه اللعبة تقنية Provable Fair لتحديد نتيجة اللعبة. ',
  ProvablyFairMaximumBet: 'الحد الأقصى للرهان:',
  ProvablyFairMaxWinFor1Bet: 'الحد الأقصى للفوز برهان واحد',
  promotionsTitle: 'الترقيات',
  promotionsNoLosingData: 'عذرًا، لا تتوفر عروض ترويجية لمكافأة الخسارة ',
  promotionsNoDepositData: 'عذرًا، لا تتوفر عروض ترويجية لمكافأة الإيداع ',
  promotionsCurrency: ' عملة.',
  promotionsViewBtn: 'منظر',
  promotionsClaimSuccess: 'لقد قمت بالمطالبة بنجاح',
  promotionsAvailability: 'التوفر: ',
  promotionsAvailabilityTabLosing: 'خسارة المكافأة',
  promotionsAvailabilityTabDeposit: 'مكافأة الإيداع',
  promotionsDepositBonusTableCode: 'شفرة',
  promotionsDepositBonusTableMinDeposit: 'دقيقة. ',
  promotionsDepositBonusTablePercentage: 'نسبة مئوية',
  promotionsDepositBonusTableMaxBonus: 'ماكس مكافأة',
  promotionsDepositBonusTableRolloverMultipler: 'مضاعف التمديد',
  promotionsDepositBonusTableMaxRolloverPerBet: 'الحد الأقصى للرهان لكل رهان',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'حان الوقت لتحقيق هدف التمديد',
  promotionsBonusCode: 'رمز المكافأة: ',
  promotionsLossesClaim: 'يمكن المطالبة بالخسائر (خلال فترة الحملة): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'نسبة مئوية',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'دقيقة. ',
  promotionsBonusStatus: 'حالة المكافأة: ',
  promotionsBonusAmount: 'مبلغ المكافأة: ',
  promotionsRolloverTarget: 'هدف التمديد: ',
  promotionsRolloverAchieved: 'تم تحقيق التمديد: ',
  promotionsActivatebtn: 'تفعيل',
  promotionsClaimBtn: 'مطالبة',
  promotionsCancelBtn: 'يلغي',
  promotionsBackBtn: 'خلف',
  promotionsBonus: 'علاوة',
  promotionsTermAndConditions: ' شروط',
  promotionsClaimDepositBonusTitle: 'خطوات المطالبة بمكافأة الإيداع',
  promotionsClaimDepositBonusFirst: 'تفعيل رمز المكافأة',
  promotionsClaimDepositBonusSecond: 'إيداع المبلغ في المحفظة',
  promotionsClaimDepositBonusThird: 'تحقيق هدف التمديد للمطالبة بالمكافأة',
  promotionsClaimLosingBonusTitle: 'خطوات المطالبة بالمكافأة الخاسرة',
  promotionsClaimLosingBonusFirst: 'تفعيل رمز المكافأة',
  promotionsClaimLosingBonusSecond: 'العب ألعابك المفضلة',
  promotionsClaimLosingBonusThird: 'انقر فوق "المطالبة بالخسائر" للمطالبة بالخسائر خلال فترة الحملة',
  promotionsWentWrong: 'هناك خطأ ما!',
  transactionTitle: 'المعاملات',
  transactionBetId: 'معرف الرهان',
  transactionUser: 'مستخدم',
  transactionPayout: 'سيصرف',
  transactionAmount: 'كمية',
  transactionProfit: 'ربح',
  transactionCashout: 'المصروفات',
  transactionBet: 'رهان',
  transactionMultix: 'متعدد.x',
  transactionWin: 'يفوز',
  transactionFairness: 'الإنصاف',
  transactionReferFriend: 'أوص أحد الأصدقاء',
  transactionTotal: 'إجمالي الرهانات',
  transactionWins: 'انتصارات',
  transactionRefresh: 'ينعش',
  transactionFilterTopMultipliers: 'أعلى المضاعفات',
  transactionFilterHugeWins: 'انتصارات ضخمة',
  transactionFilterBiggestWins: 'أكبر الانتصارات',
  transactionFilterMultipliers: 'المضاعفات',
  transactionRealBalance: 'التوازن الحقيقي',
  depositWithdrawTXDate: 'تاريخ',
  depositWithdrawTXAmount: 'كمية',
  depositWithdrawTXTxId: 'معرف TxID',
  depositWithdrawTXTxType: 'نوع المعاملة',
  depositWithdrawTXStatus: 'حالة',
  depositWithdrawTXNoData: 'لا تتوافر بيانات.',
  depositTitle: 'إيداع',
  depositHistory: 'تاريخ',
  depositSubTitle: 'لا يمكن للمستخدم إيداع أو سحب العملة الورقية. ',
  depositCopyNotSupported: 'متصفحك لا يدعم النسخ التلقائي إلى الحافظة. يرجى محاولة نسخ الرمز يدويًا.',
  depositCopied: 'تم نسخ العنوان.',
  depositAddressCreated: 'تم إنشاء العنوان بنجاح.',
  depositGenerateAddress: 'إنشاء عنوان',
  depositWarningText: 'يرجى عدم الإيداع عبر التحويل عبر السلسلة',
  withdrawTitle: 'ينسحب',
  withdrawSubTitle: 'لا يمكن للمستخدم إيداع أو سحب العملة الورقية. ',
  withdrawNoteDesc: 'يرجى التأكد من عدم إدخال عنوان BEP2 أو BEP20 (BSC) كمحفظة عنوان سحب LTC',
  withdrawNoteSublist: ' من فضلك لا تنسحب كتحويل عبر السلسلة',
  withdrawHistory: 'تاريخ',
  withdrawWithdrawBtn: 'طلب سحب',
  withdrawFees: 'رسوم الانسحاب {{fees}} {{token}}',
  withdrawWithdrawPending: 'طلب السحب الخاص بك معلق. ',
  withdrawEnterFields: 'أدخل مبلغ السحب وعنوان محفظتك.',
  withdrawSelectWallet: 'الرجاء تحديد المحفظة للانسحاب.',
  withdrawAmountFieldTitle: 'المبلغ (الحد الأدنى 0.03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'لك {{wallet.currency.code}} عنوان',
  withdrawAddressFieldPlaceholder: 'اكتب عنوانك',
  withdrawNoBalance: 'لا يوجد رصيد كافي.',
  withdrawMinAmount: 'الرجاء إدخال مبلغ أكبر من أو يساوي {{min}} .',
  fiatCurrencyInfo: 'لا يمكن للمستخدم إيداع أو سحب العملة الورقية. ',
  paginationNextBtn: 'التالي',
  paginationPrevBtn: 'سابق',
  wentWrong: 'هناك خطأ ما.',
  referralTitle: 'الإحالة',
  referralSubTitle: 'قم بإحالة أصدقائك',
  referralEarn: ' اربح 150 روبية هندية لكل منهما',
  referralReferAndEarn: 'قم بالإشارة واكسب مكافأة لكل إحالة',
  referralReferPara: ' إذا قمت بإحالة هذا الرمز إلى أي مستخدم',
  referralReferLink: 'الرجوع الرابط',
  referralCopyReferralCode: 'انسخ رمز الإحالة',
  referralTotalRewards: 'إجمالي المكافأة',
  TwoWayAuthentication: 'المصادقة في اتجاهين',
  LoginRequired: 'تسجيل الدخول مطلوب!',
  BetPlacedSuccessfully: 'تم وضع الرهان بنجاح',
  BetAddedInQueueSuccessfully: 'تمت إضافة الرهان في قائمة الانتظار بنجاح',
  BetRemovedFromQueueSuccessfully: 'تمت إزالة الرهان من قائمة الانتظار بنجاح',
  BetCancelledSuccessfully: 'تم إلغاء الرهان بنجاح',
  PlayerEscapedSuccessfully: 'هرب اللاعب بنجاح',
  CopiedSuccessfull: 'تم النسخ بنجاح',
  NotEnoughBalance: 'لا يوجد رصيد كافي',
  AutoBetStarted: 'بدأ الرهان التلقائي ',
  AutoBetFinished: 'انتهى الرهان التلقائي ',
  IdCopied: 'تم نسخ المعرف',
  usersConsent: 'من خلال الدخول إلى هذا الموقع، أؤكد أن عمري 18 عامًا، وقد قمت بمراجعة وفهم وموافقة على',
  termsOfService: 'شروط الخدمة',
  email: 'بريد إلكتروني',
  playNow: 'العب الان',
  orContinueWith: 'أو الاستمرار مع',
  metamaskExtensionNotAvailable: 'الرجاء تثبيت ملحق metaMask وتحديث الصفحة.',
  cashier: 'أمين الصندوق',
  account: 'حساب',
  hello: 'مرحبًا',
  balance: 'توازن',
  referrals: 'الإحالات',
  settings: 'إعدادات',
  withdrawals: 'عمليات السحب',
  demoWallet: 'المحفظة التجريبية',
  myAccount: 'حسابي',
  wallet: 'محفظة',
  user: 'مستخدم',
  USD: 'دولار أمريكي',
  amountIsRequired: 'المبلغ مطلوب',
  minDepositAmount: 'يجب أن يكون مبلغ الإيداع أكبر من {{min}}.',
  maxDepositAmount: 'يجب أن يكون مبلغ الإيداع أقل من {{max}}.',
  minWithdrawAmount: 'يجب أن يكون مبلغ السحب أكبر من {{min}}',
  maxWithdrawAmount: 'يجب أن يكون مبلغ السحب أقل من {{max}}',
  withdrawMustLessThanWallet: 'يجب أن يكون مبلغ السحب أقل من مبلغ محفظتك',
  accountTypeIsRequired: 'نوع الحساب مطلوب.',
  mobileNumberLength: 'رقم الجوال يجب أن يكون من {{length}} أرقام',
  phoneIsRequired: 'الهاتف مطلوب',
  cpfError: 'يجب أن يكون CPF {{length}} الشخصيات',
  cpfRequiredError: 'مطلوب CPF',
  cnpjError: 'يجب أن يكون CNPJ من {{length}} الشخصيات',
  mainMenu: 'القائمة الرئيسية',
  casino: 'كازينو',
  sports: 'رياضات',
  crypto: 'تشفير',
  NFT: 'نفت',
  poker: 'لعبة البوكر',
  earnRewards: 'كسب المكافآت',
  rankUp: 'رتبة أعلى',
  inviteFriends: 'ادعو أصدقاء',
  partnerProgram: 'برنامج الشريك',
  home: 'بيت',
  sportsbook: 'كتاب رياضي',
  menu: 'قائمة طعام',
  liveBets: 'الرهانات الحية',
  allBets: 'كل الرهانات',
  highRollers: 'بكرات عالية',
  luckyBets: 'الرهانات المحظوظة',
  trade: 'تجارة',
  game: 'لعبة',
  player: 'لاعب',
  time: 'وقت',
  wager: 'الرهان',
  multiplierBan: 'المضاعف',
  payoutText: 'سيصرف',
  bannerHeadOne: 'تجربة الابتكار الحقيقي مع أعلى المستويات',
  bannerHeadTwo: 'برنامج المكافآت داخل الصناعة.',
  registerNow: 'سجل الان',
  monthly: 'شهريا',
  weekly: 'أسبوعيا',
  daily: 'يوميا',
  winner1: 'الفائز 1',
  winner2: 'الفائز 2',
  winner3: 'الفائز 3',
  spin: 'دوران',
  spinsWon: 'الدورات التي فاز بها',
  joinedOn: 'انضم إلى في',
  wageringAmount: 'مبلغ الرهان',
  name: 'اسم',
  allGames: 'جميع الألعاب',
  allSports: 'جميع الألعاب الرياضية',
  cryptoFutures: 'العقود الآجلة للعملات المشفرة',
  tradeNow: 'استبدل الان',
  allTables: 'جميع الجداول',
  nft: 'نفت',
  viewAllGames: 'شاهد جميع الألعاب',
  viewAll: 'اظهار الكل',
  rewards: 'المكافآت',
  profile: 'حساب تعريفي',
  totalBets: 'إجمالي الرهانات',
  totalWagered: 'إجمالي الرهان',
  joinDate: 'تاريخ الانضمام',
  displaySocialLinks: 'عرض الحسابات الاجتماعية المرتبطة',
  privateProfile: 'الملف الشخصي الخاص',
  rank: 'رتبة',
  winner: 'الفائزون',
  nextRank: 'المرتبة التالية',
  changeUsername: 'غير اسم المستخدم',
  newPassword: 'كلمة المرور الجديدة',
  changeName: 'تغيير الإسم',
  linkSocialAccount: 'ربط الحساب الاجتماعي',
  privateProfileTooltip: 'بعد جعل ملفك الشخصي خاصًا، لن يرى المستخدمون الآخرون اسم المستخدم الخاص بك إلى جانب الرهانات والسباقات وما إلى ذلك.',
  socialLinksTooltip: 'المحتوى غير متوفر',
  rankTooltip: 'المحتوى غير متوفر',
  balances: 'أرصدة',
  createReferralBalance: 'إنشاء رصيد الإحالة',
  total: 'المجموع',
  switchBalance: 'تبديل الرصيد',
  mainAccount: 'الحساب الرئيسي',
  tips: 'نصائح',
  receivedAmount: 'المبلغ الذي تسلمه',
  dateOrTime: 'التاريخ/الوقت',
  view: 'منظر',
  allChains: 'جميع السلاسل',
  creditAmount: 'مبلغ الائتمان',
  setCustomReferralCode: 'قم بتعيين رمز الإحالات المخصص',
  changeEmail: 'تغيير الايميل',
  newEmail: 'بريد إلكتروني جديد',
  createPassword: 'إنشاء كلمة المرور',
  newUsername: 'اسم مستخدم جديد',
  username: 'اسم المستخدم',
  changePassword: 'تغيير كلمة المرور',
  updateProfileMsg: 'تم تحديث الملف الشخصي بنجاح',
  twoFactorAuthentication: 'توثيق ذو عاملين',
  twoFactorAuthenticationTitle: 'يوصى بشدة باستخدام المصادقة الثنائية لأنها تحمي حسابك بكل من كلمة المرور الخاصة بك وهاتفك.',
  twoFactorAuthenticationSubTitle: 'يجب ضبط البريد الإلكتروني وكلمة المرور لتفعيل المصادقة الثنائية. ',
  enable2fa: 'تمكين 2FA',
  verifyIdentityByKyc: 'التحقق من هويتك (kyc)',
  oldPassword: 'كلمة المرور القديمة',
  live: 'يعيش',
  soccer: 'كرة القدم',
  tennis: 'تنس',
  fifa: 'اتحاد كرة القدم',
  basketball: 'كرة سلة',
  iceHockey: 'هوكي الجليد',
  volleyball: 'الكرة الطائرة',
  tableTennis: 'تنس طاولة',
  baseball: 'البيسبول',
  rubgy: 'كرة القدم الامريكية',
  golf: 'جولف',
  boxing: 'ملاكمة',
  myBets: 'رهاني',
  favourites: 'المفضلة',
  americanFootball: 'كرة القدم الأمريكية',
  buyCrypto: 'شراء التشفير',
  oldEmail: 'البريد الإلكتروني القديم',
  loggedInSuccess: 'تم تسجيل الدخول بنجاح',
  loggedOutSuccess: 'تم تسجيل الخروج بنجاح',
  wagerLimit: 'تم تعيين حد الرهان بنجاح',
  lossLimit: 'تم تعيين حد الخسارة بنجاح',
  accountDisable: 'تم تعطيل الحساب بنجاح',
  sessionTimeout: 'تم ضبط مهلة الجلسة بنجاح',
  signup: 'تم التسجيل بنجاح',
  depositLimit: 'تم تعيين حد الإيداع بنجاح',
  tokenVerify: 'تم إرسال الرمز إلى بريدك الإلكتروني، يرجى التحقق',
  emailVerify: 'تم التحقق من البريد الإلكتروني',
  emailChangedSuccess: 'تم تغيير البريد الإلكتروني بنجاح',
  profileUpdatedSuccess: 'تحديث الملف الشخصي',
  otpVerified: 'تم التحقق من OTP',
  twoFADeactivated: 'تم إلغاء تنشيط مصادقة 2FA',
  internalServerError: 'خطأ في الخادم الداخلي',
  unAuthorized: 'غير مصرح',
  dataNotFound: 'لم يتم العثور على بيانات',
  setReferralCode: 'تعيين رمز الإحالة...',
  ultimate: 'ذروة',
  notFound: 'غير معثور عليه',
  casinoTransactions: 'معاملات الكازينو',
  sportsbookTransactions: 'المعاملات الرياضية',
  status: 'حالة',
  success: 'نجاح',
  failed: 'فشل',
  pending: 'قيد الانتظار',
  date: 'تاريخ',
  to: 'ل',
  gameName: 'اسم اللعبة',
  transactionId: 'رقم المعاملة',
  roundId: 'معرف الجولة',
  bet: 'رهان',
  win: 'يفوز',
  transactionType: 'نوع المعاملة',
  at: 'في',
  downloadAll: 'تحميل الكل',
  gameText: 'لعبة',
  betId: 'معرف الرهان',
  gameId: 'رقم اللعبة',
  actionType: 'نوع الإجراء',
  searchGames: 'ألعاب البحث',
  provider: 'مقدمي الخدمات',
  search: 'يبحث',
  sortBy: 'ترتيب حسب',
  popular: 'شائع',
  challengePool: 'تجمع التحدي',
  loadMore: 'تحميل المزيد',
  bitcoin: 'بيتكوين',
  chat: 'محادثة',
  trades: 'الصفقات',
  battles: 'المعارك',
  loginToChat: 'تسجيل الدخول للدردشة',
  rules: 'قواعد',
  chatRule1: 'لا تقم بمضايقة أو إهانة المستخدمين الآخرين',
  chatRule2: 'لا تتسول أو تطلب القروض أو التوافه أو النصائح',
  chatRule5: 'لا تعلن عن أي شكل من أشكال التداول أو شراء أو بيع الخدمات',
  chatRule6: 'لا تشارك أو تعلن عن رمز الإحالة الخاص بك',
  chatRule7: 'لا تطلب أن تصبح موظفا',
  chatRule8: 'الانجليزية فقط',
  chatRule9: 'احترام التعديلات والمسؤولين والمستخدمين الآخرين',
  send: 'يرسل',
  expand: 'يوسع',
  signIn: 'تسجيل الدخول',
  cross: 'يعبر',
  collapse: 'ينهار',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'إزالة من المفضلة',
  addToFavourite: 'أضف إلى المفضلة',
  footerAboutSite: 'هل نتحدث قليالً عن العلم؟ موقع كاش ماشين كازينو هو جزء منك، ألننا جمي ًعا مكونون من نفس الذرات. تفاعلك معه يؤكد هذه .الصلة.',
  footerRightsReserved: 'جميع الحقوق محفوظة. Casino Machine Сash',
  signupFirstName: 'الاسم الأول',
  signupAddress: 'عنوان',
  signupCity: 'مدينة',
  signupPostcode: 'شفرة البريد ',
  signupCounty: 'دولة',
  signupCurrency: 'عملة',
  signupGender: 'جنس',
  signupMan: 'رجل',
  signupWomen: 'نحيف',
  signupOther: 'آخر',
  signupLoginDetails: 'تسجيل الدخول التفاصيل',
  signupPersonalDetails: 'تفاصيل شخصية',
  signupConfirm: 'يتأكد',
  signupPrivacyPolicy: 'لقد قرأت وقبلت سياسة الخصوصية',
  signupTermAndConditions: 'أوافق على معالجة بياناتي الشخصية. ',
  signupNewsLetter: 'أرغب في الحصول على مكافآت أو عروض ترويجية أو أخبار أو معلومات أخرى ذات صلة بشكل دوري عن طريق رسالة إخبارية.',
  signupSms: 'أرغب في تلقي المكافآت أو العروض الترويجية أو الأخبار أو غيرها من المعلومات ذات الصلة بشكل دوري عبر الرسائل النصية القصيرة.',
  initialHeaderContent: 'احصل على نسبة مذهلة 100%',
  secoundaryHeaderContent: 'مكافأة ترحيب تصل إلى 100 دولار',
  playNowButton: 'العب الان',
  registerButtonContent: 'يسجل',
  results: 'نتائج',
  refresh: 'ينعش',
  cancel: 'يلغي',
  select: 'يختار',
  current: 'حاضِر',
  set: 'تعيين',
  remove: 'يزيل',
  save: 'يحفظ',
  not: 'لا',
  before: 'قبل',
  after: 'بعد',
  action: 'فعل',
  withdrawal: 'انسحاب',
  requested: 'مطلوب',
  withdraw: 'ينسحب',
  areYouSure: 'هل أنت متأكد',
  yes: 'نعم',
  no: 'لا',
  cancelled: 'ألغيت',
  approved: 'موافقة',
  refunded: 'ردها',
  chargeback: 'رد المبالغ المدفوعة',
  processedOn: 'تمت معالجتها على',
  transaction: 'عملية',
  dateTime: 'التاريخ/الوقت',
  more: 'أكثر',
  fromDate: 'من التاريخ',
  toDate: 'ان يذهب في موعد',
  percentage: 'نسبة مئوية',
  info: 'معلومات',
  min: 'دقيقة',
  max: 'الأعلى',
  change: 'يتغير',
  continueToSite: 'المتابعة إلى الموقع',
  choose: 'يختار',
  enterWithdrawalAmount: 'اختر أو أدخل مبلغ السحب الخاص بك',
  capDeposit: 'إيداع',
  capWithdrawal: 'انسحاب',
  capAmount: 'كمية',
  minimum: 'الحد الأدنى',
  required: 'مطلوب',
  of: 'ل',
  isRequired: 'مطلوب',
  isAllowed: 'مسموح به',
  method: 'طريقة',
  capWithdraw: 'ينسحب',
  showLess: 'عرض أقل',
  clearFilter: 'مرشح واضح',
  quantity: 'كمية',
  reset: 'إعادة ضبط',
  incorrect: 'غير صحيح',
  load: 'حمولة',
  applied: 'مُطبَّق',
  ok: 'نعم',
  list: 'قائمة',
  all: 'الجميع',
  favorite: 'مفضل',
  endPointNotFound: 'هناك خطأ ما في اتصال الشبكة',
  category: 'فئة',
  emailUserNameRequired: 'البريد الإلكتروني/اسم المستخدم مطلوب',
  emailUserNamePlaceHolder: 'أدخل بريدك الإلكتروني أو اسم المستخدم',
  passwordRequired: 'كلمة المرور مطلوبة',
  accountFrozen: 'حسابك مجمد حتى',
  resendEmail: 'إعادة إرسال البريد الإلكتروني',
  resendLink: 'إعادة إرسال الرابط',
  userLoggedOut: 'تم تسجيل الخروج بنجاح',
  emailAlreadyRegistered: 'عنوان البريد الإلكترونى هذا مسجل بالفعل',
  userNameAlreadyTaken: 'أسم المستخدم مأخوذ مسبقا',
  fillAllFields: 'املأ جميع الحقول قبل الانتقال إلى الخطوة التالية',
  pleaseAccept: 'الرجاء الموافقة',
  acceptPrivacyPolicy: 'سياسة الخصوصية',
  acceptTerms: 'أحكام وشروط',
  modeOfComm: ' وطريقة الاتصال',
  beforeNextStep: 'قبل الانتقال إلى الخطوة التالية',
  userNamePlaceholder: 'ادخل اسم المستخدم',
  min8Characters: 'الحد الأدنى 8 أحرف',
  min1Number: 'الحد الأدنى 1 رقم',
  min1LowerCase: 'حرف واحد صغير على الأقل',
  min1UpperCase: 'الحد الأدنى 1 حرف كبير',
  min1SpecialChar: 'حرف خاص واحد على الأقل',
  passwordTip: 'يجب أن تحتوي كلمة المرور على ثمانية أحرف على الأقل، بما في ذلك حرف كبير واحد وحرف صغير واحد ورقم واحد وحرف خاص واحد على الأقل',
  passwordPlaceholder: 'أدخل كلمة المرور',
  confirmPasswordPlaceholder: 'أدخل تأكيد كلمة المرور',
  acceptAll: 'قبول الكل',
  firstNamePlaceholder: 'أدخل الاسم الأول',
  lastNamePlaceholder: 'إدخال اسم آخر',
  addressPlaceholder: 'أدخل العنوان',
  cityPlaceholder: 'أدخل المدينة',
  postCodePlaceholder: 'أدخل الرمز البريدي',
  phonePlaceholder: 'أدخل رقم الهاتف',
  invalidEmail: 'بريد إلكتروني خاطئ',
  emailRequired: 'البريد الإلكتروني (مطلوب',
  confirmPasswordRequired: 'تأكيد كلمة المرور مطلوبة',
  firstNameRequired: 'الاسم الأول مطلوب',
  lastNameRequired: 'نشاط اسم مطلوب',
  phoneRequired: 'الهاتف مطلوب',
  dateOfBirthRequired: 'تاريخ الميلاد مطلوب',
  genderRequired: 'الجنس مطلوب',
  userNameRequired: 'اسم المستخدم (مطلوب',
  addressRequired: 'العنوان مطلوب',
  cityRequired: 'المدينة مطلوبة',
  postCodeRequired: 'الرمز البريدي مطلوب',
  currencyCodeRequired: 'رمز العملة مطلوب',
  countryRequired: 'البلد مطلوب',
  matchPassword: 'كلمة المرور يجب ان تتطابق',
  fName3Chars: 'يجب أن يتكون الاسم الأول من 3 أحرف على الأقل',
  onlyAlphabet: 'الحروف الهجائية مسموحة فقط',
  lName3Chars: 'يجب أن يتكون الاسم الأخير من 3 أحرف على الأقل',
  mustBe18: 'يجب أن يكون عمرك 18 عامًا حتى تتمكن من التسجيل',
  mustBeUnder200: 'يجب أن يكون عمرك أقل من 200 عام حتى تتمكن من التسجيل',
  validDOB: 'من فضلك ادخل تاريخ ميلاد صحيح',
  max20Characters: 'الحد الأقصى المسموح به هو 20 حرفًا',
  min3Characters: 'الحد الأدنى 3 أحرف مطلوبة',
  max100Characters: 'الحد الأقصى المسموح به هو 100 حرف',
  max50Characters: 'الحد الأقصى المسموح به هو 50 حرفًا',
  invalidNumber: 'رقم غير صالح',
  preferredLanguageRequired: 'اللغة المفضلة مطلوبة',
  invalidPass: 'رمز مرور خاطئ',
  prevBtn: 'سابق',
  dayRequired: 'اليوم مطلوب',
  monthRequired: 'الشهر مطلوب',
  yearRequired: 'السنة مطلوبة',
  uploadImage: 'تحميل الصور',
  removeImage: 'إزالة الصورة',
  preferredLanguage: 'اللغة المفضلة',
  wantToChangePass: 'هل تريد تغيير كلمة المرور؟',
  selectCountryCode: 'حدد رمز البلد',
  selectPreferredLanguage: 'حدد اللغة المفضلة',
  fName50Chars: 'يجب أن يتكون الاسم الأول من 50 حرفًا على الأقل',
  lName50Chars: 'يجب أن لا يقل اسم العائلة عن 50 حرفًا',
  invalidDate: 'تاريخ غير صالح',
  DOBEarlyThanToday: 'يجب أن يكون تاريخ الميلاد أقدم من اليوم',
  enterValidPhone: 'الرجاء إدخال رقم هاتف صحيح',
  accountStatus: 'حالة الحساب',
  kycVerified: 'لقد تم التحقق من KYC الخاص بك بنجاح.',
  denied: 'رفض',
  chooseFile: 'اختر ملف',
  upload: 'رفع',
  countryCantChange: 'أنت على وشك بدء التحقق من KYC. ',
  proceed: 'يتابع',
  update: ' ',
  history: 'تاريخ',
  type: 'يكتب',
  amount: 'كمية',
  forfeited: 'مصادرة',
  expired: 'منتهي الصلاحية',
  completed: 'مكتمل',
  zeroedOut: 'صفر خارج',
  active: 'نشيط',
  lapsed: 'انقضت',
  yourActiveBonus: 'المكافآت النشطة الخاصة بك',
  currentActiveBonus: 'المكافآت النشطة الحالية',
  bonusStatus: 'حالة',
  bonusWager: 'الرهان',
  bonusActivate: 'تفعيل',
  bonusForfeit: 'مصادرة المكافأة',
  notUsableBonus: 'إذا كانت الإجابة بنعم، فلن تتمكن من استخدام هذه المكافأة مرة أخرى.',
  bonusLapsed: 'لم يكن لديك ما يكفي من الخسائر للحصول على استرداد نقدي. ',
  inProcess: 'تحت المعالجة',
  claiming: 'المطالبة',
  loyaltyPopover: 'يمكن استخدام نقاط الولاء هذه للحصول على استرداد نقدي. ',
  loyaltyPage: 'صفحة الولاء',
  loyaltyPerCurrency: 'الولاء لكل عملة',
  maxLevelReached: 'سوف أزور إندونيسيا بعد شهرين اريد اللقاء معك',
  pointsToReach: 'نقاط للوصول ',
  loyaltyBannerHeading: 'أنت تستحق ولاءنا الجديد',
  loyaltyBannerDesc: 'مكافأة ترحيبية بنسبة 100% تصل إلى 200 دولار',
  loyaltyHeadingOne: 'تتم مكافأتك دائمًا في CashMachine',
  loyaltyHeadingOneDesc: 'في CashMachine، نأخذ الولاء إلى مستوى جديد تمامًا من الإثارة، ونمطرك بالمكافآت في كل منعطف ومنعطف! ',
  loyaltySubHeadOne: 'كسب نقاط الولاء',
  loyaltySubHeadOneDesc: 'مقابل كل 10 يورو من الرهانات النقدية التي يتم وضعها في الكازينو الخاص بنا، سوف تكسب نقطة ولاء واحدة. ',
  loyaltySubHeadTwo: 'تسلق سلم الولاء',
  loyaltySubHeadTwoDesc: 'كلما زاد عدد النقاط التي تجمعها، كلما ارتقت إلى مستويات أعلى عبر مستويات الولاء، مما يفتح المزيد من المكافآت الرائعة على طول الطريق. ',
  loyaltySubHeadThree: 'يصل المستوى، والحصول على مكافأة ',
  loyaltySubHeadThreeDesc: 'ارفع مستوى تجربة الألعاب الخاصة بك من خلال التقدم عبر مستويات المكافآت الجذابة لدينا، حيث يفتح كل مستوى أعلى مجموعة مبهجة من اللفات المجانية لزيادة فرصك في الفوز. ',
  loyaltyTestimonialHeadOne: 'في CashMachine Casino نحن نتأكد من ذلك',
  loyaltyTestimonialHeadTwo: 'كل دورة لها أهميتها',
  loyaltyTestimonialDesc: 'كل دورة تقوم بها في ماكينات القمار ليست من أجل المتعة فحسب، ولكنها أيضًا تجعلك أقرب إلى بعض المكافآت الرائعة! ',
  loyaltyPoints: 'نقاط',
  headerLevel: 'مستوى',
  loyaltyCashback: 'استرداد النقود',
  loyaltyBannerBtn: 'تحقق من التقدم',
  loyaltyHeadingTwo: 'أطلق العنان لتجربة الألعاب المثالية: برنامج CashMachine VIP',
  loyaltyHeadingTwoDesc: 'بمجرد وصولك إلى المستوى 6 في برنامج الولاء الخاص بنا، ستفتح لك عالمًا من الامتيازات الحصرية كعضو في برنامج CashMachine VIP المرموق. ',
  loyaltySubHeadFour: 'حدود أعلى للإيداع والسحب',
  loyaltySubHeadFourDesc: 'استمتع بالمرونة في زيادة حدود الإيداع والسحب كعضو VIP.',
  loyaltySubHeadFive: 'المكافآت الحصرية والترقيات',
  loyaltySubHeadFiveDesc: 'احصل على إمكانية الوصول إلى المكافآت الخاصة والعروض الترويجية المصممة خصيصًا للاعبين VIP لدينا',
  loyaltySubHeadSix: 'مدير الحساب الشخصي',
  loyaltySubHeadSixDesc: 'احصل على دعم مخصص ومساعدة شخصية من مدير حسابك.',
  loyaltySubHeadSeven: 'أوقات سحب أسرع',
  loyaltySubHeadSevenDesc: 'باعتبارك أحد كبار الشخصيات، ستستمتع بأوقات سحب سريعة، مما يضمن إمكانية الوصول إلى أرباحك بشكل أسرع.',
  loyaltySubHeadEight: 'مكافآت الاسترداد النقدي الشهرية',
  loyaltySubHeadEightDesc: 'عزز رصيدك من خلال مكافآت استرداد نقدي شهرية سخية حصريًا لأعضائنا من كبار الشخصيات.',
  loyaltySubHeadNine: 'دعوات للأحداث والبطولات الحصرية',
  loyaltySubHeadNineDesc: 'احصل على الدعوات المرغوبة لحضور الأحداث والبطولات الحصرية، حيث يمكنك الاختلاط مع زملائك من كبار الشخصيات والتنافس للحصول على جوائز مذهلة.',
  loyaltySubHeadTen: 'مكافآت عيد الميلاد',
  loyaltySubHeadTenDesc: 'احتفل بيومك الخاص بأناقة مع مكافأة عيد ميلاد مبهجة خصيصًا لك!',
  loyaltySubHeadEleven: 'الهدايا الفاخرة',
  loyaltySubHeadElevenDesc: 'انغمس في مجموعة رائعة من الهدايا الفاخرة المنتقاة بعناية لمكافأة اللاعبين الأكثر قيمة لدينا.',
  loyaltyTableHeading: 'نظرة عامة على المستويات والمكافآت',
  loyaltyTableHeaderOne: 'مستوى الولاء',
  loyaltyTableHeaderTwo: 'نقاط الولاء',
  loyaltyTableHeaderThree: 'استرداد النقود اليومي',
  promReadMore: 'اقرأ أكثر',
  currentPortalBlock: 'إذا كنت تريد حظر حسابك فقط على هذه البوابة.',
  allPortalBlock: 'لحظر حسابك من جميع البوابات.',
  limit24Reset: 'بمجرد تعيين حدود الرهان والخسارة والإيداع، ستكون قابلة للتعديل والإزالة بعد 24 ساعة ولكن يمكن تقليل الحدود على الفور.',
  enterAmount: 'أدخل المبلغ',
  limit: 'حد',
  loss: 'خسارة',
  takeABreak: 'خذ قسطا من الراحة',
  session: 'حصة',
  selfExclusion: 'الإقصاء الذاتي',
  used: 'مستخدم',
  edit: 'يحرر',
  updatedAt: 'تم التحديث في',
  cannotBeRemoved: 'لا يمكن إزالتها من قبل',
  timePeriod: 'فترة زمنية',
  custom: 'مخصص',
  hours: 'ساعات',
  days: 'أيام',
  timePeriodPlaceholder: 'عدد الأيام',
  months: 'شهور',
  permanent: 'دائم',
  onlyNumbers: 'مسموح بالأرقام فقط',
  notNumbers: 'الأرقام غير مسموح بها',
  userNameAllowed: 'يجب أن تبدأ بالأبجدية. ',
  timePeriodRequired: 'الفترة الزمنية المطلوبة',
  cannotBeIncreased: 'لا يمكن زيادتها من قبل',
  amountGTZero: 'يجب أن يكون المبلغ أكبر من 0',
  amountEqualOrLess: 'يجب أن يكون المبلغ مساوياً أو أقل من الأسبوعي والشهري',
  amountInBetween: 'يجب أن يكون المبلغ بين يومي وشهري (شامل)',
  amountEqualOIrGreater: 'يجب أن يكون المبلغ مساوياً أو أكبر من اليومي والشهري',
  limitRemoved: 'ستتم إزالة الحد التالي الخاص بك.',
  limitSet24Hrs: 'أنت على وشك تعيين الحد التالي لحسابك. ',
  takeABreakInfo: 'أنت على وشك حظر الوصول إلى حسابك لفترة زمنية محددة مسبقًا. ',
  sessionLimitInfo: 'أنت على وشك تعيين الحد التالي لحسابك. ',
  limitCantSetBefore: 'لا يمكن تعيين حد الجلسة الخاصة بك من قبل',
  selfExclusionInfo: 'أنت على وشك حظر الوصول إلى حسابك لفترة زمنية محددة مسبقًا. ',
  quickSearch: 'بحث سريع',
  cash: 'نقدي',
  nonCash: 'غير النقدية',
  userDetail: 'تفاصيل المستخدم',
  gameIdentifier: 'معرف اللعبة',
  rollback: 'التراجع',
  rollbackBeforeBetWin: 'التراجع قبل فوز الرهان',
  freeSpins: 'يدور الحرة',
  betInternal: 'الرهان الداخلي',
  winInternal: 'فوز داخلي',
  addMoney: 'إضافة المال',
  removeMoney: 'إزالة المال',
  addMoneyInternal: 'إضافة أموال داخلية',
  removeMoneyInternal: 'إزالة الأموال الداخلية',
  depositInternal: 'الودائع الداخلية',
  withdrawInternal: 'سحب داخلي',
  promotionTitle: 'عنوان الترويج',
  cancelWithdrawRequest: 'تريد إلغاء هذا الطلب',
  rowsPerPage: 'صفوف لكل صفحة',
  availedBonus: 'لديك بالفعل مكافأة نشطة. ',
  capForfeit: 'مصادرة',
  itFirst: 'أولا.',
  selectYourBonus: 'حدد مكافأتك',
  skip: 'يتخطى',
  with: 'مع',
  without: 'بدون',
  selectPaymentMethod: 'الرجاء اختيار طريقة الدفع الخاصة بك',
  volatility: 'التقلب',
  paylines: 'خطوط الدفع',
  theme: 'سمة',
  tryForFree: 'حاول مجانا',
  resultsFound: 'العثور على نتائج',
  games: 'ألعاب',
  in: 'في',
  low: 'قليل',
  medium: 'واسطة',
  high: 'عالي',
  'medium-high': 'متوسط ​​الارتفاع',
  'very-high': 'عالي جدا',
  'low-medium': 'متوسط ​​منخفض',
  fantasy: 'خيالي',
  ancient_civilizations: 'الحضارات القديمة',
  fruits: 'الفاكهة',
  africa: 'أفريقيا',
  military: 'جيش',
  joker: 'مهرج',
  asia: 'آسيا',
  luxurylife: 'حياة الترف',
  underwater_world: 'العالم تحت الماء',
  book_off: 'الكتاب من',
  western: 'الغربي',
  retro: 'الرجعية',
  egypt: 'مصر',
  party: 'حزب',
  st_patrick_day: 'يوم القديس باتريك',
  space: 'فضاء',
  easter: 'عيد الفصح',
  girls: 'فتيات',
  branded: 'وصفت',
  x_mas_and_new_year: 'عيد الميلاد ورأس السنة الجديدة',
  horrors: 'أهوال',
  other: 'آخر',
  loginFirst: 'يرجى تسجيل الدخول أولا لإضافة اللعبة إلى المفضلة',
  loginFirstPlay: 'الرجاء تسجيل الدخول أولا للعب هذه اللعبة',
  sport: 'رياضة',
  pirates: 'القراصنة',
  sweets: 'حلويات',
  luxury_life: 'حياة الترف',
  st_valentines_day: 'عيد الحب',
  halloween: 'عيد الرعب',
  food: 'طعام',
  lobby: 'ردهة',
  favourite: 'مفضل',
  playNGO: 'العب ن اذهب',
  loyaltySystem: 'نظام الولاء',
  progressionSystem: 'نظام التقدم',
  startPoint: 'نقطة البداية',
  endPoint: 'نقطة النهاية',
  loyaltyBonus: 'مكافأة ولاء',
  pageRemoved: 'هذه الصفحة غير موجودة أو تمت إزالتها',
  suggestToBackHome: 'نقترح عليك العودة إلى المنزل',
  backToHome: 'العودة إلى المنزل',
  goHome: 'اذهب للمنزل',
  oopps: 'عفوًا',
  somethingNotWorking: 'عذرًا، هناك شيء لا يعمل هنا!',
  tournament: 'المسابقة',
  none: 'لا أحد',
  validOnDays: 'صالحة في أيام',
  validTill: 'صالح حتى',
  yourRequest: 'طلبك',
  withdrawalRequestPending: 'لديك طلب سحب واحد في حالة انتظار. ',
  realBalance: 'التوازن الحقيقي',
  bonusInCasino: 'مكافأة في الكازينو. Name',
  bonusSelected: 'تم تحديد المكافأة',
  payWith: 'ادفع عن طريق',
  reached: 'وصل',
  receiptOfYour: 'استلام الخاص بك',
  capEmail: 'بريد إلكتروني',
  capCreditCard: 'رقم البطاقة الائتمانية',
  capExpiry: 'انقضاء',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY ليس بالتنسيق الصحيح',
  min13Numbers: 'الحد الأدنى 13 رقمًا مطلوبًا',
  max19Numbers: 'الحد الأقصى المسموح به هو 19 رقمًا',
  min3Numbers: 'مطلوب 3 أرقام على الأقل',
  max4Numbers: 'الحد الأقصى المسموح به هو 4 أرقام',
  Other: 'آخر',
  forfeit: 'مصادرة',
  withdrawRequestCreated: 'تم إنشاء طلب السحب بنجاح',
  noBetHistory: 'لم يتم العثور على سجل الرهان',
  noPromotions: 'لم يتم العثور على أي عروض ترويجية',
  noBonuses: 'لم يتم العثور على مكافآت',
  noHistory: 'لم يتم العثور على التاريخ',
  noWithdrawalHistory: 'لم يتم العثور على تاريخ السحب',
  noCMS: 'لم يتم العثور على نظام إدارة المحتوى',
  noProvidersFound: 'لم يتم العثور على موفري الخدمة',
  noDataFound: 'لاتوجد بيانات',
  countryBlocked: 'البلد محظور',
  forbidden: 'مُحرَّم',
  notAcceptingVisitors: 'نأسف بشدة لهذا الأمر، ولكن موقع Shinywilds.com غير متوفر في بلدك.',
  contactUsAt: 'إذا كنت تعتقد أن هذا قد ظهر لك عن طريق الخطأ، فيرجى الاتصال بالدعم لدينا عبر البريد الإلكتروني',
  siteUnderMaintenance: 'الموقع تحت الصيانة',
  weWillBeRightBack: 'سوف نعود!',
  serviceUnavailable: 'خدمتنا غير متوفرة مؤقتا. ',
  tryAgainLater: 'الرجاء معاودة المحاولة في وقت لاحق.',
  checkYourEmail: 'تحقق من بريدك الالكتروني',
  sentAVerification: 'لقد أرسلنا رابط التحقق',
  toYourEmail: 'إلى بريدك الإلكتروني.',
  backToLogin: 'العودة إلى تسجيل الدخول',
  goToLogin: 'اذهب إلى تسجيل الدخول',
  accountVerified: 'تم التحقق من الحساب',
  yourAccountVerified: 'تم التحقق من حسابك',
  successfully: 'بنجاح',
  verificationFailed: 'فشل التحقق',
  emailFailedContact: 'فشل التحقق. إذا كان هذا يحدث باستمرار، يرجى الاتصال ',
  setNewPass: 'قم بتعيين كلمة مرور جديدة',
  passResetLink: 'لقد أرسلنا رابط إعادة تعيين كلمة المرور إلى',
  toYourAccount: 'إلى حسابك',
  sendPasswordResetLink: 'طلب إعادة تعيين كلمة المرور',
  gamePlayWagerComplete: 'تهانينا، لقد أكملت الرهان بنجاح وأموال المكافأة التي ربحتها أكبر من الحد الأقصى المسموح به لمبلغ الربح. ',
  real: 'حقيقي',
  demo: 'تجريبي',
  money: 'مال',
  formatRquired: 'مطلوب تنسيق png و.jpg و.jpeg',
  chooseImage: 'الرجاء اختيار صورة',
  imageSize3MB: 'أدخل صورة بحجم أقل من 3 ميغابايت',
  noProfileImage: 'لا توجد صورة للملف الشخصي متاحة للإزالة',
  limitSetSuccess: 'تم تعيين الحد بنجاح',
  accountDisabled: 'تم تعطيل الحساب بنجاح',
  loginSuccess: 'تم تسجيل الدخول بنجاح',
  signupSuccess: 'الاشتراك بنجاح',
  linkExpired: 'انتهت صلاحية الرابط',
  linkSent: 'تم إرسال الرابط',
  accAlreadyVerified: 'تم التحقق من الحساب بالفعل. ',
  profileImage: 'صورة الملف الشخصي',
  profileImageRemoved: 'تمت إزالة صورة الملف الشخصي بنجاح',
  docs: 'وثائق',
  updatedSuccess: 'تم التحديث بنجاح',
  MATCH: 'مباراة',
  BALANCE: 'توازن',
  FREESPINS: 'يدور الحرة',
  MATCH_1: 'مباراة',
  DEPOSIT: 'إيداع',
  WAGERING: 'الرهان',
  PROMOTION: 'ترقية',
  CREDITCARD: 'بطاقة إئتمان',
  WALLET: 'محفظة',
  CRYPTO: 'تشفير',
  OTHER: 'آخر',
  INSTANTBANKING: 'الخدمات المصرفية الفورية',
  cookieTitle: 'بسكويت!',
  declineButton: 'انخفاض',
  acceptButton: 'يقبل',
  cookieDeclinedMessage: 'للحصول على أفضل تجربة ممكنة، يجب قبول ملفات تعريف الارتباط لمواصلة استخدام هذا الموقع. ',
  cookieDescription: 'مرحبا بكم في Shinywilds.com! ',
  forfeitMessageFirst: 'لديك حاليًا المكافأة النشطة التالية:',
  forfeitMessageSecond: 'يتعين عليك التنازل عن هذه المكافأة قبل المطالبة بمكافأة جديدة.',
  forfeitButton: 'مصادرة',
  pleaseVerifyFirstText: 'لكي نتمكن من طلب السحب، نحتاج أولاً إلى التحقق من عنوان بريدك الإلكتروني. ',
  clickHere: 'انقر هنا',
  pleaseVerifyLastText: 'حتى نتمكن من إعادة إرسال رسالة التحقق إليك عبر البريد الإلكتروني والمتابعة.',
  pleseWait: 'يرجى الانتظار ل',
  toResend: 'لإعادة إرسال الرابط',
  notVerified: 'يجب عليك التحقق من عنوان بريدك الإلكتروني لسحب الأموال',
  verification: 'تَحَقّق',
  UPLOAD_IMG_MSG: 'قم بسحب وإفلات الملفات هنا، أو انقر لتحديد الملفات',
  affiliate: 'انضم',
  affiliateTitle: 'إنضم لبرنامج',
  affiliateDescription: ' قم بإحالة علامتنا التجارية واكسب المال من خلال المشاركة في برنامج الشركاء التابعين لماكينات الصراف الآلي! ',
  refferalTitle: 'برنامج الإحالة',
  refferalDescription: 'يمكنك مشاركة رابط الإحالة هذا مع الآخرين وكسب بعض المكافآت إذا قام المستخدم بالتسجيل باستخدام الرابط المحدد الخاص بك',
  affiliateButtonText: 'تصبح تابعة',
  affiliateLink: 'الرابط التابع',
  affiliateStatus: 'تم إرسال طلب الشراكة الخاص بك بنجاح',
  currentStatus: 'والحالة التابعة الحالية هي {{status}}',
  referralLink: 'رابط الإحالة',
  copyTitleMessage: 'يرجى نسخ هذا الرابط',
  copiedMessage: 'تم نسخ الرابط',
  searchedGame: 'لعبة بحثت عنها',
  favoriteGame: 'الألعاب المفضلة لديك',
  randomGames: 'العاب عشوائية',
  poolPrize: 'مجموع جوائز',
  chooseYourWelcomeBonus: 'اختر مكافأة الترحيب الخاصة بك',
  useNow: 'استخدم الان',
  readTheTerms: 'اقرأ الشروط',
  proceedWithoutWelcomeBonus: 'المضي قدما دون مكافأة ترحيب',
  doYouAlreadyHaveAccount: 'هل لديك حساب بالفعل؟ ',
  registerViaSocialNetworks: 'التسجيل عبر الشبكات الاجتماعية',
  iconfirmIm18YearsOlder: 'أؤكد أن عمري 18 عامًا أو أكبر',
  iAgreeWithTermsAndConditions: 'وأنا أتفق مع شروط وأحكام',
  thankYouSuccessfullyRegisteringCashMachine: 'شكرًا لك على تسجيلك بنجاح في ماكينة الصراف الآلي.',
  verifyEmailMessage: 'نطلب منك التحقق من بريدك الإلكتروني بالضغط على رابط التحقق المرسل إلى بريدك الإلكتروني لتسجيل الدخول بنجاح على الموقع.',
  gotIt: 'فهمتها',
  phonecodeRequired: 'مطلوب رمز الهاتف',
  cpNotAllow: 'النسخ/اللصق غير مسموح به',
  playFun: 'لعب المرح',
  playReal: 'لعب حقيقي',
  yourRealBalance: 'رصيدك الحقيقي هو:',
  chooseYourPaymentMethod: 'اختر طريقتك في الدفع',
  balanceHistory: 'تاريخ الرصيد',
  liveChat: 'دردشة مباشرة',
  mail: 'بريد',
  chooseEnterYourDepositAmounts: 'اختر أو أدخل مبالغ الودائع الخاصة بك',
  noBonusDataAvailable: 'لا توجد بيانات مكافأة متاحة',
  nowTryPlayForRealBets: 'حاول الآن اللعب من أجل الرهانات الحقيقية',
  registerAndPlayForRealBets: 'قم بالتسجيل واللعب للحصول على رهانات حقيقية',
  tournamentDataNotAvailable: 'بيانات البطولة غير متوفرة',
  startDate: 'تاريخ البدء',
  endDate: 'تاريخ الانتهاء',
  merchant: 'تاجر',
  seachHere: 'ابحث هنا',
  show: 'يعرض',
  betResults: 'نتائج الرهان:',
  noTransactionsFoundForselectedDate: 'لم يتم العثور على معاملات للتاريخ المحدد',
  loginError: 'خطأ في تسجيل الدخول',
  errorMessageIncorrectlyAuthorization: 'تم إدخال البريد الإلكتروني / تسجيل الدخول أو كلمة المرور بشكل غير صحيح خطأ في التفويض.',
  profileSaved: 'تم حفظ الملف الشخصي',
  addInfo: 'أضف معلومات',
  iHaveReadAndAgreePaymentSystemRestrictions: 'لقد قرأت ووافقت على قيود أنظمة الدفع*',
  paymentOptions: 'خيارات الدفع',
  language: 'لغة',
  betTime: 'وقت الرهان',
  noActiveBonusFound: 'لم يتم العثور على مكافأة نشطة',
  bonusType: 'نوع المكافأة',
  requiredWageringAmount: 'مبلغ الرهان المطلوب',
  currentWageringAmount: 'مبلغ الرهان الحالي',
  validUpto: 'صالحة حتى',
  bonusCode: 'رمز المكافأة',
  wageringProgressBar: 'شريط تقدم الرهان',
  noGamesFound: 'لم يتم العثور على أي ألعاب',
  noteYouMayOptfreeSpin: 'ملحوظة: يمكنك اختيار FREESPIN على أي من هذه الألعاب',
  viewOfferDetails: 'عرض تفاصيل العرض',
  viewFreespinGames: 'عرض ألعاب Freespin',
  depositBonusPercentage: 'نسبة مكافأة الإيداع: ',
  validity: 'صلاحية',
  activated: 'مفعل',
  bonusName: 'اسم المكافأة',
  freeRounds: 'جولات مجانية',
  wagering: 'الرهان',
  ended2: 'انتهى',
  searchBonusTitle: 'البحث عن طريق عنوان المكافأة',
  claimed: 'ادعى',
  noBonusHistoryFoundselectedDates: 'لم يتم العثور على سجل مكافأة للتواريخ المحددة',
  noBonusDetailsFound: 'لم يتم العثور على تفاصيل المكافأة',
  enterCode: 'ادخل الرمز',
  transactionTime: 'وقت الصفقة',
  credit: 'ائتمان',
  debit: 'دَين',
  // bonusInfoOfHeading: 'المكافآت هي مدفوعات إضافية تكافئ أدائك',
  bonusInfoOfHeading: 'المكافآت هي مكافآت إضافية تعترف بنشاطك وإنجازاتك',
  onlineCasino: 'كازينو على الانترنت',
  isTheTop: ' هو الأعلى ',
  bonusInfoPara0: 'هل تبحث عن أفضل ألعاب الكازينو على الإنترنت؟ ',
  tournamentsHistory: 'تاريخ البطولات',

  loginWelcomeBonusInfo: ` ترحب Cash Machine باللاعبين الجدد بمكافأة ترحيبية تصل إلى 100%
   الإيداع + 100 دورة مجانية في لعبة "Pat's Heroes" (Platipus).
   الحد الأدنى لمبلغ الإيداع هو 20 يورو / 900 ريال أرجنتيني / 30 دولار أسترالي / 500 كرونة تشيكية
   / 150 كرونة دنماركية / 200 كرونة نرويجية / 30 دولار نيوزيلندي / 80 زلوتي بولندي / 20 دولار أمريكي / 300 راند جنوب أفريقي / 70 لاري جورجي
   / 9000 تينج / TJS 200 / UZS 200000 / ليرة تركية 130`,

  loginDepositBonusInfo: `الحد الأقصى لمبلغ المكافأة: 100% من مبلغ الإيداع، ولكن ليس أكثر
   أكثر من 100 يورو / 4000 ريال أرجنتيني / 150 دولار أسترالي / 2500 كرونة تشيكية / 750 كرونة دنماركية / 1000 كرونة نرويجية
   / 150 دولار نيوزيلندي / 400 زلوتي بولندي / 100 دولار أمريكي / 1500 راند جنوب أفريقي / 350 لاري جورجي / 45000 تينج كازاخستاني /
   TJS 1000 / UZS 1 000 000 / ليرة تركية 600`,

  loginFreespinBonusInfo: `يتم إصدار اللفات المجانية خلال 5 أيام منذ تفعيل المكافأة،
   20 دورة مجانية يوميا. يتم إضافة جميع الأموال التي تم ربحها في الدورات المجانية إلى
   حساب المكافأة ومتاح للعب/المراهنة
   علاوة. السحب متاح بعد الرهان بـ X40. وقت الرهان الإضافي
   هو 7 أيام.`,
  loginMaximumBetLimitInfo: `الحد الأقصى للرهان أثناء الرهان: 1 يورو / 45 بيزو أرجنتيني / 1.5 دولار أسترالي / كرونة تشيكية
   25 / 7 كرونة دنماركية / 10 كرونة نرويجية / 1.5 دولار نيوزيلندي / 5 زلوتي بولندي / 1 دولار أمريكي / 15 راند جنوب أفريقي / 3.5 لاري جورجي /
   KZT 450 / TJS 10 / UZS 10000 / ليرة تركية 6`,

  bonusInfoPara1: ' ، مع مجموعة كبيرة من الألعاب الرائعة والعروض المميزة المبهرة. تأتي ألعاب القمار الخاصة بنا في مجموعة واسعة من المواضيع، حيث تقدم ألعاب القمار ذات الجوائز الكبرى التقدمية للاعبين فرصة مربحة لتحقيق فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك وقد تم خلطها∂ وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي.',
  bonusInfoPara2: 'تأتي ألعاب القمار الخاصة بنا في مجموعة واسعة من المواضيع، حيث توفر ألعاب القمار ذات الجوائز الكبرى التقدمية فرصة مربحة لتحقيق فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك يتم خلطها وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي. تأتي ألعاب القمار لدينا في مجموعة واسعة من المواضيع، مع ألعاب القمار ذات الجوائز الكبرى التقدمية التي تقدم للاعبين فرصة مربحة للتسجيل فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك يتم خلطها وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي.',
  bonusInfoPara3: 'تأتي ألعاب القمار الخاصة بنا في مجموعة واسعة من المواضيع، حيث تقدم ألعاب القمار ذات الجوائز الكبرى التقدمية للاعبين فرصة مربحة لتحقيق فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك يتم خلطها وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي.',
  bonusInfoPara4: 'تأتي ألعاب القمار الخاصة بنا في مجموعة واسعة من المواضيع، حيث تقدم ألعاب القمار ذات الجوائز الكبرى التقدمية للاعبين فرصة مربحة لتحقيق فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك يتم خلطها وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي. تأتي ألعاب القمار لدينا في مجموعة واسعة من المواضيع، مع ألعاب القمار ذات الجوائز الكبرى التقدمية التي تقدم للاعبين فرصة مربحة للتسجيل فوز كبير. نحن نقدم أيضًا ألعاب الموزع المباشر المثيرة حيث يمكنك رؤية بطاقاتك يتم خلطها وتوزيعها في الوقت الفعلي! غير متأكد من اللعبة المناسبة لك؟ لا تقلق - يمكنك التدرب في الوضع التجريبي أولاً لتتعرف على كل لعبة قبل أن تختار اللعب الحقيقي.',
  cashMachineFooterPara1: 'هل تبحث عن أفضل كازينو على اإلنترنت؟ مرحًبا بكم في',
  cashMachineFooterPara2: 'حيث نجمع بين األلعاب المثيرة والمكافآت السخية والبطوالت الحصرية لنقدم لكم أفضل ترفيه عبر اإلنترنت. هل ترغب في الفوز بالجائزة الكبرى في ماكينات القمار التقدمية لدينا أو المنافسة على الجوائز في البطوالت أو تجربة حظك في األلعاب المباشرة مع الموزعين؟ لدينا كل شيء! ما الذي يجعلنا مميزين؟ في',
  // tournamentDescTitle: 'الكازينو المباشر، فتحات ومسابقات الكازينو',
  cashMachineTitle1: 'CASH MACHINE',
  cashMachineTitle2: 'CASH MACHINE',
  cashMachineTitle3: 'CASH MACHINE CASINO!',
  cashMachineFooterPara3: 'يمكنك الفوز ليس فقط بالجوائز النقدية، ولكن أي ًضا بالجوائز الفعلية! نحن فخورون بأن نكون الخيار المفضل لالعبين حول العالم. هل أنت جاهز لالستكشاف؟ العب أي لعبة متاحة في منطقتك واستمتع باإلثارة التي ال مثيل لها. اختبر األفضل، والعب مع األفضل — فقط في',
  tournamentDescTitle: 'البطوالت هي فرصة رائعة للتنافس مع العبين آخرين والفوز بجوائز سخية',
  tournamentPara1: 'هل تبحث عن أفضل ألعاب الكازينو عبر الإنترنت؟ إذاً لقد أتيت إلى المكان الصحيح.',
  tournamentPara2: ' مع مجموعة رائعة من الألعاب المذهلة والعروض الخاصة الساحرة. تأتي ألعاب الفتحات لدينا في مجموعة واسعة من المواضيع، مع ألعاب الجائزة التقدمية التي توفر للاعبين فرصة مربحة للفوز بجائزة كبيرة. نحن نقدم أيضًا ألعاب موزعي البث المباشر المثيرة حيث يمكنك رؤية البطاقات الخاصة بك واختيارها في الوقت الحقيقي! غير متأكد من أي لعبة تناسبك؟ لا تقلق - يمكنك ممارسة اللعبة في وضع العرض أولاً لتحصل على شعور بكل لعبة قبل أن تختار اللعب بشكل حقيقي.',
  tournamentPara3: ' تأتي ألعاب الفتحات لدينا في مجموعة واسعة من المواضيع، مع ألعاب الجائزة التقدمية التي توفر للاعبين فرصة مربحة للفوز بجائزة كبيرة. نحن نقدم أيضًا ألعاب موزعي البث المباشر المثيرة حيث يمكنك رؤية البطاقات الخاصة بك واختيارها في الوقت الحقيقي! غير متأكد من أي لعبة تناسبك؟ لا تقلق - يمكنك ممارسة اللعبة في وضع العرض أولاً لتحصل على شعور بكل لعبة قبل أن تختار اللعب بشكل حقيقي.',
  'New Games': 'ألعاب جديدة',
  'Popular Games': 'ألعاب شائعة',
  'Games Of The Month': 'ألعاب الشهر',
  Exclusive: 'حصري',
  'Bonus Buy': 'شراء مكافأة',
  'Jackpot Games': 'ألعاب الجائزة الكبرى',
  lightMode: 'وضع النهار',
  darkMode: 'وضع الليل',
  searchProvider: 'مزود البحث',
  tournamentHistoryDataNotAvailable: 'بيانات تاريخ البطولة غير متوفرة',
  viewMore: 'عرض المزيد',
  description: 'وصف',
  codeCopied: 'تم نسخ الكود',
  tournamentGames: 'ألعاب البطولة',
  freeSpinGames: 'ألعاب الدورات المجانية',
  promoCode: 'رمز الترويج',
  paymentNote: 'ملاحظة: يرجى عدم إغلاق النافذة أو تحديثها أثناء معالجة العملية',
  liveGames: 'الألعاب المباشرة',
  play: 'لعب',
  demo1: 'ديمو',
  registration: 'التسجيل',
  confirmation: 'تأكيد',
  forfeitBonusMessage: 'إذا قمت بتفعيل هذه الميزة، فسيتم فقدان أي ميزة قديمة نشطة.',

  bonusConfirmationMessage: 'هل أنت متأكد أنك تريد تفعيل هذه المكافأة؟',
  forfeitRewardMessage: 'إذا قمت بتنشيط هذه المكافأة، فسيتم مصادرة أي مكافأة نشطة قديمة.',
  rewardConfirmationMessage: 'هل أنت متأكد أنك تريد تفعيل هذه المكافأة؟',
  reward: 'جائزة',
  purchasedReward: 'مكافأة تم شراؤها',
  noActiveRewardFound: 'لم يتم العثور على مكافأة نشطة',
  noRewardDetailsFound: 'لم يتم العثور على مكافأة',
  betDate: 'تاريخ الرهان',
  shopDes: 'المتجر هو المكان الذي يمكنك فيه استبدال النقاط التي جمعتها بمكافآت متنوعة، وعروض، وجوائز',
  vipInfoHeading: '.هي عروض حصرية ألكثر العبينا وال ًء VIP مكافآت'
}
