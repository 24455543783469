import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import {
  CustomFormLabel,
  CustomTextField
} from 'components/common-ui/CustomFormInputs/CustomFormInputs';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/layout/ErrorMessage/index';
import { useDispatch, useSelector } from 'react-redux';
import { ReferWrapper } from './style';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { openSuccessToaster } from 'helpers/toaster.helpers';
import { SpinnerSVG } from 'assets/icons/index';
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton';
import { LOADER_HANDLER_TYPES } from 'constants/index';
import { getReferralLink } from 'redux-thunk/thunk/user.thunk';
const { REACT_APP_FRONTEND_BASE_URL } = process.env

const Refer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader);
  const { user } = useSelector((state) => state.user);
  const [referCode, setReferCode] = useState(user?.referCode ? `${REACT_APP_FRONTEND_BASE_URL}/?referCode=${user?.referCode}` : '');

  const handleCopy = () => {
    navigator.clipboard.writeText(referCode).then(() => {
      (openSuccessToaster({ message: t('copiedSuccessfull') }))
    }).catch((error) => {
      console.error('Failed to copy text: ', error);
    });
  };

  const handleRegenerate = () => {
    dispatch(getReferralLink())
  };

  useEffect(() => {
    if (user?.referCode) {
      setReferCode(`${REACT_APP_FRONTEND_BASE_URL}/?referCode=${user.referCode}`);
    }
  }, [user?.referCode]);

  return (
    <>
      <ReferWrapper theme={theme} className='content-wrapper'>
        {referCode ? <Formik
          enableReinitialize
          initialValues={{
            referCode: referCode,
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
          }) => {
            return (
              <Form autoComplete='off'>
                <Box component='form' noValidate>
                  <Grid container spacing={1.25}>
                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('referCode')}</CustomFormLabel>
                      <CustomTextField
                        fullWidth
                        type='text'
                        name='referCode'
                        placeholder={t('referCode')}
                        value={values?.referCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleCopy} edge="end">
                                <ContentCopyIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='referCode'
                        key='referCode'
                        className='error-message'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik> : (
          <Grid item xs={12} lg={12}>
          <Grid container spacing={{ md: 0, xs: 0 }}>
          <Grid item xs={12} lg={12}>
           <h1>{t('noReferCode')}</h1>
           </Grid>
          <Grid item xs={12} lg={6}>
           <Box className='form-btn-box'>
           <CustomPrimaryButton type='submit' onClick={handleRegenerate} disabled={loading} style={{width: '50%'}}>
             {!loading
               ? t('generateReferCode')
               : <SpinnerSVG style={{ fill: 'white' }} />}
           </CustomPrimaryButton>
         </Box>
         </Grid>
         </Grid>
         </Grid>
           )}
      </ReferWrapper>
    </>
  );
};

export default React.memo(Refer);
