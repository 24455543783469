import { createSlice } from '@reduxjs/toolkit'
import { getActiveReward, getAllRewards, getRewardDetail } from 'redux-thunk/thunk/shop.thunk'

const initialState = {
  allRewards: null,
  activeRewards: null,
  rewardDetail: null
}

const {
  // actions: {
  //   // setUserBonus,
  // },
  reducer
} = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    // setUserBonus: (state, action) => ({
    //   ...state,
    //   userBonus: action.payload
    // }),

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRewards.fulfilled, (state, action) => {
        return {
          ...state,
          allRewards: action.payload
        }
      })
      .addCase(getRewardDetail.fulfilled, (state, action) => {
        return {
          ...state,
          rewardDetail: action.payload
        }
      })
      .addCase(getActiveReward.fulfilled, (state, action) => {
        return {
          ...state,
          activeRewards: action.payload,
          bonusLoading: false
        }
      })
  }
})

export default reducer
