import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { BonusConfirmDialogWrapper } from './style'
import { useTranslation } from 'react-i18next'
import { SpinnerSVG } from 'assets/icons/index'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import AlertDialog from 'components/common-ui/AlertDialog/AlertDialog'

const ForfeitConfirmationDialog = ({
  handleActivateBonus,
  claimed,
  isClaimed,
  loading,
  forfeitConfirmationPopup,
  setForfeitConfirmationPopup
}) => {
  const [maxWidth] = React.useState('customModal_sm')
  const { t } = useTranslation()

  return (
    <AlertDialog
      maxWidth={maxWidth}
      dialogOpen={forfeitConfirmationPopup}
      handleDialogClose={() => setForfeitConfirmationPopup(false)}
      aria-labelledby='customized-dialog-title'
    >
      <BonusConfirmDialogWrapper>
        <Box className='dialog-content-wrap'>
          <Box className='bonus-details-wrap'>
            <Box className='text-box'>
              <Typography variant='body1' className='heading-text'>
                {t('bonuses')} {t('confirmation')}
              </Typography>
              <Typography variant='body1' className='text-1'>
                {t('forfeitBonusMessage')}
              </Typography>
              <Typography variant='body1' className='text-1'>
                {t('bonusConfirmationMessage')}
              </Typography>
            </Box>
            {loading
              ? <SpinnerSVG style={{ fill: 'white' }} />
              : (
                <Box className='dialog-button-wrap'>
                  <CustomMainButton
                    onClick={handleActivateBonus}
                    disabled={claimed || isClaimed}
                    className='dialog-button'
                  >
                    {t('yes')}
                  </CustomMainButton>
                  <CustomMainButton
                    onClick={() => setForfeitConfirmationPopup(false)}
                    disabled={claimed || isClaimed}
                  >
                    {t('no')}
                  </CustomMainButton>
                </Box>
                )}
          </Box>
        </Box>
      </BonusConfirmDialogWrapper>
    </AlertDialog>
  )
}

export default ForfeitConfirmationDialog
