import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { ActiveBonusesWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SpinnerSVG } from 'assets/icons/index'
import BonusCard from 'pages/Shop/BonusCard'
import { getActiveReward } from 'redux-thunk/thunk/shop.thunk'
import { defaultCurrency } from 'utils/constant'
import { LOADER_HANDLER_TYPES } from 'constants/index'

const { REACT_APP_S3_URL } = process.env

const PurchasedReward = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { [LOADER_HANDLER_TYPES.content]: contentLoading } = useSelector(state => state.loader)

  const { activeRewards } = useSelector(state => state?.shop)
  const userCurrency = useSelector((state) => state.user?.user)

  useEffect(() => {
    dispatch(getActiveReward())
  }, [])

  const activeBonusElement = (
    <ActiveBonusesWrapper theme={theme} className='content-wrapper'>
      <Box className='offer-wrap'>
        {activeRewards?.userReward?.rows?.length
          ? activeRewards?.userReward?.rows?.map((activeReward, i) => {
              return (
                <BonusCard
                  rewardData={activeReward?.reward}
                  key={activeReward?.id}
                  from='activeReward'
                  activeRewardData={activeReward}
                  userCurrency={userCurrency?.currencyCode || defaultCurrency}
                  rewardId={activeReward?.reward?.id}
                  userRewardId={activeReward?.id}
                  rewardImage={activeReward?.reward?.imageUrl ? `${REACT_APP_S3_URL}/${activeReward?.reward?.imageUrl}` : '/assets/images/stock-images/offers/offer-thumbnail.png'}
                  // submitLoading={submitLoading}
                  // isAuthenticated={isAuthenticated}
                  className='active-bonus'
                />
              )
            })
          : <Typography variant='h3' className='form-title' sx={{ height: '250px' }}>{t('noActiveRewardFound')}</Typography>}
      </Box>
    </ActiveBonusesWrapper>
  )
  return (
    <>
      {contentLoading ? <div style={{ display: 'flex', justifyContent: 'center', height: '350px' }}><SpinnerSVG style={{ fill: 'white' }} /></div> : activeBonusElement}
    </>
  )
}

export default React.memo(PurchasedReward)
