import { Box, styled } from '@mui/material'

export const GameCardWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiCard-root': {
      background: theme.palette.solidColor.transparent,
      borderRadius: theme.spacing(0),
      boxShadow: theme.shadows[0],
      cursor: 'pointer',
      position: 'relative',
      transition: '500ms all',
      '& .MuiCardContent-root': {
        lineHeight: '0',
        padding: theme.spacing(0),
        '& .game-img-box': {
          aspectRatio: '31/44',
          maskImage: 'url("/assets/images/stock-images/game-img-mask.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
          // '& .game-txt-box': {
          //   position: 'absolute',
          //   bottom: 0,
          //   width: '100%',
          //   background: 'linear-gradient(to right, rgba(15, 13, 35, 0.01), rgba(15, 13, 35, 0.6), rgba(15, 13, 35, 0.8), rgba(15, 13, 35, 1))',
          //   paddingBottom: 0,
          //   display: 'flex',
          //   justifyContent: 'flex-end',
          //   alignItems: 'center',
          //   padding: '8px 10px'
          // },
          // '& .game-txt': {
          //   width: '90%',
          //   textAlign: 'right',
          //   color: '#FFDC3A',
          //   fontSize: theme.spacing(0.88),
          //   fontWeight: 400,
          //   lineHeight: 1.1,
          //   fontFamily: theme.typography.fontFamily
          // }
        },
        '& .border-img': {
          width: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        },
        '& .button-overlay': {
          width: '100%',
          background: theme.palette.gradientColor.gameCardOverlay,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'column',
          aspectRatio: '31/44',
          maskImage: 'url("/assets/images/stock-images/game-img-mask.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          opacity: '0',
          padding: theme.spacing(1.75, 1),
          position: 'absolute',
          inset: '0',
          transition: '300ms all',
          '& .favorite-btn-box': {
            zIndex: '100',
            marginLeft: 'auto',
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            '& .favorite-btn': {
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              '& svg': {
                fill: theme.palette.warning.dark
              }
            }
          },
          '& img': {
            width: theme.spacing(5.375),
            height: theme.spacing(5.375),
            display: 'block',
            margin: 'auto',
            transition: '300ms all'
          },
          '& .text-1': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.typographyText.textWhite,
            fontSize: theme.spacing(1.5),
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            textAlign: 'center',
            textShadow: theme.shadows[2]
          },
          '& .text-2': {
            color: theme.palette.typographyText.text_02,
            fontSize: theme.spacing(1.25),
            fontWeight: '600',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            textShadow: theme.shadows[2],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(0.25),
            '& svg': {
              width: theme.spacing(1.25),
              height: theme.spacing(1.25),
              fill: theme.palette.typographyText.text_02,
              filter: `drop-shadow(${theme.shadows[2]})`
            }
          },
          '&:active': {
            '& img': {
              transform: 'scale(0.95)'
            }
          }
        }
      },
      '&:hover': {
        zIndex: '1',
        transform: 'rotate(-3deg)',
        animationName: 'swingCard',
        animationDuration: '1000ms',
        animationDelay: '300ms',
        animationTimingFunction: 'ease-in',
        animationIterationCount: '1',
        transition: '300ms all',
        '@keyframes swingCard': {
          '30%': {
            transform: 'rotate(-2deg)'
          },
          '55%': {
            transform: 'rotate(-3deg)'
          },
          '80%': {
            transform: 'rotate(-2.5deg)'
          },
          '1000%': {
            transform: 'rotate(-3deg)'
          }
        },
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            opacity: '1'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            padding: theme.spacing(1.25, 0.75),
            '& img': {
              width: theme.spacing(3),
              height: theme.spacing(3)
            },
            '& .text-1': {
              fontSize: theme.spacing(0.75)
            },
            '& .text-2': {
              fontSize: theme.spacing(0.75),
              '& svg': {
                width: theme.spacing(0.75),
                height: theme.spacing(0.75)
              }
            }
          }
        }
      }
    }
  }
})

export const GameCardHoverNonWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiCard-root': {
      background: theme.palette.solidColor.transparent,
      borderRadius: theme.spacing(0),
      boxShadow: theme.shadows[0],
      overflow: 'visible',
      cursor: 'pointer',
      position: 'relative',
      transition: '500ms all',
      '& .MuiCardContent-root': {
        lineHeight: '0',
        padding: theme.spacing(0),
        '& .game-img-box': {
          aspectRatio: '31/44',
          maskImage: 'url("/assets/images/stock-images/game-img-mask.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
          // '& .game-txt-box': {
          //   position: 'absolute',
          //   bottom: 0,
          //   width: '100%',
          //   background: 'linear-gradient(to bottom, rgba(15, 13, 35, 0.01), rgba(15, 13, 35, 1), rgba(15, 13, 35, 1))',
          //   height: '40%',
          //   paddingBottom: 0,
          //   display: 'flex',
          //   justifyContent: 'center',
          //   alignItems: 'center'
          // },
          // '& .game-txt': {
          //   width: '70%',
          //   textAlign: 'center',
          //   color: '#FFDC3A',
          //   fontSize: theme.spacing(1.35),
          //   fontWeight: 400,
          //   lineHeight: 1.1,
          //   fontFamily: theme.typography.fontFamily
          // }
        },
        '& .border-img': {
          width: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        }
      },
      '& .MuiCardActions-root': {
        zIndex: '1',
        padding: theme.spacing(0),
        position: 'absolute',
        left: '52%',
        transform: `translate(-48%, ${theme.spacing(-1.938)})`
        // '& .MuiButtonBase-root': {
        //   height: theme.spacing(3),
        //   minHeight: theme.spacing(3),
        //   '& .text': {
        //     fontSize: theme.spacing(1.438),
        //   },
        // },
      },
      '&:hover': {
        zIndex: '1',
        transform: 'rotate(-3deg)',
        animationName: 'swingCard',
        animationDuration: '1000ms',
        animationDelay: '300ms',
        animationTimingFunction: 'ease-in',
        animationIterationCount: '1',
        transition: '300ms all',
        '@keyframes swingCard': {
          '30%': {
            transform: 'rotate(-2deg)'
          },
          '55%': {
            transform: 'rotate(-3deg)'
          },
          '80%': {
            transform: 'rotate(-2.5deg)'
          },
          '1000%': {
            transform: 'rotate(-3deg)'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            padding: theme.spacing(1.25, 0.75),
            '& img': {
              width: theme.spacing(3),
              height: theme.spacing(3)
            },
            '& .text-1': {
              fontSize: theme.spacing(0.75)
            },
            '& .text-2': {
              fontSize: theme.spacing(0.75),
              '& svg': {
                width: theme.spacing(0.75),
                height: theme.spacing(0.75)
              }
            }
          }
        }
      }
    }
  }
})

export const GameCardLargeWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiCard-root': {
      background: theme.palette.solidColor.transparent,
      borderRadius: theme.spacing(0),
      boxShadow: theme.shadows[0],
      cursor: 'pointer',
      position: 'relative',
      transition: '500ms all',
      '& .MuiCardContent-root': {
        lineHeight: '0',
        padding: theme.spacing(0),
        '& .game-img-box': {
          aspectRatio: '427/284',
          maskImage: 'url("/assets/images/stock-images/game-img-mask-large.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
        },
        '& .border-img': {
          width: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        },
        '& .button-overlay': {
          width: '100%',
          background: theme.palette.gradientColor.gameCardOverlay,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'column',
          aspectRatio: '427/284',
          maskImage: 'url("/assets/images/stock-images/game-img-mask-large.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          opacity: '0',
          padding: theme.spacing(1.75, 1),
          position: 'absolute',
          inset: '0',
          transition: '300ms all',
          '& img': {
            width: theme.spacing(5.375),
            height: theme.spacing(5.375),
            display: 'block',
            margin: 'auto',
            transition: '300ms all'
          },
          '& .text-1': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1.5),
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            textAlign: 'center',
            textShadow: theme.shadows[2]
          },
          '& .text-2': {
            color: theme.palette.typographyText.text_02,
            fontSize: theme.spacing(1.25),
            fontWeight: '600',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            textShadow: theme.shadows[2],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(0.25),
            '& svg': {
              width: theme.spacing(1.25),
              height: theme.spacing(1.25),
              fill: theme.palette.typographyText.text_02,
              filter: `drop-shadow(${theme.shadows[2]})`
            }
          },
          '&:active': {
            '& img': {
              transform: 'scale(0.95)'
            }
          }
        }
      },
      '&:hover': {
        zIndex: '1',
        transform: 'rotate(-3deg)',
        animationName: 'swingCard',
        animationDuration: '1000ms',
        animationDelay: '300ms',
        animationTimingFunction: 'ease-in',
        animationIterationCount: '1',
        transition: '300ms all',
        '@keyframes swingCard': {
          '30%': {
            transform: 'rotate(-2deg)'
          },
          '55%': {
            transform: 'rotate(-3deg)'
          },
          '80%': {
            transform: 'rotate(-2.5deg)'
          },
          '1000%': {
            transform: 'rotate(-3deg)'
          }
        },
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            opacity: '1'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            padding: theme.spacing(1.25, 0.75),
            '& img': {
              width: theme.spacing(3),
              height: theme.spacing(3)
            },
            '& .text-1': {
              fontSize: theme.spacing(0.75)
            },
            '& .text-2': {
              fontSize: theme.spacing(0.75),
              '& svg': {
                width: theme.spacing(0.75),
                height: theme.spacing(0.75)
              }
            }
          }
        }
      }
    }
  }
})

export const GameCardLargeHoverNonWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiCard-root': {
      background: theme.palette.solidColor.transparent,
      borderRadius: theme.spacing(0),
      boxShadow: theme.shadows[0],
      overflow: 'visible',
      cursor: 'pointer',
      position: 'relative',
      transition: '500ms all',
      '& .MuiCardContent-root': {
        lineHeight: '0',
        padding: theme.spacing(0),
        '& .game-img-box': {
          aspectRatio: '427/284',
          maskImage: 'url("/assets/images/stock-images/game-img-mask-large.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
        },
        '& .border-img': {
          width: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        }
      },
      '& .MuiCardActions-root': {
        zIndex: '1',
        padding: theme.spacing(0),
        position: 'absolute',
        left: '50%',
        transform: `translate(-50%, ${theme.spacing(-1.938)})`
      },
      '&:hover': {
        zIndex: '1',
        transform: 'rotate(-3deg)',
        animationName: 'swingCard',
        animationDuration: '1000ms',
        animationDelay: '300ms',
        animationTimingFunction: 'ease-in',
        animationIterationCount: '1',
        transition: '300ms all',
        '@keyframes swingCard': {
          '30%': {
            transform: 'rotate(-2deg)'
          },
          '55%': {
            transform: 'rotate(-3deg)'
          },
          '80%': {
            transform: 'rotate(-2.5deg)'
          },
          '1000%': {
            transform: 'rotate(-3deg)'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        '& .MuiCardContent-root': {
          '& .button-overlay': {
            padding: theme.spacing(1.25, 0.75),
            '& img': {
              width: theme.spacing(3),
              height: theme.spacing(3)
            },
            '& .text-1': {
              fontSize: theme.spacing(0.75)
            },
            '& .text-2': {
              fontSize: theme.spacing(0.75),
              '& svg': {
                width: theme.spacing(0.75),
                height: theme.spacing(0.75)
              }
            }
          }
        }
      }
    }
  }
})

export const HeadingGameCardsWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
    '& .heading-box': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.75),
      '& img': {
        width: theme.spacing(4),
        maxWidth: '100%',
        height: 'auto'
      },
      '& .MuiTypography-root, & .MuiTypography-h2': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.typographyText.containerHeading,
        fontSize: theme.spacing(1.5),
        fontWeight: '400',
        lineHeight: 'normal'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .heading-box': {
        '& img': {
          width: theme.spacing(2),
          maxWidth: '100%',
          height: 'auto'
        },
        '& .MuiTypography-root, & .MuiTypography-h2': {
          fontSize: theme.spacing(0.875)
        }
      }
    }
  }
})
