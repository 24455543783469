import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Paper, Typography, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { BonusDialogWrapper } from './style'
import Parser from 'html-react-parser'
import GameGrid from 'pages/GameGrid/index'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CustomTableWrapper } from 'components/common-ui/CustomTable/style'
import MyAccountDialog from 'components/common-ui/MyAccountDialog/MyAccountDialog'
import { SpinnerSVG } from 'assets/icons/index'
import { getRewardDetail } from 'redux-thunk/thunk/shop.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { REWARD_TYPE } from './constant'
import { defaultCurrency } from 'utils/constant'

export const BonusDialog = ({
  viewMore,
  handleViewMore,
  rewardId,
  userCurrency,
  userRewardId,
  from
}) => {
  const theme = useTheme()
  const [maxWidth] = React.useState('customModal_lg')
  const langCode = useSelector((state) =>
    state.language.selectedLanguage?.code?.toUpperCase()
  )

  const dispatch = useDispatch()

  const { [LOADER_HANDLER_TYPES.page]: isFetchingReward } = useSelector(state => state.loader)

  const [value, setValue] = React.useState(0)
  const { t } = useTranslation()

  const { rewardDetail } = useSelector(state => state?.shop)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function CustomTabPanel (props) {
    const { children, value, index, ...other } = props

    return (
      <Box
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    )
  }

  const notfoundElement = (
    <Box style={{ display: 'flex', justifyContent: 'center', padding: '5rem' }}>
      <Typography variant='h5' className='text-7'>
        {t('notFound')}
      </Typography>
    </Box>
  )

  useEffect(() => {
    if (from === 'activeReward') {
      dispatch(getRewardDetail({ rewardId, userRewardId }))
    } else {
      dispatch(getRewardDetail({ rewardId }))
    }
  }, [])

  const tableHeader = []
  const tableData = []
  if (rewardDetail?.rewards?.rewardType === 'freespin') {
    tableHeader.push('Type', 'Required Loyalty Point', 'Freespin Quantity', 'Required Wager Multiplier', 'Spin Rate', 'Wager Time')
    tableData.push(
      REWARD_TYPE[rewardDetail?.rewards?.rewardType],
      rewardDetail?.rewards?.requireLoyaltyPoint,
      rewardDetail?.rewards?.quantity,
      rewardDetail?.rewards?.requiredWagerMultiplier,
      rewardDetail?.rewards?.spinRate,
      rewardDetail?.rewards?.userWagerTime + ' day'
    )
    if (from === 'activeReward') {
      tableHeader.push('Claim Amount', 'Current Wagered Amount')
      tableData.push(
        rewardDetail?.userRewards?.claimAmount,
        rewardDetail?.userRewards?.currentWageredAmount
      )
    }
  } else if (rewardDetail?.rewards?.rewardType === 'cash') {
    const rewardValue = rewardDetail?.rewards?.currency[userCurrency]?.amount ? rewardDetail?.rewards?.currency?.[defaultCurrency]?.amount : 'NA'

    tableHeader.push('Type', 'Required Loyalty Point', 'Cash Amount')
    tableData.push(
      REWARD_TYPE[rewardDetail?.rewards?.rewardType],
      rewardDetail?.rewards?.requireLoyaltyPoint,
      rewardValue
    )
  } else if (rewardDetail?.rewards?.rewardType === 'prize') {
    tableHeader.push('Type', 'Required Loyalty Point', 'Prize')
    tableData.push(
      REWARD_TYPE[rewardDetail?.rewards?.rewardType],
      rewardDetail?.rewards?.requireLoyaltyPoint,
      rewardDetail?.rewards?.prize?.[langCode] || rewardDetail?.rewards?.prize?.EN
    )
  }

  return (
    <MyAccountDialog
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      dialogOpen={viewMore}
      handleDialogClose={() => handleViewMore(false)}
      aria-labelledby='customized-dialog-title'
    >
      {
        isFetchingReward
          ? (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid red' }}>
              <SpinnerSVG style={{ fill: 'white', width: '45px', height: '45px' }} />
            </div>
            )
          : (
            <BonusDialogWrapper>
              <Box className='dialog-content-wrap'>
                <Box className='bonus-details-wrap'>
                  <Box className='text-box'>
                    <Typography variant='body1' className='heading-text'>
                      {rewardDetail?.rewards?.title?.[langCode] || rewardDetail?.rewards?.title?.EN}
                    </Typography>

                    <CustomTableWrapper theme={theme} component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {
                            tableHeader?.map((item, index) => (
                              <TableCell key={index}>{item}</TableCell>
                            ))
                          }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {
                            tableData?.map((item, index) => (
                              <TableCell key={index}>{item}</TableCell>
                            ))
                          }
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CustomTableWrapper>
                  </Box>
                </Box>

                <Box className='more-bonus-card-wrap'>
                  <Box className='more-bonus-card-container'>
                    <Box className='more-bonus-card-box'>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant='fullWidth'
                        aria-label='scrollable force tabs example'
                      >
                        <Tab
                          label={<Box className='inner-box'> {t('description')} </Box>}
                        />

                        {rewardDetail?.rewards?.rewardType === 'freespin' && (
                          <Tab label={<Box className='inner-box'> {t('freespin Games')} </Box>} />
                        )}
                        {rewardDetail?.rewards?.rewardType === 'freespin' && (
                          <Tab label={<Box className='inner-box'> {t('Allowed Wager Games')} </Box>} />
                        )}
                      </Tabs>

                      <Box>

                        <CustomTabPanel value={value} index={0}>
                          {rewardDetail?.rewards?.description
                            ? (
                              <Typography variant='h5' className='text-7'>
                                {rewardDetail?.rewards?.description[langCode]
                                  ? Parser(rewardDetail?.rewards?.description[langCode])
                                  : Parser(rewardDetail?.rewards?.description?.EN)}
                              </Typography>
                              )
                            : (
                                notfoundElement
                              )}
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                          {rewardDetail?.gamesForFreeSpin?.count
                            ? (
                              <>
                                <GameGrid
                                  gameArr={rewardDetail?.gamesForFreeSpin?.rows}
                                  headingImg='/assets/images/stock-images/game-icon-3.png'
                                  headingName='Freespin Games'
                                  hideViewBtn
                                  hideFavorite
                                />
                              </>
                              )
                            : (
                              <Typography variant='h5' className='text-7'>
                                {t('games')} {t('notFound')}
                              </Typography>
                              )}
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={2}>
                          {rewardDetail?.allowedWagerGames?.count
                            ? (
                              <>
                                <GameGrid
                                  gameArr={rewardDetail?.allowedWagerGames?.rows}
                                  headingImg='/assets/images/stock-images/game-icon-3.png'
                                  headingName='Allowed Wager Games'
                                  hideViewBtn
                                  hideFavorite
                                />
                              </>
                              )
                            : (
                              <Typography variant='h5' className='text-7'>
                                {t('games')} {t('notFound')}
                              </Typography>
                              )}
                        </CustomTabPanel>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </BonusDialogWrapper>
            )
}
    </MyAccountDialog>
  )
}
