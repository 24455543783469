import React, { useState } from 'react'
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material'
import { BonusCardWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { InfoCircleIcon } from 'assets/icons/index'
// import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { BonusDialog } from './BonusDialog'
import { useTranslation } from 'react-i18next'
import { setAuthModal } from 'redux-thunk/redux/slices/auth.slice'
import ForfeitConfirmationDialog from './ForfeitConfirmationDialog'
import { REWARD_TYPE, STATUS } from './constant'
import { purchaseReward } from 'redux-thunk/thunk/shop.thunk'
import { formatDateDMY } from 'utils/dateFormatter'

const BonusCard = ({
  activeRewardData,
  rewardData,
  from,
  rewardImage,
  rewardId,
  isClaimed,
  userCurrency,
  submitLoading,
  isAuthenticated,
  userRewardId
}) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const langCode = useSelector((state) =>
    state.language.selectedLanguage?.code?.toUpperCase()
  )
  const dispatch = useDispatch()
  // const [claimed, setClaimed] = useState(isClaimed)
  // const [copySuccess, setCopySuccess] = useState('')
  // const [loading, setLoading] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const [forfeitConfirmationPopup, setForfeitConfirmationPopup] = useState(false)

  const { t } = useTranslation()

  let rewardValue

  if (rewardData?.rewardType) {
    switch (rewardData?.rewardType) {
      case 'freespin':
        rewardValue = 'Freespin Quantity ' + rewardData?.quantity
        break
      case 'cash':
        rewardValue = rewardData?.currency[userCurrency]?.amount ? rewardData?.currency[userCurrency]?.amount : 'NA'
        break
      case 'prize':
        rewardValue = rewardData?.prize?.[langCode] || rewardData?.prize?.EN
        break
      default:
        break
    }
  }

  // const bonusGamesData = {
  //   headingName: `${t('freeSpins')} ${t('games')}`,
  //   headingImg: '/assets/images/stock-images/game-icon-4.png'
  // }

  const onActivateBonusSuccess = () => {
    setForfeitConfirmationPopup(false)
    // setViewMore(true)
    // if (from === 'active') dispatch(getActiveBonuses())
    // else dispatch(getUserBonus())
  }

  const handleActivateBonus = () => {
    if (isAuthenticated) {
      // setLoading(true)
      const data = {
        rewardId: rewardId
      }
      dispatch(purchaseReward({ data, onSuccess: onActivateBonusSuccess }))
    } else {
      dispatch(setAuthModal('signup'))
    }
  }

  // const activeBonuses = (
  //   <Box className='content-wrap'>
  //     <Typography variant='subtitle1' className='text-1'>{rewardData?.title[langCode] || rewardData?.title?.EN}</Typography>
  //     {/* <Typography variant='subtitle2' className='text-2'>{depositBonusPercent > 0 && `${t('depositBonus')} -${depositBonusPercent}%`} {depositBonusPercent > 0 && freeSpinCount > 0 && '+'} {freeSpinCount > 0 && `${t('freeSpins')} -${freeSpinCount}FS`}</Typography> */}

  //     {/* {activeBonusElement} */}
  //     {/* <IconButton className='info-icon' onClick={() => setViewMore(true)}><InfoCircleIcon /></IconButton> */}

  //     {/* {viewGames
  //       ? (
  //           gamesArr?.length
  //             ? (
  //               <GameGrid
  //                 gameArr={gamesArr}
  //                 headingImg={bonusGamesData.headingImg}
  //                 headingName={bonusGamesData.headingName}
  //                 hideViewBtn
  //               />
  //               )
  //             : (
  //               <Typography variant='h3' className='text-4'>
  //                 {t('noGamesFound')}
  //               </Typography>
  //               )
  //         )
  //       : (
  //           activeBonusElement
  //         )}
  //     {viewGames && gamesArr?.length && (
  //       <Typography variant='body1' className='text-4'>
  //         {t('noteYouMayOptfreeSpin')}{' '}
  //       </Typography>
  //     )}
  //     {gamesArr?.length && (
  //       <CustomMainButton
  //         onClick={() => setViewGames(!viewGames)}
  //         className='view-btn'
  //       >
  //         {viewGames ? t('viewOfferDetails') : t('viewFreespinGames')}
  //       </CustomMainButton>
  //     )} */}
  //   </Box>
  // )

  const defaultReward = (
    <Box className='content-wrap'>
      <Typography variant='subtitle1' className='text-1'>{rewardData?.title?.[langCode] || rewardData?.title?.EN}</Typography>
      {/* <Typography variant='subtitle2' className='text-2'>{depositBonusPercent > 0 && `${t('depositBonus')} -${depositBonusPercent}%`} {depositBonusPercent > 0 && freeSpinCount > 0 && '+'} {freeSpinCount > 0 && `${t('freeSpins')} -${freeSpinCount}FS`}</Typography> */}
      <div>
        <Typography variant='subtitle2' className='text-2'>{REWARD_TYPE[rewardData?.rewardType]}</Typography>
        <Typography variant='subtitle2' className='text-2'>{rewardValue}</Typography>
      </div>
      <Typography variant='subtitle2' className='text-2'>Required Loyalty Point: {rewardData?.requireLoyaltyPoint}</Typography>

      {
        from === 'activeReward' && (
          <>
            <Typography variant='subtitle2' className='text-2'>Purchased At: {formatDateDMY(activeRewardData?.purchasedAt)}</Typography>
            {
              (activeRewardData?.status === STATUS.inProcess || activeRewardData?.status === STATUS.claimed)
                ? <Typography variant='subtitle2' className='text-2'>Claim Amount: {activeRewardData?.claimAmount}</Typography>
                : null
            }
          </>
        )
      }

      {
        from !== 'activeReward' &&
          <CustomMainButton
            onClick={() => setForfeitConfirmationPopup(true)}
          >
            {t('purchase')}
          </CustomMainButton>
      }
      <div>
        <IconButton className='info-icon' onClick={() => setViewMore(true)}><InfoCircleIcon /></IconButton>
      </div>
    </Box>
  )

  return (
    <BonusCardWrapper>
      <Box className='bonus-card-wrap'>
        <Box className='bonus-img-box'>
          <Box className='img-box'>
            <img src={rewardImage} alt='Bonus' loading='lazy' />
          </Box>
          <Box className='text-box'>
            {/* <Typography variant='body1'>{rewardData?.title?.[langCode] || rewardData?.title?.EN}</Typography> */}
            {/* <Typography variant='body2'>{depositBonusPercent > 0 && `${depositBonusPercent}%`} <span>{depositBonusPercent > 0 && freeSpinCount > 0 && '+'}{freeSpinCount > 0 && `${freeSpinCount}FS`}</span></Typography> */}
          </Box>
        </Box>

        <Box className='bonus-text-box'>
          {isDesktop
            ? (
              <img
                src='/assets/images/stock-images/bonus-img-border.png'
                className='bonus-img-border'
                alt='Border'
                loading='lazy'
              />)
            : (
              <img
                src='/assets/images/stock-images/bonus-img-border-mobile.png'
                className='bonus-img-border'
                alt='Border'
                loading='lazy'
              />)}
          {defaultReward}
        </Box>
      </Box>
      {viewMore && (
        <BonusDialog
          viewMore={viewMore}
          handleViewMore={setViewMore}
          rewardId={rewardId}
          userCurrency={userCurrency}
          userRewardId={userRewardId}
          from={from}
        />
      )}
      {forfeitConfirmationPopup && (
        <ForfeitConfirmationDialog
          forfeitConfirmationPopup={forfeitConfirmationPopup}
          setForfeitConfirmationPopup={setForfeitConfirmationPopup}
          handleActivateBonus={handleActivateBonus}
          // claimed={claimed}
          // isClaimed={isClaimed}
          loading={submitLoading}
          showFeitMessage={rewardData?.rewardType === 'freespin'}
          showPrizeMsg={rewardData?.rewardType === 'prize'}
        />
      )}
    </BonusCardWrapper>
  )
}

export default BonusCard
