import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  generalDataLoading: false,
  generalData: null,
  siteData: null,
  settingData: null,
  currencyData: null,
  ipData: null,
  sponsorData: null
}

const {
  actions: {
    getGeneralDataSuccess,
    getSiteDataSuccess,
    getIpDataSuccess,
    getSettingDataSuccess,
    getCurrencyDataSuccess,
    getSponsorDataSuccess,
  },
  reducer
} = createSlice({
  name: 'general',
  initialState,
  reducers: {
    getGeneralDataSuccess: (state, { payload }) => {
      return {
        ...state,
        generalDataLoading: false,
        generalData: payload
      }
    },
    getSiteDataSuccess: (state, { payload }) => {
      return {
        ...state,
        siteData: payload
      }
    },

    getCurrencyDataSuccess: (state, { payload }) => {
      return {
        ...state,
        currencyData: payload
      }
    },
    getIpDataSuccess: (state, { payload }) => {
      return {
        ...state,
        ipData: payload
      }
    },
    getSettingDataSuccess: (state, { payload }) => {
      return {
        ...state,
        settingData: payload
      }
    },
    getSponsorDataSuccess: (state, {payload}) => {
      return{
        ...state,
        sponsorData: payload
      }
    }
  }
})

export default reducer
export {
  getGeneralDataSuccess,
  getSiteDataSuccess,
  getSettingDataSuccess,
  getCurrencyDataSuccess,
  getIpDataSuccess,
  getSponsorDataSuccess
}
