import React from 'react'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { Slider3DWrapper } from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import GameCardLargeHoverNon from 'pages/GameCard/GameCardLargeHoverNon'
import SectionHeading from 'components/common-ui/SectionHeading/SectionHeading'
import HeadingGameCards from 'pages/GameCard/HeadingGameCards'
import Jackpot from 'pages/Jackpot/index'

const Slider3D = ({ gameSliderArr, headingName, metaData, headingImg, showJackpot }) => {
  const theme = useTheme()

  return (
    <Slider3DWrapper theme={theme}>
      <Box className='slider-3d-wrap'>
        {metaData?.textAnimation
          ? <SectionHeading headingNameNeon={headingName} id='game3dslider' hideViewBtn />
          : <HeadingGameCards headingImg={headingImg} headingName={headingName} hideViewBtn />}
        {showJackpot && <Jackpot />}

        <Box className='slider-3d-box'>
          <Swiper
            modules={[Autoplay, EffectCoverflow]}
            effect='coverflow'
            grabCursor
            centeredSlides
            loop
            slidesPerView='2'
            slideShadows={false}
            spaceBetween={100}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 40,
              depth: 52,
              modifier: 5,
              slideShadows: false
            }}
            breakpoints={{
              992: {
                coverflowEffect: {
                  rotate: 0,
                  stretch: 40,
                  depth: 52,
                  modifier: 5,
                  slideShadows: false
                }
              },
              768: {
                slidesPerView: '2',
                coverflowEffect: {
                  rotate: 0,
                  stretch: 40,
                  depth: 52,
                  modifier: 5,
                  slideShadows: false
                }
              },
              540: {
                slidesPerView: '1.5',
                coverflowEffect: {
                  rotate: 0,
                  stretch: 40,
                  depth: 52,
                  modifier: 5,
                  slideShadows: false
                }

              },

              450: {
                slidesPerView: '1.35',
                coverflowEffect: {
                  rotate: 0,
                  stretch: 50,
                  depth: 62,
                  modifier: 7.5,
                  slideShadows: false
                }
              },
              0: {
                slidesPerView: '1.25',
                coverflowEffect: {
                  rotate: 0,
                  stretch: 50,
                  depth: 52,
                  modifier: 7.5,
                  slideShadows: false
                }
              }
            }}
            className='mySwiper'
          >
            {gameSliderArr.map(({ thumbnailUrl, id, casinoGameId }) => (
              <SwiperSlide key={id}>
                <GameCardLargeHoverNon gameImg={thumbnailUrl} gameCode={casinoGameId} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Slider3DWrapper>
  )
}
export default Slider3D
